.resourceSection {
    background-image: url(../../img/banner/aakashganga_bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #131518;

    & .resourceWrap {
        position: relative;
        &.playListHead{
            & h1 {
                font-weight: 500;
                padding-left: 0;
                margin-bottom: 30px;
                &::before {
                    display: none;
                }
            }
        }

        & h1 {
            text-align: left;
            font-size: 30px;
            color: $primary;
            font-weight: 400;
            letter-spacing: -2px;
            position: relative;
            padding-left: 58px;
            margin-bottom: 10px;

            @media #{$desktop} {
                font-size: 46px;
                padding-left: 90px;
                margin-bottom: 0;
            }

            &::before {
                content: "";
                width: 50px;
                height: 20px;
                background-image: url('../../img/icons/wave.svg');
                background-repeat: no-repeat;
                background-size: contain;
                display: block;
                position: absolute;
                left: 0;
                top: 10px;

                @media #{$tablet} {
                    width: 80px;
                    height: 28px;
                    top: 16px;
                }
            }

            & span {
                font-weight: 600;
                position: relative;
            }
        }


        & p {
            font-size: 16px;
            color: $dark-400;
            padding-top: 10px;
            text-align: justify;

            @media #{$desktop} {
                font-size: 20px;
                text-align: left;
            }
        }

        & .searchWrap {
            position: relative;
            margin-top: 15px;
    
            @media #{$desktop} {
                margin-bottom: 0;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                margin-top: 0;
            }
    
            & input {
                padding: 9px 16px;
                border-radius: 10px;
                border: 1px solid #E5E7F3;
                font-size: 16px;
                font-weight: 400;
                color: $dark;
                width: 100%;
                background-color: #E5E7F3;
                padding-left: 40px;
                margin-bottom: 0;
    
                @media #{$desktop} {
                    width: auto;
                    padding: 9px 20px;
                    padding-left: 40px;
                }
            }
    
            & b {
                position: absolute;
                left: 12px;
                top: 50%;
                transform: translateY(-50%);
                color: $primary;
                font-size: 18px;
            }
        }
    }


    & .resourceBox {
        width: 100%;
        height: 100%;
        background-color: $light;
        padding-bottom: 20px;
        position: relative;
        overflow: hidden;
        transition: all 800ms ease-in-out;

        &:before {
            content: "";
            width: 100%;
            height: 20px;
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
        }

        &.borderBox {
            border-radius: 0 30px 0 30px;
            margin-bottom: 20px;

            @media #{$desktop} {
                margin-bottom: 0;
            }

            &:before {
                background-color: #FFD165;
            }
        }


        &.yellow {
            &:before {
                background-color: #FFD165;
            }
        }

        &.green {
            &:before {
                background-color: #C1D878;
            }
        }

        &.orange {
            &:before {
                background-color: #E98654;
            }
        }


        &-text {
            padding: 15px 20px;
            text-align: center;

            @media #{$desktop} {
                padding: 15px 35px 20px 35px;
            }

            & h3 {
                font-size: 16px;
                font-weight: 700;
                color: $primary;

                @media #{$desktop} {
                    font-size: 18px;
                }
            }

            & p {
                font-size: 14px;
                font-weight: 400;
                color: $dark;
                line-height: 1.5;
                padding: 10px 0;
                margin-bottom: 15px;
                text-align: justify;
                letter-spacing: -.45px;
            }
            & .button {
                font-size: 16px !important;
                padding: 14px 20px !important;
                border-radius: 15px !important;
                @media #{$desktop} {
                    font-size: 26px !important;
                    padding: 17px 28px !important;
                    border-radius: 20px !important;  
                }
            }
        }
    }

    & .resourceList {
        padding: 40px 0;

        & ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -15px;

            @media #{$desktop} {
                flex-wrap: nowrap;
            }

            & li {
                list-style: none;
                padding: 15px;
                width: 100%;

                @media #{$desktop} {
                    padding: 0 15px;
                }


            }
        }
    }

    & .pptList {
        padding: 15px 0;

        & ul {
            display: flex;
            margin: 0 auto;
            flex-wrap: wrap;

            @media #{$desktop} {
                margin: 0 -30px;
            }

            & li {
                list-style: none;
                padding: 15px;
                width: 100%;

                @media #{$desktop} {
                    padding: 30px 26px;
                    width: 33.3%;
                }
            }
        }
    }

    & .pptBox {
        width: 100%;
        height: 100%;
        position: relative;
        transition: all 800ms ease-in-out;

        &-icon {
            width: 136px;
            height: 136px;
            border-radius: 50%;
            overflow: hidden;
            border: 1px solid transparent;
            margin: 0 auto;
            display: block;
            margin-bottom: -73px;
        }


        &-text {
            padding: 15px 20px;
            padding-top: 100px;
            text-align: center;
            background-color: $light;
            box-shadow: 0px 0px 14px rgba(0, 155, 148, 0.1);
            border-radius: 20px;

            @media #{$desktop} {
                padding: 25px;
                padding-top: 100px;
            }

            & h3 {
                font-size: 16px;
                font-weight: 600;
                color: $primary;

                @media #{$desktop} {
                    font-size: 18px;
                }
            }

            & p {
                font-size: 14px;
                font-weight: 400;
                color: $dark;
                line-height: 1.5;
                text-align: center;
                margin-bottom: 15px;
                padding: 8px 0;

                @media #{$desktop} {
                    font-size: 14px;
                    text-align: left;

                }
            }
        }
    }


    & .videoList {
        padding: 15px 0;

        & ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 -20px;


            & li {
                list-style: none;
                padding: 20px 20px;
                width: 100%;

                @media #{$desktop} {
                    padding: 30px 20px;
                    width: 33.3%;

                }
            }
        }
    }


    & .videoBox {
        width: 100%;
        height: 100%;
        background-color: $light;
        padding-bottom: 15px;
        position: relative;
        overflow: hidden;
        transition: all 800ms ease-in-out;
        box-shadow: 0px 0px 14px rgba(0, 155, 148, 0.1);

        &:before {
            content: "";
            width: 100%;
            height: 15px;
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
        }

        &.borderBox {
            border-radius: 0 30px 20px 20px;

            @media #{$desktop} {
                margin-bottom: 0;
            }

            &:before {
                background-color: #FFD165;
            }
        }


        &.yellow {
            &:before {
                background-color: #C1D878;
            }
        }

        &.green {
            &:before {
                background-color: #C1D878;
            }
        }

        &.orange {
            &:before {
                background-color: #C1D878;
            }
        }


        &-text {
            padding: 15px 20px;
            text-align: center;
            position: relative;

            @media #{$desktop} {
                padding: 15px 25px;
            }

            & h3 {
                font-size: 18px;
                font-weight: 600;
                color: $secondary;
                padding-bottom: 5px;

                @media #{$desktop} {
                    font-size: 20px;
                }
            }

            & a {
                font-size: 18px;
                font-weight: 600;
                color: #009B94;
                padding-bottom: 5px;
                text-decoration: none;

                @media #{$desktop} {
                    font-size: 20px;
                }
            }

            & small {
                position: absolute;
                right: 15px;
                bottom: 5px;
                font-size: 12px;
                color: $dark-400;

            }

        }
    }
}

.resourceBtn {
    & .button{
        text-transform: uppercase;
        font-size: 20px;
        @media #{$desktop} {
            font-size: 30px; 
        }
    }
}



.playListWrap {
    & ul {
        & li{
            padding: 20px; 
            list-style-type: none;
            background: rgba(91, 171, 186, 0.17);
            margin-bottom: 14px;
            &:nth-child(2) {
                background: rgba(156, 161, 240, 0.16);
            }
            &:nth-child(3) {
                background: rgba(255, 176, 27, 0.17);
            }
            &:nth-child(4) {
                background: rgba(236, 194, 143, 0.17);
            }
            &:nth-child(5) {
                background: rgba(153, 206, 53, 0.17);
            }
            &:nth-child(6) {
                background: rgba(255, 202, 0, 0.17);
            }
            & .playListBox {
                display: inline-flex;
                width: 100%;
                @media #{$mobile-only} {
                    display: block;
                }
                & span {
                    display: inline-block;
                    width: 190px;
                    
                    vertical-align: top;
                    @media #{$mobile-only} {
                        text-align: center;
                        width: 100%;
                    }
                    & img {
                        max-width: 100%;
                    }
                }
                & .playList-text {
                    position: relative;
                    display: inline-block;
                    width: calc(100% - 190px);
                    vertical-align: top;
                    padding-left: 15px;
                    padding-bottom: 40px;
                    @media #{$mobile-only} {
                        width: 100%;
                        padding-left: 0;
                        padding-top: 10px;
                    }
                    & h3 {
                        font-weight: 700;
                        font-size: 42px;
                        line-height: 51px;
                        color: #006A93;
                        @media #{$mobile-only} {
                            font-size: 25px;
                            line-height: 33px;  
                        }
                    }
                    a {
                        text-decoration: none;
                        cursor: pointer;
                        font-weight: 700;
                        font-size: 42px;
                        line-height: 51px;
                        color: #006A93;
                    }
                    & p{
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 17px;
                        color: #000000;
                        line-height: 24px;
                    }   
                    & small {
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 17px;
                        color: #404040;
                        position: absolute;
                        left: 16px;
                        bottom: 0;
                        @media #{$mobile-only} {
                            left: 0;
                        }
                    }  
                    & .playList-link {
                        background: #009B94 url(../../img/icons/playArrow.svg) center no-repeat;
                        width: 50px;
                        height: 50px;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        border-radius: 50%;
                        @media #{$mobile-only} {
                            right: -8px;
                            bottom: -8px;
                        }
                    }         
                }
            }
        }
    }
}

.error-footer {
    & .ag_redline_wrap {
        margin-top: 0;
    }
}
