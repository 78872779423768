// Body Reset
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
}

html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

body {
    font-family: $familyDefault, $fallbackfont;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    font-variant-ligatures: none;
    font-feature-settings: "liga", 0;
    color: #000;
    background-color: #F0F0F0;
}

ul,
ol {
    margin-bottom: 0;
    padding: 0;
}

img {
    max-width: 100%;
    border: none;
}

button,
input,
select,
textarea {
    appearance: none;
    font-family: $familyDefault, $fallbackfont;

    &:focus {
        outline: none;
        box-shadow: none;
    }
}

textarea {
    resize: vertical;
    min-height: 60px;
    max-height: 240px;
}

a {
    color: $primary;
}

[role="button"] {
    cursor: pointer;
}

button::-moz-focus-inner {
    border: 0;
}

input[type="search"] {
    box-sizing: border-box;
    box-shadow: none;
    appearance: none;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"] {
    box-shadow: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

select {
    appearance: none;
    position: relative;
    cursor: pointer;
}

select::-ms-expand {
    display: none;
}

input[type="checkbox"][readonly]+label {
    pointer-events: none;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
}

.hidden {
    display: none;
}

.text-normal {
    text-transform: none !important;
}

.text-dbl {
    font-size: 1.125rem !important;
}

.remove-gutters {
    padding-left: 0;
    padding-right: 0;
}

.mobileonly {
    @media #{$desktop} {
        display: none !important;
    }
}

.tabletonly {
    @media #{$desktop-lg} {
        display: none !important;
    }
}

.desktoponly {
    display: none !important;

    @media #{$tablet} {
        display: inline-block !important;
    }
}

.col-form-label {
    &.onlylabel {
        color: #999;
        font-size: 1rem;
        font-weight: normal;
        display: block;
        text-align: center;

        @media #{$desktop} {
            padding: 1.25rem 1rem;
            padding-right: 0;
            padding-bottom: 0;
        }
    }
}

.hr-dashed {
    border-style: dashed;
    border-color: rgba(#000, .25);
    width: 100%;
}

.fileInput,
.hideInput {
    width: 0;
}

 

.mobile-text-center {
    @media #{$mobile-only} {
        text-align: center !important;
    }
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-ntv {
    color: $dark-900 !important;
}

.text-secondary {
    color: $secondary !important;
}

.font-bold {
    font-weight: 700;
}

 

[data-type="ebook"] {
    color: $blue !important;
}

[data-type="new"] {
    color: $green !important;
}

[data-type="used"] {
    color: $gray !important;
}
[data-type="rental"] {
    color: $primary !important;
}

p {
    margin: 0;
}