/*---- Responsive CSS Start ----*/

@media only screen and (max-width: 1700px) {
  .ag_publishjournal_text p {
    margin: 35px 0;
     
  }

  // .nation-img img {
  //   text-align: center;
  //   height: 636px;
  //   max-width: 612px;
  // }

 

  .choosing-main-text {
    max-width: 754px;
  }
}

@media only screen and (max-width: 1500px) {
  .nation-img img {
    text-align: center;
    // height: 550px;
    // max-width: 529px;
  }

  .choosing-main-text {
    max-width: 514px;
  }
//   .ag_footer_wrap p, .ag_footer_wrap a {
//     font-size: 13px !important;
// }

}

@media only screen and (max-width: 1400px) {
  .nation-img img {
    text-align: center;
    // height: 544px;
    // max-width: 528px;
  }

  .nation-right-col .pera-text {
    font-size: 16px;
  }

  .choosing-main-text {
    max-width: 440px;
  }
}

@media only screen and (max-width: 1300px) {
  .nation-img img {
    text-align: center;
    height: 530px;
    max-width: 487px;
  }

  .nation-right-col .pera-text {
    font-size: 14.5px;
  }

  .choosing-main-text {
    max-width: 354px;
  }
}

@media (max-width: 1600px) {
  .container-full .ag_heading_center_wrap span::after {
    top: 2px;
    left: 1px;
  }

  body {
    font-size: 16px;
  }

  .ag_menu>ul {
    margin-right: 0px;
  }

  .ag_header_wrap.fixed .ag_menu>ul {
    margin-right: 20px;
  }

  .ag_menu>ul>li {
    margin: 0 20px;
  }

  .ag_menu .ag_btn {
    margin-left: 30px;
  }

  .ag_header_wrap.fixed .ag_menu .ag_btn {
    margin-left: 20px;
  }

  .ag_header_wrap.fixed .ag_menu>ul>li>a {
    padding: 20px 0px;
  }

  .ag_special_offer_img p {
    font-size: 30px;
    width: 83%;
  }
 


  .ag_howworks_btn {
    margin-top: -120px;
  }

  .ag_resourcehub_wrap {
    padding-top: 50px;
  }

  .ag_resourcehub_wrap .row {
    padding: 0 50px;
  }

  .ag_header_wrap.fixed .ag_btn {
    font-size: 16px;
    padding: 0px 15px;
    min-width: 130px;
    height: 43px;
  }

  // .ag_content_text .ag_btn_red {
  //   margin-bottom: 3px;
  //   width: 360px;
  //   font-size: 20px;
  //   height: 44px;
  // }

  .ag_content_text p {
    font-size: 17px;
  }

  .ag_opportunities_wrap .col-xl-4 {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  .ag_opportunities_box {
    padding: 15px;
  }

  .ag_opportunities_box h3{
    font-size: 18px;
  }
  .ag_opportunities_box h4 {
    font-size: 18px;
    font-weight: 500;
  }

  .ag_opportunities_box p,
  .ag_opportunities_box a {
    font-size: 18px;
  }

 

  .ag_special_offer_btn .ag_btn_red {
    width: 330px;
    height: 48px;
    font-size: 20px;
  }

  .ag_special_offer_btn p {
    font-size: 14px;
    margin-top: 5px;
  }

  // .ag_heading_wrap h2,
  // .ag_heading_center_wrap h2,
  // .ag_heading_right_wrap h2,
  // .ag_partners_wrap .ag_heading_center_wrap h2,
  // .ag_partners_wrap .ag_heading_center_wrap h2 span {
  //   font-size: 37px;
  //   line-height: 50px;
  // }

  // .ag_heading_wrap span,
  // .ag_heading_center_wrap span,
  // .ag_heading_right_wrap span,
  // .ag_special_offer_wrap .ag_heading_wrap h2,
  // .ag_publishjournal_wrap .ag_heading_wrap h2 {
  //   font-size: 25px;
  //   line-height: normal;
  // }

  // .ag_special_offer_text {
  //   padding: 30px;
  // }

  // .ag_megajournals_text p {
  //   font-size: 21px;
  // }

  // .ag_megajournals_text p b {
  //   font-size: 18px;
  // }

  /*.ag_megajournals_img_boxes ul li img {
        height: 43px;
    }*/
  .ag_livesign {
    width: 36px;
    height: 36px;
    padding: 5px;
    left: -18px;
  }

  .ag_livesign span {
    width: 65px;
    height: 26px;
    font-size: 18px;
  }

  .ag_megajournals_img_boxes ul li .ag_megajournals_img:hover .ag_livesign span,
  .ag_megajournals_img_boxes ul li .ag_megajournals_img_right:hover .ag_livesign span {
    top: -40px;
  }

  .ag_megajournals_wrap .ag_btn_red {
    width: 300px;
    height: 48px;
    font-size: 20px;
  }

  // .ag_publishjournal_text p {
  //   font-size: 16px;
  // }

  .ag_publishjournal_text .ag_btn_red {
    width: 300px;
    height: 48px;
    font-size: 20px;
  }

  .ag_howworks_btn .ag_btn_red {
    width: 330px;
    height: 48px;
    font-size: 20px;
  }

  .ag_howworks_btn p {
    font-size: 14px;
  }

  .ag_resourcehub_box {
    padding: 20.5px;
  }

  .ag_resourcehub_box p,
  .ag_resourcehub_box a {
    font-size: 20px;
  }

  .ag_resourcehub_box p {
    min-height: 100px;
  }

  .ag_resourcehub_box h3 {
    font-size: 22px;
    width: 205px;
    padding: 10px 10px 10px 25px;
  }

  .ag_resourcehub_wrap .col-xl-6 {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

 

  // .ag_redline_wrap h2 {
  //   font-size: 26px;
  // }

  .ag_ftr_box h4 {
    font-size: 20px;
  }

  .ag_footer_wrap p,
  .ag_footer_wrap a {
    font-size: 17px;
  }

  .ag_copyright_wrap p,
  .ag_copyright_wrap a { 
    font-size: 14px;
  }
  .ag_special_offer_img .ag_big_img {
    height: 637px;
  }

  .ag_publishjournal_img img {
    height: 610px;
  }

  .ag_newsletter_input {
    padding: 8px;
  }

  // .ag_newsletter_input input {
  //   height: 40px;
  //   font-size: 15px;
  // }

  .ag_newsletter_input button img {
    height: 40px;
  }

  .ag_newsletter_input input::placeholder {
    font-size: 16px;
  }

  .ag_newsletter_input input:-ms-input-placeholder {
    font-size: 16px;
  }

  .ag_newsletter_input input::-ms-input-placeholder {
    font-size: 16px;
  }

  .ag_menu>ul>li>a {
    font-size: 13px;
  }
}

@media (max-width: 1366px) {
  .sell-banner-text .title span::before {
    top: 5px;
  }

  .howitworks {
    background-size: contain;
  }

  .about-sec img.sell-logo {
    max-width: 525px;
    margin-left: 0;
  }
 

  .ag_menu>ul>li ul li a {
    font-size: 14px;
  }

  .ag_menu>ul>li ul li {
    display: inline-block;
    width: 100%;
  }

  .sub-lines {
    font-size: 13px;
  }

  .ag_special_offer_img .ag_big_img {
    height: 517px;
  }

  .ag_publishjournal_text p {
    font-size: 16px;
  }
}

@media (max-width: 1306px) {
  .howitworks {
    background-size: contain;
  }

  .ag_publishjournal_img img {
    height: 594px;
  }

  .ag_publishjournal_text p {
    font-size: 15px;
  }

  .ag_megajournals_text p {
    font-size: 18px;
  }

  .ag_megajournals_text p b {
    font-size: 15px;
    font-weight: 700;
  }

  .ag_menu>ul>li>a {
    font-size: 14px;
  }

  .ag_menu .ag_btn,
  .ag_header_wrap.fixed .ag_menu .ag_btn {
    margin-left: 20px;
  }
  .ag_logo img {
    height: 50px;
}
  .ag_menu>ul,
  .ag_header_wrap.fixed .ag_menu>ul {
    margin-right: 0px;
  }

  .ag_menu>ul>li,
  .ag_header_wrap.fixed .ag_menu>ul>li {
    margin: 0 5px;
  }

  .ag_menu>ul>li>a {
    padding: 20px 0px;
    font-size: 14px;
  }

  .ag_special_offer_img p {
    font-size: 24px;
  }

  .ag_special_offer_btn {
    width: 100%;
  }

  .ag_resourcehub_box p,
  .ag_resourcehub_box a {
    font-size: 16.5px;
  }

  .ag_resourcehub_box p {
    min-height: 79px;
  }

  .ag_opportunities_box h3,
  .ag_opportunities_box h4 {
    font-size: 17px;
  }

  .ag_opportunities_box p,
  .ag_opportunities_box a {
    font-size: 17px;
  }

  .ag_heading_wrap span,
  .ag_heading_center_wrap span,
  .ag_heading_right_wrap span,
  .ag_special_offer_wrap .ag_heading_wrap h2,
  .ag_publishjournal_wrap .ag_heading_wrap h2 {
    font-size: 26px;
  }

  .ag_special_offer_text p {
    font-size: 16px;
  }

  body {
    font-size: 16px;
  }

  .ag_heading_wrap h2,
  .ag_heading_center_wrap h2,
  .ag_heading_right_wrap h2,
  .ag_partners_wrap .ag_heading_center_wrap h2,
  .ag_partners_wrap .ag_heading_center_wrap h2 span {
    font-size: 36px;
    line-height: 40px;
  }

  .ag_special_offer_img p img {
    top: -5px;
    width: 53px;
  }

  .ag_special_offer_img .ag_big_img {
    height: 480px;
  }

  .ag_banner_wrap {
    padding: 87px 0 0 0;
  }
}

@media (max-width: 1200px) {

  .ag_btn,
  .ag_btn:focus,
  .ag_btn_red,
  .ag_btn_red:focus {
    font-size: 14px;
    padding: 0px 15px;
    min-width: 120px;
    height: 50px;
  }

 

  .register-button button,
  .register-button a {
    padding: 10px 30px;
  }
}

@media (max-width: 1180px) {
  .ag_header_bottom .col-lg-3.col-md-4.col-9 {
    width: 18%;
  }

  .ag_header_bottom .col-lg-9.col-md-8.col-3 {
    width: 82%;
  }

  .ag_header_bottom .ag_btn,
  .ag_header_wrap.fixed .ag_btn {
    font-size: 14px;
    padding: 0px 12px;
    min-width: 130px;
    height: 42px;
  }

  .ag_menu>ul>li>a {
    padding: 20px 0px;
    font-size: 8px !important;
  }

  .ag_content_text .ag_btn_red {
    margin-bottom: 10px;
    width: 290px;
    font-size: 16px;
    height: 48px;
    margin: 0 auto 10px auto;
  }

  .ag_banner_video {
    padding-bottom: 60%;
  }
}

@media (max-width: 1060px) {
  .ag_header_bottom .container {
    padding: 0 20px;
}
}
@media (max-width: 1024px) {
  .videoembed {
    top: 20px;
  }

  .container {
    padding: 0 20px;
  }

  html,
  body {
    overflow-x: hidden !important;
  }

  .university-title {
    font-size: 24px;
    line-height: 32px;
  }

  .ag_header_bottom .container {
    padding: 0 20px;
  }

  .row.quiz {
    max-width: 90%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 992px) {
  .banner-logo img {
    max-width: 400px;
  }

  .banner-logo {
    top: 160px;
  }

  .ag_menu>ul>li>a {
    font-size: 10px;
  }

  .ag_header_bottom .container {
    max-width: 100%;
    padding: 0px 15px;
  }

  .ag_special_offer_wrap .col-lg-5 {
    margin: 15px 15px 0 15px;
    width: calc(100% - 30px);
  }

  .ag_main_wrap.ag_newways_wrap .col-4.align-items-center.aos-init.aos-animate {
    width: 100%;
    margin-bottom: 80px;
  }

  .container {
    max-width: 100%;
    padding: 0 30px;
  }

  .content-area .large-title {
    font-size: 26px !important;
    margin: 35px 0 25px 0;
    padding-bottom: 20px;
  }

  .banner-section .page-title {
    max-width: 80%;
  }

  .content-area .italic-title.blue-text {
    font-size: 24px !important;
  }

  .click-here-shastra button {
    width: auto;
  }

  .box-content.box-with-small-content-center .box-inner {
    text-align: center;
    max-width: 100%;
    margin: 0 auto;
  }

  .register-button button {
    font-size: 20px;
  }

  .banner-section .title span::before {
    top: 5px;
    left: 2px;
  }

  img.icon-oimage {
    max-width: 130px;
    margin-bottom: 15px;
  }

  .content-area .small-title {
    font-size: 20px;
  }

  .table-content td {
    font-size: 18px;
  }

  .row.quiz .col-md-4 img {
    max-width: 300px;
    bottom: -50px;
    top: auto;
  }
}

@media (max-width: 991px) {
  .ag_menu_icon {
    display: flex;
    z-index: 2;
  }

  .ag_menu>ul {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-color: #ffffff;
    flex-direction: column;
    padding-top: 110px;
    align-items: flex-start;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .ag_menu>ul.clicked {
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .ag_menu>ul>li>a,
  .ag_menu>ul>li>a:hover {
    margin: 6px 0;
    width: 100%;
    padding: 5px 10px;
    border-bottom: 0px;
    color: #01081d;
  }

  .ag_menu>ul>li>a::after {
    display: none;
  }

  .ag_menu>ul>li:first-child {
    margin-left: 8px;
  }

  .ag_menu>ul>li:first-child,
  .ag_header_wrap.fixed .ag_menu>ul>li:first-child {
    margin-left: 8px;
  }

  .ag_menu>ul>li ul {
    position: initial;
    background-color: transparent;
    box-shadow: none;
    width: 100%;
    border-top: 0px;
  }

  .ag_menu>ul.clicked>li ul {
    opacity: 1;
    visibility: visible;
  }

  .ag_menu>ul>li ul li a {
    text-transform: capitalize;
    margin: 3px 0px 3px 0px;
    padding: 3px 10px;
    font-size: 14px;
  }

  .ag_menu>ul ul.black_text.auto-width {
    width: 100% !important;
    padding: 0;
  }

  .ag_menu>ul>li ul li a:hover,
  .ag_menu>ul>li ul li.active a {
    color: #0a80da;
    background-color: transparent;
  }

  .ag_menu .ag_btn_black {
    margin-left: 15px;
    margin-top: 15px;
  }

  .ag_menu>ul>li {
    width: 100%;
  }

  .ag_header_wrap.fixed .ag_menu>ul>li {
    margin: 0 8px;
  }

  .ag_btn.deskonly {
    display: none;
  }

  .ag_menu ul.clicked li ul {
    flex-direction: column;
    margin: 0;
    padding: 0;
    max-width: 95%;
    opacity: 0;
    max-height: 0;
    transition: all 0.2s ease-in 0s;
    -webkit-transition: all 0.2s ease-in 0s;
    transform: unset;
  }

  .sub-lines {
    padding-left: 0px !important;
  }


  .ag_menu ul.clicked li ul li a:first-child {
    padding: 0;
  }

  .ag_menu ul.clicked li a {
    font-size: 18px;
  }

  .ag_menu ul.clicked {
    padding-top: 70px;
    margin-right: 0 !important;
    padding-left: 10px;
  }

  .ag_menu ul.clicked {
    padding-top: 70px;
    overflow: scroll;
    margin: 0;
  }

  .ag_menu ul.clicked li a {
    font-size: 18px;
  }

  .ag_menu ul.clicked li:focus ul,
  .ag_menu ul.clicked li a:focus ul,
  .ag_menu ul.clicked li:target ul,
  .ag_menu ul.clicked li a:target ul,
  .ag_menu ul.clicked li:focus-within ul,
  .ag_menu ul.clicked li a:focus-within ul {
    opacity: 1;
    max-height: 100%;
  }

  .how-publish-icons-second .col-lg-5 {
    justify-content: flex-start !important;
  }

  .how-publish-icons-second .col-lg-5 img.icon-oimage {
    max-width: 130px;
    margin-bottom: 15px;
    width: 100%;
    padding: 0;
  }

  .how-publish-icons .col-lg-8 {
    width: 100% !important;
  }

  .how-publish-icons ul.flex-li li {
    margin-left: 0 !important;
  }

  .width75text {
    max-width: 100%;
    margin: 0 auto 20px auto;
  }

  .box-with-small-content-center.we-are-offer-box {
    max-width: 100%;
  }

  .box-with-small-content-center.we-are-offer-box .large-title.no-bottom-border {
    font-size: 28px;
  }

  .article-processing .text-white {
    font-size: 22px;
    line-height: 25px;
  }

  .article_col_box .row.m-0.px-4 {
    padding: 0 !important;
  }

  .article-processing .article h5,
  .article-table-data h5.price {
    font-size: 32px;
  }
}

@media (max-width: 768px) {

  /*    18-09-2023*/
  .article-processing .article h5 {
    font-size: 24px;
  }

  .ag_footer_wrap .footer-align-bottom .ag_ftr_socials {
    margin-bottom: 35px;
    top: auto;
  }

  .article-processing .article p {
    font-size: 18px;
  }

  .ag_heading_wrap h2,
  .ag_heading_center_wrap h2,
  .ag_heading_right_wrap h2,
  .ag_partners_wrap .ag_heading_center_wrap h2,
  .ag_partners_wrap .ag_heading_center_wrap h2 span {
    font-size: 25px;
  }

  .ag_special_offer_img p {
    font-size: 18px;
  }

  .ag_footer_wrap .footer-align-bottom .ag_ftr_contacts {
    margin-bottom: 35px;
  }

  .click-here-shastra button {
    width: auto;
  }

  .content-area .large-title {
    font-size: 22px !important;
  }
}

@media (max-width: 767px) {
  .welcome-text {
    font-size: 20px;
  }

  .hero-bold-text {
    font-size: 28px;
  }

  .banner-logo img {
    max-width: 230px;
  }

  .banner-logo {
    top: 80px;
  }

  .banner-logo img {
    max-width: 350px;
  }

  .banner-logo {
    top: 100px;
  }

 

  p.pera-text.px-3.px-lg-0 {
    padding: 0 !important;
  }

  .box-with-small-content-center.we-are-offer-box .box-content {
    padding: 15px;
  }

  .box-with-small-content-center.we-are-offer-box .large-title.no-bottom-border {
    font-size: 20px;
    line-height: 25px;
  }

  .box-with-small-content-center.we-are-offer-box p.bg-pera {
    font-size: 16px;
    padding-left: 50px;
    width: 100%;
  }

  .myspace {
    margin-top: 50px !important;
  }

  .updating-article>div {
    padding: 0;
  }

  .article_col_box ul li.bold-text {
    font-size: 15px;
  }

  .box-with-small-content-center.we-are-offer-box {
    margin-top: 40px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .article-processing {
    display: block;
  }

  .article_col_box {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .article_col_box .col-lg-12.py-3.mb-3.px-5 {
    padding: 10px !important;
  }

  .article_col_box .text-white {
    font-size: 18px;
    line-height: 22px;
  }

  .article-processing .article h5 {
    text-align: left;
    font-size: 20px;
  }

  .article_col_box .row.m-0.px-4>p,
  .article_col_box .row.m-0.px-4 li {
    padding: 0 !important;
  }

  .article-table-data p {
    font-size: 16px !important;
  }

  .updating-article h4 {
    font-size: 20px !important;
  }

  .container.updating-article.atricle-box-content {
    padding: 0;
  }

  .row.quiz .pera-text.takequiz {
    margin: 20px auto 30px auto;
    text-align: center;
  }

  .banner-section .page-title.opensci .title span::before {
    width: 8px;
    height: 8px;
    top: 6px;
    left: 2px;
  }

  .row.quiz .col-md-4.col-12 {
    text-align: center;
  }

  .row.quiz .col-md-4 img {
    position: inherit;
    margin: 20px auto 0px auto;
  }

  .row.quiz .register-button {
    margin: 20px 0px 30px;
  }

  .banner-section .page-title {
    max-width: 98%;
  }

  .opensci h1,
  .banner-section .title {
    font-size: 28px;
    line-height: normal;
  }

  .content-area .box-content {
    padding: 15px;
  }

  .banner-section .title span::before {
    width: 8px;
    height: 8px;
    top: 2px;
    left: 1px;
  }

  .register-button button {
    font-size: 18px !important;
    padding: 10px 30px;
}

  .blue-bg-box p {
    max-width: 100%;
  }

  .blue-bg-box {
    margin-top: 35px !important;
    padding: 10px !important;
  }

  .content-area .small-title1 {
    font-size: 24px;
  }

  .register-button {
    text-align: center;
    margin: 30px 0;
  }

  .ag_publishjournal_text.custom-boxx {
    padding: 15px !important;
  }

  .ag_publishjournal_text.custom-boxx h4 {
    margin-bottom: 15px;
    font-size: 24px;
  }

  .updating-article p {
    padding: 0 !important;
  }

  .table-content {
    width: 100%;
    overflow-x: auto;
  }

  .archive-content {
    font-size: 19px;
    padding: 30px 30px 30px 30px;
  }

  .archiving {
    font-size: 24px;
  }

  .ag_redline_wrap h2 {
    font-size: 20px;
  }

  .university-title {
    font-size: 23px;
    line-height: normal;
  }

  .ag_menu_icon {
    display: flex;
    z-index: 2;
  }

  .ag_menu>ul {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-color: #ffffff;
    flex-direction: column;
    padding-top: 110px;
    align-items: flex-start;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .opensci h1,
  .banner-section .title {
    padding: 0 15px;
  }
}

@media (max-width: 500px) {
  .banner-logo img {
    max-width: 250px;
  }

  .banner-logo {
    top: 80px;
  }

  .opensci h1,
  .banner-section .title {
    font-size: 24px !important;
    line-height: normal;
  }

  .banner-section .title span::before {
    width: 7px;
    height: 7px;
    top: 1px;
    left: 0px;
  }

  .banner-section .page-title.opensci .title span::before {
    width: 6px;
    height: 6px;
    top: 2px;
    left: 1px;
  }

  .row.quiz .col-md-4 img {
    max-width: 100%;
    bottom: -50px;
    top: auto;
  }
}

@media (max-width: 500px) {
  .banner-section .title span::before {
    left: 1px;
  }

  .banner-section .title span::before {
    width: 6px;
    height: 6px;
    top: 3px;
    left: 1px;
  }
}

@media (max-width: 400px) {
  .banner-section .title span::before {
    width: 6px;
    height: 6px;
    top: 2px;
    left: 1px;
  }
}

@media only screen and (min-width: 1800px) {
  .ag_banner_video {
    padding-bottom: 58%;
  }

  .ag_partners_slider.owl-carousel.owl-drag .owl-item .item img {
    max-width: 200px;
  }

  .ag_opportunities_wrap h2.cus-title .custom-i-dot::before {
    top: 0px;
    left: 0;
  }

  .ag_opportunities_box {
    min-height: 610px;
    max-height: 610px;
    overflow: hidden;
  }

  .howitworks {
    height: 1200px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1460px) {
  .ag_logo img {
    height: 50px;
}
}
@media only screen and (min-width: 1300px) and (max-width: 1314px) {
  .header-main-row .ag_menu > ul > li a {
    font-size: 12px !important;
}
}
@media only screen and (min-width: 1180px) and (max-width: 1224px) {
  body .header-main-row .ag_menu > ul > li a {
    font-size: 10px !important;
}
.ag_logo img {
  height: 40px;
}
}
@media only screen and (min-width: 1650px) and (max-width: 1873px) {
  .ag_megajournals_text p {
    font-size: 26px;
    margin: 15px 0;
  }

  .ag_megajournals_text p.paragraph {
    font-size: 25px;
    margin: 15px 0;
  }

  .ag_megajournals_text p b.intro {
    font-size: 23px
  }

  .ag_megajournals_text p b span.management {
    font-size: 20px
  }

  .ag_megajournals_text p b.info {
    font-size: 22px
  }
}

 

@media only screen and (min-width: 1000px) and (max-width: 1050px) {
 

  .banner-logo {
    top: 170px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1100px) {
  .ag_banner_video {
    padding-bottom: 80%;
  }
 
}

@media only screen and (min-width: 1100px) and (max-width: 1200px) {
 

  .ag_banner_video {
    padding-bottom: 65%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .ag_menu>ul>li {
    margin: 0 10px;
  }
  .ag_logo img{
    height: 50px;
  }
  .ag_banner_video {
    padding-bottom: 50%;
  }

  .ag_partners_slider.owl-carousel.owl-drag .owl-item .item {
    padding: 0 10px;
  }

  .ag_opportunities_box {
    min-height: 485px;
    max-height: 485px;
  }

  span.publish-i-dot {
    position: relative;
    font-weight: 700;
  }

  span.publish-i-dot::before {
    width: 5px;
    height: 5px;
    top: 5px;
    left: 1px;
  }

  .ag_publishjournal_wrap .ag_heading_wrap h2 {
    padding-right: 8px;
  }

  .warm-slider-des {
    width: 83%;
  }

 

  .banner-logo img {
    max-width: 400px;
  }

  .ag_banner_video {
    padding-bottom: 60%;
  }

  .ag_main_wrap.ag_builtwith_wrap {
    margin-top: 80px;
    margin-bottom: 30px;
  }

  .banner-logo {
    top: 160px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1700px) {
 
  .ag_megajournals_text p b {
    // font-size: 17px !important;
  }
  .ag_megajournals_text p b.info {
    font-size: 18px !important
  }
  .ag_publishjournal_text span.publish-i-dot {
    font-weight: 600;
    font-size: 30px;
  }

  .videoembed {
    height: calc(100vh - 98px);
  }
  
  .ag_menu>ul>li {
    margin: 0 10px;
  }

  .ag_banner_video {
    padding-bottom: 50%;
  }

  .ag_partners_slider.owl-carousel.owl-drag .owl-item .item {
    padding: 0 10px;
  }

  // .ag_opportunities_box {
  //   min-height: 510px;
  //   max-height: 510px;
  // }

  span.publish-i-dot {
    position: relative;
    font-weight: 500;
  }

  span.publish-i-dot::before {
    width: 7px;
    height: 7px;
    top: 3px;
    left: .5px;
  }

  .ag_publishjournal_wrap .ag_heading_wrap h2 {
    padding-right: 8px;
  }

  .warm-slider-des {
    width: 83%;
  }
}



@media only screen and (max-width: 900px) {
  .ag_content_text {
    position: initial;
    width: 100%;
    order: 2;
    margin-top: 50px;
    color: #111111;
  }

  .ag_megajournals_text {
    padding: 0 28px;
  
  }
  .ag_newways_wrap .ag_special_offer_btn {
    position: relative;
    left: auto;
  }
  .testimonial-slider .ag_main_wrapmain-col {
    height: auto;
    background-size: cover;
  }

  .ag_banner_video {
    padding-bottom: 0;
  }

  .welcome-text {
    color: #000;
    text-align: center;
  }

  .welcome-text strong {
    color: #000;
  }

  .hero-bold-text {
    text-align: center;
    color: #000;
  }

  .ag_content_text p {
    color: #111111;
  }

  .ag_content_text .ag_btn_red {
    max-width: 100%;
  }

  .hero {
    display: flex;
    flex-direction: column;
  }

  .ag_main_wrap {
    width: 100%;
    padding: 0 20px !important;
  }

  .ag_builtwith_video video {
    max-width: 100%;
    // &::-webkit-media-controls { display: none !important; }
    // &::-webkit-media-controls-play-button {display: none !important;}
    &::-webkit-media-controls { display: none !important; opacity: 0 !important; visibility: hidden !important;  -webkit-appearance: none !important;  }
    &::-webkit-media-controls-play-button { display: none !important; opacity: 0 !important; visibility: hidden !important;  -webkit-appearance: none !important;  }
    &::-webkit-media-controls-start-playback-button { display: none !important; opacity: 0 !important; visibility: hidden !important;  -webkit-appearance: none !important;  }
    &::-webkit-media-controls-container { display: none !important; opacity: 0 !important; visibility: hidden !important;  -webkit-appearance: none !important;  }

    &::-webkit-media-controls-volume-slider {display: none !important;}
    
    &::-webkit-media-controls-mute-button {display: none !important;}
    
    &::-webkit-media-controls-timeline {display: none !important;}
    
    &::-webkit-media-controls-current-time-display {display: none !important;}
  }
  
  .ag_builtwith_video video::-webkit-media-controls { display: none !important; }
  .ag_builtwith_video video::-webkit-media-controls-play-button {display: none !important;}

  .ag_builtwith_video video::-webkit-media-controls-volume-slider {display: none !important;}
    
  .ag_builtwith_video video::-webkit-media-controls-mute-button {display: none !important;}
    
  .ag_builtwith_video video::-webkit-media-controls-timeline {display: none !important;}
    
  .ag_builtwith_video video::-webkit-media-controls-current-time-display {display: none !important;}


  .ag_main_wrap.ag_builtwith_wrap {
    padding: 0 20px 10px 20px !important;
  }

  .ag_main_wrap .ag_heading_center_wrap h2 {
    font-size: 32px !important;
  }

  .ag_main_wrap.ag_partners_wrap {
    padding-bottom: 30px !important;
  }

  .ag_header_bottom .col-lg-3.col-md-4.col-9 {
    width: 65%;
  }

  .ag_header_bottom .col-lg-9.col-md-8.col-3 {
    width: 35%;
  }

  .ag_logo img {
    height: auto;
    max-height: 60px;
  }

  .ag_search_box input:focus {
    width: 70vw;
    right: 30px;
    opacity: 1;
    cursor: text;
  }

  .ag_menu ul.clicked li ul li a:first-child {
    padding: 0;
  }

  .ag_menu ul.clicked li ul li .sub-lines br {
    display: none;
  }

  .ag_menu ul.clicked li:focus ul,
  .ag_menu ul.clicked li a:focus ul,
  .ag_menu ul.clicked li:target ul,
  .ag_menu ul.clicked li a:target ul,
  .ag_menu ul.clicked li:focus-within ul,
  .ag_menu ul.clicked li a:focus-within ul {
    opacity: 1;
    max-height: 100%;
  }

  .ag_header_bottom .ag_btn,
  .ag_header_wrap.fixed .ag_btn {
    font-size: 14px;
    padding: 4px 12px;
    min-width: 150px;
    height: 50px;
  }

  .ag_banner_video {
    padding-bottom: 50vh;
  }

  .ag_main_wrap.ag_banner_wrap {
    padding: 0 !important;
  }

  .ag_main_wrap.ag_banner_wrap .container {
    padding: 0;
  }

  video.videoembed {
    height: auto;
    top: 0;
    margin: 70px 0 0 0;
  }

  .ag_banner_video {
    padding-bottom: 35vh;
  }

  h2.cus-title,
  h2.cus-title * {
    display: inline;
  }

  .ag_opportunities_box {
    min-height: 500px;
    max-height: 500px;
    overflow: hidden;
    margin-bottom: 30px;
  }

  .ag_opportunities_box .extrapadding {
    padding-bottom: 0;
  }

  .ag_special_offer_img p {
    width: 100%;
    margin-right: 0;
  }

  .ag_main_wrap.ag_special_offer_wrap {
    padding-top: 40px !important;
  }

  .ag_special_offer_wrap .col-lg-5 {
    position: relative;
    left: 0;
    padding: 0 10px;
    border-radius: 28px;
    top: -150px;
  }

  .ag_newways_wrap .col-4.align-items-center {
    width: 100%;
  }

  .ag_newways_wrap .ag_special_offer_btn {
    margin-bottom: 60px;
  }

  .ag_newways_wrap .col-12 img {
    margin-bottom: 60px;
  }

  .ag_megajournals_inner_wrap {
    padding: 30px 5px;
  }

  .ag_megajournals_img,
  .ag_megajournals_img_right {
    width: 80%;
  }

  .ag_megajournals_img_boxes ul li img {
    height: auto;
  }

  .ag_megajournals_wrap .container {
    padding: 0 !important;
  }

  .ag_megajournals_img_boxes ul {
    padding: 0 10px 0 25px;
  }

  .ag_main_wrap.ag_publishjournal_wrap {
    padding-top: 30px !important;
  }

  .ag_publishjournal_wrap .col-lg-5 {
    right: 0;
  }

  .ag_publishjournal_text {
    margin: 0;
    padding: 30px 45px;
  }

  .ag_main_wrap.ag_howworks_wrap {
    padding: 60px 0 0 0 !important;
  }

  .howitworks {
    height: 250px;
  }

  .ag_howworks_btn {
    margin-top: 10px;
  }

  .ag_main_wrap.ag_resourcehub_wrap {
    padding-top: 60px !important;
  }

  .ag_resourcehub_wrap .row {
    padding: 0;
  }

  .ag_main_wrap.ag_warmwords_wrap.mb-5 {
    padding: 30px 0 0 0 !important;
    text-align: center;
  }

  .warm-slider-des {
    width: 100%;
  }

  .warm-slider-des-wrp {
    padding: 30px 10px;
  }

  .ag_copyright_wrap {
    flex-direction: column;
  }

  .ag_partners_slider.owl-carousel.owl-drag .owl-item:after {
    display: none;
  }

  .ag_partners_slider.owl-carousel.owl-drag .owl-item .item img {
    padding-top: 0 !important;
  }

  .ag_main_wrap.ag_opportunities_wrap .container,
  .ag_main_wrap.ag_partners_wrap .container,
  .ag_main_wrap.ag_special_offer_wrap .container,
  .ag_main_wrap.ag_resourcehub_wrap .container {
    padding: 0;
  }

  .ag_main_wrap.ag_megajournals_wrap {
    padding-bottom: 30px !important;
  }

  .ag_publishjournal_text .ag_btn_red {
    max-width: 100%;
  }

  .warm-slider-des-wrp span::before,
  .warm-slider-des-wrp span::after {
    width: 20px;
  }

  a.ag_ftr_register {
    margin-top: -30px;
  }

  .ag_ftr_links {
    height: auto;
  }

  .ag_header_wrap.fixed .ag_menu>ul>li>a {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ag_opportunities_wrap h2.cus-title {
    font-size: 34px !important;
  }

  .ag_special_offer_wrap .ag_heading_wrap h2 {
    line-height: 36px;
  }

  .ag_special_offer_btn .ag_btn_red {
    max-width: 100%;
    height: auto;
    min-height: 45px;
  }

  .ag_howworks_btn .ag_btn_red {
    width: 100%;
  }

  .ag_main_wrap.ag_megajournals_wrap h2 span {
    font-size: 20px !important;
    line-height: 35px !important;
  }

  .ag_main_wrap.ag_megajournals_wrap h2 span span {
    font-weight: 400;
  }

  .ag_megajournals_text p {
    margin: 10px 0;
  }

  .ag_main_wrap.ag_megajournals_wrap .col-lg-12.col-12.d-flex.justify-content-center.mt-5 {
    margin-top: 0 !important;
  }
}

@media only screen and (min-width: 200px) and (max-width: 400px) {
  .ag_banner_video {
    padding-bottom: 45vh;
  }
}

@media only screen and (min-width: 400px) and (max-width: 600px) {
  .ag_banner_video {
    padding-bottom: 50vh;
  }

  .warm-slider-des-wrp {
    padding: 30px 20px;
  }

  a.ag_ftr_register {
    margin-top: -10px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 750px) {
  .ag_partners_slider.owl-carousel.owl-drag .owl-item .item img {
    max-width: 120px;
  }

  .ag_opportunities_wrap .col-xl-4 {
    width: 100%;
  }

  // .ag_opportunities_wrap .ag_opportunities_box {
  //   min-height: 460px;
  //   max-height: 460px;
  // }

  .howitworks {
    height: 350px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 330px) and (min-height: 480px) and (max-height: 550px) {
  .ag_opportunities_box {
    min-height: 560px;
    max-height: 560px;
    overflow: hidden;
    margin-bottom: 30px;
  }

  .ag_publishjournal_text {
    margin: 0;
    padding: 30px 15px;
  }

  span.publish-i-dot::before {
    top: 2px;
    width: 4px;
    height: 4px;
  }
}

@media only screen and (min-width: 200px) and (max-width: 600px) and (orientation: landscape) {
  .ag_banner_video {
    padding-bottom: 20vh;
  }

  .videocontainer {
    height: 100vh;
  }

  .ag_opportunities_wrap h2.cus-title {
    font-size: 32px !important;
  }

  .howitworks {
    height: 280px;
  }
   
}

@media only screen and (min-width: 600px) and (max-width: 900px) and (orientation: landscape) {
  .ag_banner_video {
    /* padding-bottom: 20vh; */
    padding-bottom: 0vh;
  }

  .videocontainer {
    height: auto;
    top: 75px;
    display: block;
    position: relative;
  }

  .ag_opportunities_wrap h2.cus-title {
    font-size: 32px !important;
  }

  .howitworks {
    height: 380px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 500px) and (min-height: 750px) and (orientation: portrait) {
  .ag_banner_video {
    padding-bottom: 35vh;
  }
}

@media only screen and (min-width: 800px) and (max-width: 900px) and (orientation: landscape) {
  .ag_logo img {
    height: auto;
    max-height: 45px;
  }

  .ag_header_bottom .col-lg-3.col-md-4.col-9 {
    width: 20%;
  }

  .ag_header_bottom .col-lg-9.col-md-8.col-3 {
    width: 80%;
  }

  .ag_header_bottom .ag_btn,
  .ag_header_wrap.fixed .ag_btn {
    height: 40px;
  }

  .ag_menu>ul>li:hover ul {
    top: 80%;
    padding: 5px 0;
    left: 0;
    right: 0;
    max-width: 100%;
  }

  .ag_menu>ul>li ul li a,
  .ag_menu>ul>li ul li .sub-lines {
    font-size: 13px;
  }

  .howitworks {
    height: 580px;
  }

  a.ag_ftr_register {
    margin-top: -10px;
  }
}

@media only screen and (min-width: 1030px) and (max-width: 1199px) {
  .sell-banner-wrp{
    margin-top: 66px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1299px) {
  .header-main-row .ag_menu > ul > li ul li .sub-lines {
    font-size: 10px;
  }
  .sell-banner-wrp{
    margin-top: 66px;
  }
  /*Nav Menu*/
  .ag_logo img {
    height: 50px;
  }

  .ag_menu>ul>li {
    margin: 0 7px;
  }
  .ag_menu > ul, .ag_header_wrap.fixed .ag_menu > ul{
    margin-right: 0px;
  }
  .header-main-row .ag_menu > ul > li a{
    font-size: 11px !important;
  }
  /*Homepage */

  body.home .ag_banner_wrap .ag_content_text {
    bottom: 10%;
    left: 9%;
  }

  body.home .ag_main_wrap.ag_builtwith_wrap {
    margin-top: 0;
    margin-bottom: 0;
  }

  body.home .ag_opportunities_box p,
  body.home .ag_opportunities_box a {
    font-size: 18px;
  }

  body.home .ag_special_offer_img p {
    font-size: 23px;
  }

  body.home .ag_special_offer_wrap .ag_heading_wrap h2 {
    font-size: 25px;
  }

  body.home .ag_oppbullets li {
    font-size: 18px;
  }

  body.home .ag_opportunities_box {
    min-height: 500px;
    max-height: 500px;
  }

  body.home .ag_special_offer_text p {
    font-size: 15px;
  }

  body.home .ag_publishjournal_wrap span.publish-i-dot::before {
    top: 4px;
    left: 1px;
  }

  body.home .ag_publishjournal_text {
    padding: 60px 60px 70px 60px;
  }

  body.home .ag_heading_center_wrap.our-partner:after {
    width: 32%;
  }

  body.home .ag_newways_wrap img {
    margin-top: -5px;
  }

  body.home .ag_megajournals_wrap .ag_heading_center_wrap:after {
    width: 20%;
  }

  body.home .ag_publishjournal_text .ag_heading_wrap:after {
    width: 30%;
  }

  body.home .ag_resourcehub_box p,
  body.home .ag_resourcehub_box a {
    font-size: 14px;
  }

  body.home .ag_special_offer_img p img {
    width: 45px;
  }

  body.home .Transforming-btm-text h4,
  body.home .Transforming-btm-text h4 p {
    font-size: 26px;
    line-height: 36px;
  }

  body.home .Transforming-btm-text .pera-text {
    font-size: 18px;
  }

  body.home .ag_newways_wrap .ag_special_offer_btn {
    left: 15px;
  }

  body.home .ag_main_wrapmain-col {
    height: 380px;
  }
  .ag_footer_wrap p, .ag_footer_wrap a {
    font-size: 12px !important;
  }

  /*Sell Page*/
  body.sell .Transforming-btm-text h4 *,
  body.sell .Transforming-btm-text h4 {
    font-size: 26px;
    line-height: 39px;
  }

  body.sell .Transforming-btm-text .pera-text {
    font-size: 19px;
    line-height: 31px;
    max-width: 100%;
    padding: 0 120px;
  }

  body.sell img.sell_logo {
    max-width: 100%;
  }

  body.sell .row.about-sec .col-lg-6 {
    width: 57%;
  }

  body.sell .row.about-sec .col-lg-6.text-center {
    width: 43%;
  }

  body.sell .row.about-sec .col-lg-6 .pera-text {
    font-size: 17px;
  }

  body.sell .row.about-sec .col-lg-6.text-center span {
    font-size: 21.7px;
    margin: 0 0 0 -19px;
  }

  body.sell .nation-right-col {
    padding-left: 50px;
  }

  body.sell .col-lg-7.choosing-main-text {
    width: calc(70% - 80px);
    margin: 0 80px 0 0;
  }

  body.sell .col-lg-4.choosingg-main-img {
    width: 30%;
  }

  body.sell .row.choosing-main-col-row.my-5.px-lg-5.px-3 {
    padding: 0 !important;
    align-items: start;
  }

  body.sell .row.choosing-main-col-row .pera-text.mb-5 {
    margin-bottom: 2.4rem !important;
  }

  body.sell .convinced-col-1 img,
  body.sell .convinced-col-2 .convinced-img-col img,
  body.sell .convinced-col-3 .convinced-img-col img,
  body.sell .convinced-col-4 .convinced-img-col img {
    width: 98.5%;
  }

  body.sell .convinced-right-text.convinced-text,
  body.sell .convinced-left-text.convinced-text {
    width: 66%;
    right: 55px;
  }

  body.sell .convinced-left-text.convinced-text {
    right: 290px;
  }

  body.sell .convinced-img-wrp .convinced-text-col p {
    font-size: 17px;
    line-height: 25px;
  }

  body.sell .convinced-left-text.convinced-text {
    right: 323px;
    width: 63%;
  }

  body.sell .convinced-col-4 .convinced-text-col {
    top: -18px;
  }

  body.sell .convinced-col-2 .convinced-text-col,
  body.sell .convinced-col-1 .convinced-text-col {
    top: -20px;
  }

  body.sell .convinced-col-1 .convinced-text-col {
    padding: 0 30px 0 10px;
  }

  body.sell .community-col.ag_heading_center_wrap p {
    font-size: inherit;
    line-height: inherit;
  }

  body.sell .foreign-university-text h4 {
    font-size: 28px;
    line-height: 50px;
    margin-bottom: 20px;
  }

  body.sell .container.container-full.Transforming-btm-col h2 span {
    font-size: inherit;
  }

  body.sell .container.container-full.Transforming-btm-col .ag_heading_center_wrap span::after {
    width: 7px;
    height: 7px;
    top: 7px;
    left: 2px;
  }

  /*Sell Foreign Page*/
  body.sell-fr .Transforming-btm-text h4 *,
  body.sell .Transforming-btm-text h4 {
    font-size: 26px;
    line-height: 39px;
  }

  body.sell-fr .Transforming-btm-text .pera-text {
    font-size: 19px;
    line-height: 31px;
    max-width: 100%;
    padding: 0 65px;
  }

  body.sell-fr img.sell_logo {
    max-width: 100%;
  }

  body.sell-fr .row.about-sec .col-lg-6 {
    width: 57%;
  }

  body.sell-fr .row.about-sec .col-lg-6.text-center {
    width: 43%;
  }

  body.sell-fr .row.about-sec .col-lg-6 .pera-text {
    font-size: 17px;
  }

  body.sell-fr .row.about-sec .col-lg-6.text-center span {
    font-size: 21.7px;
    margin: 0 0 0 -19px;
  }

  body.sell-fr .nation-right-col {
    padding-left: 50px;
  }

  body.sell-fr .col-lg-7.choosing-main-text {
    width: calc(70% - 80px);
    margin: 0 80px 0 0;
  }

  body.sell-fr .col-lg-4.choosingg-main-img {
    width: 30%;
  }

  body.sell-fr .row.choosing-main-col-row.my-5.px-lg-5.px-3 {
    padding: 0 !important;
    align-items: start;
  }

  body.sell-fr .row.choosing-main-col-row .pera-text.mb-5 {
    margin-bottom: 2.4rem !important;
  }

  body.sell-fr .convinced-col-1 img,
  body.sell-fr .convinced-col-2 .convinced-img-col img,
  body.sell-fr .convinced-col-3 .convinced-img-col img,
  body.sell-fr .convinced-col-4 .convinced-img-col img {
    width: 98.5%;
  }

  body.sell-fr .convinced-right-text.convinced-text,
  body.sell-fr .convinced-left-text.convinced-text {
    width: 66%;
    right: 55px;
  }

  body.sell-fr .convinced-left-text.convinced-text {
    right: 290px;
  }

  body.sell-fr .convinced-img-wrp .convinced-text-col p {
    font-size: 17px;
    line-height: 25px;
  }

  body.sell-fr .convinced-left-text.convinced-text {
    right: 323px;
    width: 63%;
  }

  body.sell-fr .convinced-col-4 .convinced-text-col {
    top: -18px;
  }

  body.sell-fr .convinced-col-2 .convinced-text-col,
  body.sell-fr .convinced-col-1 .convinced-text-col {
    top: -20px;
  }

  body.sell-fr .convinced-col-1 .convinced-text-col {
    padding: 0 30px 0 10px;
  }

  body.sell-fr .community-col.ag_heading_center_wrap p {
    font-size: inherit;
    line-height: inherit;
  }

  body.sell-fr .foreign-university-text h4 {
    font-size: 28px;
    line-height: 50px;
    margin-bottom: 20px;
  }

  body.sell-fr .container.container-full.Transforming-btm-col h2 span {
    font-size: inherit;
  }

  body.sell-fr .container.container-full.Transforming-btm-col .ag_heading_center_wrap span::after {
    width: 7px;
    height: 7px;
    top: 7px;
    left: 2px;
  }

  body.sell-fr .global-publish-challenges .container {
    padding: 0 90px;
  }

  body.sell-fr .global-publish-challenges .row.main-global-publish-challenge .pera-text {
    font-size: 17px;
  }
}

@media only screen and (min-width: 1363px) and (max-width: 1400px) {
  body.home .ag_special_offer_text {
    padding: 52px;
  }

  .ag_special_offer_img .ag_big_img {
    height: 540px;
  }

  .ag_heading_wrap span,
  .ag_heading_center_wrap span,
  .ag_heading_right_wrap span,
  .ag_special_offer_wrap .ag_heading_wrap h2,
  .ag_publishjournal_wrap .ag_heading_wrap h2 {
    font-size: 27px;
    line-height: normal;
  }

  .ag_megajournals_text p {
    font-size: 20px;
  }

  .ag_megajournals_text p b {
    font-size: 16px;
    font-weight: 700;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1362px) {
  body.home .ag_special_offer_text {
    padding: 50px;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1399px) {
  .ag_menu > ul > li ul.subNavBox li:nth-child(4) .manageNavi .sub-lines {
    width: 158px !important;
}
  .ag_menu > ul > li ul.subNavBox li:nth-child(6) .manageNavi .sub-lines{
    
  min-width: 140px !important;
  }
  .header-main-row .ag_menu > ul > li ul li .sub-lines {
    font-size: 10px !important;
}
.ag_menu > ul > li ul.subNavBox li:nth-child(3) .manageNavi .sub-lines {

  min-width: 150px !important;
}
  body.home .ag_banner_wrap .ag_content_text {
    bottom: 10%;
  }

  body.home .ag_main_wrap.ag_builtwith_wrap {
    margin-top: 0;
    margin-bottom: 0;
  }

  body.home .ag_opportunities_box p,
  body.home .ag_opportunities_box a {
    font-size: 19.5px;
  }

  body.home .ag_oppbullets li {
    font-size: 18px;
  }

  body.home .ag_opportunities_box {
    min-height: 520px;
    max-height: 520px;
  }

  body.home .ag_special_offer_text p {
    font-size: 14px;
  }

  body.home .ag_publishjournal_wrap span.publish-i-dot::before {
    top: 6px;
    left: 2px;
  }

  body.home .ag_publishjournal_text {
    padding: 60px 60px 70px 60px;
  }

  body.home .ag_heading_center_wrap.our-partner:after {
    width: 32%;
  }

  body.home .ag_newways_wrap img {
    margin-top: -5px;
  }

  body.home .ag_megajournals_wrap .ag_heading_center_wrap:after {
    width: 20%;
  }

  body.home .ag_publishjournal_text .ag_heading_wrap:after {
    width: 30%;
  }

  body.home .ag_resourcehub_box p,
  body.home .ag_resourcehub_box a {
    font-size: 16px;
  }

  body.home .ag_special_offer_img p img {
    width: 45px;
  }

  /* body.home .ag_special_offer_text {
    padding: 50px;
  } */

  body.home .ag_special_offer_wrap .ag_heading_wrap h2 {
    font-size: 24px;
  }

  body.home .ag_newways_wrap .ag_special_offer_btn {
    left: 25px;
  }

  /*Sell Page*/
  body.sell .Transforming-btm-text h4 *,
  body.sell .Transforming-btm-text h4 {
    font-size: 26px;
    line-height: 39px;
  }

  body.sell .Transforming-btm-text .pera-text {
    font-size: 18px;
    line-height: 31px;
    max-width: 100%;
    padding: 0 170px;
  }

  body.sell img.sell_logo {
    max-width: 89%;
  }

  body.sell .nation-right-col p {
    font-size: 17px;
    line-height: 25px;
  }

  body.sell .convinced-img-wrp.convinced-col-3 img {
    height: 280px;
  }

  body.sell .row.about-sec .col-lg-6 {
    width: 55%;
  }

  body.sell .row.about-sec .col-lg-6.text-center {
    width: 45%;
  }

  body.sell .row.about-sec .col-lg-6 .pera-text {
    font-size: 17px;
  }

  body.sell .row.about-sec .col-lg-6.text-center span {
    font-size: 21.7px;
    margin: 0 0 0 -19px;
  }

  body.sell .nation-right-col {
    padding-left: 50px;
  }

  body.sell .col-lg-7.choosing-main-text {
    width: calc(70% - 80px);
    margin: 0 80px 0 0;
  }

  body.sell .col-lg-4.choosingg-main-img {
    width: 30%;
  }

  body.sell .row.choosing-main-col-row.my-5.px-lg-5.px-3 {
    padding: 0 !important;
    align-items: start;
  }

  body.sell .row.choosing-main-col-row .pera-text.mb-5 {
    margin-bottom: 2.4rem !important;
  }

  body.sell .convinced-col-1 img,
  body.sell .convinced-col-2 .convinced-img-col img,
  body.sell .convinced-col-3 .convinced-img-col img,
  body.sell .convinced-col-4 .convinced-img-col img {
    width: 98.5%;
  }

  body.sell .convinced-right-text.convinced-text,
  body.sell .convinced-left-text.convinced-text {
    width: 66%;
    right: 55px;
  }

  body.sell .convinced-left-text.convinced-text {
    right: 290px;
  }

  body.sell .convinced-img-wrp .convinced-text-col p {
    font-size: 17px;
    line-height: 25px;
  }

  body.sell .convinced-left-text.convinced-text {
    right: 323px;
    width: 63%;
  }

  body.sell .convinced-col-4 .convinced-text-col {
    top: -18px;
  }

  body.sell .convinced-col-2 .convinced-text-col,
  body.sell .convinced-col-1 .convinced-text-col {
    top: -20px;
  }

  body.sell .convinced-col-1 .convinced-text-col {
    padding: 0 30px 0 10px;
  }

  body.sell .community-col.ag_heading_center_wrap p {
    font-size: inherit;
    line-height: inherit;
  }

  body.sell .foreign-university-text h4 {
    font-size: 28px;
    line-height: 50px;
    margin-bottom: 20px;
  }

  body.sell .container.container-full.Transforming-btm-col h2 span {
    font-size: inherit;
  }

  body.sell .container.container-full.Transforming-btm-col .ag_heading_center_wrap span::after {
    width: 7px;
    height: 7px;
    top: 7px;
    left: 2px;
  }

  /*Sell Foreign Page*/
  body.sell-fr .Transforming-btm-text h4 *,
  body.sell .Transforming-btm-text h4 {
    font-size: 26px;
    line-height: 39px;
  }

  body.sell-fr .Transforming-btm-text .pera-text {
    font-size: 19px;
    line-height: 31px;
    max-width: 100%;
    padding: 0 65px;
  }

  body.sell-fr img.sell_logo {
    max-width: 100%;
  }

  body.sell-fr .row.about-sec .col-lg-6 {
    width: 57%;
  }

  body.sell-fr .row.about-sec .col-lg-6.text-center {
    width: 43%;
  }

  body.sell-fr .row.about-sec .col-lg-6 .pera-text {
    font-size: 17px;
  }

  body.sell-fr .row.about-sec .col-lg-6.text-center span {
    font-size: 21.7px;
    margin: 0 0 0 -19px;
  }

  body.sell-fr .nation-right-col {
    padding-left: 50px;
  }

  body.sell-fr .col-lg-7.choosing-main-text {
    width: calc(70% - 80px);
    margin: 0 80px 0 0;
  }

  body.sell-fr .col-lg-4.choosingg-main-img {
    width: 30%;
  }

  body.sell-fr .row.choosing-main-col-row.my-5.px-lg-5.px-3 {
    padding: 0 !important;
    align-items: start;
  }

  body.sell-fr .row.choosing-main-col-row .pera-text.mb-5 {
    margin-bottom: 2.4rem !important;
  }

  body.sell-fr .convinced-col-1 img,
  body.sell-fr .convinced-col-2 .convinced-img-col img,
  body.sell-fr .convinced-col-3 .convinced-img-col img,
  body.sell-fr .convinced-col-4 .convinced-img-col img {
    width: 98.5%;
  }

  body.sell-fr .convinced-right-text.convinced-text,
  body.sell-fr .convinced-left-text.convinced-text {
    width: 66%;
    right: 55px;
  }

  body.sell-fr .convinced-left-text.convinced-text {
    right: 290px;
  }

  body.sell-fr .convinced-img-wrp .convinced-text-col p {
    font-size: 17px;
    line-height: 25px;
  }

  body.sell-fr .convinced-left-text.convinced-text {
    right: 350px;
    width: 62%;
  }

  body.sell-fr .convinced-col-4 .convinced-text-col {
    top: -18px;
  }

  body.sell-fr .convinced-col-2 .convinced-text-col,
  body.sell-fr .convinced-col-1 .convinced-text-col {
    top: -20px;
  }

  body.sell-fr .convinced-col-1 .convinced-text-col {
    padding: 0 30px 0 10px;
  }

  body.sell-fr .community-col.ag_heading_center_wrap p {
    font-size: inherit;
    line-height: inherit;
  }

  body.sell-fr .foreign-university-text h4 {
    font-size: 28px;
    line-height: 50px;
    margin-bottom: 20px;
  }

  body.sell-fr .container.container-full.Transforming-btm-col h2 span {
    font-size: inherit;
  }

  body.sell-fr .container.container-full.Transforming-btm-col .ag_heading_center_wrap span::after {
    width: 7px;
    height: 7px;
    top: 7px;
    left: 2px;
  }

  body.sell-fr .global-publish-challenges .container {
    padding: 0 90px;
  }

  body.sell-fr .global-publish-challenges .row.main-global-publish-challenge .pera-text {
    font-size: 17px;
  }

  body.sell-fr img.sell-logo {
    max-width: 93%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1499px) {
  .header-main-row .ag_menu > ul > li ul li .sub-lines {
    font-size: 11px !important;
}
  .ag_menu > ul > li ul.subNavBox li:nth-child(2) .manageNavi .sub-lines {
    max-width: 210px;
    min-width: 190px !important;
}
.ag_menu > ul > li ul.subNavBox li:nth-child(6) .manageNavi .sub-lines {
  min-width: 160px!important;
}
.ag_menu > ul > li ul.subNavBox li:nth-child(7) .manageNavi .sub-lines{
  max-width: 210px;
  min-width: 140px !important;
}
.ag_menu > ul > li ul.subNavBox li:nth-child(5) .manageNavi .sub-lines{
  max-width: 210px;
  min-width: 140px !important;
}
  body.home .ag_newways_wrap .ag_special_offer_btn {
    left: 30px;
  }
  .ag_special_offer_img p {
    font-size: 30px !important;
    width: 87% !important;
  }
  .ag_publishjournal_wrap .ag_heading_wrap h2 {
    font-size: 30px !important;
  }
  .ag_newsletter_box h4 {
    font-size: 25px;
  }
  .nation-right-col .pera-text {
    font-size: 16px !important;
    letter-spacing:-0.2px !important;
  }
  .journals {
    width:100% !important; 
  }
   
  body.home .ag_banner_wrap .ag_content_text {
    bottom: 10%;
  }
  .table-content td {
    font-size: 17px !important;
}
  .ag_ftr_box p.description {
    font-size: 16px;
  }
  // .section {
  //   letter-spacing: -0.4px !important;
  //   font-size: 18px !important
  // }
  .publishplatform {
    font-size: 18px !important;
    letter-spacing:-0.3px !important
  }
  .ag_heading_wrap::after,
  .ag_heading_center_wrap::after,
  .ag_heading_right_wrap::after {
    content: "";
    width: 126px !important;
  }
  body.home .ag_main_wrap.ag_builtwith_wrap {
    margin-top: 0;
    margin-bottom: 0;
  }

  body.home .ag_opportunities_box p,
  body.home .ag_opportunities_box a {
    font-size: 18px;
  }
  // .cactive-descrip-one {
  //   font-size: 21px !important;
  // }
  body.home .ag_oppbullets li {
    font-size: 18px;
  }

  body.home .ag_opportunities_box {
    min-height: 532px;
    max-height: 532px;
  }

  body.home .ag_special_offer_text p {
    font-size: 16px;
  }

  body.home .ag_publishjournal_wrap span.publish-i-dot::before {
    top: 6px;
    left: 2px;
  }

  body.home .ag_publishjournal_text {
    padding: 60px 60px 70px 60px;
  }

  body.home .ag_heading_center_wrap.our-partner:after {
    width: 32%;
  }

  body.home .ag_newways_wrap img {
    margin-top: -5px;
  }

  body.home .ag_megajournals_wrap .ag_heading_center_wrap:after {
    width: 19%;
  }

  body.home .ag_publishjournal_text .ag_heading_wrap:after {
    width: 30%;
  }

  body.home .ag_resourcehub_box p,
  body.home .ag_resourcehub_box a {
    font-size: 17px;
  }

  body.home .ag_special_offer_text {
    padding: 60px;
  }
  .platformm {
    font-size: 17px !important
  }
  body.home .ag_special_offer_wrap .ag_heading_wrap h2 {
    font-size: 26px !important;
}

  body.home .ag_special_offer_img p img {
    width: 45px;
  }

  /*Sell Page*/
  body.sell .Transforming-btm-text h4 *,
  body.sell .Transforming-btm-text h4 {
    font-size: 26px;
    line-height: 39px;
  }
  .convinced-img-wrp .convinced-text-col p {
    font-size: 16px !important;
  }
 
  body.sell .Transforming-btm-text .pera-text {
    font-size: 18px;
    line-height: 31px;
    max-width: 100%;
    padding: 0 170px;
  }

  body.sell img.sell_logo {
    max-width: 83%;
  }

  body.sell .nation-right-col p {
    font-size: 18px;
    line-height: 28px;
  }
  .Transforming-btm-text .pera-text {
    font-size: 22px;
  }
  .content-area ul li {
    font-size: 18px !important;
  }

  body.sell .convinced-img-wrp.convinced-col-3 img {
    height: 280px;
  }

  body.sell .row.about-sec .col-lg-6 {
    width: 55%;
  }

  body.sell .row.about-sec .col-lg-6.text-center {
    width: 45%;
  }

  body.sell .row.about-sec .col-lg-6 .pera-text {
    font-size: 18px;
    line-height: 30px;
  }

  body.sell .row.about-sec .col-lg-6.text-center span {
    font-size: 21.7px;
    margin: 0 0 0 -19px;
  }

  body.sell .nation-right-col {
    padding-left: 50px;
  }

  body.sell .col-lg-7.choosing-main-text {
    width: calc(64% - 80px);
    margin: 0 80px 0 0;
  }

  body.sell .col-lg-4.choosingg-main-img {
    width: 30%;
  }

  body.sell .row.choosing-main-col-row.my-5.px-lg-5.px-3 {
    padding: 0 !important;
    align-items: start;
  }

  body.sell .row.choosing-main-col-row .pera-text.mb-5 {
    margin-bottom: 3rem !important;
  }

  body.sell .convinced-col-1 img,
  body.sell .convinced-col-2 .convinced-img-col img,
  body.sell .convinced-col-3 .convinced-img-col img,
  body.sell .convinced-col-4 .convinced-img-col img {
    width: 98.5%;
  }

  body.sell .convinced-right-text.convinced-text,
  body.sell .convinced-left-text.convinced-text {
    width: 66%;
    right: 55px;
  }

  body.sell .convinced-left-text.convinced-text {
    right: 290px;
  }

  body.sell .convinced-img-wrp .convinced-text-col p {
    font-size: 17px;
    line-height: 25px;
  }

  body.sell .convinced-left-text.convinced-text {
    right: 323px;
    width: 63%;
  }

  body.sell .convinced-col-4 .convinced-text-col {
    top: -18px;
  }

  body.sell .convinced-col-2 .convinced-text-col,
  body.sell .convinced-col-1 .convinced-text-col {
    top: -20px;
  }

  body.sell .convinced-col-1 .convinced-text-col {
    padding: 0 30px 0 10px;
  }

  body.sell .community-col.ag_heading_center_wrap p {
    font-size: inherit;
    line-height: inherit;
  }
  .global-publish-challenges h4 { 
    letter-spacing: -0.8px !important;
  }
 
  body.sell .foreign-university-text h4 {
    font-size: 28px;
    line-height: 50px;
    margin-bottom: 20px;
  }

  body.sell .container.container-full.Transforming-btm-col h2 span {
    font-size: inherit;
  }
  .convinced-img-wrp .convinced-text-col p.review {
    width:100%;
    font-size: 24px; 
  }
  body.sell .container.container-full.Transforming-btm-col .ag_heading_center_wrap span::after {
    width: 7px;
    height: 7px;
    top: 7px;
    left: 2px;
  }

  /*Sell Foreign Page*/
  body.sell-fr .Transforming-btm-text h4 *,
  body.sell .Transforming-btm-text h4 {
    font-size: 26px;
    line-height: 39px;
  }

  body.sell-fr .Transforming-btm-text .pera-text {
    font-size: 19px;
    line-height: 31px;
    max-width: 100%;
    padding: 0 65px;
  }

  body.sell-fr img.sell_logo {
    max-width: 100%;
  }

  body.sell-fr .row.about-sec .col-lg-6 {
    width: 57%;
  }

  body.sell-fr .row.about-sec .col-lg-6.text-center {
    width: 43%;
  }

  body.sell-fr .row.about-sec .col-lg-6 .pera-text {
    font-size: 17px;
  }

  body.sell-fr .row.about-sec .col-lg-6.text-center span {
    font-size: 21.7px;
    margin: 0 0 0 -19px;
  }
  body.home .ag_special_offer_text span.Offers {
    font-size: 24px;
  }
  body.sell-fr .nation-right-col {
    padding-left: 50px;
  }

  body.sell-fr .col-lg-7.choosing-main-text {
    width: calc(70% - 80px);
    margin: 0 80px 0 0;
  }

  body.sell-fr .col-lg-4.choosingg-main-img {
    width: 30%;
  }
  .pera-text-att {
    font-size: 18px
  }
  .pera-text.para {
    font-size: 18px;
    letter-spacing: -0.3px
  }
  body.sell-fr .row.choosing-main-col-row.my-5.px-lg-5.px-3 {
    padding: 0 !important;
    align-items: start;
  }
  .postpublishing {
    font-size: 18px !important;
    letter-spacing: -0.2px
  }
  body.sell-fr .row.choosing-main-col-row .pera-text.mb-5 {
    margin-bottom: 2.4rem !important;
  }

  body.sell-fr .convinced-col-1 img,
  body.sell-fr .convinced-col-2 .convinced-img-col img,
  body.sell-fr .convinced-col-3 .convinced-img-col img,
  body.sell-fr .convinced-col-4 .convinced-img-col img {
    width: 98.5%;
  }

  body.sell-fr .convinced-right-text.convinced-text,
  body.sell-fr .convinced-left-text.convinced-text {
    width: 66%;
    right: 55px;
  }
  
  .convinced-img-wrp .convinced-text-col p.firmly {
    width:100%;
    font-size: 24px; 
  }

  body.sell-fr .convinced-left-text.convinced-text {
    right: 290px;
  }
  // .ag_heading_wrap h2,
  // .ag_heading_center_wrap h2,
  // .ag_heading_right_wrap h2,
  // .ag_partners_wrap .ag_heading_center_wrap h2,
  // .ag_partners_wrap .ag_heading_center_wrap h2 span {
  //   font-size: 42px !important;
  // }
  .community-col p {
    font-size: 42px !important;
  }
  body.sell-fr .convinced-img-wrp .convinced-text-col p {
    font-size: 17px;
    line-height: 25px;
  }
  .pera-text-des {
    font-size: 16px;
    margin-bottom: 1rem !important;
    line-height: 26px;
    font-family: "Montserrat", sans-serif;
    color: #464444;
  }
  .convinced-col-1 img {
    width: 100% !important;
  }
  body.sell-fr .convinced-left-text.convinced-text {
    right: 350px;
    width: 62%;
  }
  .convinced-img-wrp .convinced-text-col p.global {
    width:100%;
    font-size: 24px; 
  }
  body.sell-fr .convinced-col-4 .convinced-text-col {
    top: -18px;
  }
  .globalvisibility { 
    font-size: 18px !important;
    width:100% !important
  }
  body.sell-fr .convinced-col-2 .convinced-text-col,
  body.sell-fr .convinced-col-1 .convinced-text-col {
    top: -20px;
  }
  .convinced-img-wrp .convinced-text-col p.welev {
    width:100%;
    font-size: 23px; 
  }
  .peerreviewers {
    width:85% !important; 
  }
  body.sell-fr .convinced-col-1 .convinced-text-col {
    padding: 0 30px 0 10px;
  }
  .science {
    font-size: 16px !important
  }
  // .content-area ul li,
  // .pera-text {
  //   letter-spacing: -0.4px !important;
  //     font-size: 16px !important;
  //     line-height: 26px;
  // }
  .content-area ul li {
    font-size: 17px;
    margin-right: 9px;
  }
  .about-sec p {
    font-size: 16px !important;
  }
  body.sell-fr .community-col.ag_heading_center_wrap p {
    font-size: inherit;
    line-height: inherit;
  }
  .banner-section .title {
    font-size: 37px !important;
}
  body.sell-fr .foreign-university-text h4 {
    font-size: 28px;
    line-height: 50px;
    margin-bottom: 20px;
  }
  body.home .ag_special_offer_text span.publisharticle {
    font-size: 18px;
  }
  // .article_col_box .top_title h4 {
  //   font-size: 30px !important;
  // }
  .article_col_box .article-table-title.article p {
    font-size: 18px;
  }
  .part {
    font-size: 18px
  }
  body.home .ag_special_offer_text span.publish {
    font-size: 37px;
  }

  body.sell-fr .container.container-full.Transforming-btm-col h2 span {
    font-size: inherit;
  }

  // .warm-slider-des-wrp span.description { 
  //   letter-spacing: -0.2px;
  //   font-size: 15px;
  // }
  body.sell-fr .container.container-full.Transforming-btm-col .ag_heading_center_wrap span::after {
    width: 7px;
    height: 7px;
    top: 7px;
    left: 2px;
  }
  .plenty {
    width:100% !important; 
  }
  .effortsarchiving {
    font-size: 18px !important;
    letter-spacing: -0.6px !important
  }
  .convinced-col-4 .convinced-img-col img {
    width: 100% !important;
  }
  // .convinced-img-wrp .convinced-text-col h4 {
  //   margin-left: 28% !important;
  // }
  .updating-article p {
    font-size: 18px !important;
    letter-spacing: -0.4px
  }
  .about-sec p {
    font-size: 15px;
  }
  body.sell-fr .global-publish-challenges .container {
    padding: 0 90px;
  }
  .control {
    width:100% !important; 
  }

  body.sell-fr .global-publish-challenges .row.main-global-publish-challenge .pera-text {
    font-size: 18px;
  }
  body.home .ag_resourcehub_box p,
  .ag_resourcehub_box a {
    font-size: 15px;
  }
  body.sell-fr img.sell-logo {
    max-width: 88%;
  }
  .ag_resourcehub_box a.unlockLink { font-size: 20.5px; }
}

@media only screen and (min-width: 1500px) and (max-width: 1590px) {
  body.home .ag_newways_wrap .ag_special_offer_btn {
    left: 60px;
  }

  body.home .ag_banner_wrap .ag_content_text {
    bottom: 10%;
  }

  body.home .ag_main_wrap.ag_builtwith_wrap {
    margin-top: 0;
    margin-bottom: 0;
  }

  body.home .ag_opportunities_box p,
  .ag_opportunities_box a {
    font-size: 17.5px;
  }

  body.home .ag_oppbullets li {
    font-size: 18px;
  }

  body.home .ag_opportunities_box {
    min-height: 490px;
    max-height: 490px;
  }

  body.home .ag_special_offer_text p {
    font-size: 17px;
  }

  body.home .ag_publishjournal_wrap span.publish-i-dot::before {
    top: 6px;
    left: 2px;
  }

  body.home .ag_publishjournal_text {
    padding: 60px 60px 70px 60px;
  }

  body.home .ag_heading_center_wrap.our-partner:after {
    width: 32%;
  }

  body.home .ag_newways_wrap img {
    margin-top: -5px;
  }

  body.home .ag_megajournals_wrap .ag_heading_center_wrap:after {
    width: 19%;
  }

  body.home .ag_publishjournal_text .ag_heading_wrap:after {
    width: 25%;
  }

  body.home .ag_resourcehub_box p,
  body.home .ag_resourcehub_box a {
    font-size: 20px;
  }

  body.home .ag_special_offer_text {
    padding: 70px;
  }

  body.home .ag_special_offer_wrap .ag_heading_wrap h2 {
    font-size: 24px;
  }

  body.home .ag_special_offer_img p img {
    width: 45px;
  }

  /*Sell Page*/
  body.sell .Transforming-btm-text h4 *,
  body.sell .Transforming-btm-text h4 {
    font-size: 26px;
    line-height: 39px;
  }

  body.sell .Transforming-btm-text .pera-text {
    font-size: 18px;
    line-height: 31px;
    max-width: 100%;
    padding: 0 170px;
  }

  body.sell img.sell_logo {
    max-width: 77%;
  }

  body.sell .nation-right-col p {
    font-size: 18px;
    line-height: 28px;
  }

  body.sell .convinced-img-wrp.convinced-col-3 img {
    height: 280px;
  }

  body.sell .row.about-sec .col-lg-6 {
    width: 55%;
  }

  body.sell .row.about-sec .col-lg-6.text-center {
    width: 45%;
  }

  body.sell .row.about-sec .col-lg-6 .pera-text {
    font-size: 18px;
    line-height: 30px;
  }

  body.sell .row.about-sec .col-lg-6.text-center span {
    font-size: 21.7px;
    margin: 0 0 0 -19px;
  }

  body.sell .nation-right-col {
    padding-left: 50px;
  }

  body.sell .col-lg-7.choosing-main-text {
    width: calc(64% - 80px);
    margin: 0 80px 0 0;
  }

  body.sell .col-lg-4.choosingg-main-img {
    width: 30%;
  }

  body.sell .row.choosing-main-col-row.my-5.px-lg-5.px-3 {
    padding: 0 !important;
    align-items: start;
  }

  body.sell .row.choosing-main-col-row .pera-text.mb-5 {
    margin-bottom: 3rem !important;
  }

  body.sell .convinced-col-1 img,
  body.sell .convinced-col-2 .convinced-img-col img,
  body.sell .convinced-col-3 .convinced-img-col img,
  body.sell .convinced-col-4 .convinced-img-col img {
    width: 98.5%;
  }

  body.sell .convinced-right-text.convinced-text,
  body.sell .convinced-left-text.convinced-text {
    width: 66%;
    right: 55px;
  }

  body.sell .convinced-left-text.convinced-text {
    right: 290px;
  }

  body.sell .convinced-img-wrp .convinced-text-col p {
    font-size: 17px;
    line-height: 25px;
  }

  body.sell .convinced-left-text.convinced-text {
    right: 323px;
    width: 63%;
  }

  body.sell .convinced-col-4 .convinced-text-col {
    top: -18px;
  }

  body.sell .convinced-col-2 .convinced-text-col,
  body.sell .convinced-col-1 .convinced-text-col {
    top: -20px;
  }

  body.sell .convinced-col-1 .convinced-text-col {
    padding: 0 30px 0 10px;
  }

  body.sell .community-col.ag_heading_center_wrap p {
    font-size: inherit;
    line-height: inherit;
  }

  body.sell .foreign-university-text h4 {
    font-size: 28px;
    line-height: 50px;
    margin-bottom: 20px;
  }

  body.sell .container.container-full.Transforming-btm-col h2 span {
    font-size: inherit;
  }

  body.sell .container.container-full.Transforming-btm-col .ag_heading_center_wrap span::after {
    width: 7px;
    height: 7px;
    top: 7px;
    left: 2px;
  }

  /*Sell Foreign Page*/
  body.sell-fr .Transforming-btm-text h4 *,
  body.sell .Transforming-btm-text h4 {
    font-size: 26px;
    line-height: 39px;
  }

  body.sell-fr .Transforming-btm-text .pera-text {
    font-size: 19px;
    line-height: 31px;
    max-width: 100%;
    padding: 0 65px;
  }

  body.sell-fr img.sell_logo {
    max-width: 100%;
  }

  body.sell-fr .row.about-sec .col-lg-6 {
    width: 57%;
  }

  body.sell-fr .row.about-sec .col-lg-6.text-center {
    width: 43%;
  }

  body.sell-fr .row.about-sec .col-lg-6 .pera-text {
    font-size: 17px;
  }

  body.sell-fr .row.about-sec .col-lg-6.text-center span {
    font-size: 21.7px;
    margin: 0 0 0 -19px;
  }

  body.sell-fr .nation-right-col {
    padding-left: 50px;
  }

  body.sell-fr .col-lg-7.choosing-main-text {
    width: calc(70% - 80px);
    margin: 0 80px 0 0;
  }

  body.sell-fr .col-lg-4.choosingg-main-img {
    width: 30%;
  }

  body.sell-fr .row.choosing-main-col-row.my-5.px-lg-5.px-3 {
    padding: 0 !important;
    align-items: start;
  }

  body.sell-fr .row.choosing-main-col-row .pera-text.mb-5 {
    margin-bottom: 2.4rem !important;
  }

  body.sell-fr .convinced-col-1 img,
  body.sell-fr .convinced-col-2 .convinced-img-col img,
  body.sell-fr .convinced-col-3 .convinced-img-col img,
  body.sell-fr .convinced-col-4 .convinced-img-col img {
    width: 98.5%;
  }

  body.sell-fr .convinced-right-text.convinced-text,
  body.sell-fr .convinced-left-text.convinced-text {
    width: 66%;
    right: 55px;
  }

  body.sell-fr .convinced-left-text.convinced-text {
    right: 290px;
  }

  body.sell-fr .convinced-img-wrp .convinced-text-col p {
    font-size: 17px;
    line-height: 25px;
  }

  body.sell-fr .convinced-left-text.convinced-text {
    right: 400px;
    width: 62%;
  }

  body.sell-fr .convinced-col-4 .convinced-text-col {
    top: -18px;
  }

  body.sell-fr .convinced-col-2 .convinced-text-col,
  body.sell-fr .convinced-col-1 .convinced-text-col {
    top: -20px;
  }

  body.sell-fr .convinced-col-1 .convinced-text-col {
    padding: 0 30px 0 10px;
  }

  body.sell-fr .community-col.ag_heading_center_wrap p {
    font-size: inherit;
    line-height: inherit;
  }

  body.sell-fr .foreign-university-text h4 {
    font-size: 28px;
    line-height: 50px;
    margin-bottom: 20px;
  }

  body.sell-fr .container.container-full.Transforming-btm-col h2 span {
    font-size: inherit;
  }

  body.sell-fr .container.container-full.Transforming-btm-col .ag_heading_center_wrap span::after {
    width: 7px;
    height: 7px;
    top: 7px;
    left: 2px;
  }

  body.sell-fr .global-publish-challenges .container {
    padding: 0 175px;
  }

  body.sell-fr .global-publish-challenges .row.main-global-publish-challenge .pera-text {
    font-size: 18px;
  }

  body.sell-fr img.sell-logo {
    max-width: 88%;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1699px) {
  .ag_menu > ul > li ul.subNavBox li:nth-child(2) .manageNavi .sub-lines {
    max-width: 210px;
    min-width: 190px !important;
}
  // .header-main-row .ag_menu > ul > li ul li a {
  //   font-size: 15px;
  // }
  body.home .ag_newways_wrap .ag_special_offer_btn {
    left: 0;
  }

  body.home .ag_banner_wrap .ag_content_text {
    bottom: 10%;
  }

  body.home .ag_main_wrap.ag_builtwith_wrap {
    margin-top: 0;
    margin-bottom: 0;
  }
 
  body.home .ag_opportunities_box p,
  body.home .ag_opportunities_box a {
    font-size: 18px;
  }

  .cactive-descrip-one {
    font-size: 23px !important;
  }

  .about-sec p {
    font-size: 19px;
  }

  // p .cactive-descrip:first-child {
  //   font-size: 22px !important;
  // }

  body.home .ag_oppbullets li {
    font-size: 23px;

  }

  .updating-article p {
    font-size: 21px;
  }

  .ag_ftr_box p.description {
    font-size: 18px;
  }

  body.home .ag_opportunities_box {
    min-height: 555px;
    max-height: 555px;
  }

  body.home .ag_special_offer_img p {
    font-size: 40px;
  }

  .convinced-col-2 .convinced-img-col img {
    width: 100%;
  }

  body.home .ag_special_offer_wrap .ag_heading_wrap h2 {
    font-size: 32px;
    line-height: 46px;
  }

  body.home .ag_main_wrap.ag_newways_wrap span.publish-i-dot:before {
    top: 8px;
    left: 2px;
  }

  body.home .ag_main_wrap.ag_megajournals_wrap span.publish-i-dot::before {
    top: 8px;
    left: 3px;
  }

  body.home .ag_special_offer_text p {
    font-size: 19px;
  }

  body.home .ag_opportunities_box h3 {
    font-size: 21px;
  }

  body.home .ag_publishjournal_wrap span.publish-i-dot::before {
    top: 14px;
    left: 2px;
  }

  body.home .ag_publishjournal_text {
    padding: 60px 60px 70px 60px;
  }

  body.home .ag_heading_center_wrap.our-partner:after {
    width: 41%;
  }

  body.home .ag_newways_wrap img {
    margin-top: -5px;
  }

  body.home .ag_megajournals_wrap .ag_heading_center_wrap:after {
    width: 26%;
  }

  body.home .ag_publishjournal_text .ag_heading_wrap:after {
    width: 30%;
  }

  body.home .ag_resourcehub_box p,
  .ag_resourcehub_box a {
    font-size: 20px;
  }

  body.home .ag_special_offer_text .ag_heading_wrap::after {
    // width: 225px;
    width: 162px
  }

  body.home .howitworks {
    height: 1050px;
  }

  body.home .ag_howworks_btn {
    bottom: 10%;
  }

  body.home .ag_special_offer_text {
    padding: 60px;
  }

  body.home .ag_special_offer_img p img {
    width: 45px;
  }

  body.home .ag_special_offer_text span {
    // font-size: 21px;
    font-size: 15px;
  }

  body.home .ag_special_offer_text span.publish {
    font-size: 48px;
  }

  body.home .ag_special_offer_text span.publisharticle {
    font-size: 24px;
  }

  .content-area ul li {
    font-size: 21px;
  }

  body.home .ag_special_offer_text span.Offers {
    font-size: 32px;
  }

  .updating-article p {
    font-size: 22px;
  }

  body.home .ag_main_wrapmain-col {
    height: 400px;
  }

  /*Sell Page*/
  body.sell .Transforming-btm-text h4 *,
  body.sell .Transforming-btm-text h4 {
    font-size: 28px;
    line-height: 45px;
  }

  body.sell .Transforming-btm-text .pera-text {
    font-size: 22px;
    line-height: 32px;
    max-width: 100%;
    padding: 0 250px;
  }

  body.sell img.sell_logo {
    max-width: 80%;
  }

  body.sell .nation-right-col p {
    font-size: 21px;
    line-height: 28px;
  }

  body.sell .convinced-img-wrp.convinced-col-3 img {
    height: 280px;
  }

  body.sell .row.about-sec .col-lg-6 {
    width: 50%;
  }

  body.sell .row.about-sec .col-lg-6.text-center {
    width: 50%;
  }

  body.sell .row.about-sec .col-lg-6 .pera-text {
    font-size: 20px;
    line-height: 30px;
  }

  body.sell .row.about-sec .col-lg-6.text-center span {
    font-size: 28px;
    margin: 0 0 0 -19px;
  }

  body.sell .nation-right-col {
    padding-left: 50px;
  }

  body.sell .col-lg-7.choosing-main-text {
    width: calc(64% - 80px);
    margin: 0 80px 0 0;
  }

  body.sell .col-lg-4.choosingg-main-img {
    width: 30%;
  }

  body.sell .row.choosing-main-col-row.my-5.px-lg-5.px-3 {
    padding: 0 !important;
    align-items: start;
  }

  body.sell .row.choosing-main-col-row .pera-text.mb-5 {
    margin-bottom: 3rem !important;
  }

  body.sell .convinced-col-1 img,
  body.sell .convinced-col-2 .convinced-img-col img,
  body.sell .convinced-col-3 .convinced-img-col img,
  body.sell .convinced-col-4 .convinced-img-col img {
    width: 98.5%;
  }

  body.sell .convinced-right-text.convinced-text,
  body.sell .convinced-left-text.convinced-text {
    width: 66%;
    right: 80px;
  }

  body.sell .convinced-left-text.convinced-text {
    right: 290px;
  }

  body.sell .convinced-img-wrp .convinced-text-col p {
    font-size: 19px;
    line-height: 25px;
  }

  body.sell .convinced-left-text.convinced-text {
    right: 425px;
    width: 66%;
  }

  body.sell .convinced-col-4 .convinced-text-col {
    top: -18px;
  }

  body.sell .convinced-col-2 .convinced-text-col,
  body.sell .convinced-col-1 .convinced-text-col {
    top: -20px;
  }

  body.sell .convinced-col-1 .convinced-text-col {
    padding: 0 30px 0 10px;
  }

  body.sell .community-col.ag_heading_center_wrap p {
    font-size: inherit;
    line-height: inherit;
  }

  body.sell .foreign-university-text h4 {
    font-size: 36px;
    line-height: 60px;
    margin-bottom: 20px;
  }

  body.sell .container.container-full.Transforming-btm-col h2 span {
    font-size: inherit;
  }

  body.sell .container.container-full.Transforming-btm-col .ag_heading_center_wrap span::after {
    width: 7px;
    height: 7px;
    top: 7px;
    left: 2px;
  }

  body.sell .ag_special_offer_btn .ag_btn_red {
    font-size: 26px;
    width: 380px;
    height: 55px;
  }

  /*Sell Foreign Page*/
  body.sell-fr .Transforming-btm-text h4 *,
  body.sell-fr .Transforming-btm-text h4 {
    font-size: 28px;
    line-height: 39px;
  }

  body.sell-fr .Transforming-btm-text .pera-text {
    font-size: 22px;
    line-height: 31px;
    max-width: 100%;
    padding: 0 65px;
  }

  body.sell-fr img.sell_logo {
    max-width: 100%;
  }

  body.sell-fr .row.about-sec .col-lg-6 {
    width: 57%;
  }

  body.sell-fr .row.about-sec .col-lg-6.text-center {
    width: 43%;
  }

  body.sell-fr .row.about-sec .col-lg-6 .pera-text {
    font-size: 23px;
  }

  body.sell-fr .row.about-sec .col-lg-6.text-center span {
    font-size: 26.7px;
    margin: 0 0 0 -19px;
  }

  body.sell-fr .nation-right-col {
    padding-left: 50px;
  }

  body.sell-fr .col-lg-7.choosing-main-text {
    width: calc(70% - 80px);
    margin: 0 80px 0 0;
  }

  body.sell-fr .col-lg-4.choosingg-main-img {
    width: 30%;
  }

  body.sell-fr .row.choosing-main-col-row.my-5.px-lg-5.px-3 {
    padding: 0 !important;
    align-items: start;
  }

  body.sell-fr .row.choosing-main-col-row .pera-text.mb-5 {
    margin-bottom: 2.4rem !important;
  }

  body.sell-fr .convinced-col-1 img,
  body.sell-fr .convinced-col-2 .convinced-img-col img,
  body.sell-fr .convinced-col-3 .convinced-img-col img,
  body.sell-fr .convinced-col-4 .convinced-img-col img {
    width: 98.5%;
  }

  body.sell-fr .convinced-right-text.convinced-text,
  body.sell-fr .convinced-left-text.convinced-text {
    width: 66%;
    right: 80px;
  }

  body.sell-fr .convinced-left-text.convinced-text {
    right: 290px;
  }

  body.sell-fr .convinced-img-wrp .convinced-text-col p {
    font-size: 19px;
    line-height: 30px;
  }

  body.sell-fr .convinced-left-text.convinced-text {
    right: 445px;
    width: 62%;
  }

  body.sell-fr .convinced-col-4 .convinced-text-col {
    top: -18px;
  }

  body.sell-fr .convinced-col-2 .convinced-text-col,
  body.sell-fr .convinced-col-1 .convinced-text-col {
    top: -20px;
  }

  body.sell-fr .convinced-col-1 .convinced-text-col {
    padding: 0 30px 0 10px;
  }

  body.sell-fr .community-col.ag_heading_center_wrap p {
    font-size: inherit;
    line-height: inherit;
  }

  body.sell-fr .foreign-university-text h4 {
    font-size: 28px;
    line-height: 50px;
    margin-bottom: 20px;
  }

  body.sell-fr .container.container-full.Transforming-btm-col h2 span {
    font-size: inherit;
  }

  body.sell-fr .container.container-full.Transforming-btm-col .ag_heading_center_wrap span::after {
    width: 7px;
    height: 7px;
    top: 7px;
    left: 2px;
  }

  body.sell-fr .global-publish-challenges .container {
    padding: 0 225px;
  }

  body.sell-fr .global-publish-challenges .row.main-global-publish-challenge .pera-text {
    font-size: 20px;
  }

  body.sell-fr img.sell-logo {
    max-width: 88%;
  }

  body.sell-fr .container.container-full.Transforming-btm-col .ag_heading_center_wrap span::after {
    width: 7px;
    height: 7px;
    top: 7px;
    left: 2px;
  }

  body.sell-fr .ag_special_offer_btn .ag_btn_red {
    font-size: 26px;
    width: 380px;
    height: 55px;
  }

  body.sell-fr .global-publish-challenges h4 {
    font-size: 23px;
    line-height: 29px;
  }

  .warm-slider-des-wrp span.description {
    font-size: 18px;
  }

  // body.home .ag_oppbullets li {
  //   font-size: 18px;
  // }

  .ag_newsletter_box h4 {
    font-size: 30px;
  }

  .pera-text {
    font-size: 20px
  }
  .part {
    font-size: 21px
  }

  .science {
    font-size: 21px !important
  }

  .platformm {
    font-size: 20px !important
  }

  .publishplatform {
    font-size: 21px !important
  }

  .regplatform {
    font-size: 21px !important;
    letter-spacing: -0.2px
  }

  .reviewsysytematic {
    font-size: 21px !important;
    letter-spacing: -0.2px
  }

  .pera-text-att {
    font-size: 18px
  }

  .abstarct {
    font-size: 20px
  }

  .section {
    font-size: 21px
  }

  .automated {
    font-size: 20px !important;
    letter-spacing: -0.2px
  }
  .takequiz{
    font-size: 32px!important
  }
  .global-publish-challenges h4 {

    font-size: 22px;

  }

  .convinced-col-1 img {
    width: 100%;
  }

 
 

   

  // .convinced-img-wrp .convinced-text-col p {
  //   font-size: 22px;
  //   letter-spacing: -0.2px;
  // }

 
  .globalvisibility { 
    font-size: 18px !important; 
  }
  .table-content td {
    letter-spacing: -0.3px;
    font-size: 21px;
  }

  .efforts {
    font-size: 21px;
    letter-spacing: -0.2px
  }

  .effortsarchiving {
    font-size: 21px;
    letter-spacing: -0.3px
  }

  .providing {
    font-size: 21px;
  }

  .significance {
    font-size: 21px;
  }

  .content-area .small-title {
    font-size: 32px;
  }

  .postpublishing {
    font-size: 21px !important;
    letter-spacing: -0.2px
  }

  .alternate {
    font-size: 21px
  }

  .peer {
    font-size: 21px
  }

  .pera-text-attgg {
    font-size: 21px
  }

  .pera-text-des {
    font-size: 17px
  }

  // .convinced-img-wrp .convinced-text-col h4 {
  //   margin-left: 15%;
  // }

  // .convinced-img-wrp .convinced-text-col p {
  //   margin-left: 13%;

  // }

  .convinced-img-wrp .convinced-text-col p.welev {
    font-size: 18px; 
  }

  .convinced-img-wrp .convinced-text-col p.review {
    font-size: 18px; 
  }

  .convinced-img-wrp .convinced-text-col p.global {
    font-size: 18px; 
  }

  .convinced-img-wrp .convinced-text-col p.firmly {
    font-size: 18px; 
  }

  .pera-text.para {
    font-size: 18px;
    letter-spacing: -0.2px
  }

  .updating-article p {
    font-size: 18px;
    letter-spacing: -0.2px
  }

  // .convinced-img-wrp .convinced-text-col p:last-child {
  //   margin-left: 3%;
  // }
  .pera-text.newclass {
    font-size: 21px
  }
  .banner-section .title {
    font-size: 48px !important;
}
.ag_footer_wrap p,
.ag_footer_wrap a {
  font-size: 16px;
}
.university-title {
  font-size: 36px !important;
}
.banner-section .title span::before {
  content: '';
  width: 11px;
  height: 11px;
  top: 7px;
  left: 2px;
}
  body.sell-fr .global-publish-challenges h4 * {
    font-size: inherit;
    line-height: inherit;
  }
}
@media only screen and (min-width: 2500px) and (max-width: 2599px) {
  .ag_menu > ul > li ul.subNavBox li:nth-child(1) .manageNavi .sub-lines {
    max-width: 180px;
    min-width: 180px !important;
}
.ag_menu > ul > li ul.subNavBox li:nth-child(3) .manageNavi .sub-lines {
  max-width: 180px;
  min-width: 180px !important;
}
.ag_menu > ul > li ul.subNavBox li:nth-child(4) .manageNavi .sub-lines {
  max-width: 180px;
  min-width: 180px !important;
}
.ag_menu > ul > li ul.subNavBox li:nth-child(6) .manageNavi .sub-lines {
  max-width: 180px;
  min-width: 180px !important;
}
.ag_menu > ul > li ul.subNavBox li:nth-child(7) .manageNavi .sub-lines {
  max-width: 180px;
  min-width: 180px !important;
}
}
@media only screen and (min-width: 1700px) and (max-width: 1799px) {
  // .header-main-row .ag_menu > ul > li ul li a {
  //   font-size: 16px;
  // }
}
@media only screen and (min-width: 1800px) and (max-width: 1890px) {
  // .header-main-row .ag_menu > ul > li ul li a {
  //   font-size: 17px;
  // }
}
.center-image.videoPageChk iframe { border-radius: 20px; box-shadow: 0px 0px 8px -3px #ff8300; }
///////////////////////////////////////////////// VARUN CSS ////////////////////////////////////////////
@media screen and (max-width: 3400px) { 
  .ag_opportunities_box p,
  .ag_opportunities_box a {
    font-size: 18px;
    color: #000000;
    text-align: left;
    font-weight: 400;
  }
  .ag_opportunities_box a {
    font-weight: 600;
  }

  .ag_menu > ul > li ul.subNavBox li:nth-child(1) .manageNavi .sub-lines { 
    // width: 161px;
    max-width: 180px; min-width: auto;
  }
  .ag_menu > ul > li ul.subNavBox li:nth-child(2) .manageNavi .sub-lines { 
    // width: 240px; 
    max-width: 200px; min-width: auto;
  }
  .ag_menu > ul > li ul.subNavBox li:nth-child(3) .manageNavi .sub-lines { 
    width: 160px; 
    max-width: 180px; min-width: auto;
  }
  .ag_menu > ul > li ul.subNavBox li:nth-child(4) .manageNavi .sub-lines { width: 158px; }
  .ag_menu > ul > li ul.subNavBox li:nth-child(5) .manageNavi .sub-lines { width: 210px; }
  .ag_menu > ul > li ul.subNavBox li:nth-child(6) .manageNavi .sub-lines { width: 160px; min-width: auto;max-width: 180px; }
  .ag_menu > ul > li ul.subNavBox li:nth-child(7) .manageNavi .sub-lines {
    // width: 180px;
    max-width: 180px;min-width: auto;
  }
  .header-main-row .ag_menu > ul > li ul li { width: 100%; }
 
 
}

@media screen and (max-width: 1920px) { 
  .leftSpace,
  .leftSpace03,
  .leftSpace04 {margin-left: 0; }

  .sub-lines { 
    max-width: 78%; 
    width: 100% !important;
        min-width: 100%;
  }
}

@media screen and (max-width: 1900px) { 
  .leftSpace {margin-left: 3vw; }
  .leftSpace03 {margin-left: 3vw; }
  .leftSpace04 {margin-left: 3vw; }
}

@media screen and (max-width: 1660px) { 
  .leftSpace.leftSpace01 {margin-left: 15vw; }
  .leftSpace {margin-left: 14vw; }
  .leftSpace03 {margin-left: 17vw; }
  .leftSpace04 {margin-left: 14vw; }
  
  .sub-lines { 
    max-width: 98%; 
  }
  .ag_menu>ul>li ul.subNavBox {
    & li {
        &:nth-child(1){
            & .manageNavi {
                & .sub-lines{
                    width: 138px;
                }
            }
        }
        &:nth-child(2){
            & .manageNavi {
                & .sub-lines{
                    width: 191px;
                }
            }
        }
        &:nth-child(3){
            & .manageNavi {
                & .sub-lines{
                    width: 138px;
                }
            }
        }
          &:nth-child(4){
            & .manageNavi {
                & .sub-lines{
                    width: 138px;
                }
            }
        }
           &:nth-child(5){
            & .manageNavi {
                & .sub-lines{
                    width: 190px;
                }
            }
        }
        &:nth-child(6){
            & .manageNavi {
                & .sub-lines{
                    width: 130px;
                }
            }
        }
        &:nth-child(7){
            & .manageNavi {
                & .sub-lines{
                    width: 150px;
                }
            }
        }
        &:last-child{
            border-right: 0;
        }
        & a {
            text-align: left;
        }
    }
}
.header-main-row .ag_menu > ul > li ul li {
  width: auto;
}
}

@media screen and (max-width: 1500px) { 
  .leftSpace.leftSpace01 {margin-left: 21vw; }
    .leftSpace {
      margin-left: 19vw;
    }
    .leftSpace03 {
      margin-left: 22vw;
    }
    .leftSpace04 {
      margin-left: 15vw;
    }
}

@media screen and (max-width: 1440px) { 
  .leftSpace.leftSpace01 {margin-left: 21vw; }
  .leftSpace {
    margin-left: 21vw;
  }
  .leftSpace03 {
    margin-left: 22vw;
  }
  .leftSpace04 {
    margin-left: 15vw;
  }
  .hameMianBanner .ag_content_text { 
    bottom: 9vh;
    left: 5vh;
  }
  .hameMianBanner .ag_content_text .ag_btn_red {
    font-size: 22px;
     width: auto; 
   
  }
  .header-main-row {
    & .ag_menu > ul > li ul li a {
        font-size: 13px;
    }
    & .ag_menu > ul > li ul li .sub-lines { font-size: 13px;}
  }
}

@media screen and (max-width: 1380px) { 
  .ag_menu > ul > li > a img{
    margin-left: 3px;
  }
  .leftSpace.leftSpace01 {margin-left: 24vw; }
  .leftSpace {
    margin-left: 24vw;
  }
  .leftSpace03 {
    margin-left: 24vw;
  }
  .leftSpace04 {
    margin-left: 20vw;
  }
  .markitingHeader {
    max-width: 100%;
    padding: 0 2vw;
}

.header-main-row {
  & .ag_menu > ul > li a {
      font-size: 13px;
  }  
}
.hameMianBanner .ag_content_text {
  bottom: 8vh;
  left: 6vh;
}

}
@media screen and (max-width:1280px) { 
// .hameMianBanner .ag_content_text { 
//   bottom: 6vh;
//   left: 3vh;
//   width: 33vw;
//   min-width: 20px;
// }
  // .hameMianBanner .ag_content_text .ag_btn_red {
  //   font-size: 2vh;
  //   width: auto; 
  
  // }
}

@media screen and (max-width: 1025px) {  
  .hameMianBanner .hameMianContent { max-width: 416px; z-index: 2; top: 78%;}
  .hameMianBanner .hameMianContent span { font-size: 24px; }
  .hameMianBanner .hameMianContent h2,
  .hameMianBanner .hameMianContent h1 { font-size: 32px;}
  .hameMianBanner .hameMianContent .content { font-size: 24px; line-height: 28px; }
  .hameMianBanner .hameMianContent .ag_content_text { margin-top: 16px; }
  .hameMianBanner .ag_content_text .ag_btn_red { font-size: 18px; }
  // .header-main-row { padding-right: 40px; }
  .navNotify { position: absolute; right: 15px;}

 
}

@media screen and (max-width: 1023px) {
.menu-center-part.active {
  & .ag_menu {
     & .menulist {
         position: fixed;
         opacity: 1;
         visibility: visible;
         width: 100%;
         height: calc(100vh - 36px);
         overflow: auto;
         top: 56px;
         padding-top: 20px;
         padding-bottom: 20px;
         &:hover{
           & .subNavBox {
             display: block;
           }
         }
         & .subNavBox {
           display: none;
           transform: translateX(0);
           & li {
             display: block;
             text-align: left;
             border-bottom: 1px solid #dddddd;
             & .sub-lines {
               padding-left: 5px !important;
             }
           }
         }
     }
   }
 }
 a.ag_btn.mobileonly {
  display: block;
  height: auto;
}
}

@media screen and (max-width: 767px) {
  .ag_special_offer_img p img {
    width: 35px;
  }
  .ag_special_offer_img p { padding-left: 50px; }
  .ag_main_wrap {
    padding: 0 10px !important;
  }
  .maniSearch {
    position: fixed;
    top: 74px;
    width: 100%;
  }
  .videocontainer {
    & img {
      width: 100%;
    }
  }
  .ag_heading_center_wrap {
    &.our-partner.mt-5{
      margin-top: 0px !important;
    }
  }
  .megaRightContent {
    padding: 0 30px;
    & .paragraph {
      font-size: 18px;
      line-height: 24px;
    }
    & .megaList li {
      font-size: 18px;
      line-height: 24px;
      & b {
        font-size: 18px;
        line-height: 24px;
      }
      & span {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }


  .warm-slider-des-wrp span::after {
    top: -25px;
  }
  .ag_oppbullets li {
    font-size: 16px;
  }
  .ag_special_offer_text {
    padding: 20px;
  }
  .warm-slider-des .warm-subtitle {
    margin-bottom: 30px;
  }

  .ag_publishjournal_wrap .ag_heading_wrap h2 {
    padding-right: 0;
  }
  .ag_copyright_wrap {
    text-align: center;
    & p {
      padding: 10px 0;
    }
  }
  .warm-slider-des-wrp span { 
    font-size: 18px;
  }
  .faq-side-list { 
    display: flex;
    align-items: center;
    overflow: auto;
    padding-bottom: 0;
      & li {
      font-size: 16px;
      white-space: nowrap;
    }
  }
  .overviewscss {

  }
  .overviewscss.faq-overviewscss {
    display: block !important;
    & .blackbold  {
      word-wrap: break-word;
    }
  }
  .leftSpace,
  .leftSpace.leftSpace01 {
    margin-left: 0;
  }
  .leftSpace03 {
    margin-left: 0;
  }
  .leftSpace04 {
    margin-left: 0;
  }
  .convinced-img-wrp .convinced-text .convinced-text-col {
    padding: 20px !important;
  }
  .convinced-img-wrp .convinced-text {
    margin: 10px auto;
  }
  .foreign-university-text a.ag_btn_red { font-size: 22px;}

  .comePublic .dotPoint::before {
    top: 3px;
    left: 1px;
    width: 6px;
    height: 6px;
  }

  .hameMianBanner .ag_content_text { 
    bottom: 33vh;
    left: 3vh;
    width:80vw;
    min-width: 20px;
  }
  .hameMianBanner .ag_content_text .ag_btn_red {
    font-size: 2.333vh;
     width: auto; 
   
  }

  .ag_newways_wrap img {
    margin-top: -30px;
  }

  .ag_heading_wrap span.Offers { font-size: 21px; }
  .desktop-image.tagimg { display: none;}
  .ag_main_wrap.ag_newways_wrap .col-4.align-items-center.aos-init.aos-animate { margin-bottom: 10px; }
  .ag_heading_wrap .comePublic span,
  .ag_publishjournal_wrap .ag_heading_wrap h2,
  .ag_heading_wrap span, .ag_heading_center_wrap span, .ag_heading_right_wrap span, .ag_special_offer_wrap .ag_heading_wrap h2, .ag_publishjournal_wrap .ag_heading_wrap h2 { font-size: 20px; }
  .ag_publishjournal_text p { margin: 15px 0; }
  .ag_main_wrap .ag_heading_center_wrap h2 { font-size: 24px !important; }
  .warm-slider-des-wrp .sliderDescription .descriptionContent { width: 100%; padding-left: 0; }
  .warm-slider-des .warm-sliderHead .sliderContent { margin-left: 0; }
  .mobileShow {display: inline-block !important;}
  .deskShow { display: none !important;}
  .warm-slider-des-wrp .sliderImage { width: 120px; }
  .warm-slider-des .warm-sliderHead .sliderContent .titleWrap { font-size: 22px; }
  .warm-slider-des-wrp .sliderDescription { font-size: 16px; }
  .warm-slider-des .warm-sliderHead .sliderContent .designationWrap { font-size: 16px;}
  .warm-slider-des-wrp .sliderDescription .descriptionContent::before { height: 15px;}
  .warm-slider-des-wrp .sliderDescription .descriptionContent::after { height: 15px; top: -13px; left: -19px; }
  .ag_newsletter_box h4 { font-size: 20px; line-height: 25px;}
  a.ag_ftr_register { margin-top: -15px; }
  .journalBackWrap { display: block; text-align: center;}
  .journalBackWrap span { margin-bottom: 40px;  display: block; margin-right: 0; border-right: 0;}
  .main-group-btns { padding:0px; }
  
  .main-group-btns .groupcss { font-size: 16px; margin: 5px 0; }
  .journalInfoList .custom-li-journal a { font-size: 18px; }
  .custom-journalist-class li { margin-bottom: 2px; }
  .pera-text { font-size: 16px; line-height: 22px; }
  .content-area ul li { font-size: 16px; margin-bottom: 6px;}
  .processingAPCWrap .understandWrap .ag_special_offer_img p { width: 100%; font-size: 16px; padding-bottom: 23px; }
  .ag_heading_center_wrap h2 br { display: none;}
  .processingAPCWrap .tagul.underList li:last-child { font-size: 14px;     line-height: 20px; }
  .processingAPCWrap .tagul.underList li { font-size: 14px; }
  .col-lg-5.article_col_box.px-lg-0.px-3.customapccard { padding: 0 !important; margin: 30px auto; width: 94% !important;}
  .customapccard .row { padding: 0 0 16px 0;}
  .customapccard .row .row.m-0.px-4 { padding: 0 15px !important;}
  .customapccard .row .row.m-0.px-4 .article-table-title article { padding-left: 0;}
  .blue-border-text { margin-top: 20px;}
  .processingAPCWrap .ag_publishjournal_text.foreign-nation { left: 0; }
  .foreign-nation h4 { font-size: 20px; }
  .foreign-nation h6 {font-size: 18px; line-height: 26px; }
  .processingAPCWrap .ag_publishjournal_text.foreign-nation p { font-size: 16px; }
  .updating-article .tagp.indNat { margin-bottom: 30px; }
  .fontSize22,
  .fontSize20 { font-size: 16px !important; line-height: 22px !important;}
  .table-content td { font-size: 16px; line-height: 22px; padding: 6px 20px;  }
  .table-content th { font-size: 18px; }
  .table-content th.pl-5 { padding-left: 20px !important; }
  .texmanageBox { text-align: left; }
  .how-publish-icons { margin-top: 10px; }
  .archive-content { font-size: 16px; }
  .quizContent { margin-top: 0; }
  .quizRight .quizContent { margin-bottom: 30px;}
  .banner-section { height: 280px;  margin-top: 77px; position: relative; }
  .banner-section::before { content: ''; background: rgba(0,0,0,0.30); width: 100%; height: 100%;  position: absolute; z-index: 0; }
  .banner-section .page-title .title { position: relative; z-index: 1; color: #ffffff; }
  .Transforming-btm-text p.pera-text { font-size: 16px; line-height: 24px; }
  .Transforming-btm-col .ag_heading_center_wrap h2 { font-size: 26px; line-height: 31px; }
  .container-full .ag_heading_center_wrap span::after { width: 7px; height: 7px; left: 1px; top: 3px !important; }
  .sell_logo.tagimg { max-width: 292px; margin-top: 30px; }
  .about-sec span { font-size: 14px; line-height: 8px; }
  .convinced-img-wrp .convinced-text-col p { font-size: 16px; line-height: 22px; }
  .container-full .ag_heading_center_wrap h2 { line-height: 27px; }
  .foreign-university-text h4 { font-size: 22px; line-height: 29px; } 
  .foreign-university-text { margin-top: 30px; border-radius: 20px; margin: 25px auto;  max-width: 94%;}
  .content-area .italic-title.blue-text { font-size: 18px !important; line-height: 30px; } 
  .center-image.videoPageChk .tagimg.imgmargin { width: 100% !important;}
  
  .content-area .box-content.p-5 { padding: 20px !important; }
  .journalShadow .ag_menu { display: block; }
  .journalShadow .ag_menu > ul { visibility: visible; opacity: 1; padding: 20px; display: block; }
  .journalShadow .ag_menu > ul li  { display: inline-block;   margin: 0 10px; position: relative; }
  .journalShadow .ag_menu > ul li a { margin-top: 0; margin-bottom: 0; padding-left: 0; font-size: 16px; }
  .journalShadow .ag_menu > ul li:first-child{ position: relative; z-index: 3; }
  .ag_menu > ul > li .tagliSubNave { left: -18px; }
  .ag_menu > ul > li .tagliSubNave ul.disciplinesscroll li { display: block;}
  .ag_opportunities_box p, .ag_opportunities_box a { font-size: 16px; }
  .custom-journal-last .ag_opportunities_box { min-height: auto; }
  .blog-type { margin-bottom: 10px; } 
  .ag_content_text.faq-banner-content .questionsstyle { font-size: 30px; line-height: 40px;}
  .ag_content_text.faq-banner-content p { font-size: 24px !important; line-height: 32px;  }
  .ag_content_text.faq-banner-content p.fontsize22.mar {  font-size: 24px !important; }
  .ag_content_text.faq-banner-content { padding: 0 40px; }
  .overviewscss.faq-overviewscss p { font-size: 16px;}
  .faq-main-div .faw-lists { padding: 0 10px; margin-top: 40px;}
  .origins { font-size: 18px !important; display: inline-block; vertical-align: middle; } 
  .card p { font-size: 16px; }
  .faw-lists .card { padding: 10px 29px 14px 14px; }
  .minusicon { position: absolute; top: 10px; right: -6px; }
  .faw-lists .card .para { font-size: 16px; font-weight: 500; line-height: 20px;  padding-bottom: 6px; }
  .card span { font-size: 16px;}
  .hameMianBanner .hameMianContent { top: 64%;}
  .hameMianBanner .ag_content_text p { font-size: 12px; }
  .hameMianBanner .hameMianContent .ag_content_text { text-align: left; }
  .hameMianBanner .hameMianContent h1 { display: none;}
  .hameMianBanner .hameMianContent .content { display: none; }
  .downPosi { margin-top: 0;}
  .ag_special_offer_btn .ag_btn_red { font-size: 16px; }
  .header-main-row { padding-right: 0px; }
  .header-main-row .ag_btn { font-size: 12px; min-width: 90px; height: 34px; }
  .header-main-row .ag_btn img { width: 16px;}
  .ag_logo { width: 160px; }
  .sitemapBox { display: block; padding: 0 20px; }
  .navNotify { position: absolute; right: 5px; }
  .editorPage { display: block; }
  .editorPage .editorHead.editorialHead { margin-right: 0; margin-bottom: 40px; display: block; }
  .editorialPage .content-journal { padding-right: 0; }

}


 

@media screen and (max-width: 380px) {
  .ag_opportunities_box {
    min-height: 570px;
    max-height: 570px;
  }
}
