
.hidden {
  visibility: hidden;
}

.container {
  margin: 0;
}

.loaderContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btnStyle{
  border-radius: 10px;
  border-style: none;
  color: #FFFFFF;
  font-weight: bold;
}
.droupdownPart{
  margin: 10px;
  background-color: #F6F9FB;
  border-radius: 10px;
  color: #167092;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
#dropdown-basic{
  border-style: none;
  font-weight: bold;
}
.superateline{
  height: 100px;
}
.btn{
  --bs-btn-color: #167092 !important;
  ;
}

.grid-container {
  display: grid;
  grid-template-columns: 2fr 0.3fr 6fr;
}
