@import "../../../styles/typography.scss";


.reset-password-page {
    background-image: url("../../../svg/body-base.svg");
    background-color: $body-base_color;
    min-height: 100vh;
    height: 100vh;
    background-size: cover;
    overflow: auto;

    .left-base {
        background: linear-gradient(180deg, #019C94 0%, #098DAD 51.58%, #1D5CAA 100%);
        border-right: 3px solid rgba(255, 176, 27, 0.8392156863);
    }

    .frame {
        padding: 45px 100px 12px 100px;
        border-radius: 10px;
        min-height: 80vh;
        margin-bottom: 20px;

        label {
            @extend .inputLabel;
        }
    }

    .top-fixed_link{
        position: absolute;
        right: 32px;
        top: 16px;
    }

    .title {
        @extend .frameHeading;
    }

    .welcome-title {
        @extend .welcomeTitle;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 129.02%;
    }

    .link {
        @extend .linkFont;
          font-size: 14px;
    }

    .row {
        margin: 0;
    }

    .w-90 {
        width: 90%;
    }

    .label {
        @extend .tinyFont;
    }

    .labelColor {
        @extend .labelColor;
    }

    .shadow-box {
        background: #F8F9FD;
        box-shadow: 0px 0px 9px rgb(0 155 148 / 8%);
        border-radius: 10px;
        padding: 16px;
        margin-bottom: 1rem;
        text-align: left;

        p {
            @extend .labelColor;
            font-weight: 600;
        }
    }

    .logo_container {
        text-align: center;
        img {
            width: 450.95px !important;
            margin-left: 0;
        }
    }

    .label-form {
        @extend .formLabel;
    }

}