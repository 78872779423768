footer {
    background-color: $light;


    & .footer {
        &-title {
            font-weight: 500;
            font-size: 16px;
            color: $light;
            padding: 10px 20px;
            background-color: $red;
            text-align: center;

            @media #{$desktop} {
                font-size: 35px;
                padding: 15px 0;
            }
           

            & h2 {
                font-weight: 500;
                font-size: inherit;
                margin: 0;
                letter-spacing: normal;
                transform: scaleY(1.5);
                font-size: 26px;
                word-spacing: 3px;
                text-transform: uppercase;
                @media #{$mobile-only} {
                    font-size: 16px;
                }
            }
        }

        &-rest {
            display: flex;
            justify-content: space-between;
            padding: 20px 0;
            flex-wrap: wrap;

            @media #{$desktop} {
                flex-wrap: nowrap;
                padding: 20px 35px;
            }

            & h3 {
                color: black;
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 5px;

                @media #{$desktop} {
                    font-size: 16px;
                    
                    color: black;
                }
            }

            & ul {
                padding: 0;
                margin: 0;

                & li {
                    list-style: none;
                    padding: 3px 0;

                    @media #{$desktop} {
                        padding: 6px 0;
                    }

                    & a {
                        text-decoration: none;
                        color: $secondary !important;
                        font-size: 14px;
                        font-weight: 600;
                        cursor: pointer;

                        @media #{$desktop} {
                            font-size: 16px;
                        }

                        &.active {
                            font-weight: 800;
                        }

                        &:hover {
                            text-decoration: underline;
                            opacity: .85;
                        }
                    }
                }
            }
        }

        &-intro {
            @media #{$desktop} {
                max-width: 365px;
                width: 100%;
            }

            & .cmyLogo {
                padding-bottom: 10px;
            }

            & .introText {
                padding-left: 10px;

                & ul {
                    & li {
                        padding: 4px 0;
                    }
                }
            }

            & p {
                font-size: 12px;
                font-weight: 400;
                color: $dark-700;

                & b[class^=icon-] {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 10px;
                    margin-top: -2px;
                    font-size: 125%;
                }
            }

            & small {
                font-size: 10px;
                font-weight: 400;
                display: block;
                line-height: normal;
                color: $dark-700;
                padding-top: 10px;
            }
        }

        &-links {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 0;

            @media #{$desktop} {
                max-width: calc(100% - 365px);
                width: 100%;
                padding-left: 100px;
                flex-wrap: nowrap;
                margin: 0 -20px;
                padding-top: 15px;
            }
        }

        &-col {
            padding: 10px 20px;
            width: 50%;

            @media #{$desktop} {
                width: auto;
                padding: 15px 0;
            }
        }

    }

    & .socialLinks {
        text-align: center;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            width: 100%;
            height: 2px;
            background-color: rgba($dark, .1);
            display: block;
        }

        &-wrap {
            padding: 0 15px;
            background-color: $light;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 1;
        }

        & h3 {
            font-weight: 600;
            font-size: 14px;
            padding: 0 10px;
            color: $dark-400;

            @media #{$desktop} {
                padding: 0 15px;
                font-size: 16px;
            }
        }

        & ul {
            display: flex;
            justify-content: center;
            margin: 0;
            padding: 0;

            & li {
                list-style: none;
                padding: 0 5px;

                @media #{$desktop} {
                    padding: 0 10px;
                }

                & a {
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    border: 1px solid $secondary;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $secondary !important;
                    text-decoration: none;

                    @media #{$desktop} {
                        width: 32px;
                        height: 32px;
                    }

                    &:hover {
                        color: $light !important;
                        background-color: $secondary;
                    }
                }
            }
        }
    }

    & .footerFeeback {

        width: 120px;
        display: block;
        margin: 0 auto;
        margin-top: 15px;

        @media #{$desktop} {
            position: absolute; 
            left: 55px;
            bottom: 0;
            width: 208px;
            margin: 0;
        }

        & a {
            display: block;
        }

       
    }

    & .containWrap {
        position: relative;
    }

    & .copyRight {
        padding: 15px 0;
        text-align: center;
        // border-top: 1px solid rgba($dark, .1);

        & small {
            font-size: 12px;
            font-weight: 400;
            color: $dark-700;
            display: block;
            padding-bottom: 10px;
        }

        .legalLinks {
            small {
                padding-bottom: 0px;
            }
        }
    }
    & .startup-img {
        width: 300px;
        margin-top: 15px;
   }
    & .contactLinks {
        font-weight: 500;
        font-size: 16px;
        color: $dark-400;
        padding: 5px 15px;

        & p {
            font-weight: 500;
            font-size: 16px;
            color: $dark-400;
            text-decoration: none;
        }
    }

    & .legalLinks {

        & a {
            text-decoration: none;
            color: $secondary !important;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            display: inline-block;
            vertical-align: middle;
            margin: 0 10px;
            position: relative;

            &.know-link {
                font-size: 12px;
                color: #777 !important;
                text-decoration: underline;
                padding: 0;
                vertical-align: revert;
                margin: 0;
                font-weight: bold;
            }

            @media #{$desktop} {
                font-size: 16px;
                margin: 0 14px;
            }

            &.active {
                font-weight: 700;
            }

            &:hover {
                text-decoration: underline;
                opacity: .85;
            }

            &:first-child {
                &:after {
                    content: "";
                    position: absolute;
                    top: 10%;
                    right: -10px;
                    width: 1px;
                    height: 80%;
                    background-color: rgba($dark, .1);
                    display: block;

                    @media #{$desktop} {
                        right: -14px;
                    }
                }
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }

    }
}