/** VAR **/
@import "../base/themecomponents/_variables_app.scss";

/***/
.pageMenu {
    padding: 30px 26px;

    &-link {
        padding: 8px 0 24px 0;
        border-bottom: 1px solid rgba($dark-cc,.5); 
        margin-bottom: 24px;

        &:first-child { 
            border-top: none;
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
            padding-top: 0;
            & a {
                color: #404040 !important; 
            }
        }

        & .onlineStatus {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: $dark;
             font-weight: 400;
        }
        
        & .walletStatus {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: $dark;
             font-weight: 400;

             & b {
                width: 18px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 4px;
                & img {
                    display: block;
                    margin: 0;
                }
             }
        }
        & li {
            padding: 9px 0;
            list-style: none;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }

            & a {
                font-size: 16px;
                font-weight: 700;
                color: $primary !important;  
                text-decoration: none;
                cursor: pointer;
                display: inline-block;
                position: relative;

                &:hover {
                    opacity: .85;
                }
                & small{
                    position: absolute;
                    right: -8px;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: #BF1E2E;
                    display: inline-block;
                    top: 4px;
                }

                &.active {
                    font-weight: 700;
                    padding-bottom: 8px;
                    color: #404040 !important;
                    &:hover {
                        opacity: 1;
                        cursor: default;
                    }

                    &:after {
                        content: "";
                         
                        width: 34px;
                        height: 8px;
                        border-radius: 8px;
                       // background: linear-gradient(270deg, rgba(1, 183, 174, 0.84) 27.38%, rgba(45, 51, 141, 0.84) 154.29%);
                       background: linear-gradient(270deg, rgba(255, 176, 27, 0.84) -2.89%, rgba(227, 232, 123, 0.84) 100%); 
                       display: block;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        z-index: 1;
                    }
                }
            }
        }
    }
}

.register-page .button-primary, .login-page .button-primary, .inner-pages .button-primary, .mobile-login-page .button-primary, .modal .button-primary {
    background-color: #167092 !important;
}