.button {
    padding: 12px 24px;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    box-shadow: none;
    border: none;
    font-weight: 700;
    border-radius: 14px;
    min-width: 60px;
    font-style: normal;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0 0 22px rgba($dark, 0.1);
    transition: all 350ms ease-in-out;

    &-sm {
        padding: 8px 14px !important;
        font-size: 14px !important;
        border-radius: 10px !important;

        @media #{$desktop} {
            padding: 10px 18px !important;
            font-size: 16px !important;
            border-radius: 10px !important;
        }

    }

    @media #{$desktop} {
        padding: 17px 28px;
        font-size: 26px;
        border-radius: 20px;
    }

    & b[class^=icon-] {
        font-size: 145%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        margin-top: -3px;
    }

    &-primary {
        color: $light;
    }

    &-white {
        background: $light;
        color: $secondary;

        &:hover {
            background: $secondary;
            color: $light;
        }

    }

    &-fill {
        color: $light;
        background: $secondary;

        &:hover {
            color: $light;
            background: $secondary;
            opacity: .85;
        }
    }

    &-text {
        color: $secondary;
        background: none !important;
        box-shadow: none;

        &:hover {
            color: $secondary;
            background: none !important;
            opacity: .85;
        }

    }

    &-ntv {
        color: $dark-900;
        background: none !important;
        box-shadow: none;

        &:hover {
            color: $dark-900;
            background: none !important;
            opacity: .85;
        }

    }
}