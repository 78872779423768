.header-author {
    width: 100%;
    position: fixed;
    z-index: 1040;
    top: 0;
}

.content {
    overflow: auto;
}

.link-end {
    position: absolute;
    right: 12px;
    top: 37px;
}

.profile-row {
    background-color: #f2f2f2;
    padding: 45px 5px;
    border-top-left-radius: 42px;
    position: relative;
}

.my-profile_upload {
    img {
        bottom: -37px;
        width: 150px;
        height: 150px;
        position: absolute;
        border-radius: 50%;
    }

    i {
        position: absolute;
        bottom: 0;
        font-size: 2rem;
        transform: translate(169%, -53%);
        color: #ffffff;
    }
}

.article-nav{
    font-size: 1rem;
    li{
        padding: 0 20px 0 0;
        text-decoration: underline;
    }
}

.author-dashboard{
    .carousel-indicators{
        bottom: -5px;
    }
    .carousel-indicators [data-bs-target]{
        background-color: gray;
    }
    .carousel-control-prev-icon{
        display: none;
    }
    .carousel-control-prev-icon{
        display: none;
    }
}