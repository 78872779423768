

/****/
.cookiesWrap {
    background-color: $light;
    border-radius: 20px 20px 0 0;
    border: 1px solid $secondary;
    position: fixed;
    z-index: 99;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    max-width: 1180px;
    margin: 0 auto;
    padding: 15px;
    width: 100%;
    box-shadow: 0px 3px 34px rgba($dark, 0.1);

    @media #{$tablet} {
        padding: 20px 25px;
    }
}

.cookiesBox {
    @media #{$tablet} {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-img {
        max-width: 50px;
        display: block;

        @media #{$tablet} {
            max-width: 112px;
        }
    }

    &-info {
        width: 100%;

        & h2 {
            font-size: 16px;
            font-weight: 700;
            color: $secondary;
            margin-bottom: 5px;

            @media #{$tablet} {
                font-size: 20px;
            }

            & a {
                margin-right: 10px;
            }
        }

        & p {
            font-size: 12px;
            font-weight: 400;
            color: $dark-400;
            line-height: 1.4;

            @media #{$tablet} {
                font-size: 14px;
            }
        }

        & a {
            color: $secondary;
            font-weight: 700;
            cursor: pointer;
            text-decoration: none;

            &:hover {
                color: $secondary;
                opacity: .85;
            }
        }

    }

    &-left {
        display: flex;
        align-items: flex-start;

        @media #{$tablet} {
            align-items: flex-end;
        }

        & .cookiesBox-info {
            max-width: 580px;
            padding-left: 15px;

            @media #{$tablet} {
                padding: 0 15px;
            }
        }
    }

    &-right {
        text-align: center;

        @media #{$mobile-only} {
            & .button-sm {
                padding: 8px 10px !important;
            }
        }

        @media #{$tablet} {
            text-align: right;
        }
    }
}

.cookiesOpt {
    display: none;
    width: 100%;

    &-info {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-top: 0;
        flex-wrap: wrap;

        @media #{$tablet} {
            padding-top: 20px;
            flex-wrap: nowrap;
        }

    }

    &-box {
        background-color: rgba($dark, 0.02);
        border-radius: 6px;
        padding: 8px 12px;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 5px;

        @media #{$tablet} {
            max-width: calc(280px - 15px);
            margin-left: 15px;
            margin-top: 0;
        }

        &:first-child {
            margin-top: 0;
            margin-left: 0;
        }

        & label {
            font-size: 13px;
            font-weight: 500;
            color: $dark;
            margin: 0;

            @media #{$tablet} {
                font-size: 15px;
            }
        }

        & .badge {
            font-size: 10px;
            font-style: italic;
            color: #2D338D;
            padding: 2px 6px;
            background-color: rgba(#2D338D, .1);
            border-radius: 5px;
            display: inline-block;
            font-weight: 500;
        }



    }
}

.customSwitch {
    position: relative;
    display: inline-block;

    & label {
        display: block;
        width: 34px;
        position: relative;
    }

    & input[type=checkbox] {
        height: 0;
        width: 0;
        visibility: hidden;
        position: absolute;
    }

    & span {
        cursor: pointer;
        text-indent: -9999px;
        width: 34px;
        height: 16px;
        background: rgba($dark-900, 0.25);
        display: block;
        border-radius: 8px;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 1px;
            left: 1px;
            width: 15px;
            height: 15px;
            background: $dark-900;
            border-radius: 50%;
            transition: 0.3s;
        }
    }


    & input:checked+span {
        color: $secondary;
        background: rgba($secondary, 0.12);
    }

    & input:checked+span:after {
        left: calc(100% - 1px);
        transform: translateX(-100%);
        background: $secondary;
    }

}