@import "../../styles/variables.scss";
@import "../../styles/typography.scss";

#congratsModal {
  .modal-content {
    .modal-footer {
      button {
        font-size: 16px;
        box-shadow: none;
      }
    }
  }
}
.static-modal {
  .modal-header {
    border-bottom: 0px;

    .modal-title {
      @extend .midHeading;
      // font-weight: 800;
    }
  }

  .label {
    @extend .inputLabel;
    width: 95%;
    margin: auto;
  }

  .modal-content {
    border-radius: 20px;
    padding: 25px;
    border: 1.5px solid #167092;
  }

  .modal-footer {
    border-top: 0px;
  }

  .link {
    //@extend .linkFont;
    color:  #167092 !important;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
  }

  .link:hover {
    color:  #167092 !important;
  }
 
  .upload-area {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 35px 0 0px 0;
    margin-bottom: 2rem;
    span:nth-child(1) {
      background: rgba(0, 155, 147, 0.05);
      padding: 2rem 1rem;
      border-radius: 10px;
    }

    span {
      margin: 0 10px;
      width: 100%;
      &.noBgColor{
        padding: 0 0 30px 0;
      }
    }

    span:nth-child(2) {
      background: rgba(0, 155, 147, 0.05);
      padding: 2rem 1.5rem;
      border-radius: 10px;
    }

    .user-img {
      border-radius: 50%;
      width: 140px;
      object-fit: contain;
      height: 140px;
    }

    .profile-upload {
      text-align: center;
    }
  }

  .custom_button {
    padding: 10px 86px 12px 86px;
  }
}

.success-modal {
  #successMsg {
    .modal-content {
      padding: 10px 30px 12px 30px;
      max-width: 480px;

      @media screen and (max-width: 767px) {
        max-width: auto;
      }

      .text-center {
        a {
          color: $secondary-color;
        }
      }
    }
  }

  .modal-header {
    border-bottom: 0px;

    .modal-title {
      @extend .midHeading;
      // font-weight: 800;
    }
  }

  .label {
    @extend .inputLabel;
    width: 95%;
    margin: auto;
  }

  .modal-content {
    border-radius: 20px;
    padding: 15px 10px 18px 10px;
    border: 1.5px solid $secondary-color;
  }

  .modal-footer {
    border-top: 0px;
  }

  .header-before {
    position: absolute;
    top: -47px;

    img {
      width: 109px;
    }
  }

  .custom_button {
    padding: 10px 25px 12px 25px;
  }
}

.label-error {
  text-align: left;
  font-size: 12px;
  font-style: italic;
  color: #be1e2d;
  margin-top: 5px;
}

.label-info {
  font-size: 14px !important;
  line-height: 19px !important;
}

.modal-close {
  position: absolute;
  right: 26px;
  top: 26px;
  cursor: pointer;
  z-index: 99;
  display: inline-block;
  width: 20px;
}

.modal-dialog {
  width: fit-content;
}

.modal-footer {
  padding: 0;
}

.big-heading {
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  margin: 10px 0 0 0;
}

.mt-38 {
  margin: 38px 0 0 0;
}

.profile-modal {
  .custom_button {
    padding: 7px 26px !important;
  }
  .modal-content {
    min-width: 481px;

    @media screen and (max-width: 767px) {
      min-width: auto;
    }
  }
}

.founder-message {
  .custom_button {
    padding: 10px 22px 12px 22px !important;
  }
}
.color-black {
  color: black !important;
}

.link:hover {
  color: $secondary-color !important;
}

.emoji-popup {
  ul {
    list-style: none;
  }
  .emoji-row {
    img {
      width: 40px;
      margin-right: 5px;
    }
  }
}

.verified-mobile_popup {
  .modal-dialog {
    max-width: 378px;

    @media screen and (max-width: 767px) {
      max-width: auto;
    }
  }
}

.btn-success_modal {
  padding: 11px 35px !important;
}

.modal-seperate_border {
  border-top: 0.5px solid #404044;
}

.remove-profile_modal {
  .custom_button {
    padding: 10px 50px 12px 50px;
  }
}

.termsModal {
  & .modal-title {
    width: auto;
    position: absolute;
    left: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #2d338d;
  }

  & .modal-header {
    display: flex;
    justify-content: flex-end;
    gap: 30px;
    margin: 0 20px;
  }

  & .modal-body {
    padding: 0 30px;
  }

  & .modal-body h3 {
    float: left;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }

  & .modal-body p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 148.6%;
    text-align: justify;
    color: #000000;
    margin-bottom: 15px;
    display: inline-block;
    width: 100%;
  }

  & .modal-body a {
    color: #009B94 !important;
    cursor: pointer;
    font-weight: 700;
  }

  & .modal-body hr {
    border: 0.5px solid #D1D1D1;
  }

  & .agree-btn {
    background-color: #7ccbc7 !important;
    border-radius: 10px !important;
    color: #ffffff;
    padding: 10px 70px 12px 70px;
    border: 1px solid;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }

  & .agree-btn:hover {
    background-color: #009B94 !important;
    border: 1px solid #009B94;
  }

  & .modal-footer {
    display: flex;
    gap: 30px;
    margin-bottom: 20px;
  }
  
  & .btn-footer {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #97979b;
    border: none;
    background: none;
  }
}

.custom_button{
  background-color: #009B94;
  border-radius: 10px !important;
  color: #ffffff;
  padding: 10px 70px 12px 70px;
  border: 1px solid #009B94;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}
 label {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #404040;
  display: block;
  // text-align: left;
  margin-bottom: 6px;
}
.custom_input {
  position: relative;

  .grey-input {
    // @extend .inputField;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #97979B;
    border: 0.5px solid #858585;
    border-radius: 10px;
    padding: 9px 12px 11px 12px;
    background-color: transparent;
  }

  .active-input {
    @extend .inputActive;

    :focus {
      color: $primary-color !important;
    }
  }


  .with_icon {
    padding: 9px 74px 11px 36px;
    @extend .inputFont;
  }
  .input_pre {
    position: absolute;
    left: 12px;
    z-index: 1;
    top: 4px;
    color: $input-pre_color;
  }

  .input_pre-active {
    position: absolute;
    left: 12px;
    z-index: 1;
    top: 5px;
    color: $input-pre_color;
  }
  .active-input{
    font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: $primary-color;
  border: 0.5px solid #858585;
  border-radius: 10px;
  padding: 9px 12px 11px 36px;
  background-color: transparent !important;
  }

  .input_post {
    position: absolute;
    right: 16px;
    top: 0.25rem;
    cursor: pointer;
  }
}

.remove-profile_modal { 
  & .modal-header {
    margin-bottom: 5px;
  }
  & .modal-footer {
    margin-top: 20px;
  }
}

.f-size-16 {
  font-size: 16px !important;
}