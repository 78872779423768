.tagbody {
  position: relative;
  overflow: hidden;
}

.tagbody::after {
  background: url("../images/right-shape-arrow.png");
  position: absolute;
  right: 70%;
  content: "";
  width: 100%;
  height: 100%;
  background-size: inherit;
  background-repeat: repeat-y;
  top: 0px; 
  z-index: -1;
}

.tagbody::before {
  background: url("../images/right-shape-arrow.png");
  position: absolute;
  left: 70%;
  content: "";
  width: 100%;
  height: 100%;
  background-size: inherit;
  background-repeat: repeat-y;
  top: 10px;
  overflow: hidden;
  z-index: -1;
}

.small-title.text-center::before {
  margin: 0 auto;
  right: 0;
}

.detail-col {
  padding-top: 108px;
}

.article_col_box {
  margin: 0 30px;
}

/*.content-area .container {
    max-width: 1200px;
    padding: 0;
}*/
.article_col_box .top_title h4 {
  font-size: 26px;
  font-weight: 600;
  line-height: 33px;
}

.article_col_box .article-table-title.article p {
  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
}

.blue-border-text p {
  display: inline-block;
  width: 100%;
  font-size: 20px;
  margin-bottom: 0;
}

.article-table-data.article p {
  font-size: 32px;
  font-weight: 700;
}

.blue-border-text {
  // margin-top: 90px;
  display: inherit;
  width: 100%;
  // margin-bottom: 30px;
  margin: 90px auto 30px;
  max-width: 945px;
}

.maxWidthManage {
  max-width: 945px;
  width: 100%;
}

/* warm-slider css stert */

.testimonial-i-dot {
  position: relative;
}

.testimonial-i-dot::before {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  background: #c0262f;
  border-radius: 100px;
  top: 3px;
  left: 1px;
}

.warm-slider-img.slider-nav {
  display: flex;
  width: 400px;
  margin: 0 auto;
  justify-content: center;
  height: 80px;
  position: relative;
  top: -140px;
  display: none;
}

.warm-slider-img.slider-nav img {
  display: inline-block;
  padding: 0 10px;
  width: 90px;
  object-fit: contain;
}

.warm-slider-img.slider-nav img.slick-active.slick-center {
  transform: scale(1.3);
  filter: unset;
}

.warm-slider-img.slider-nav .slick-track {
  // height: 100%;
}

.warm-slider-img.slider-nav img.slick-active {
  filter: grayscale(1);
}

.warm-slider-img.slider-nav img.slick-clone {
  filter: grayscale(1);
}

.ag_main_wrapmain-col {
  background: url(../images/slider-banner.png) no-repeat;
  height: auto;
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  margin-top: 90px;
  background-size: cover;
  margin-bottom: 50px;
}

.warm-slider-des {
  display: flex;
  width: 72%;
  margin: 0 auto;
  justify-content: center;
  margin-top: 0;
  background: #fff;
  border-radius: 20px;
  text-align: center;
  /*    padding: 35px 30px;*/
  position: relative;
  top: -85px;
  box-shadow: 0px 0px 7px rgba(112,112,112,0.20);
  border-bottom: 10px solid #bc1c2b;
  & .warm-sliderHead {
    display: block;
    overflow: hidden;
    & .sliderImage {
      width: 280px;
      display: inline-block;
      vertical-align: middle;
      & img {
        display: block;
        width: 100%;
      }
    }
    & .sliderContent {
       text-align: left;
       margin-left: 280px;
      & .designationWrap {
        max-width: 100%;
        & span {
          display: inline-block;
        }
      }
      & .titleWrap {
        font-size: 28px;
        color: #bc1c2b; 
      }
      & .subTitleWrap {
        color: #336d91;
        font-weight: 700;
      }
    }
  }
}

.warm-slider-des-wrp {
  & .sliderImage {
    width: 220px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    overflow: hidden;
    & img {
      width: 100%;
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate(-50%, 0);
    }
  }
  & .sliderDescription {
      position: relative;
      width: 100%;
      display: inline-block;
      text-align: left;
      margin-top: 30px;
      font-size: 18px;
      padding-bottom: 30px;

    & .descriptionContent {
        display: inline-block;
        vertical-align: middle;
        text-align: left;
        width: calc(100% - 240px);
        padding-left: 60px;
        position: relative;
        &::after {
          position: absolute;
          top: -10px;
          left: 20px;
          background: url(../images/testimonial-left-quote.png) no-repeat center;
          width: 41px;
          content: "";
          background-size: contain;
          height: 28px;
        }
        &::before {
          position: absolute;
          bottom: -32px;
          right: 0;
          background: url(../images/testimonial-right-quote.png) no-repeat center;
          width: 41px;
          content: "";
          background-size: contain;
          height: 28px;
        }
    }
  }
}

// .warm-slider-des-wrp .sliderDescription::after {
//   position: absolute;
//   top: -50px;
//   left: 0;
//   background: url(../images/testimonial-left-quote.png) no-repeat center;
//   width: 41px;
//   content: "";
//   background-size: contain;
//   height: 28px;
// }

.warm-slider-des-wrp {
  padding: 30px;
}

// .warm-slider-des-wrp .sliderDescription::before {
//   position: absolute;
//   bottom: -20px;
//   right: 0;
//   background: url(../images/testimonial-right-quote.png) no-repeat center;
//   width: 41px;
//   content: "";
//   background-size: contain;
//   height: 28px;
// }

.warm-slider-des .warm-subtitle {
  color: #336d91;
  font-weight: 700;
}

.warm_slider {
  display: inline-block;
  width: 100%;
  position: relative;
}

.warm_slider .slick-arrow {
  position: absolute;
  top: 62%;
    
  box-shadow: none;
}

.warm_slider .slick-next.slick-arrow {
  background: url(../images/testimonial-right-arrow.svg) no-repeat;
  display: inline-block;
  border: unset;
  font-size: 0;
  right: 0;
  width: 50%;
  background-size: contain;
  height: 100%;
  top: 0;
  transform: translateY(0);
  opacity: 0;
  
}

.warm_slider .slider__counter {
  position: absolute;
  bottom: -44px;
  color: #fff;
  display: none !important;
}

.warm_slider .slick-prev.slick-arrow {
  background: url(../images/testimonial-left-arrow.svg) no-repeat;
  font-size: 0; 

  left: 0;
  display: inline-block;
  border: unset;
  font-size: 0;
  width: 50%;
  background-size: contain;
  height: 100%;
  top: 0;
  transform: translateY(0);
  opacity: 0;
}

.warm_slider .slick-dots {
  position: absolute;
  bottom: -50px;
  display: flex;
  display: none !important;
}

.detail-slider-title {
  display: inline-block;
  width: 100%;
}

/* warm-slider css stert */

/*megamenu css start*/

.ag_menu>ul>li {
  position: unset;
}

.ag_menu>ul>li ul {
  max-width: 80%;
  width: unset;
  opacity: 0;
  visibility: hidden;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  right: unset;
  left: unset;
  box-shadow: -3px 1px 4px 3px #00000029;
}

.ag_menu>ul>li ul li a {
  color: #336d91;
}

.ag_menu>ul>li ul li span {
  color: #111111;
}

.ag_menu>ul>li ul li {
  position: relative;
  flex-wrap: wrap;
  border-right: 1px solid #ccc;
}

.ag_menu>ul>li ul li span {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  padding: 1px 15px;
}

.ag_menu>ul>li ul li>ul {
  box-shadow: unset;
  margin: 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.ag_menu>ul>li ul>li ul li a {
  color: #111111;
}

.ag_menu>ul>li:hover ul {
  border: 1px solid #057296;
  top: 80%;
  padding: 5px 0;
}

.ag_menu>ul>li ul li a {
  padding: 1px 15px;
}

.ag_menu>ul>li.explire-journey ul {
  left: 0;
  right: 0;
}

.ag_ftr_links {
  height: 130px;
}

.ag_register_footer {
  height: unset;
}

/*megamenu css end*/

.article-processing .article p {
  display: inline-block;
  width: 100%;
  font-size: 23px;
  font-weight: 600;
}

.article-processing .article h5 {
  display: inline-block;
  width: 100%;
  font-size: 28px;
  font-weight: 600;
}

.underline-custom {
  text-decoration: underline;
  color: #000 !important;
}

.article-processing .col-lg-6.article h5.price {
  color: #bc1c2b;
  text-align: right;
}

span.red-dit {
  color: #bc1c2b;
}

.register-button.mt-small {
  margin-top: 10px;
}

.article-processing ul li::before {
  display: none !important;
}

.article-launch-offer .ag_heading_center_wrap {
  width: 100%;
}

.article-launch-offer+.ag_heading_center_wrap {
  width: 100%;
}

.article-processingg-charges .article-title {
  width: 100%;
}

.foreign-nation h4 {
  font-size: 35px;
  font-weight: 600;
}

.foreign-nation h6 {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.foreign-nation p {
  display: inline-block;
  width: 100%;
  font-size: 24px;
  font-style: italic;
}

.foreign-nation p span {
  color: #bc1c2b;
}

.updating-article h4 {
  font-size: 35px;
}

.updating-article p { 
  font-size: 18px;
  line-height: 28px;
}
.updating-article .tagp.indNat { 
  font-weight:400; 
  font-style: italic;
  margin-top: 10px;
  & a {
    color: #000;
    text-decoration: underline !important;
  }
}

.updating-article strong {
  font-weight: 600;
}

.article-table-data.article p {
  font-size: 18px;
}

.ag_publishjournal_text {
  box-shadow: 0px 0px 8px -3px #ff8300;
}

.updating-article p span {
  color: #bc1c2b;
  font-weight: 700;
}

.updating-article .ag_btn_red {
  width: 330px;
  height: 48px;
  font-size: 20px;
}

/*sell-page css start*/

.sell-banner {
  display: inline-block;
  width: 100%;
  /*    margin-top: 98px;*/
  height: calc(100vh - 100px);
}

.sell-banner img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.sell-banner-wrp {
  display: inline-block;
  width: 100%;
  position: relative;
  height: calc(100vh - 100px);
  margin-top: 98px;
}

.sell-banner-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 592px;
  left: 80px;
}
.sell-banner-text-article {
  position: absolute;
  top: 100px;
  transform: translateY(-50%);
  max-width: 592px;
  left: 80px;
}

.sell-banner-text-article h3 {
  display: inline-block;
  width: 100%;
  font-size: 80px;
  font-weight: 700;
  line-height: 52px;
  margin-bottom: 20px;
  color: #fff;
}

.sell-banner-text h3 {
  display: inline-block;
  width: 100%;
  font-size: 48px;
  font-weight: 700;
  line-height: 52px;
  margin-bottom: 20px;
  color: #fff;
}

.sell-banner-text p {
  display: inline-block;
  width: 100%;
  font-size: 35px;
  font-weight: 600;
  line-height: 42px;
  color: #fff;
  margin-bottom: 30px;
}

 
.container-full .ag_heading_center_wrap h2 {
  display: inline-block;
  width: 100%;
  line-height: 45px;
}

.container-full .ag_heading_center_wrap span {
  font-size: 35px;
  font-weight: 700;
  position: relative;
}

.container-full .ag_heading_center_wrap span::after {
  content: "";
  width: 11px;
  height: 11px;
  border-radius: 100%;
  display: -webkit-inline-box;
  background-color: #bc1c2b;
  position: absolute;
  top: 8px;
  left: 2px;
}

.sell-banner-text .title span {
  position: relative;
}

.sell-banner-text .title span::before {
  content: "";
  width: 13px;
  height: 13px;
  border-radius: 100%;
  display: -webkit-inline-box;
  background-color: #bc1c2b;
  position: absolute;
  top: 5px;
  left: 1px;
}

.Transforming-btm-col .ag_heading_center_wrap h2 {
  margin-top: 20px;
}

.about-sec h4 {
  display: inline-block;
  width: 100%;
  font-size: 48px;
  color: #336d91;
  font-weight: 700;
  line-height: 60px;
}

.about-sec p {
  display: inline-block;
  width: 100%;
  font-size: 18px; 
}

.about-sec {
  margin-bottom: 10px;
  // margin-top: 60px;
}

 

.about-sec span {
  display: inline-block;
  width: 100%;
  font-size: 28px;
  font-weight: 500;
  line-height: 20px;
  margin-top: -20px;
  padding-right: 21px;
}

.pera-text.foraing.spacing-more {
  max-width: 1215px;
}

.specific-issue {
  display: inline-block;
  width: 100%;
  position: relative;
  height: 200px;
  overflow: hidden;
  z-index: 1;
}

.specific-issue::after {
  position: absolute;
  content: "";
  background: #000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 43%;
}

.specific-issue .specific-issue-text {
  display: inline-block;
  width: 100%;
  position: absolute;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
  z-index: 111;
  left: 0;
}

.specific-issue .specific-issue-text h3 {
  display: inline-block;
  width: 100%;
  font-size: 48px;
  font-weight: 700;
  line-height: 82px;
  color: #fff;
}

.nation-one .row {
  box-shadow: 0px 0px 12px #00000039;
  margin-top: 3rem !important;
  border-radius: 20px;
  padding: 2rem;
  max-width: 1335px;
  margin: 0 auto;
}
.sellImgPosition { text-align: right; }
.nation-right-col {
  padding-left: 44px;
}

.nation-one {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
}

.nation-one .nation-wrp {
  display: inline-block;
  position: relative;
}

.nation-one .nation-text {
  display: inline-block;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.nation-one .nation-text h4 {
  display: inline-block;
  width: 100%;
  font-size: 65px;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
  line-height: 81px;
}

.nation-one .nation-text h5 {
  display: inline-block;
  width: 100%;
  font-size: 45px;
  font-weight: 400;
  line-height: 125px;
  color: #fe9b00;
}

.nation-one .nation-text p {
  display: inline-block;
  width: 100%;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
}

.convinced-img-col img {
  display: inline-block;
  width: 100%;
}

.choosing-main-col .row {
  margin-top: 5rem !important;
}

.choosing-main-text {
  display: inline-block;
  width: 100%;
  max-width: 869px; 
}

.choosingg-main-img {
  display: inline-block;
  width: 100%;
  max-width: 469px;
}

 

.convinced-img-wrp {
  display: inline-block;
  width: 100%;
  position: relative;
}

.convinced-text {
  display: inline-block;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
  max-width: 964px;
}

.convinced-col-1 .convinced-text-col {
  display: inline-block;
  width: 100%;
  position: relative;
  top: -30px;
  padding: 0px 50px 0 0;
}

.convinced-text.convinced-right-text {
  right: 9%;
}

.convinced-col-1 .convinced-text.convinced-right-text {
  right: 16%;
}

.convinced-col-1 img {
  width: 93%;
}

.convinced-col-2 .convinced-text.convinced-left-text {
  right: 31%;
}

.convinced-col-2 .convinced-img-col {
  display: inline-block;
  text-align: right;
  width: 100%;
}

.convinced-col-2 .convinced-img-col img {
  width: 94%;
}

.convinced-col-2 .convinced-text-col {
  display: inline-block;
  width: 100%;
  position: relative;
  top: -30px;
  padding: 0px 0 0 0;
}

.convinced-col-3 .convinced-text.convinced-right-text {
  right: 10%;
  max-width: 1077px;
}

.convinced-col-3 .convinced-text-col {
  display: inline-block;
  width: 100%;
  position: relative;
  top: -30px;
  padding: 0px 10px 0 0;
}

.convinced-col-3 .convinced-img-col img {
  height: auto;
}

.convinced-col-4 .convinced-text.convinced-left-text {
  right: 31%;
}

.convinced-col-4 .convinced-text-col {
  display: inline-block;
  width: 100%;
  position: relative;
  top: -30px;
  padding: 0px 0 0 0;
}

.convinced-col-4 .convinced-img-col {
  display: inline-block;
  text-align: right;
  width: 100%;
}

.convinced-col-4 .convinced-img-col img {
  width: 94%;
}

.bottom-form-col {
  padding-left: 70px;
}

.convinced-img-wrp .convinced-text-col h4 {
  display: inline-block;
  width: auto;
  font-size: 28px;
  color: #109194;
  font-weight: 600;
  position: relative;
  margin-bottom: 16px;
}

.convinced-img-wrp .convinced-text-col h4::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #fe8300;
  width: 55%;
  left: 0;
  bottom: -8px;
}

.convinced-img-wrp .convinced-text-col p {
  display: inline-block;
  // width: 100%;
  font-size: 18px;
  color: #000000;
  line-height: 29px;
  font-weight: 400;
}

.convinced-img-wrp .convinced-text-col p:last-child {
  margin: 0;
}

.convinced-col-3.convinced-img-wrp .convinced-text-col h4::after {
  width: 100%;
}

.convinced-col-4.convinced-img-wrp .convinced-text-col h4::after {
  width: 100%;
}

.community-col p {
  display: inline-block;
  width: 100%;
  font-size: 48px;
  font-weight: 700;
  margin: 0;
}

.questionsstyle {
  font-weight: 700;
  color: skyblue;
  margin-bottom: 32px;
  margin-left: -28px;
  font-size: 26px;
  line-height: 26px
}

.questionss {
  display: flex;
}

.overviewscss {
  border-radius: 10px !important;
  justify-content: center !important;
  display: flex;
  height: 200px !important;
  border-bottom: 10px solid #336d91 !important;
}

.articlenews {
  border: none;
  color: #ffffff;
  background-color: #336d91;
  border-radius: 5px;
  font-size: 16px;
  padding: 2px 40px 3px 9px;
  /* text-transform: uppercase; */
  min-width: 139px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  font-weight: 700;
}

.articlenews {
  background-color: #bc1c2b !important;
}

.fontsize22 {
  font-size: 24px !important;
}

.mar {
  margin-right: 35%;
}

.origins {
  font-size: 30px !important;
  color: #336d91 !important;
  margin-top: 0px;
  margin-left: 10px;
  font-weight: 700;
}

.linee {
  width: 96%;
  height: 4px;
  background-color: orange;
}

.btn {
  border-radius: -1px !important;
  width: 100px;
  color: white;
  margin-top: 10px;
  background-color: #109194;
}

.org {
  margin-top: 40px;
  display: flex;
}

.martop-10 {
  margin-top: 24px;
}

.wid {
  width: 93% !important;
}

.font22 {
  font-size: 20px !important;
  display: inline-block;
  width: 100%;
  color: #336d91;
}

.follw {
  display: inline-block;
  width: 100%;
  color: #336d91;
  // text-transform: uppercase;
  font-size: 22px;
}

.fontsizedate {
  font-size: 24px !important;
}

.list {
  margin-right: 10px;
  border-right: 1px solid #d3d8dd !important;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.160784313);
  padding: 10px 4px 14px 20px
}

.journalslist {
  background: transparent !important;
  border-radius: 10px !important;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.160784313);
  padding: 10px 4px 14px 20px;
}

.bg {
  background: white !important;
}

.mt-0 {
  margin-top: 0rem !important;
}

.journalBannercontainer {
  padding: 0px !important;
}

.para {
  margin-top: 3px !important;
  margin-bottom: 0rem !important
}

.search-input {
  display: flex;
  align-items: center;
  width: 200px;
  /* You can adjust the width as needed */
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

input[type="text"] {
  flex: 1;
  // border: none;
  outline: none;
}

.search-icon {
  margin-right: 10px;
  color: #555;
  /* You can change the color as needed */
}

.minusicon {
  margin-top: 5px;
  height: 30px;
  float: right;
  margin-right: 15px;
  cursor: pointer;
}

.wid34 {
  width: 34%
}

.bgimggroup {
  margin-left: 42px;
  background-size: 100%100% !important;
  background: url("../images/groupimg.png");
  width: 100%;
  height: 500px;
  border-radius: 27px;
  background-repeat: no-repeat;
}

.font31 {
  font-size: 31px !important;
}

.maingroup {
  padding: 20px !important;
  & .groupcss {
    margin: 10px 0;
  }
}

.justify {
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.160784313);
  margin-bottom: 42px;
  // border-bottom: 1px solid gray;
  display: flex;
  padding: 10px 10px 10px 10px;
  justify-content: center !important
}

// .main {
//   width: 50%;
//   margin: 50px auto;
// }



.font12 {
  font-size: 16px !important;
}

.groupcss {
  
  display: inline;
  width: 50p;
  border-radius: 10px;
  padding: 9px 35px 12px 15px;
  background-color: white;
  box-shadow: inset 0px 0px 12px #FF8300;
}

.mar40 {
  margin-left: 40% !important;
}

.journalpage {
  font-size: 22px !important;
}

.marlef50 {
  margin-left: 50px;
}

.disflex {
  display: flex;
}

.colr {
  color: #336d91;
  margin-left: 12px;
}

.footer-align-bottom {
  & .follw {
    display: inline-flex;
    width: auto;
    margin-bottom: 0;
  }
  & .ag_ftr_socials {
    display: inline-flex;
    align-items: center;
    margin-left: 25px;
  }
}
 

.maincard {
  border-bottom: 5px solid #336d91 !important;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.160784313) !important;
  padding: 10px 4px 50px 20px !important
}

.maindiv {
  width: 100%;
  display: flex;
  max-width: 100%;
  padding: 0 108px;
  margin: auto;
  max-width: 1400px;
}

.div30 {
  width: 28%;
}

.div70 {
  width: 72%;
}

.history {
  height: 50px !important;
}

.input30 {
  height: 50px !important;
  width: 97% !important;
}

.faqimg {
  // margin-left: 42px;
  margin-left: 0pc;
  background-size: 100%100%;
  // background-image: url("../images/faqimg.png");
  background-image: url("../images/aakashgangafaq.gif");
  width: 100%;
  height: 550px;
  border-radius: 27px;
  background-repeat: no-repeat;
}

.community-col::after {
  bottom: -25px;
  width: 28%;
}

.form-row label {
  display: inline-block;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}

.form-row label span {
  color: red;
}

.register-unlocking-btn a {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  border: none;
  padding: 10px 75px;
  background-color: #bc1c2b;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 12px;
}

// .form-row input.form-control {
//   display: inline-block;
//   width: 100%;
//   font-size: 24px;
//   font-weight: 500;
//   line-height: 36px;
//   margin-bottom: 40px;
//   padding: 0;
//   border: none;
//   border-bottom: 1px solid #707070;
//   background: unset;
//   padding: 0 0 15px 0;
// }


.form-row .col {
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
}

.form-row {
  display: inline-block;
  width: 100%;
  padding-right: 50px;
}

.container-full .ag_heading_center_wrap {
  display: inline-block;
  width: 100%;
}

.foreign-university-text {
  display: inline-block;
  background: #336d91;
  padding: 80px 30px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-left: -14px;
}

.foreign-university-text h4 {
  display: inline-block;
  width: 100%;
  font-size: 34px;
  color: #fff;
  line-height: 50px;
  margin-bottom: 30px;
}

.foreign-university-text a.ag_btn_red {
  color: #336d91;
  background: #fff;
  font-size: 20px;
  max-width: 250px;
}

.global-publish-challenges h4 {
  display: inline-block;
  width: 100%;
  font-size: 28px !important;
  color: #109194;
  font-weight: 600;
  position: relative;
  margin-top: -14px;
}

.global-publish-challenges h4 p {
  display: inline-block;
  font-size: 25px;
  font-weight: 600;
  margin: 0;
}

.global-publisher-col p.pera-text:nth-child(2) {
  margin-top: 14px;
}

.global-publish-challenges h4::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #fe8300;
  width: 67%;
  bottom: -12px;
  left: 0;
}

.main-form-btm {
  padding: 2.3rem !important;
  position: relative;
  z-index: 1;
}

.global-publisher-col {
  display: inline-block;
  position: relative;
  padding: 40px;
  padding-left: 100px;
  width: 45%;
}

.global-publisher-col::before {
  position: absolute;
  content: "";
  background: url(../images/global-publisher-arrow.png) no-repeat center;
  width: 90px;
  height: 117px;
  top: -12px;
  left: -4px;
  display: inline-block;
  z-index: 2;
  background-size: contain;
}

.global-publisher-col.publisher-2::before {
  background: url(../images/global-publisher-arrow2.png) no-repeat center;
  background-size: contain;
}

.global-publisher-col.publisher-2::after {
  background: #5d0a11;
}

.global-publisher-col.publisher-3::before {
  background: url(../images/global-publisher-arrow3.png) no-repeat center;
  background-size: contain;
}

.global-publisher-col.publisher-3::after {
  background: #094062;
}

.global-publisher-col.publisher-4::before {
  background: url(../images/global-publisher-arrow4.png) no-repeat center;
  background-size: contain;
}

.global-publisher-col.publisher-4::after {
  background: #d46916;
}

.global-publisher-col::after {
  position: absolute;
  content: "";
  width: 9px;
  height: 37px;
  background: #094062;
  top: 4px;
  left: -4px;
  border-top-right-radius: 0;
  border-top-left-radius: 20px;
}

.main-global-publish-challenge {
  justify-content: space-around;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  & .pera-text {
    font-size: 20px;

  }
}

.challenge-2 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.row.main-global-publish-challenge.challenge-1 {
  margin-top: 90px;
}

.global-publish-challenges .container {
  padding: 0 250px;
}

.article-launch-offer .ag_heading_center_wrap h2 span {
  display: inline-block;
  width: 100%;
  font-size: 48px;
  font-weight: 700;
}

.article-launch-offer .article_subtext {
  display: inline-block;
  width: 100%;
  font-size: 24px;
  font-weight: 400;
  color: #000;
  margin-top: 15px;
  margin-bottom: 15px;
}
.publisharticle {
  display: inline-block;
  width: 100%;
  font-size: 18px !important;
  font-weight: 400;
  color: #000 !important;
  margin-top: 15px;
  margin-bottom: 0;
}

.blue-text {
  color: #336d91 !important;
}

.large-title.text-left {
  text-align: left;
}

/*.large-title.text-left::before {
    left: 0;
    transform: unset;
}*/
.subtext_col-wrp {
  display: inline-block;
  margin: 0;
  width: 100%;
}

.publish-register-btn {
  margin-bottom: 80px;
}

.ag_megajournals_text ul li p br {
  display: none;
}

/*sell-page css end*/

@media screen and (max-width: 1920px) {
  .Transforming-btm-text h4 {
    display: inline-block;
    width: 100%; 
    font-weight: 500; 
    color: #336d91;
  }

  .Transforming-btm-text p {
    margin: 0;
  }

  .Transforming-btm-col .ag_heading_center_wrap::after {
    right: 0;
    margin: 0 auto;
    width: 26%;
    bottom: -24px;
  }

  .Transforming-btm-text .pera-text { 
    max-width: 870px; 
    margin: 0 auto;
  }
}

@media screen and (max-width: 1300px) {
  .about-sec p {
    font-size: 14px; 
  }
}

@media screen and (max-width: 1500px) {
  .Transforming-btm-text .pera-text {
    font-size: 21px;
    max-width: 1085px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1600px) {
  // .convinced-img-wrp.convinced-col-3 img {
  //   height: 340px;
  // }

  .article-launch-offer .ag_heading_center_wrap h2 span {
    font-size: 37px;
    line-height: 50px;
  }

  .global-publish-challenges h4 {
    font-size: 25px;
  }

  .convinced-img-wrp .convinced-text-col h4 {
    font-size: 20px;
  }

  .convinced-img-wrp .convinced-text-col p {
    font-size: 18px;
    line-height: 25px;
  }

  .sell-banner-text p {
    font-size: 35px;
    line-height: 43px;
    margin-bottom: 20px;
  }

  .sell-banner-text h3 {
    font-size: 37px;
    line-height: 50px;
  }

  .Transforming-btm-text h4 { 
    line-height: 28px;
  }

  .Transforming-btm-text p {  
    padding: 0 80px;
  }

  .about-sec h4 {
    font-size: 37px;
    line-height: 60px;
  }

  .about-sec p {
    font-size: 18px; 
  }

  

  .specific-issue .specific-issue-text h3 {
    font-size: 37px;
    line-height: 60px;
  }

  .nation-right-col p {
   
  }

  // .form-row input.form-control {
  //   font-size: 18px;
  //   line-height: 25px;
  //   margin-bottom: 15px;
  // }

  .form-row label {
    font-size: 18px;
    margin-bottom: 15px;
    line-height: 25px;
  }

  .foreign-university-text h4 {
    font-size: 34px;
    line-height: 50px;
    margin-bottom: 20px;
  }

  .foreign-university-text {
    padding: 40px 50px;
    margin-left: -12px;
  }

  .sell-banner-text .title span::before {
    width: 9px;
    height: 9px;
    top: 6px;
    left: 1px;
  }
}

@media screen and (max-width: 1440px) {
  .article-table-data.article p {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
  }
}

@media screen and (max-width: 768px) {
  .sell-banner-text h3 {
    font-size: 30px;
    line-height: 44px;
    margin-bottom: 5px;
  }

  .sell-banner-text {
    max-width: 70%;
  }

  .sell-banner-text p {
    font-size: 30px;
    line-height: 40px;
  }

  .sell-banner-text .title span::before {
    top: 2px;
    left: 1px;
  }

  .Transforming-btm-text h4 {
    font-size: 20px;
    line-height: 28px;
  }

  .Transforming-btm-text {
    margin-bottom: 30px !important;
  }

  .about-sec h4 {
    font-size: 25px;
    line-height: 40px;
  }

  .about-sec {
    margin-bottom: 40px;
  }

  specificc-issue-img img {
    width: 100%;
    height: 200px;
  }

  .article-processing .article h5 {
    font-size: 24px;
  }

  .article-processing .article p {
    font-size: 18px;
  }

  .ag_heading_wrap h2,
  .ag_heading_center_wrap h2,
  .ag_heading_right_wrap h2,
  .ag_partners_wrap .ag_heading_center_wrap h2,
  .ag_partners_wrap .ag_heading_center_wrap h2 span {
    font-size: 25px;
  }

  .ag_special_offer_img p {
    font-size: 18px;
  }

  .nation-right-col {
    margin-top: 30px;
  }

  .nation-img {
    text-align: center;
  }

  .convinced-img-col img {
    height: 300px;
  }

  .convinced-img-wrp.convinced-col-2 img {
    height: 450px;
  }

  .convinced-img-wrp.convinced-col-3 img {
    height: 400px;
  }

  .form-row {
    margin-top: 30px;
    padding: 0;
  }

  .foreign-university-text h4 {
    font-size: 25px;
    line-height: 34px;
  }

  .convinced-text {
    margin-top: 20px;
  }

  .convinced-img-wrp .convinced-text-col h4 {
    margin-bottom: 20px;
  }

  .Transforming-btm-text p {
    padding: 0;
  }

  .sell-banner {
    margin-top: 75px;
  }

  .sell-banner-text h3 {
    font-size: 22px;
    line-height: 35px;
  }

  .sell-banner-text p {
    font-size: 18px;
    line-height: 30px;
  }

  .sell-banner-text .title span::before {
    top: 2px;
    left: -1px;
    width: 7px;
    height: 7px;
  }

  .specificc-issue-img img {
    height: 250px;
  }

  .specific-issue .specific-issue-text h3 {
    font-size: 25px;
    line-height: 40px;
  }

  .challenge-2 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .global-publisher-col {
    width: 100%;
    margin-bottom: 50px;
  }

  .convinced-img-col {
    display: none;
  }

  .convinced-text {
    position: unset;
    transform: unset;
    max-width: 100%;
  }
}

body.home .ag_opportunities_box {
  background: #fff;
}

body.home .ag_partners_slider {
  background: #fff;
}

body.home .ag_main_wrap.ag_special_offer_wrap.mt-5 {
  background: none;
}

body.home .ag_main_wrap.ag_banner_wrap {
  background: #fff;
}

body.home .ag_resourcehub_box {
  background: #fff;
}

.pera-text.px-lg-0.px-3.custom-affordableApc {
  color: #027195 !important
}

.col-lg-5.article_col_box.px-lg-0.px-3.customapccard {
  box-shadow: 0px 0px 6px rgba(254, 134, 0, 0.3) !important;
   
  border-radius: 28px !important;
  overflow: hidden !important;
}

.customarticleapccard-processing {
  color: #336D91 !important;
}

.customarticleapccard-introductory {
  color: #109194 !important;
  font-style: italic;
}

.article-table-data p {
  text-align: right;
}
.customapccardimpinfo {
  border-top: 1px solid #ddd !important;
  & strong {
    font-size: 18px;
    font-weight: 600;
  }
}

.customimpnoteind {
  font-weight: 600;
  color: #336D91 !important;
}

.customimpnotepublishnew {
  flex-direction: row !important;
}

.customunderstabingapc {
  position: relative !important;
}

.customregprocess {
  text-align: center !important;
  margin-top: 15px !important;
}

.customseoprimary,
.customversionsprimary {
  color: #2a6d8d !important;
}

.customfootercontent {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
}

.customNewWaypage {
  display: none !important;
}

.custompartnerimg {
  padding-top: 0px !important;
  width: 100% !important;
}

.customopenaccess {
  width: 200px !important;
}

.custompartner-96 {
  max-width: 95px !important;
}

.customjoinpublishcomm {
  box-shadow: 0px 0px 12px #00000039 !important;
  border-radius: 24px !important;
}

.customjoinyourmsg {
  height: 140px;
}

.customjoinwritemsg {
  flex-direction: row;
  width: 100%;
}

 

.customjoinconnectnow {
  width: 100%;
}

.customarticleprocessing {
  justify-content: center;
  margin-bottom: 80px;
  & .customapccard{
    position: relative;
    &:last-child {
      &::before{
        content: '';
        position: absolute;
        left: 0;
        top: -30px;
         
        height: 30px;
        width: 100%;
      }
    }
  }
}

.customarticleprocessingcolor {
  color: #336D91;
}

.customarticleprocessingbackgrd {
  background: #336D91;
  border-radius: 10px;
}

.customarticleprocessingfont {
  font-weight: 600;
  color: #336D91
}

.customauthorsubmitpageAIclr {
  color: #2a6d8d;
}

.customauthorsubmitpagefontstyle {
  font-style: italic;
}

.customhowwepublishcontent {
  justify-content: flex-end;
}

.customhowwepublishcolor {
  color: black;
}

.customSellpageflexdirection {
  flex-direction: row;
}

.customSellpagejustify {
  justify-content: center;
}

.customSellpageboxclr {
  background: #ffffff;
  box-shadow: 0px 0px 12px #00000039;
  border-radius: 20px;
  padding: 20px;
  max-width: 290px;
}

.customSellpageboxclr24 {
  box-shadow: 0px 0px 12px #00000039;
  border-radius: 24px;
}

.customsellpageheight {
  height: 140px;
}

.customsellpageflexwidth {
  flex-direction: row;
  width: 100%;
}

.customsellwidth {
  width: 100%;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 3.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.form-group.has-search {
  border: 1px solid #ced4da;
  width: 97%;
  margin: 0;
  border-radius: 5px
}

// 23-10-2023

.testimonial-slider .ag_main_wrapmain-col {
  height: auto;
}

.warm-slider-img.slider-nav img {
  border-radius: 50%;
  padding: 8px 12px;
}

.overviewscss.faq-overviewscss {
  height: -webkit-fill-available !important;
  flex-wrap: wrap;
}

// 25-10-2023
a.custom-journal {
  font-size: 18px !important;
}

.custom-submit {
  width: 18%;
  padding: initial;
}

.custom-submit {
  width: 36%;
  padding: initial;
  font-size: 24px !important;
  font-weight: bold;
  height: 53px;
}

.main-heading-home {
  align-items: flex-end;
  margin-right: 100px;
}

.main-heading-home h2 {
  position: relative;
  font-size: 24px !important;
}

.main-heading-home.ag_heading_center_wrap::after {
  left: 70% !important;
  transform: translate(-50%, -50%) !important;
  bottom: -5px;
  width: 149px;
}

.main-heading-home h2.journalpage:after {
  content: "";
  position: absolute;
  background-color: #707070;
  height: 40px;
  width: 3px;
  right: -50px;
  bottom: 9px;
}

.info-custom h2 {
  font-size: 24px !important;
}

li.custom-li-journal {
  font-size: 24px;
}

.custom-journalist-class {
  padding: 10px 4px 14px 30px;
}

.publish-article-custom {
  font-size: 24px;
  font-weight: 600;
}

.main-class-publish {
  padding: 20px 30px;
}

 

.signup-btn-journal {
  font-size: 20px;
  height: 48px;
  width: 50%;
  margin: 10px auto 0;
  &:focus{
    font-size: 20px;
  height: 48px;
  }
}

.custom-24 {
  font-size: 24px !important;
}

.horizontal-line {
  margin-left: 50px;
}

.underline-class a {
  text-decoration: underline;
}

.content-journal {
  font-size: 18px;
  text-align: justify;
}
h1.marlef50.content-journal::before{
  content: "";
  bottom: -10px;
  width: 149px;
  position: absolute;
  background-color: #fe8300;
  height: 2.5px;
}
h1.marlef50.content-journal{
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 25px;
  color: rgb(51, 109, 145);
  position: relative;
  margin-right: 60px;
  font-weight: 600;
}

li.c-ml-0 {
  margin-left: 0 !important;
}

.font-48 {
  font-size: 48px !important;
}

.advantages {
  width: auto !important;
  margin-left: 38px;
}

.main-group-btns {
  height: auto;
  padding: 20px;
  max-width: 96%;
  margin-left: 50px;
}

.main-group-btns .groupcss {
  padding: 20px 35px 20px 15px;
  display: inline-block;
  margin: 12px 0;
}

.interested-head {
  width: auto;
}

.blog-type {
  margin-bottom: 40px;
}

.custom-journalist-class {
  padding: 20px 0;
}

.article_col_box ul li {
  padding-left: 15px;
}

.article_col_box .top_title h4 {
  margin-bottom: 0;
}

.custom-journalist-class li {
  padding: 10px 0px 0px 0px;
  text-align: center;
}

.blog-type .ag_opportunities_box h3 {
  margin-top: -35px;
  border-radius: 0 40px 0 30px;
}

.faq-side-list {
  box-shadow: none;
  position: relative;
}
.faq-side-list::before{
  content: '';
  position: absolute;
  right: -21px;
  top: 0;
  width: 1px;
  height: 100%;
  background: #cccccc;
}

.origin-btn {
  font-size: 20px !important;
  font-weight: bold;
}

.origins {
  font-size: 36px !important;
}

.faq-main {
  margin-bottom: 50px;
}

.card p {
  margin-bottom: 0;
}

.card span:last-child {
  margin-top: 20px !important;
}

.card p.para {
  font-weight: bold;
  font-size: 24px;
}

.main-form-btm .bottom-form-col {
  padding-left: 0px;
  flex: none;
  margin-left: 0px !important;
}

.main-form-btm .bottom-form-col {
  padding-left: 0px;
  flex: none;
  margin-left: 70px !important;
  width: calc(50% - 70px);
}

.content-area .large-title {
  font-size: 35px !important; 
}

.register-button button {
  font-size: 20px !important;
}

.content-area .italic-title.blue-text {
  font-size: 36px !important;
}

body.home .ag_oppbullets li,
.ag_opportunities_box p {
  font-weight: 400 !important;
}

.article_col_box .row.m-0.px-4 {
  align-items: center;
}

.text-white.blue-border-text a.underline.text-white {
  font-weight: 600;
  text-decoration: underline !important;
}

.about-sec img.sell-logo {
  max-width: 720px;
  margin-left: 0;
}

.custom-bold {
  font-weight: 600 !important;
}

a.black-colorr {
  color: #000 !important;
  text-decoration: underline;
}

@media screen and (max-width: 3400px) {
    .container-full .ag_heading_center_wrap span::after {
        top: 1px;
        left: 0px;
    }
}

@media only screen and (max-width: 1440px) and (min-width: 1367px) {
  .testimonial-i-dot::before {
    top: 4px;
    left: 0px;
  }

  .about-sec img.sell-logo {
    max-width: 510px;
    margin-left: -10px;
  }

  .ag_search_box input:focus {
    max-width: 600px;
  }

  .ag_special_offer_text .ag_heading_wrap::after {
    width: 165px !important;
  }

  .faqimg.faq-banner {
    margin-left: 0px;
    height: 600px;
  }

  // .ag_content_text.faq-banner-content {
  //   left: 100px;
  //   text-align: left;
  //   bottom: 50% !important;
  //   transform: translateY(50%);
  // }

  .ag_content_text.faq-banner-content .questionsstyle {
    margin-left: 0;
    font-size: 44px;
    margin-bottom: 50px;
    display: block;
    color: #01CBE1;
  }

  .ag_content_text.faq-banner-content p.fontsize22.mar {
    font-size: 26px !important;
    font-weight: 600;
  }

  .ag_content_text.faq-banner-content p {
    font-size: 34px !important;
    font-weight: 500;
    line-height: 45px;
    margin-bottom: 35px;
  }

  .overviewscss.faq-overviewscss p {
    max-width: 1100px;
    margin: 10px auto 15px auto;
    display: block;
    font-size: 22px;
  }

  .overviewscss.faq-overviewscss {
    height: -webkit-fill-available !important;
    flex-wrap: wrap;
  }

  .faw-lists .card p {
    margin-bottom: 0;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .faw-lists .card {
    padding: 20px;
  }

  .faw-lists .card span.linee {
    margin: 10px 0;
  }

  .faw-lists .card span {
    font-size: 22px;
  }

  .faw-lists {
    margin: 60px 0 0 50px;
  }

  .list.faq-side-list li {
    color: #707070;
    font-size: 22px !important;
    
    &.active{
      box-shadow: inset 0 0 15px 0 #c7c7c7;
    }
    &:hover{
      box-shadow: inset 0 0 15px 0 #c7c7c7;
    }
  }
 

  button.btn.origin-btn {
    font-size: 26px;
    width: max-content !important;
  }

  .main-heading-home h2.journalpage {
    font-size: 25px !important;
  }

  .main-heading-home.ag_heading_center_wrap::after {
    left: 59% !important;
    bottom: -9px;
  }

  .custom-submit {
    width: 52%;
  }

  .main-heading-home {
    margin-left: 20% !important;
  }

  .publish-article-custom {
    font-size: 19px;
  }

  .publish-article-custom a,
  .signup-btn-journal {
    font-size: 20px;
  }

  .custom-journal-last {
    padding-top: 0px;
  }

  .main-class-publish .info-custom h2,
  .custom-journalist-class .info-custom h2 {
    font-size: 32px !important;
  }

  

  .nation-img img {
    height: 100% !important;
    max-width: 100% !important;
  }

  .global-publish-challenges .container {
    padding: 0 120px;
  }

  // .convinced .convinced-img-wrp .convinced-text-col h4 {
  //   margin-left: 33% !important;
  // }

  .convinced .convinced-col-2 .convinced-img-col img {
    width: 100%;
  }

 

 

  
  .convinced .convinced-col-2 .convinced-img-col img {
    height: 310px;
  }

 
 

  
 
 

  .foreign .convinced-col-2 .convinced-img-col img {
    height: 250px;
  }

  body.home .ag_banner_wrap .ag_content_text {
    left: 10.1%;
  }
}

@media only screen and (max-width: 1680px) and (min-width: 1441px) {
  .faqimg.faq-banner {
    margin-left: 0px;
    height: 800px;
  }

  .ag_special_offer_text .ag_heading_wrap::after {
    width: 220px !important;
  }

 

  .ag_content_text.faq-banner-content .questionsstyle {
    margin-left: 0;
    font-size: 50px;
    margin-bottom: 50px;
    display: block;
    color: #01CBE1;
  }

  .ag_content_text.faq-banner-content p.fontsize22.mar {
    font-size: 32px !important;
    font-weight: 600;
  }

  .ag_content_text.faq-banner-content p {
    font-size: 40px !important;
    font-weight: 500;
    line-height: 45px;
    margin-bottom: 35px;
  }



  .faw-lists .card p {
    margin-bottom: 0;
    font-size: 20px;
  }

 

  .faw-lists .card span.linee {
    margin: 10px 0;
  }

   

  .faw-lists {
    margin: 60px 0 0 0;
  }

  .list.faq-side-list li {
    color: #707070;
    font-size: 24px !important;
  }

  button.btn.origin-btn {
    font-size: 32px;
    width: max-content !important;
  }

  .custom-submit {
    width: 53%;
  }

 

  .font-48 {
    font-size: 38px !important;
  }

  .fulldiv-blog-type {
    margin-top: 0px !important;
  }

  .main-heading-home.ag_heading_center_wrap::after {
    left: 60% !important;
  }

  // .blog-type .ag_opportunities_box h3 {
  //   margin-top: -50px;
  // }

  .nation-img img {
    height: 100% !important;
    max-width: 100% !important;
  }

  // .convinced .convinced-col-1 img {
  //   height: 310px !important;
  // }

  .convinced .convinced-col-1 .convinced-text-col {
    top: -35px;
  }
  .convinced .convinced-col-2 .convinced-img-col img {
    height: auto;
  }

  .convinced .convinced-col-3 .convinced-text-col p.review {
    // font-size: 21px; 
    max-width: 970px;
  }

  .convinced .convinced-col-4 .convinced-text-col .firmly {
    // font-size: 21px;
    // margin-left: 10%;
    max-width: 860px;
  }

  // .convinced .convinced-col-4 .convinced-text-col h4 {
  //   margin-left: 10% !important;
  // }

  .convinced .convinced-col-3 .convinced-img-wrp .convinced-text-col p.review {
    margin-left: 30%;
  }

  .foreign p.peerreviewers,
  .plenty {
    max-width: 100%;
    width: 100% !important;
  }

  .foreign .convinced-col-4 p.control {
 
    max-width: 860px;
  }

 

  

  body.home .ag_banner_wrap .ag_content_text {
    left: 8.1%;
  }

 
}

@media only screen and (max-width: 1950px) and (min-width: 1681px) {
  

  .faw-lists .card p {
    margin-bottom: 0;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .faw-lists .card {
    padding: 20px;
  }

  .faw-lists .card span.linee {
    margin: 10px 0;
  }

  .faw-lists .card span {
    font-size: 20px;
  }

  .faw-lists {
    margin: 60px 0 0 20px;
    padding-right: 18px;
  }

  .list.faq-side-list li {
    color: #707070;
    font-size: 24px !important;
  }

  button.btn.origin-btn {
    font-size: 32px;
    width: max-content !important;
  }

  body.home .ag_banner_wrap .ag_content_text {
    left: 10.2%;
  }

  .ag_special_offer_text .ag_heading_wrap::after {
    width: 240px !important;
  }
 

}

.mar-20 {
  margin-left: 60px;
  margin-bottom: -45px;
}
.home .ag_banner_wrap a{
  text-decoration: none;
}
a{
  text-decoration: none !important;
}
.menu-center-part span.custom-journal {
  font-size: 18px;
}
.ag_menu.justify {
  box-shadow: none !important;
  margin-bottom: 0;
}
h2.font31.font-48.tagh2 {
  font-size: 35px !important;
}
.ag_ftr_socials {
  list-style: none;
}
h1.marlef50.content-journal::before{
  content: "";
  bottom: -10px;
  width: 149px;
  position: absolute;
  background-color: #fe8300;
  height: 2.5px;
}
h1.marlef50.content-journal{
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 25px;
  color: rgb(51, 109, 145);
  position: relative;
  margin-right: 60px;
  font-weight: 600;
}
.underline_or_head{
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 25px;
  margin-top: 25px;
  color: #336d91;
  position: relative;
  margin-right: 60px;
  font-weight: 600;
}
.underline_or_head::before{
  content: "";
    // transform: translateX(-50%);
    // left: 50%;
    bottom: -10px;
    width: 149px;
    position: absolute;
    background-color: #fe8300;
    height: 2.5px;
}
.dsmar-25 .journalShadow .ag_menu ul{
  width: 100%;
  justify-content: space-between !important;

}
.dsmar-25 sup{
  color: #336d91;
  text-transform: lowercase;
}
.dsmar-25 .ag_menu ul li a{
  text-transform: capitalize !important;
}
.dsmar-25 .journalShadow .ag_menu ul li{
  margin: 0px 0px !important;
  font-family: "Montserrat", sans-serif !important;
}
.pointer-events-none{
  pointer-events: none;
  & button::after{
    display: none;
  }
}
.editorial-board-ds{
  .accordion-item{
    margin-bottom: 20px;
    box-shadow: 0px 3px 6px #00000029;
    border: none;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    .accordion-header{
      box-shadow: 0px 3px 6px #00000029;
      border-bottom: 4px solid #FF8300 ;
      .accordion-button{
        background-color: transparent;
        color: #336d91;
        text-transform: none !important;
        font: normal normal bold 20px / 26px Montserrat;
      }
    }
    .accordion-body{
      p{
        font-size: 16px;
        // font-weight: 600;
      }
      .designation{
        color: #336d91;
      }
    }
  }
}
.modal-backdrop.show{
  display: block !important;
}

.scroll-mt-95{
  scroll-margin-top: 95px;
}
.callpprlivebox{
  padding: 20px;
  background: linear-gradient(90deg, rgba(248, 187, 121, 0.6) 0%, #F2F2F2 100%);
  justify-content: center;
  border-radius: 10px;
  max-width: 860px;
  margin: 50px auto;
    p{
      font-size: 20px;
      font-weight: 600;
      color: #BC1C2B;
    }
    .ag_btn_red{
      max-width: 320px;
    }
}
.mw-830{
  max-width: 830px !important;
}
.sell-banner-text.mw-830 h3{
  font-size: 47px;
}
.sell-banner-text.mw-830 p{
  font-size: 28px;
}
.text-justify{
  text-align:justify !important; ;
}
.section-three .section-box {
  background-image: url('../images/partnership-options-image.png');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}
.section-three .section-box h1 {
  font-size: 40px;
  font-weight: bold;
  color: white;
}
.theme-blue-color{
  color:#336d91;
}
.cost-to-publish .card-head {
  background-color: #109194;
  color: white;
}
.cost-to-publish .card-text.blue {
  color: #2F6D90;
}
.cost-to-publish .card-text {
  color: #109194;
}
.cost-to-publish .card-footer a {
  color: rgba(188, 28, 43, 1);
}
.cost-to-publish .card-text-one {
  color: #FE8300;
}
.section-seven h5 {
  font-size: 25px;
  font-weight: bold; 
  color: rgba(51, 109, 145, 1);
}
.section-seven .box {
  background: linear-gradient(180deg, rgba(255, 131, 0, 0.75) 0%, rgba(255, 131, 0, 0.35) 100%);
}
.section-seven .form-control {
  height: 50px;
  border-radius: 5px;
}
.section-seven textarea {
  height: 150px !important;
}
.section-seven .btn-submit {
  background-color: #BC1C2B;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.section-seven .btn-submit:hover {
  background-color: #336D91;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.partnership-opportunities .box-two {
  background-color: #336D91;
  color: white;
}
.partnership-opportunities .box-two p {
  font-size: 38px;
}
.ds-text-grey {
  color: #97979B !important;
}
.fs-14px{
  font-size: 14px !important;
}
.fs-30px{
  font-size: 30px;
}
.ds-object-fit-cover{
  aspect-ratio: 2 / 1.1;
  object-fit: cover;
}
.mw-250{
  max-width: 250px !important;
}
.text-capitalize{
  text-transform: capitalize;

}
.blue-small-btn{
  background-color: #336D91;
    width: fit-content;
    padding: 1px 10px;
    color: #fff;
    border-radius: 8px;
    font-weight: 500;
}
.allarticle-mainheadline{
  top: 20%;
  h3{
    font-size: 70px;
  }
}
.limited-width{
  width: fit-content;
    white-space: nowrap;
    margin: auto;
}
.pe-w-text-justify{
  padding-right: 6%;
  text-align: justify;
}
.fs-21px{
  font-size: 21px;
}

.removebefore{
  padding-left:0px !important;
}
.removebefore::before{
  content:none !important;
  padding-left:0px;
}