@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.taghtml, .tagbody{
    overflow-x: hidden;
}
.tagbody {
    background-color: #fff !important;
}
.btn.focus, .btn:focus {
    outline: 0;
    box-shadow: none;
}

header.header {
    box-shadow: 0px 12px 10px -16px #111;
}
.header-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 50px;
}
.header-main .logo img {
    max-width: 250px;
}
.header-main .nav-menu ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.header-main .nav-menu ul li {
    display: inline-block;
    margin: 0px 15px;
    position: relative;
    padding: 45px 0;
}
.header-main .nav-menu ul li.have-sub-menu {
    padding-right: 25px;
    background-image: url("https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png");
    background-repeat: no-repeat;
    background-position: right center;
}
.header-main .nav-menu ul li a {
    text-decoration: none;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    color: #000;
    text-transform: uppercase;
    font-weight: 500;
}
.header-main .nav-menu ul li a:hover {
    color: #336D91;
}
.header-main .nav-menu ul li.have-sub-menu.simple-menu ul.submenu {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    background: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 15px;
    opacity: 0;
}
.header-main .nav-menu ul li.have-sub-menu.simple-menu:hover ul.submenu {
    opacity: 1;
}
.header-main .nav-menu ul li.have-sub-menu.simple-menu ul.submenu li {
    padding: 20px 0;
}
.header-main .nav-menu ul li.have-sub-menu.simple-menu ul.submenu li a {
    text-transform: capitalize;
}
.header-main .nav-menu ul li.have-sub-menu.megamenu-main .megamenu {
    position: absolute;
    padding: 15px;
    top: 100px;
    left: 100%;
    transform: translateX(-50%);
    width: max-content;
    background: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 15px;
    opacity: 0;
}
.header-main .nav-menu ul li.have-sub-menu.megamenu-main .megamenu .megamenu-content {
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    grid-template-columns: repeat(7, minmax(auto, 1fr));
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.header-main .nav-menu ul li.have-sub-menu.megamenu-main .megamenu .megamenu-content .list-items {
    max-width: 210px;
    border-right: 1px solid grey;
    padding: 0 5px;
}
.header-main .nav-menu ul li.have-sub-menu.megamenu-main .megamenu .megamenu-content .list-items .menu-title {
    font-size: 18px;
    color: #2f6d8f;
    font-family: 'Montserrat';
    font-weight: 600;
    margin-bottom: 5px;
}
.header-main .nav-menu ul li.have-sub-menu.megamenu-main .megamenu .megamenu-content .list-items .menu-link a {
    text-transform: capitalize;
    font-size: 16px;
    font-family: 'Montserrat';
}
.header-main .nav-menu ul li.have-sub-menu.megamenu-main .megamenu .megamenu-content .list-items:last-child {
    border: none;padding-right: 0;
}
.header-main .nav-menu ul li.have-sub-menu.megamenu-main:hover .megamenu {
    opacity: 1;
}
.login .login-button {
    background-color: #336D91;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    padding: 5px 30px;
}
.login .login-button img {
    margin-left: 5px;
}
.banner-section {
    background-image: url(../images/banner.png);
    background-repeat: no-repeat;
   
    
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 98px;
}
.banner-section .title {
    margin: 0;
    color: #336D91;
    font-size: 55px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.2;
    padding: 98px 0;
}
.banner-section .title  span {
    position: relative;
}
.banner-section .title span::before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 100%;
    display: -webkit-inline-box;
    background-color: #BC1C2B;
    position: absolute;
    top: 8px;
    left: 2px;
}
.content-area {
    padding: 30px 0;
    border-top: 4px solid #c1222b;
}
.center {
    text-align: center;
}
.pera-text {
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    line-height: 30px;
    margin-bottom: 20px;
    color: #000000;
    position: relative;
    z-index: 1;
}
.pera-text.italic {
    font-style: italic;
}
.red-text {
    color: #c1222b;
}
span.red-text {
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    color: #c1222b;
}
.content-area ul {
    padding: 0;
    list-style: none;
    margin: 0;
}
.pera-text a,
span.blue-text {
    color: #2f6d8f;
    font-style: normal;
    font-weight: 500;
}
.content-area .pera-text .blackbold {
    color: #000;
    font-weight: bold;
}
.content-area ul li {
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    line-height: 28px;
    margin-bottom: 20px;
    color: #000000;
    position: relative;
    padding-left: 20px;
    list-style-type: none;
}
.ag_megajournals_img_boxes li::before {
    display: none !important;
}
.content-area ul li::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 100%;
    display: -webkit-inline-box;
    background-color: #BC1C2B;
    position: absolute;
    top: 9px;
    left: 0;
}
.texmanageBox { text-align: right; }
.register-button {
    text-align: center;
    margin: 40px 0;
    &.register-unlocking-btn{
        margin: 34px 0;
    }
}
.register-button button {
    display: inline-block;
    font-family: 'Montserrat', sans-serif;
    border: none;
    padding: 10px 75px;
    background-color: #BC1C2B;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 12px;
}
.content-area .large-title {
    text-align: center;
    margin: 50px 0;
    font-size: 44px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 25px;
    position: relative;
    color: #2f6d8f;
}
.content-area .large-title span {
    position: relative;
}
h2.inline-title {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
}
.content-area .large-title span::before {
    content: '';
    width: 11px;
    height: 11px;
    border-radius: 100%;
    display: -webkit-inline-box;
    background-color: #BC1C2B;
    position: absolute;
    top: 2px;
    left: 0px;
}
.content-area .large-title::before {
    content: '';
    width: 230px;
    height: 3px;
    background: #FE8300;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}
.content-area  .small-title {
    font-size: 26px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 20px;
    margin-bottom: 15px;
    position: relative;
    color: #2f6d8f;
}
.content-area .italic-title.blue-text {
    color: #2f6d8f;
    font-style: italic;
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 40px;
}
ul.margin-botton-none li:first-child {
    margin-bottom: 10px;
}
li.margin-left-none {
    margin-left: -10px !important;
}
.content-area  .small-title::before {
    content: '';
    width: 230px;
    height: 3px;
    background: #FE8300;
    position: absolute;
    bottom: 8px;
    left: 0%;
}
.content-area  .small-title.colorGreen {
    display: inline-block;
    font-weight: 600;
    &::before{
        width: 100%;
    }
}
.colorGreen {color: #109194 !important;}
.content-area  .center-image {
    text-align: center;
    margin-bottom: 40px;
}
.content-area .center-image img {
    max-width: 600px;
    width: 100%;
}
.pera-text strong {
    font-weight: 600;
}
.table-content th {
    font-size: 20px;
    font-family: 'Montserrat';
    font-weight: bold;
    line-height: 30px;
    background: #2a6d8d;
    color: #fff;
    padding: 10px 15px;
    vertical-align: top;
}
.table-content td {
    font-size: 22px;
    font-family: 'Montserrat';
    font-weight: 400;
    line-height: 30px;
    padding: 15px 38px;
    vertical-align: text-bottom;
}
.table-content table {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 0px 8px 0px;
    border-radius: 20px;
}
.table-content th:first-child {
    border-top-left-radius: 20px;
}
.table-content th:last-child {
    border-top-right-radius: 20px;
}
.table-content {
    margin:70px 0 50px 0;
    max-width: 1200px;
    margin: 70px auto 50px;
}
.table-content td:first-child {
    border-right: 1px solid #cfcfcf;
}
.content-area .box-content {
    padding: 12px 30px;
    margin: 50px 0;
    box-shadow: #ff8521c2 0px 1px 4px;
    border-radius: 30px;

}
.content-area .box-content p.pera-text:last-child {
    margin-bottom: 0;
}
.small-title.center-title {
    text-align: center;
    margin: 40px 0;
}
.small-title.center-title::before {
    left: 50%;
    transform: translateX(-50%);
}
.width75text {
    max-width: 75%;
    margin: 0 auto 20px auto;
}

.spacing-heading-top {
    margin-top: 92px !important;
}
.how-publish-icons-second .col-lg-5:first-child {
    display: inherit;
    justify-content: flex-end;
}
.how-publish-icons-second img {
    padding-right: 0px;
}
.flex-li{
    display: flex;
}
.content-area .flex-li li::before{
    left: 9px;
}
.how-publish-icons {
/*    margin-left: -80px;*/
    margin-top: 40px;
    margin-bottom: 50px !important;
}
.archiving {
    background: #336D91;
    max-width: max-content;
    padding: 8px 50px 20px 50px;
    font-size: 35px;
    color: #fff;
    border-radius: 16px 16px 16px 0px;
    margin-top: 50px;
    font-weight: 600;
}
.ag_newsletter_text p {
    font-size: 17px;
    max-width: 624px;
}
.ag_ftr_logo img {
    width: 100%;
    max-width: 220px;
}
.archive-content {
    border: 1px solid #FF8300;
    padding: 30px 50px;
    font-size: 18px;
    border-radius: 25px;
    background: #ffffff;
    margin-top: -20px;
    margin-bottom: 40px;
    font-weight: 400;
}
.last-faq-section {
    text-align: center;
}
.row.quiz{
    position: relative;
    padding: 0;
    max-width: 1310px;
    margin: 90px auto;
}
.row.quiz .quizLeft { max-width: 490px; position: relative;  }
.row.quiz .quizLeft img{
    position: absolute;    
    top: -7px;
    left: -40px;
}
.row.quiz .quizRight {
     padding-left: 470px;
}
.quizContent { margin: 78px 0; }
.content-area .small-title1 {
    font-size: 29px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 20px;
    margin-bottom: 0px;
    position: relative;
    color: #2f6d8f;
    margin-top: 30px;
  }
.takequiz {
    text-align: left;
    margin-top: 3rem;
    font-size: 28px;
    line-height: 39px;
    margin-left: 60px;
}
.quiz {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    border-radius: 17px;
}
.how-publish-faq {
    // text-align: center;
    // margin-top: 80px;
}
.logo-shastra{
    margin-top: 50px;
}
.shastra-right{
    text-align: right;
    justify-content: flex-end;
    display: flex;
    margin: 0 0 0 auto;
}
.university-title {
    margin: 0;
    font-size: 35px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 25px;
    position: relative;
    color: #2f6d8f;
    line-height: 42px;
}
.click-here-shastra{
    margin-top: 0px;
}
.click-here-shastra button {
    width: 100%;
}
.shastra-logos{
    margin-top: 50px;
    position: relative;
    z-index: 1;
}
.guidlines{
    margin-top: 0;
}
.ag_partners_slider.owl-carousel.owl-drag .owl-item .item {
    min-height: 100px !important;
}
.ag_opportunities_box .extrapadding {
    padding-bottom: 0 !important;
}
.ag_opportunities_box a {
    font-weight: 600;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    &:hover{
        color: #bc1c2b;
    }
}
.ag_opportunities_box a.imgLinkWrap{
    position: relative;
    left: auto;
    bottom: auto;
    transform: translate(0, 0);
    cursor: pointer;
}
.ag_menu>ul>li ul li a {
    font-weight: bold;
    padding: 1px 5px !important;
}
.ag_menu>ul>li>a:hover img {
    transform: rotate(180deg);
}
.sub-lines {
    padding-left: 5px !important;
    max-width: 98%;
    margin-bottom: 6px;
    text-align: left;
}
.ag_menu>ul>li ul li a:hover {
    color: #bc212a;
}
.ag_menu>ul>li.bold-menu a {
    font-weight: 700;
}
.ag_menu>ul ul.black_text.auto-width li a:hover {
    color: #bc212a !important;
}
.ag_menu>ul>li ul {
    max-width: 90% !important;
    width: 100% !important;
    right: auto !important;
    left: 50% !important;
    transform: translateX(-50%);
}
.ag_menu>ul>li ul.subNavBox {
    & li {
        &:nth-child(1){
            & .manageNavi {
                & .sub-lines{
                    width: 138px;
                }
            }
        }
        &:nth-child(2){
            & .manageNavi {
                & .sub-lines{
                    width: 191px;
                }
            }
        }
        &:nth-child(3){
            & .manageNavi {
                & .sub-lines{
                    width: 138px;
                }
            }
        }
          &:nth-child(4){
            & .manageNavi {
                & .sub-lines{
                    width: 138px;
                }
            }
        }
           &:nth-child(5){
            & .manageNavi {
                & .sub-lines{
                    width: 190px;
                }
            }
        }
        &:nth-child(6){
            & .manageNavi {
                & .sub-lines{
                    width: 130px;
                }
            }
        }
        &:nth-child(7){
            & .manageNavi {
                & .sub-lines{
                    width: 150px;
                }
            }
        }
        &:last-child{
            border-right: 0;
        }
        & a {
            text-align: left;
        }
    }
}
.ag_menu>ul ul.black_text.auto-width {
    width: auto !important;
}
.ag_menu>ul ul.black_text.auto-width li a {
    font-weight: 500 !important;
}
.ag_menu>ul ul.black_text.auto-width li {
    width: auto !important;
    padding: 0 10px;
}
.ag_ftr_links {
    height: auto !important;
    margin-top: 14px;
}
.ag_ftr_socials img {
    max-width: 125px;
    // box-shadow: 0 0 2px rgba(0, 0, 0, 0.160784313);

}
 .youtubeIcon{
    & img {
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
    }
    
}
.ag_main_wrap.ag_partners_wrap {
    padding-top: 20px !important;
    padding-bottom: 10px !important;
}
.ag_main_wrap.ag_opportunities_wrap {
    padding-top: 50px;
    padding-bottom: 10px;
}
.ag_main_wrap.ag_warmwords_wrap.mb-5 {
    padding-bottom: 0;
    margin-bottom: 70px !important;
    padding-top: 0;
}
.ag_main_wrap.ag_megajournals_wrap {
    padding-bottom: 30px;
}
.ag_main_wrap.ag_newways_wrap {
    padding-top: 0;
    background: #ffffff;
}
.welcome-mega-journal .table-content td {
    width: 33.33%;
    border-right: 1px solid #cfcfcf;
    &:last-child{
        border-right: 0;
    }
}

.custom-journal-last {
    & .ag_opportunities_box {
        height: 100%;
        min-height: 480px;
       padding-bottom: 90px;
    }
}

.content-area .importantToRead .large-title:first-child { margin-top: 70px !important; margin-bottom: 10px; }



@media only screen and (min-width: 1600px) {
    .ag_ftr_socials img {
         
    }
    a.ag_ftr_register {
        margin-top: 0;
    }
    .ag_ftr_links {
        margin-bottom: 36px;
    }
    .ag_ftr_socials {
        margin-top: 20px;
    }
    
    .ag_footer_wrap .col-lg-5  {width:35.66%;}
}


@media only screen and (max-width: 1600px) {
    .ag_howworks_btn {
        margin-top: -180px !important;
    }
    .ag_resourcehub_wrap {
        padding-top: 0 !important;
    }
    .row.quiz .col-md-4 img{
        max-width: 390px;
    }
    .archiving{
        font-size: 35px;
    }
    // .archive-content{
    //     font-size: 19px;
    // }
    .how-publish-icons {
        margin-left: 0px;
        margin-top: 40px;
        margin-bottom: 50px !important;
    }
    .content-area .flex-li li::before{
        top: 6px;
    }
    .content-area .publish-with-us li::before{
        top: 6px;
    }
    .content-area ul li,
    .pera-text {
       
    }
   
    .table-content td {
        font-size: 19px;
        line-height: 25px;
        padding: 15px 38px;
        width: 50%;
    }
    .table-content th{
        font-size: 19px;
        line-height: 26px;
        text-align: center;
    }
    .content-area .small-title {
        font-size: 26px;
    }
    .content-area .large-title::before,
    .content-area  .small-title::before {
        width: 150px;
    }
    .content-area .large-title {
        font-size: 32px;
        margin: 35px 0;
        padding-bottom: 20px;
    }
    .register-button button {
        font-size: 23px;
    }
    .content-area .large-title span::before {
        width: 8px;
        height: 8px;
        top: 5px;
        left: 1px;
    }
    .banner-section .title {
        font-size: 42px;
    }
    .banner-section .page-title {
        width: 100%;
        max-width: 60%;
        text-align: center;
        margin: 0 auto;
    }
    .banner-section .title span::before {
        width: 10px;
        height: 10px;
        top: 5px;
        left: 1px;
    }
}

@media only screen and (max-width: 1400px) {
    .content-journal {
        font-size: 18px;
    }
    .custom-journalist-class li {
        padding: 10px 4px 0px 20px;
        font-size: 18px;
        margin-bottom: 10px;
    }
    .custom-journalist-class br{
        display: none;
    }
    .publish-article-custom a, .signup-btn-journal, .custom-submit{
        font-size: 18px !important;
    }
    .university-title {
        font-size: 34px;
    }
   
   
    .article-processing .article h5 {
        font-size: 32px;
    }
    
}

@media only screen and (max-width: 1366px) {
    // .ag_content_text.faq-banner-content {
	// 	left: 100px;
	// 	text-align: left;
	// 	bottom: 50% !important;
	// 	transform: translateY(50%);
    //     position: absolute !important;
    //     width: auto !important;
	// }
    .ag_content_text.faq-banner-content .questionsstyle {
		margin-left: 0;
		margin-bottom: 15px;
		display: block;
		color: #01CBE1;
	}
    .overviewscss.faq-overviewscss {
        padding: 10px 50px;
    }
    .minusicon {
        height: 20px;
    }
    .faw-lists {
        padding-left: 30px;
    }
    // .ag_content_text.faq-banner-content p.fontsize22 {
    //     color: #fff;
    //     margin-bottom: 5px;
    //     font-size: 20px !important;
    // }
    .custom-submit {
        width: -webkit-fill-available;
    }
    .journals-main-div .main-heading-home {
        margin-left: auto !important;
    }
    .journals-main-div .publish-article-custom {
        font-size: 18px;
    }
    .journals-main-div .info-custom h2 {
        font-size: 28px !important;
    }
    .journals-main-div  h2.font31.font-48 {
        font-size: 32px !important;
        line-height: normal;
    }
    .main-heading-home.ag_heading_center_wrap::after {
        left: 60% !important;
    }
    .banner-section .title span::before {
        top: 6px;
        left: 2px;
    }
    .content-area .italic-title.blue-text {
        font-size: 30px !important;
    }
    .global-publish-challenges h4 {
        font-size: 20px;
    }
    .global-publisher-col p.pera-text {
        font-size: 14px;
        margin-bottom: 10px;
    }
    .sell-btm-form p {
        font-size: 40px;
    }
    .sell-btm-form h2 {
        font-size: 30px;
    }
    .nation-img img {
        height: auto;
        width: 100%;
    }
    .global-publisher-col p.pera-text {
        font-size: 14px;
        margin-bottom: 10px;
    }    
    .sell-btm-form h2 {
        font-size: 30px;
    }    
    .sell-btm-form p {
        font-size: 40px;
    }    
    .nation-img img {
        height: 100%;
        width: 100%;
    }    
    .convinced-img-wrp .convinced-text {
        background: white;
        border-radius: 30px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        position: relative;
        right: unset !important;
        top: unset !important;
        transform: unset !important;
        margin: 30px auto;
        max-width: 100%;
        padding: 0px;
    }    
    .convinced-img-wrp  .convinced-img-col {
        display: none;
    }    
    .convinced-img-wrp .convinced-text .convinced-text-col {
        top: 0 !important;
        padding: 50px !important;
    }
    .global-publish-challenges .container {
        padding: 0 150px;
    }
    body.home .ag_special_offer_img p.customunderstabingapc {
        font-size: 34px;
    }
    .article-table-data.article p {
        font-size: 22px;
        text-align: right;
    }
    .article_col_box .article-table-title.article p {
        font-size: 18px;
    }
    .article-processing .article h5 {
        font-size: 28px;
    }
    .article_col_box .top_title h4 {
        font-size: 26px;
        line-height: 40px;
    }
    .blue-border-text p {
        font-size: 20px;
    }
    .updating-article p {
        font-size: 18px;
    }
    .updating-article h4 {
        font-size: 28px;
    }
    
}

@media only screen and (max-width: 1280px) {
    .origin-btn {
        font-size: 18px !important;
    }
    .faw-lists {
        padding-left: 15px;
    }
    .origins {
        font-size: 26px !important;
    }
    .history {
        height: 40px !important;
    }
    .overviewscss.faq-overviewscss br {
        display: none;
    }
    .faq-side-list li {
        font-size: 14px;
        margin-bottom: 10px;
    }  
    .faq-side-list {
        padding: 10px 0;
    }
    .faq-side-list br {
        display: none;
    }
    .journals-main-div .publish-article-custom {
        font-size: 17px;
    }
    .main-heading-home h2.journalpage:after {
        bottom: auto;
    }
    .main-group-btns .groupcss {
        padding: 10px 15px 10px 15px;
    }
    .register-button button {
        font-size: 23px !important;
    }
    .university-title {
        font-size: 30px;
    }
}

@media only screen and (max-width: 1024px) {
    .publish-article-custom a, .signup-btn-journal, .custom-submit {
        font-size: 14px !important;
        height: 50px;
    }
    .journals-main-div .publish-article-custom {
        font-size: 16px;
    }
    .journals-main-div .info-custom h2 {
        font-size: 20px !important;
    }
    .custom-24 {
        font-size: 16px !important;
    }
    .journals-main-div h2.font31.font-48 {
        font-size: 26px !important;
        line-height: normal;
    }
    .main-heading-home h2 {
        position: relative;
        font-size: 20px !important;
    }
    .content-journal {
        font-size: 16px;
    }
    .sell-banner-text h3 {
        font-size: 30px;
        line-height: 40px;
    }
    .about-sec img.sell-logo {
        max-width: 450px;
        margin-left: 0;
    }
    .global-publish-challenges .container {
        padding: 0 0px;
    }
    .sell-btm-form p {
        font-size: 35px;
    }
    .sell-btm-form h2 {
        font-size: 29px !important;
    }
    .sell-banner-text .title span::before {
        top: 2px;
    }
    .about-sec img.sell-logo {
        max-width: 100% !important;
        margin-left: 0;
    }
    .about-sec span {
        font-size: 18px;
        line-height: 18px;
    }
    .Transforming-btm-text h4 {
        font-size: 18px;
        line-height: 36px;
    }
    .Transforming-btm-text p {
        font-size: 22px;
        line-height: 30px;
        padding: 0 80px;
    }
    .banner-section.guidlines .page-title {
        max-width: 50%;
    }
    .article_col_box .top_title h4 {
        font-size: 22px;
        line-height: 30px;
    }
    .article_col_box ul li {
        padding-left: 15px;
        font-size: 18px;
    }
}
 
@media only screen and (max-width: 991px) {
    .university-title {
        font-size: 30px;
        text-align: center;
        margin-top: 30px;
    }
    .sell-btm-form h2 {
        font-size: 22px !important;
    }
    .sell-btm-form p {
        font-size: 28px;
    }
    .nation-img img {
        height: auto !important;
        max-width: 100% !important;
    }
    .main-form-btm .bottom-form-col {
        padding-left: 0px;
        flex: none;
        margin-left: 0px !important;
        width: calc(100% - 0px);
    }
    .main-global-publish-challenge {
        padding: 0 20px;
    }
    .sell-banner-wrp {
        display: inline-block;
        width: 100%;
        position: relative;
        height: auto;
        margin-top: 0;
    }
    .sell-banner-text .title span::before {
        top: 4px;
        left: 1px;
    }
    .Transforming-btm-col .ag_heading_center_wrap h2 {
        font-size: 22px;
    }
    .container-full .ag_heading_center_wrap span {
        font-size: 28px;
        font-weight: 700;
        position: relative;
    }
    .container-full .ag_heading_center_wrap span::after {
        width: 9px;
        height: 9px;
        top: 1px !important;
        left: 1px;
    }
    .specific-issue .specific-issue-text h3 {
        font-size: 32px;
        line-height: 44px;
    }
    .nation-right-col {
        padding-left: 0;
        margin-top: 30px;
    }
    .nation-one .nation-wrp {
        width: 100%;
    }
    .choosing-main-text {
        max-width: 100%;
        margin: 0 auto;
    }
    .choosing-main-text p.pera-text.mb-5 {
        margin-bottom: 15px !important;
    }
    .customSellpageboxclr {
        max-width: 100%;
        margin-top: 30px;
    }
    .sell-banner img {
        object-fit: cover;
    }
    .choosing-main-text {
        max-width: 100% !important;
    }
    body::after{
        display: none !important;
    }
    .register-unlocking-btn a {
        font-size: 24px;
    }
    .banner-section.guidlines .page-title {
        max-width: 60%;
    }
    .article-launch-offer h2 span {
        font-size: 30px !important;
        line-height: normal !important;
        margin: 10px 0;
    }
    .article-launch-offer h2 {
        font-size: 22px !important;
        line-height: normal !important;
    }
    
}

@media only screen and (max-width: 767px) {
    body .ag_howworks_btn {
        position: relative;
        bottom: auto;
        left: auto;
        transform: unset;
        margin-top: 0px !important;
    }
    .ag_publishjournal_wrap span.publish-i-dot::before {
        top: 4px;
        left: 1px;
    }
    .testimonial-i-dot::before {
        top: 4px;
        left: 1px;
    }
    .howitworks {
        background-size: 100%;
    }
    .howitworks {
        height: 380px;
        background-size: 100%;
        background-position: 0px 120px;
        margin-bottom: 70px;
    }
    body .ag_main_wrap.ag_special_offer_wrap {
        padding-top: 0px !important;
    }
    .ag_main_wrap.ag_newways_wrap .row.justify-content-between.align-items-center.mt-5.pt-5 {
        padding-top: 0 !important;
        margin-top: 0 !important;
    }
    span.publish-i-dot::before {
        top: 0px;
        left: 1px;
    }
    body::after{
        display: none;
    }
    .foreign-nation h4 {
        font-size: 26px;
        font-weight: 600;
    }
    .ag_publishjournal_text {
        padding: 20px 20px !important;
    }
    .updating-article p {
        font-size: 16px;
    }
   
    .ag_content_text.faq-banner-content a.ag_btn_red {
        margin-left: 0px;
    }
    .journals-main-div .div30,
    .faq-main-div .div30 {
        width: 100%;
    }
    .journals-main-div .div70,
    .faq-main-div .div70 {
        width: 100%;
    }
    .journals-main-div.maindiv,
    .faq-main-div.maindiv {
        display: block;
        padding: 0 15px;
    }
    .faw-lists {
        padding-left: 0;
    }
    .overviewscss.faq-overviewscss {
        padding: 10px 20px;
    }
    .journals-main-div.maindiv {
        margin-top: 50px;
    }
    .publish-article-custom a, .signup-btn-journal, .custom-submit {
        width: max-content !important;
    }
    .marlef50 {
        margin-left: 0;
    }
    .journals-main-div .div70 {
        margin-top: 50px;
    }
    .horizontal-line {
        margin-left: 0;
    }
    .advantages {
        width: auto !important;
        margin-left: 0;
    }
    .bgimggroup {
        margin-left: 0;
    }
    .journals-main-div .main-heading-home {
        width: 40% !important;
        margin-left: 0 !important;
        margin-right: auto !important;
        justify-content: flex-start !important;
    }
    .journals-main-div a.articlenews.custom-submit {
        width: 45% !important;
    }
    .main-heading-home.ag_heading_center_wrap::after {
        left: 65% !important;
    }
    .content-area .large-title span::before {
        width: 8px;
        height: 8px;
        top: 0px;
        left: 0px;
    }
    img.desktop-image {
        display: none;
    }
    .Transforming-btm-text p {
        font-size: 20px;
        line-height: 30px;
        padding: 0;
    }
    .Transforming-btm-text h4 {
        font-size: 16px;
        line-height: 36px;
    }
    .Transforming-btm-text .pera-text {
        font-size: 18px;
    }
    .sell-btm-form h2 {
        font-size: 18px !important;
    }
    .sell-btm-form p {
        font-size: 24px;
    }
    .row.quiz .col-md-4 img {
        bottom: 0 !important;
        left: auto !important;
    }
    .banner-section.guidlines h1.title {
        font-size: 36px !important;
    }
    .banner-section.guidlines .page-title {
        max-width: 90%;
    }
    .banner-section.guidlines {
        height: 150px;
        margin-top: -30px;
    }
    .banner-section.guidlines h1.title span::before {
        width: 8px;
        height: 8px;
        top: 6px;
        left: 2px;
    }
    .article_col_box {
        margin: 0;
    }
    body.home .ag_special_offer_img p.customunderstabingapc {
        font-size: 24px;
    }
    .container {
        padding: 0 15px !important;
    }
    .article-processingg-charges p.pera-text-att.px-3.px-lg-0 {
        padding: 0 0 !important;
    }
    .banner-section {
        margin-top: 75px;
    }
}

@media only screen and (min-width: 768px) {
    img.mobile-image {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .ag_content_text {
        margin-top: 100px;
    }
    .ag_opportunities_wrap h2.cus-title {
        font-size: 30px !important;
    }
    span.publish-i-dot::before {
        top: 4px;
        left: 1px;
    }
    span.publish-i-dot::before {
        top: 1px;
        left: 0px;
    }
    .journalBannercontainer .videocontainer {
        height: auto !important;
        margin-top: 80px;
    }
    .journalBannercontainer .disflex {
        display: block;
    }
    .journals-main-div a.articlenews.custom-submit {
        width: max-content !important;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
    }
    .journals-main-div .main-heading-home {
        width: 100% !important;
        justify-content: center !important;
        margin-left: auto !important;
        margin-right: auto !important;
        align-items: center !important;
    }
    .main-heading-home.ag_heading_center_wrap::after {
        left: 26% !important;
    }
    .main-heading-home h2.journalpage:after {
        display: none;
    }
    .about-sec img.sell-logo {
        margin-bottom: 20px;
    }
    .sell-banner-text {
        max-width: 90%;
        left: 20px;
    }
    .table-content table {
        width: 200%;
        overflow-x: auto;
    }

    .row.quiz .quizLeft img { left: 0; position: relative;  }
    .row.quiz .quizRight { padding-left: 0; }
    .quizContent span, .quizContent strong,
    .quizContent .takequiz { font-size: 18px; line-height: 28px; padding-left: 0;  }
    .takequiz { margin-left: 20px; margin-right: 20px; }

}



@media only screen and (max-width: 480px) {
    .ag_content_text {
        margin-top: 50px;
    }
    .ag_content_text.faq-banner-content {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        bottom: auto !important;
        margin-top: 20px;
    }
    .banner-section.guidlines h1.title {
        font-size: 30px !important;
    }
    .banner-section.guidlines h1.title span::before {
        top: 4px;
        left: 1px;
    }
    .university-title {
        font-size: 22px;
    }
    .ag_megajournals_img_boxes ul {
        padding: 0 0px 0 ;
    }
    .ag_megajournals_img_boxes {
        padding: 0;
    }
    .banner-section {
        height: 100px;
        margin-top: 70px;
    }
    .register-unlocking-btn a {
        font-size: 18px;
    }
    // .article_col_box .row.m-0.px-4 {
    //     display: block;
    //     padding: 0 !important;
    // }    
    // .article-table-title.article {
    //     width: 100%;
    //     padding: 0;
    // }    
    // .article-table-data.article {
    //     width: 100%;
    //     padding: 0;
    // }
    .article-table-data p {
        font-size: 18px !important;
    }
    .article_col_box .article-table-title.article p {
        font-size: 16px;
    }
    .article-processing .article h5 {
        font-size: 22px;
    }
    body.home .ag_special_offer_img p.customunderstabingapc {
        font-size: 20px;
        padding: 10px 0px 40px 45px;
        line-height: normal;
    }
    body.home .ag_special_offer_img p.customunderstabingapc img {
        width: 35px;
    }
    .article-launch-offer h2 {
        font-size: 18px !important;
    }
    .article-launch-offer h2 span {
        font-size: 24px !important;
    }
    .article-launch-offer .article_subtext {
        font-size: 17px;
    }
    .blue-border-text p {
        font-size: 16px;
    }
}

.ag_menu > ul > li ul.tagul.societyUl{
    max-width: 215px !important;
    & li {
        border: 0;
        color: #bc1c2b;
    }
}





