@import "../../../styles/variables.scss";
@import "../../../styles/typography.scss";

.mobile-login-page {
    background-image: url("../../../svg/body-base.svg");
    background-color: $body-base_color;
    min-height: 100vh;
    height: 100vh;
    background-size: cover;
    overflow: auto;

    .left-base {
        background: linear-gradient(180deg, #019C94 0%, #098DAD 51.58%, #1D5CAA 100%);
        border-right: 3px solid rgba(255, 176, 27, 0.8392156863);
    }

    .frame {
        padding: 45px 100px 12px 100px;
        border-radius: 10px;
        min-height: 80vh;
        margin-bottom: 20px;

        label {
            @extend .inputLabel;
        }
    }

    .welcome-title {
        @extend .welcomeTitle;
    }

    .title {
        @extend .frameHeading;
    }

    .link {
        @extend .linkFont;
          font-size: 14px;
    }

    .row {
        margin: 0;
    }

    .w-90 {
        width: 90%;
    }

    .label {
        @extend .tinyFont;
    }

    .labelColor {
        @extend .labelColor;
    }

    .tel-input {
        padding: 6px 70px;
    }

    .footer-note {
        @extend .frameNote;
        text-align: center;
    }

    .tel-pd {
        padding: 9px 12px 11px 70px;
    }

    .tel-active {
        position: absolute;
        left: 29px;
        top: 38.5px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $primary-color;
        z-index: 1;
    }

    .tel-disable {
        position: absolute;
        left: 29px;
        top:  40px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #97979B;
        z-index: 1;
    }

    .grey-input {
        @extend .inputField;
    }
    .logo_container {
        text-align: center;
        img {
            width: 450.95px !important;
            margin-left: 0;
        }
    }
}

@media only screen and (max-width: 768px) {
    .frame {
        padding: 45px 15px !important;
    }
}