.reactSelect-container {

    & .reactSelect {
  
      &__control {
        border-color: #858585 !important;
        border-width: 0.5px;
        border-radius: 10px;
        background-color: transparent;
        font-weight: 600;
        font-size: 14px;
        color: #97979B;
  
        &:hover {
          border-color: #858585 !important;
          box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
        }
        &:active,
        &:focus,
        &--is-focused,
        &--is-selected {
          border-color: #858585 !important;
          box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
        }
  
      }
  
      &__value-container {
        padding: 2px 8px;
      }
  
      &__placeholder {
        color: #777;
      }
  
      &__indicators {}
  
      &__indicator-separator {
        display: none;
      }
  
      &__dropdown-indicator {}
  
      &__menu {
        z-index: 9;
        border-radius: 10px;
        background: #FFFFFF;
        box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.08);
      }
  
      &__menu-list {}
  
      &__option {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #404040;
        line-height: 22px;
        border-bottom: 0.3px solid rgba(0, 0, 0, 0.21);
        text-align: left;
        &:last-child {
          border-bottom: none;
        }
  
        &:active,
        &:focus,
        &--is-focused,
        &--is-selected {
          background-color: transparent !important;
          color: #009B94;
        }
  
        &--is-disabled {
          background-color: transparent !important;
          color: #aaa;
        }
  
  
      }
  
    }
  }