/** VAR **/
@import "../base/themecomponents/_variables_app.scss";

/** PAGE HEADER **/
.boxHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-family: $familyDefault, $fallbackfont;

    & .boxStep {
        margin-bottom: 12px;

        & .stepName {
            padding: 7px 15px;
            border-radius: 10px;
            text-align: center;
            font-size: 14px;
            color: rgba($dark, .4);
            font-weight: 600;

            &.active {
                background-color: rgba($dark, .04);
                color: $dark;
                font-weight: 700;
            }
        }

        & ul {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: rgba($dark, .3);

            & li {
                list-style: none;
                padding: 0 10px;

                &:first-child {
                    padding-left: 0;
                }
            }
        }
    }

    & .boxDone {
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        color: rgba($dark, .6);
        padding: 0 15px;

        & .progressWrap {
            margin: 0 10px;

            & .progressBar {
                height: 10px;
                width: 130px;
                border-radius: 10px;
                background-color: rgba($dark, .04);
                position: relative;
                overflow: hidden;

                & .progressReal {
                    width: 0%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    border-radius: 10px;
                    background: linear-gradient(270deg, rgba(1, 183, 174, 0.84) 27.38%, rgba(45, 51, 141, 0.84) 154.29%);
                    transition: all 350ms ease-in-out;
                }

            }
        }

    }
}

/** PAGE FOOTER **/
.boxFooter {
    padding: 25px 0;
    position: relative;

    & .goBack {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        color: $dark;
        font-weight: 700;
        font-size: 18px;
        text-decoration: none;
        padding-bottom: 16px;
        cursor: pointer;

        & b {
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
}

/** POFILE PICTURE **/
.profilePic {
    display: flex;
    align-items: center;

    & .picCircle {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        background-color: $primary;
        text-align: center;
        padding: 10px;
        display: inline-block;
        overflow: hidden;
        position: relative;
        cursor: pointer;

        &:hover {
            box-shadow: 0 0 20px rgba($primary, .2);
        }

        & input {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }

        & b {
            color: $light;
            font-size: 28px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    & p {
        font-size: 14px;
        font-weight: 700;
        color: $primary;
        padding: 0 20px;
    }

    & span {
        font-size: 14px;
        font-weight: 400;
        color: $dark-400;
        padding: 0 20px;
        display: block;
    }
}

/** POFILE DETAILS **/
.presenceList {

    & .presenceItem {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 0;

        & .presenceName {
            font-size: 15px;
            font-family: $familyDefault, $fallbackfont;
            color: $dark-400;
            font-weight: 500;
            width: 20%;
        }

        & .presenceIcon {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 2px;
            background-color: rgba($dark, .04);
            vertical-align: middle;
            margin-right: 15px;
        }

        & .presenceUrl {
            max-width: 475px;
            width: 60%;
        }

        & .presenceView {
            width: 20%;
        }
    }
}

.rateList {
    & li {
        list-style: none;
    }
}

.rateList+small {
    font-size: 12px;
    color: $dark;
    line-height: 18px;
    display: block;
}

/** RATE **/
.rateLevel {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 12px 0;
}



.anotherService {
    display: flex;
    margin: 0 -40px;

    &-col {
        padding: 0 40px;
        width: 100%;
    }

    & .serviceFee {
        font-size: 14px;
        color: $dark;
        line-height: normal;
        position: relative;
        margin-top: 50px;
        max-width: 350px;

        & b {
            font-weight: 700;
            margin-bottom: 5px;
            display: block;
        }

        & a {
            color: $primary;
            text-decoration: none;
            font-weight: 700;
            position: absolute;
            right: 0;
            top: 0;
        }

    }
}

.sampleText {
    font-size: 14px;
    color: $dark-400;
}

.sampleCond {
    font-size: 12px;
    color: $dark-400;
    padding: 15px 0;

    & span {
        padding: 0 10px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    & b {
        color: $dark;
    }
}

.sampleUpload {
    display: flex;
    align-items: center;

    &-file {
        width: 168px;

        & .imgWrap {
            margin: 0;
        }
    }

    &-more {
        padding-left: 25px;

        & a {
            width: 38px;
            height: 38px;
            border-radius: 50%;
            background-color: rgba($primary, .1);
            color: $primary !important;
            font-size: 14px;
            font-weight: 600;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            cursor: pointer;
            margin-right: 10px;

            &:hover {
                opacity: .85;
            }
        }

        & small {
            display: inline-flex;
            font-size: 11px;
            color: $dark;
            font-style: italic;
            align-items: center;

            & span {
                background-color: $primary;
                color: $light !important;
                width: 18px;
                height: 18px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                font-size: 10px;
                padding: 0;
                cursor: pointer;
                text-decoration: none;
                margin-right: 5px;
            }
        }
    }
}

.serviceRates {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -40px;

    &-col {
        padding: 0 40px;
        width: 50%;
    }
}

.imageUpload {
    display: flex;
    align-items: center;

    &-row {
        display: flex;
        align-items: center;
    }

    &-file {
        width: 168px;

        & .imgWrap {
            margin: 0;
        }
    }

    &-updated {
        font-weight: 700;
        color: $primary;
        font-size: 14px;
        text-decoration: none;
        cursor: pointer;
        display: inline-block;
        margin-left: 15px;

        &:hover {
            color: $primary;
        }
    }

    &-more {
        padding-left: 25px;

        & a {
            width: 38px;
            height: 38px;
            border-radius: 50%;
            background-color: rgba($primary, .1);
            color: $primary !important;
            font-size: 14px;
            font-weight: 600;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            cursor: pointer;
            margin-right: 10px;

            &:hover {
                opacity: .85;
            }
        }

        & small {
            display: inline-flex;
            font-size: 11px;
            color: $dark;
            font-style: italic;
            align-items: center;

            & span {
                background-color: $primary;
                color: $light !important;
                width: 18px;
                height: 18px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                font-size: 10px;
                padding: 0;
                cursor: pointer;
                text-decoration: none;
                margin-right: 5px;
            }
        }
    }
}

/****/

.titleText {
    position: relative;

    & .showOpt {
        position: absolute;
        right: 6%;
        top: 0;
        font-size: 12px;
        font-weight: 400;
    }
}

.founderMsg {
    max-width: 410px;
    width: 100%;
    display: inline-table;

    &-video {
        max-width: 240px;
        margin: 15px auto;
    }

    &-title {
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        color: $primary;
    }

    & .videoWrap {
        border-radius: 2px;
        box-shadow: 0 0 35px rgba($dark, 0.09);
        background-color: $dark;
        position: relative;
        overflow: hidden;
        transition: all 800ms ease-in-out;


        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($dark, .75);
        }

        & .videoButton {
            width: 24px;
            height: 24px;
            // background-color: $light;
            position: absolute;
            left: 50%;
            top: 50%;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            display: block;
            cursor: pointer;
            z-index: 1;

            & img {
                width: 24px;
                height: 24px;
                display: block;
            }

            &:hover {
                opacity: .85;
            }
        }


        & video {
            width: 100%;
            display: block;
            margin: 0 auto;
        }
    }


}

/** PROFILE PREVIEW **/
.previewTitle {
    padding: 20px 0;
    background-color: rgba($dark-900, .06);
    margin-top: 22px;

    & h1 {
        font-size: 30px;
        color: $dark;
        font-weight: 700;
        margin-bottom: 5px;
    }

    & p {
        font-size: 22px;
        line-height: normal;
    }
}

.previewContent {
    background-color: #fbfbfb;
    padding-top: 30px;

    & .simpleTabs {
        padding-left: 25px;
    }

    & .boxCover {
        padding-top: 25px;
        padding-bottom: 36px;
        padding-left: 25px;
        padding-right: 25px;
        background: none;
        box-shadow: none;
        margin-top: 0;

        &:first-child {
            margin-top: 0;
        }
    }
}

.previewProfile {
    padding: 18px 20px;
    background-color: $light;
    box-shadow: 0 0 18px rgba($dark, .07);
    border-radius: 0 30px 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    clear: both;
    margin-bottom: 30px;

    &-img {
        position: relative;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        // background-color: #f1f1f1;
        & .overlayBg {
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.3);
            display: inline-block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
        }
        & img {
            display: block;
            max-width: 100%;
        }
    }

    &-info {
        max-width: calc(100% - (100px + 358px));  
        padding: 0 15px;
        padding-right: 0;
        width: 100%;

        & small {
            background-color: rgba($primary, .04);
            display: inline-flex;
            align-items: center;
            padding: 2px 12px;
            border-radius: 5px;
            font-size: 14px;
            color: $secondary;
            font-weight: 400;
            margin-bottom: 3px;
        }

        & p {
            font-size: 16px;
            font-weight: 600;
            color: $secondary;
            padding: 0 12px;
            border-right: 1px solid rgba($dark-900, .5);
            display: inline-flex;
            align-items: center;
            vertical-align: middle;

            & .updateLink {
                text-decoration: none;
                cursor: pointer;
                color: $primary;
                font-weight: 700;
                margin-left: 20px;
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                border-right: none;
                padding-right: 0;
            }

            & b {
                margin-right: 5px;
                vertical-align: middle;
                display: inline-block;
            }

        }
    }

    &-btn {
        min-width: 362px;
        text-align: right;
        & button {
            font-weight: 700;
        }
    }

    & .profile-camera {
        position: absolute;
        top: 0px;
        font-size: 50px;
        left: 25px;
        z-index: 9;
        width: 42px;
    }
}


/** REVIEW **/

.reviewList {
    padding-top: 10px;

    & li {
        list-style: none;
        padding: 15px 10px;
        border-bottom: 1px solid rgba($dark, .08);

        &:last-child {
            border-bottom: none;
        }
    }
}


.reviewCard {
    padding: 5px 0;
    padding-right: 120px;
    position: relative;

    & .flagMark {
        position: absolute;
        right: 52px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        text-decoration: none;

    }

    &-intro {
        display: flex;
    }

    &-img {
        width: 26px;
        height: 26px;
        display: block;
        border-radius: 50%;
        overflow: hidden;
    }

    &-info {
        padding: 0 10px;

        & .info-name {
            font-size: 12px;
            color: $dark-700;
            display: flex;
            align-items: center;

            & p {
                font-weight: 700;
                color: $primary;
                padding-right: 5px;
            }

            & small {
                font-weight: 400;
                color: $dark-400;
                padding-left: 5px;
                font-size: 12px;
            }
        }

        & .info-rating {
            display: flex;
            align-items: center;
            font-size: 0;
            padding-top: 0;

            & img {
                width: 12px;
                display: block;
            }
        }
    }

    &-text {
        padding-top: 12px;

        & p {
            margin: 0;
            padding: 0;
            font-size: 14px;
        }
    }
}


.otpInfo {
    width: 100%;
    text-align: center;
    padding: 14px 0;

    & p {
        font-size: 16px;
        font-weight: 500;
        color: $dark;
        margin-bottom: 14px;
        color: #009B93;
    }

    & small {
        display: block;
        font-size: 16px;
        font-weight: 400;
        color: $dark-900;
        padding-top: 5px;

        & a {
            font-weight: 700;
            color: $primary !important;
            text-decoration: none;
            cursor: pointer;
        }
    }
}


.simpleText {
    font-size: 14px;
    color: $dark;
}
.mb-36 {
    margin-right: 36px;
}