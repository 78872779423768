.dashboard-sidebar {
    padding: 20px;
    font-family: 'Inter';
    font-style: normal;

    .sidebar-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #FF8300;
        padding-bottom: 15px;
    }

    .sidebar-link {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #167092;
        padding-bottom: 15px;
    }
    
    .links-container {
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #E5E5E5;
    }
}