/** VAR **/
@import "../base/themecomponents/_variables_app.scss";

/***/
header  {
    &.home-header {
        background-color: $light;
        box-shadow: 0 0 20px rgba($dark-200, .1);
        width: 100%;
        margin: 0 auto;
        padding: 5px 20px; 
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
        z-index: 3;
        transition: all 350ms ease-in-out;
    
        & .menuToggle {
            width: 30px;
            height: 30px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $dark-400;
            font-size: 150%;
            margin-left: -15px;
            position: relative;
            z-index: 1;
            padding: 4px;
    
            @media #{$desktop} {
                display: none;
            }
        }
    & .headerLeft{
        display: flex;
        align-items: center;
        width: 50%;
        & .maniSearch {
            margin-left: 20px;
            max-width: 460px;
            width: 100%;
        }
    }
        & .cmyLogo {
            max-width: 100px;
            display: block;
            width: 100%;
    
            @media #{$desktop} {
                max-width: 200px;
            }
    
            & img {
                max-width: 100%;
                display: block;
            }
        }
    
        & .navWrap {
            position: fixed;
            left: 0;
            top: 50px;
            width: 100%;
            height: calc(100vh - 50px);
            background-color: $light;
            transform: translateX(-100%);
            opacity: 0;
            transition: all 350ms ease-in-out;
    
            &.active {
                transform: translateX(0);
                opacity: 1;
            }
    
            @media #{$desktop} {
                display: flex;
                vertical-align: middle;
                align-items: center;
                justify-content: end;
                width: calc(100% - 200px);
                position: relative;
                height: auto;
                top: 0;
                background-color: transparent;
                transform: translateX(0);
                opacity: 1;
    
                &.active {
                    transform: translateX(0);
                    opacity: 1;
                }
    
    
            }
    
            & .navList {
                & ul {
                    margin: 0;
                    padding: 0;
    
                    @media #{$desktop} {
                        display: flex;
                        align-items: center;
                    }
    
                    & li {
                        list-style: none;
                        padding: 30px 30px;
                        border-bottom: 1px solid rgba($dark, .04);
    
                        @media #{$desktop} {
                            padding: 0 24px;
                            border-bottom: none;
    
                            &:first-child {
                                padding-left: 0;
                            }
    
                            &:last-child {
                                //   padding-right: 0;
                            }
                        }
    
    
                        & a {
                            text-decoration: none;
                            cursor: pointer;
                            color: $secondary;
                            font-weight: 500;
                            font-size: 16px;
    
                            &.active {
                                font-weight: 800;
                            }
    
                            &:hover {
                                opacity: .85;
                            }
                        }
                    }
                }
    
    
            }
        }
    
        & .navSign {
            display: flex;
            align-items: center;
            border-left: 1px solid rgba($dark, .08);
            padding: 4px 0;
            padding-right: 128px;
    
            @media #{$desktop} {
                margin-left: 24px;
                padding-right: 154px;
            }
    
            & a {
                font-weight: 500;
                font-size: 14px;
                text-decoration: none;
                cursor: pointer;
                display: inline-block;
    
                @media #{$desktop} {
                    font-size: 16px;
    
                }
    
                &:hover {
                    opacity: .85;
                }
            }
    
            & .signIn {
                color: $secondary;
                margin: 0 20px;
    
                @media #{$desktop} {
                    margin: 0 24px;
                }
    
                &.active {
                    font-weight: 800;
                }
            }
    
            & .signUp {
                height: 100%;
                display: flex;
                background-color: $secondary;
                color: $light;
                padding: 10px 15px;
                text-align: center;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 0;
                top: 0;
    
    
                @media #{$desktop} {
                    border-radius: 20px;
                    padding: 20px;
                    width: 174px;
    
                }
    
            }
        }
    
    
        & .navheader {
            display: flex;
            align-items: center;
            padding: 16px 0;
    
            & a:not(.button) {
                font-size: 16px;
                font-weight: 600;
                color: $primary;
                text-decoration: none;
            }
    
            & .navLink {
                padding: 2px;
                // border-right: 1px solid rgba($dark-200, .25);
                margin-right: 15px;
    
                & ul {
                    display: flex;
                    align-items: center;
    
                    & li {
                        list-style: none;
                        padding: 0 15px;
                        position: relative;
                        &::before{
                            content: '';
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 1px;
                            height: 13px;
                            background: #A7A7A7;
                           
                        }
                        & a{
                            position: relative;
                            &.active {
                                color: #404040 !important;
                                &::before {
                                    content: "";
                                    width: 34px;
                                    height: 8px;
                                    border-radius: 8px;
                                    background: linear-gradient(270deg, rgba(255, 176, 27, 0.84) -2.89%, rgba(227, 232, 123, 0.84) 100%);
                                    display: block;
                                    position: absolute;
                                    left: 0;
                                    bottom: -7px;
                                    z-index: 1;
                                }
                            }
                        }
                    }
                }
            }
    
          
    
            & .navOnline {
                padding: 0 15px;
                font-weight: 600;
                color: $primary !important;
                font-size: 12px;
                display: flex;
                align-items: center;
    
                & .form-switch {
                    margin-right: 5px;
                }
            }
    
            & .navDashboard {
                padding: 0 15px;
    
                & a {
                    background-color: rgba($primary, .12);
                    padding: 6px 16px;
                    text-align: center;
                    border-radius: 12px;
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    cursor: pointer;
                    & b {
                        margin-right: 5px;
                    }
    
                    &:hover {
                        background-color: rgba($primary, 1);
                        color: $light !important;
                    }
        
                }
    
            }
    
        }
    
        @media #{$desktop} {
            &.fixed {
                position: fixed;
                left: 50%;
                top: 0;
                border-radius: 0;
                transform: translate(-50%, 0); 
                width: 100%;
                max-width: 100%;
                margin: 0 auto;
                border-bottom: 0.5px solid #009B94;
                z-index: 99;
                & .signUp {
                    border-radius: 0;
                }
            }
        }
    }
}

.maniSearch {
    margin-left: 9px;
    & input {
        border-radius: 10px; 
        border: 0;
        padding: 6px 12px 6px 34px;
        background: #F6F9FB url(../../img/icons/search.svg); 
        background-position: 10px center;
        background-repeat: no-repeat;
        font-family: "Inter", sans-serif;
        font-weight: 600;
        color: #009B94;
        width: 100%;
        border: 0.5px solid rgba(22, 112, 146, 0.35);
        &::placeholder{
            color: #404040;
            font-weight: 300;
            font-size: 14px;
        }
    }
}

.navNotify {
    padding: 0 10px 0 0;

    & a {
        font-size: 150%;
        display: flex;
        align-items: center;
        position: relative;
        &.active{
            & b{
                background: url(../../img/icons/notification_active.svg);
            }
            & span {
                display: block;
            }
        }
        & span {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #BF1E2E;
            position: absolute;
            right: 3px;
            top: 2px;

        }
        & b{
            
            display: inline-block;
            background: url(../../img/icons/notification.svg);
            width: 23px;
            height: 27px;
            &.active{
                background: url(../../img/icons/notification_active.svg);
            }
        }
    }
}

.navNotify {
    position: relative;
    & a {
        cursor: pointer;
    }
    &.active {
        & .notifigationBox {
            display: block;
        }
    }
    & .notifigationBox {
        position: absolute;
        right: 0;
        top: 55px; 
        width: 586px;
        background: #FFFFFF;
        box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        overflow: hidden;
        // display: none;
        & .notificationHead {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            text-transform: uppercase;
            padding: 25px 0px 10px 0px;
            & h2 {
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                text-transform: uppercase;
                color: #FF8300;
                margin-bottom: 0;
                padding-right: 40px;
            }
            & span {
                font-size: 14px;
            }
        }
        & .notiWrapBox {
            max-height: 440px;
            overflow: auto;
            & ul {
                list-style: none;
                padding-left: 0;
                & li {
                    padding: 10px 20px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 0.5px solid #A7A7A7;
                    background: #ffffff;
                    min-height: 75px;
                    &:last-child{
                        border-bottom: 0;
                    }
                    &.new_noti{
                        border-bottom: 0;
                        background: #F6F9FB;
                    }
                    &.notiListWrap {
                        padding: 10px 19px 10px 27px;
                        & .notiListArea{
                            padding-right: 50px;
                        }
                        & span {
                            // color: #167092;
                            color: #444444;
                            font-weight: 700;
                            font-size: 16px;
                        }
                        & i {
                            display: inline-block;
                            font-size: 16px;
                            font-style: normal;
                        }
                    }
                    & .notiTime {
                        min-width: 90px;
                        font-weight: 400 !important;
                        font-size: 16px;
                        line-height: 19px;
                        color: #000000 !important;
                    }
                }
            }
        }
    }
}
