.academicSection {
    background-image: url(../../img/bg/feather.png);
    background-blend-mode: multiply;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;

    @media #{$desktop} {
        background-size: auto;
    }

    & .divideWrap {
        background-image: url(../../img/bg/border_long.svg);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        height: 14px;
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        margin-bottom: 35px;
        background-size: contain;
    }
}

.academicWrap {
    padding-bottom: 25px;

    & h1 {
        text-align: center;
        font-weight: 600;
        color: $primary;
        font-size: 28px;
        margin-bottom: 15px;
        transition: all 800ms ease-in-out;

        & span {
            font-weight: 700;
            position: relative;

            &::after {
                content: "";
                width: 8px;
                height: 8px;
                border-radius: 50%;
                display: block;
                background-color: $red;
                position: absolute;
                left: 125px;
                top: -4px;

                @media #{$tablet} {
                    left: 255.5px;
                    top: -4px;
                    width: 12px;
                    height: 12px;
                }
            }

        }

        &::before,
        &::after {
            content: "";
            width: 22px;
            height: 8px;
            background-image: url('../../img/icons/wave.svg');
            background-repeat: no-repeat;
            background-size: contain;
            margin: 0 5px;
            margin-top: -5px;
            display: none;

            @media #{$tablet} {
                width: 60px;
                height: 28px;
                margin: 0 10px;
                display: inline-block;
                vertical-align: middle;
            }
        }

        @media #{$desktop} {
            font-size: 58px;
            letter-spacing: -.8px;
        }
    }

    & p {
        font-size: 16px;
        color: $dark-400;
        line-height: 24px;
        padding: 0 10px;
        margin-top: 35px;
        text-align: justify;


        @media #{$desktop} {
            padding: 0 100px;
            text-align: left;
            line-height: 26px;
            font-size: 19px;

        }

    }

    & .likeBtn {
        padding: 12px 10px;
        box-shadow: 0px 0px 22px rgba($dark, 0.15);
        background-color: $light;
        color: $secondary;
        font-weight: 500;
        font-size: 18px;
        text-align: center;
        text-decoration: none;
        display: table;
        margin: 0 auto;
        border-radius: 10px;
        letter-spacing: -.2px;

        @media #{$desktop} {
            font-size: 35px;
            padding: 12px 20px;
            max-width: 660px;
            // width: 100%;
            border-radius: 20px;
        }
    }
}

.advisoryWrap {
    padding-bottom: 25px;

    & h1 {
        text-align: center;
        font-weight: 600;
        color: $primary;
        font-size: 28px;
        margin-bottom: 15px;
        transition: all 800ms ease-in-out;

        & span {
            font-weight: 700;
            position: relative;

            &::after {
                content: "";
                width: 8px;
                height: 8px;
                border-radius: 50%;
                display: block;
                background-color: $red;
                position: absolute;
                left: 118.5px;
                top: -4px;

                @media #{$tablet} {
                    left: 126.5px;
                    top: -4px;
                    width: 12px;
                    height: 12px;
                }
            }

        }

        &::before,
        &::after {
            content: "";
            width: 22px;
            height: 8px;
            background-image: url('../../img/icons/wave.svg');
            background-repeat: no-repeat;
            background-size: contain;
            margin: 0 5px;
            margin-top: -5px;
            display: none;

            @media #{$tablet} {
                width: 60px;
                height: 28px;
                margin: 0 10px;
                display: inline-block;
                vertical-align: middle;
            }
        }

        @media #{$desktop} {
            font-size: 58px;
            letter-spacing: -.8px;
        }
    }

    & p {
        font-size: 16px;
        color: $dark-400;
        line-height: 24px;
        padding: 0 10px;
        margin-top: 35px;
        text-align: justify;


        @media #{$desktop} {
            padding: 0 50px;
            text-align: left;
            line-height: 26px;
            font-size: 16px;

        }

    }

    & .likeBtn {
        padding: 12px 10px;
        box-shadow: 0px 0px 22px rgba($dark, 0.15);
        background-color: $light;
        color: $secondary;
        font-weight: 500;
        font-size: 18px;
        text-align: center;
        text-decoration: none;
        display: table;
        margin: 0 auto;
        border-radius: 10px;
        letter-spacing: -.2px;

        @media #{$desktop} {
            font-size: 35px;
            padding: 12px 20px;
            max-width: 660px;
            width: 100%;
            border-radius: 20px;
        }
    }
}

.memberList {
    & ul {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 0;

        @media #{$desktop} {
            padding: 0 30px;
            justify-content: center;
        }

        & li {
            list-style: none;
            margin: 4px;
            width: calc(50% - 8px);

            @media #{$desktop} {
                width: calc(25% - 8px);
            }
        }
    }
}

.memberBox { 
    background-blend-mode: multiply;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../img/bg/feather.svg);

    &-img {
        position: relative;
        background-color: $dark;
    }

    &-know {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 0px 0;
        text-align: center;
        width: 100%;
        display: none;

        & h3 {
            font-size: 18px;
            font-weight: 700;
            color: $light;

            @media #{$desktop} {
                font-size: 20px;
            }
        }

        & a {
            font-size: 18px;
            font-weight: 700;
            cursor: pointer;
            color: $secondary !important;
            text-decoration: none;

            @media #{$desktop} {
                font-size: 20px;
            }

            &:hover {
                color: $secondary;
                opacity: .85;
            }
        }

    }



    &-info {
        padding: 20px 0;
        display: none;
        background-image: url(../../img/bg/border_short.svg);
        background-position: 50% 15px;
        background-repeat: no-repeat;
        background-size: contain;

        & p {
            padding-top: 20px;
            font-size: 15px;
            font-weight: 400;
            color: $dark-400;
            text-align: left;

            @media #{$desktop} {
                font-size: 16px;
            }
        }

        & a {
            font-size: 15px;
            font-weight: 700;
            cursor: pointer;
            color: $secondary !important;
            text-decoration: none;

            @media #{$desktop} {
                font-size: 16px;
            }

            &:hover {
                color: $secondary;
                opacity: .85;
            }
        }
    }

    &:hover {
        & .memberBox-img {
            & img {
                opacity: .4;
            }
        }

        & .memberBox-know {
            display: block;
        }

        & .memberBox-info {
            display: block;
        }
    }
}

.lateWrap {
    background-color: $light;
    border-radius: 12px;
    border: 1px solid $secondary;
    box-shadow: 0px 0px 34px rgba(255, 166, 0, 0.07);
    padding: 20px;
    margin-bottom: 40px;

    @media #{$desktop} {
        padding: 0 30px;
        margin-bottom: 50px;
    }
}

.lateGroup {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media #{$tablet} {
        flex-wrap: nowrap;
    }
}

.lateBox {
    margin: 0 15px;
    position: relative;

    @media #{$tablet} {
        max-width: 400px;
        margin: 0 40px;
    }

    &-img {
        display: block;
    }

    &-info {
        font-size: 16px;
        padding: 0 10px;
        padding-bottom: 25px;

        @media #{$tablet} {
            padding: 0 25px;
            font-size: 18px;
        }

        & h2 {
            text-align: center;
            font-size: 20px;
            font-weight: 700;
            color: $secondary;
            margin: 10px 0;

            @media #{$tablet} {
                font-size: 22px;
            }
        }

        & p {
            color: $dark-300;
            font-size: 16px;
            font-weight: 400;
            text-align: justify;

            @media #{$tablet} {
                font-size: 18px;
            }
        }

        & a {
            color: $secondary !important;
            text-decoration: none;
            font-weight: 700;
            cursor: pointer;
        }
    }

    &:after {
        content: "";
        position: absolute;
        right: -40px;
        bottom: 25px;
        width: 1px;
        height: 124px;
        background-color: rgba($secondary, .25);

    }

    &:last-child {
        &::after {
            display: none;
        }
    }
}

.lateOther { 
    font-size: 16px;
    font-weight: 600;
    color: $dark-400;
    padding-top: 25px;
    padding-bottom: 20px;
    font-family: "Inter", sans-serif;

    @media #{$desktop} {
        font-size: 22px;
    }

    & span {
        color: $secondary;
        font-weight: 600;
    }
}

.memberBar {
    background-color: #EBF3F7;

}

.memberBio {

    flex-wrap: nowrap;
    padding: 20px;

    @media #{$tablet} {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 0;
    }

    &-img {
        width: 100%;

        @media #{$tablet} {
            flex-wrap: wrap;
            width: 290px;
        }
    }

    &-social {
        padding-top: 15px;

        @media #{$desktop} {
            padding-top: 30px;
        }

        & ul {
            display: flex;
            margin: 0;
            padding: 0;

            & li {
                list-style: none;
                padding: 0 5px;

                & a {
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    border: 1px solid $primary;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $primary !important;
                    text-decoration: none;

                    @media #{$desktop} {
                        width: 32px;
                        height: 32px;
                    }

                    &:hover {
                        color: $light !important;
                        background-color: $primary;
                        & b{
                            & img {
                                filter: brightness(100);
                            }
                        }
                    }
                    & b {
                        & img {
                            margin-top: -4px;
                         }
                    }
                }
            }
        }
    }

    &-intro {
        width: 100%;
        padding: 20px 0;
        padding-bottom: 0;
        position: relative;

        @media #{$tablet} {
            width: calc(100% - 290px);
            padding: 20px 30px;
        }

        & h1 {
            color: $primary;
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 10px;

            @media #{$tablet} {
                font-size: 28px;
            }
        }

        & p {
            font-size: 14px;
            color: $dark;
            margin-bottom: 10px;
            max-width: 500px;

            @media #{$tablet} {
                font-size: 16px;
            }
        }
    }

    &-sum {
        padding: 20px;
        border: 1px solid $secondary;
        font-size: 16px;
        color: $dark-400;
        border-radius: 10px;
        background-color: $light;

        @media #{$tablet} {
            font-size: 18px;
        }

        & p {
            margin-bottom: 18px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-ctr {
        display: flex;
        padding-top: 15px;

        & a {
            color: $secondary !important;
            text-decoration: none;
            font-size: 18px;
            font-weight: 600;
            padding-bottom: 18px;
            margin-bottom: 15px;
            display: inline-block;
            position: relative;

            &:hover {
                color: $secondary !important;
                opacity: .85;
            }

            & b {
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
            }

            &:last-child {
                & b {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

.member-back {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.memberBack {
    color: $secondary !important;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 18px;
    margin-bottom: 15px;
    display: inline-block;
    position: relative;
    cursor: pointer;

    &:hover {
        color: $secondary !important;
        opacity: .85;
    }

    & b {
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
    }
}

.memberRow {
    padding: 15px 0;

    @media #{$tablet} {
        display: flex;
    }

    & .memberLeft {

        @media #{$tablet} {
            max-width: calc(100% - 270px);
            padding-right: 25px;
        }
    }

    & .memberRight {
        @media #{$tablet} {
            max-width: 270px;
        }

        & .checkOut {
            margin-top: 25px;

            @media #{$tablet} {
                margin-top: 0;
            }
        }
    }
}


.academicSection {
    & .academicWrap p {
        font-size: 16px;
        margin-top: 20px;
        @media #{$desktop} {
            padding-left: 50px;
            padding-right: 50px;
            text-align: justify;
        }
    }
}

.advisory-late-member {
    display: flex;
    justify-content: flex-end;

    & .lateOther {
        text-align: center;
        padding-top: 0;
        padding-bottom: 0;
    }
}