
$color1: #167092;
$color2: #FF8300;
$color3: #009B94;
$color_gray: #404040;
$color_black: #000000;
@import url('https://fonts.googleapis.com/css?family=Calibri:400,700,400italic,700italic');

@font-face {
    font-family: 'calibriregular'  !important;
    src: url('../font_icons/fonts/calibri_regular-webfont.woff2') format('woff2'),
         url('../font_icons/fonts/calibri_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

.loginMember {
    text-align: right;
    & span {
        display: block;
        text-align: center;
        & .labelColor {
            font-size: 16px;
            font-weight: 700;
        }
    }
    & a {
        font-size: 18px !important;
        text-align: center;
        display: block;
        margin-left: 0;
    }
}
.reason-optional {
    color: $color2;
    font-style: normal;
    padding-left: 3px;
}
.text-transform-none { text-transform: none !important;}

input::placeholder,
textarea::placeholder{
    font-size: 16px !important;
    text-transform: none;
}

.articleFormError, .fieldError {
    font-size: 14px;
    color: #BE1E2D;
    font-weight: 500;
}

/*Scroll bar nav*/
// ::-webkit-scrollbar {
//     width: 15px;
// }
/* Track */
// ::-webkit-scrollbar-track {
//     -webkit-box-shadow: auto; 
//     -webkit-border-radius: auto;
//     border-radius: auto;
//     background: #e9e9e9;    
// }

/* Handle */
// ::-webkit-scrollbar-thumb {
//     -webkit-border-radius:4px;
//     border-radius:4px;
//     background:#009B94; 
//     -webkit-box-shadow:auto; 
// }
// ::-webkit-scrollbar-thumb:window-inactive {
//     background: #009B94; 
// }
.text-left {
    text-align: left !important;
}
a {cursor: pointer;}
 
.pageWrap {
    min-height: 1250px;
}
.font18{font-size: 18px;}
.inner-pages .centerSection {
    padding-top: 98px !important;
    min-height: 100vh;
}
.inner-pages .centerSection.pt-0 {
    padding-top: 0 !important;
}
.pullUp.submitPull { bottom: 130px;}
.popover {
    border: 3px solid #BF1E2E !important;
    background: #ffffff;
    border-radius: 10px !important;
    max-width: 620px !important;
 }
 .popover .popover-arrow  {
    background: url(../img/icons/tool_red.png);
    width: 27px !important;
    height: 9px !important;
    background-size: cover;
    background-position: 3px center;
 }
 .popover .popover-arrow::after,
 .popover .popover-arrow::before { display: none !important; } 
 .mandatorySup {  color: #336d91 !important; }
 .modal-icon { background-color: transparent !important; }
 .modal .rejectModal .custom_input .active-input { color: #009B94 !important; }
.customTool { display: inline-block; height: 16px; }
.modal-dialog.md-x { max-width: 500px;}
.md-x .modal-dialog  { max-width: 520px;}
.monthRemove { padding-top: 38px;}
.navNotify {
    position: relative;
    & a {
        cursor: pointer;
    }
    &.active {
        & .notifigationBox {
            display: block;
        }
    }
    & .notifigationBox {
        position: absolute;
        right: 0;
        top: 55px; 
        width: 586px;
        background: #FFFFFF;
        box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        overflow: hidden;
        // display: none;
        & .notificationHead {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            text-transform: uppercase;
            padding: 25px 0px 10px 0px;
            & h2 {
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                text-transform: uppercase;
                color: #FF8300;
                margin-bottom: 0;
                padding-right: 40px;
            }
            & span {
                font-size: 14px;
            }
        }
        & .notiWrapBox {
            max-height: 440px;
            overflow: auto;
            & ul {
                list-style: none;
                padding-left: 0;
                & li {
                    padding: 10px 20px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 0.5px solid #A7A7A7;
                    background: #ffffff;
                    min-height: 75px;
                    &:last-child{
                        border-bottom: 0;
                    }
                    &.new_noti{
                        border-bottom: 0;
                        background: #F6F9FB;
                    }
                    &.notiListWrap {
                        padding: 10px 19px 10px 27px;
                        & .notiListArea{
                            padding-right: 50px;
                        }
                        & span {
                            // color: #167092;
                            color: #444444;
                            font-weight: 700;
                            font-size: 16px;
                        }
                        & i {
                            display: inline-block;
                            font-size: 16px;
                            font-style: normal;
                        }
                    }
                    & .notiTime {
                        min-width: 90px;
                        font-weight: 400 !important;
                        font-size: 16px;
                        line-height: 19px;
                        color: #000000 !important;
                    }
                }
            }
        }
    }
}
.linkFont, .personal-details .link {
    color: #167092;
}
.inner-pages .custom_input.approvalInput .active-input { color: #009B94 !important; }
.custom_input {
    &.approvalInput{
        background: #F3F3F3;
        border-radius: 10px;
        & .py-2 {
            padding: 0 !important;
        }
        & .custom_input {
            margin-top: 10px;
        }
        & .form-control {
            color: #009B94;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px; 
        }
        & .approvalLink{
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-transform: capitalize;
            color: #FF8300;
            top: 16px !important;
            &:hover{
                color: #FF8300 !important; 
            }
        }
    }
}
.popoverToolWrap {
    & h2 {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #BF1E2E;
    }
    & p {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #000000;
        margin-bottom: 15px;
        &:first-child{
            margin-bottom: 0;
        }
    }
    & ol,
    & ul {
        margin-left: 18px;
    }
    & li {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #000000;
        margin-bottom: 10px;
    }
    & .button-link {
        text-transform: capitalize !important;
        text-decoration: underline;
    }

    & .red-text {
        color: #BF1E2E;
    }
}


.pageMenu {
    
}
.modal-header .btn-close {
    top: 30px !important;
}
.navheader, .pageLeft {
    & .button-primary,
    & button,
    & .button-border {
        text-transform: none !important;
    }
}
.button-primary,
button,
.button-border {
    text-transform: uppercase !important;
}
.opacity-5 {
    opacity: .5;
    pointer-events: none;
}
.opacity-2 {
    opacity: .2;
    pointer-events: none;
}
.form-control:focus,
input:focus,
textarea:focus{
    box-shadow: none !important;
    border-color: #009B94 !important;
}
del.active {
    position: relative;
    
    &.filter{
        &::before{
         filter: brightness(0);
        }
    }
}
 
header .customDrop .dropdown {
    border: 0.5px solid rgba(22, 112, 146, 0.4);
    border-radius: 10px;
}
header .customDrop .dropdown .dropdown-toggle {
  height: 39px; }
.modal-footer { 
 
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 40px !important;
    & .button {
        padding: 9px 14px !important;
        font-size: 16px !important;
    }
    .btn-link-active {
        font-size: 16px;
    }
    & .button-link-gray {
        font-weight: bold;
        font-size: 16px;
    }
    & .button-link {
        font-size: 16px;
    }
}
.customDrop {
    & .removeArrow {
        &.dropdown .dropdown-toggle::after {
            display: none;
        }
    } 
    & .dropdown {
        width: 100%;
        &.show > .btn-success.dropdown-toggle{
            background-color: #F6F9FB; 
            border: 0;
             color: #167092;
             box-shadow: none; 
             &:focus{
                box-shadow: none;
             }
         }
        & .dropdown-toggle {
            color: $color1;
            background: #F6F9FB;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            border: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            width: 100%;
            
            &:focus{
                box-shadow: none;
             }
            & img {
                margin-right: 8px;
            }
            &.show{
                &::after{
                    transform: rotate(180deg);
                    transition: all .5s;
                }
            }
            &::after{
                background: #F6F9FB url(./../img/icons/arrow_down.svg);
                border: 0;
                width: 8px;
                height: 5px;
                transition: all .5s;
            }
        }
        & .dropdown-menu {
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.14);
            border-radius: 10px;
            border: 0;
            padding: 0;
            & .dropdown-item{
                border-bottom: 0.5px solid rgba(151,151,151,0.15);
                padding: 8px 12px;
                color: #404040;
                font-weight: 600;
                &:last-child{
                    border-bottom: 0;
                }
                &.active {
                    background: transparent;
                    color: $secondary;
                    font-weight: 700;
                }
                &:active{
                    background: transparent;
                    color: $color1;
                    font-weight: 700;
                }
            }
        }
    }
    &.moreCustom{
        & .dropdown {
            &.show > .btn-success.dropdown-toggle{ 
                background: transparent;
            }
            & .dropdown-toggle {
                background: transparent;

                &::after{
                    display: none;
                }
                & img {
                    transform: scale(1.5);
                }
            }
            & .dropdown-menu {
                width: 190px;
                & .dropdown-item{
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    color: $color1;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    
                    & img {
                        margin-left: 24px;
                    }
                }
            }
        }
    }
    &.sideDrop{
        margin-bottom: 12px;
        & .dropdown {
           & .dropdown-menu {
                width: 100%;
            }
        & .dropdown-toggle {
            justify-content: start;
            padding-top: 10px;
            padding-bottom: 10px;
            background: rgba(22,112,146,0.08);
            &::after {
                position: absolute;
                right: 10px;
                background: url(./../img/icons/arrow_down.svg);
            }
            }
            &.show > .btn-success.dropdown-toggle{
                background: rgba(22,112,146,0.08);
                color: $color1;
            }
        }
    }
}

.customSelect{
    background: rgba(22,112,146,0.08);
    border-radius: 10px;
    margin-top: -6px;
    margin-bottom: 20px;
    & .form-select {
        border: 0 !important;
        font-weight: 700;
        color: $color1;
    }
}
.sideTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: $color2;
    position: relative;
    &.subnav {
        cursor: pointer;
        margin-bottom: 0;
        &.active {
            margin-bottom: 10px;
            & i { 
                transform: translateY(-50%) rotate(180deg);
            }
        }
        & i { 
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            background: url(../img/icons/arrow_down.svg);
            width: 8px;
            height: 5px;
            display: inline-block;
            background-repeat: no-repeat;
        }
    }
    
}
.supTag {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #009B94;
    background: transparent;
    border: 1px solid #009B94;
    border-radius: 5px;
    display: inline-block;
    padding: 3px 8px;
    margin-top: -7px;
    position: relative;
    top: -2px; 
    font-family: $familyDefault, $fallbackfont;
    
}

.fieldForm.urlIcon{ padding-left: 30px; background-image: url(../img/icons/url_icon.svg); background-repeat: no-repeat;
    background-position: 10px;}
.selectBox { 
   position: relative;
    &.selectTitle{ 
       margin-top: -6px;
    }
    & label {
        font-size: 16px;
        padding-left: 15px;
    }
    & .reactSelect-container .reactSelect__control {
        height: 34px;
        min-height: 36px;
        background: #ffffff !important;
    }
    & .reactSelect__control .reactSelect__single-value{
        color: #009B94 !important;
        font-size: 16px;
        padding-left: 4px;
         
    }
    & .value-fill .ant-collapse-header-text{
        color: #009B94 !important;
        font-size: 16px !important;
        padding-left: 4px;
    }
    & .css-1xc3v61-indicatorContainer{
        color: $color_gray !important;
    }
    & .reactSelect__control.reactSelect__control--menu-is-open .reactSelect__dropdown-indicator {
        transform: rotate(180deg) !important;
    }
    & select {
        width: 100%;
        padding: 5px 15px;
        margin-bottom: 30px;
    }
  }

  .profileSetupWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border: 0.5px solid $color1;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 12px;
    margin-bottom: 30px;
    & p{
        color: #BF1E2E;
        & b{
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            text-transform: uppercase;
        }
        & span {
            font-weight: 400;
            font-size: 16px;
            display: block;
            color: $color_gray;
        }
    }
    & .button-link {
        margin-right: 22px;
        text-transform: uppercase;
        color: #404040; 
    }
  }
.label-error {
    font-size: 14px !important;
    font-weight: 600 !important;
    font-style: normal !important;

}
  .button-link {
    border: 0;
    background: transparent;
    color: $color1;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    text-decoration: none;
    &:hover{
        color: $color1;
        opacity: .8;
    }
  }
  .welcome {
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: $color_gray;
    margin-bottom: 8px;
  }

  .articleWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .articleLeft {
        & h2 {
            font-weight: 700;
            font-size: 20px;
            color: $color2;
            margin-bottom: 50px; 
        }
        & a {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #009B94;
            pointer-events: none;
            text-decoration: none;
        }
        & p {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: $color_gray;
        }
    }
  }

  .articleRight {
    text-align: center;
   & p {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: $color2;
        margin-bottom: 25px; 
    }
    & ul {
        display: flex;
        list-style: none;
        padding-left: 0;
        position: relative;
       
        & li {
            padding: 0 16px;
            position: relative;
            &:first-child{
                &::before{
                    display: none;
                } 
            }
            &:last-child {
                &::after{
                    display: none;
                }
            }
            &::before{
                content: '';
                background: #a7a7a7;
                height: 1px;
                left: 0;
                position: absolute;
                width: 50%;
                top: 20px;
                z-index: 0;
            }
            &::after{
                content: '';
                background: #a7a7a7;
                height: 1px;
                right: 0;
                position: absolute;
                width: 50%;
                top: 20px;
                z-index: 0;
            }
            & b{
                width: 40px;
                height: 40px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                font-weight: 700;
                font-size: 16px; 
                line-height: 12px; 
                border: 2px solid #16ACE4;
                background: #ffffff;
                border-radius: 50%;
                color: #16ACE4;
                position: relative;
                z-index: 2;
            }
            & span {
                font-weight: 600;
                font-size: 14px;
                line-height: 12px;
                color: #009B93;
                display: block;
                margin-top: 8px;
                font-family:Open Sans;
            }
        }
    }
  }
.commanTitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: $color2;
}
  .articleTabsWrap {
    // border-top: 0.5px solid #97979B;
    padding-top: 40px;
    margin-top: 40px;
  }
  .customTabs {
    &.disableLink{
        & .nav {
            opacity: .5;
            pointer-events: none;
        }
    }
    &.hide{
        display: none;
    }
    &.show{
        display: block;
    }
    & .nav {
        border-bottom: 0.5px solid rgba(151, 151, 151, 0.7);
        padding-bottom: 3px;
        & .nav-item {
            margin-right: 27px;
            position: relative;
            &:first-child{
                &::before{
                    display: none;
                }
            }
            &::before{
                content: '';
                position: absolute;
                left: -15px;
                background: #A7A7A7;
                width: 1px;
                height: 12px;
                top: 10px;
            }
            & .nav-link {
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                color: $color1;
                position: relative;
                padding: 6px 0;
                &.active {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 22px;
                    color: $color_gray;
                    &::before{
                        content: "";
                        width: 34px;
                        height: 8px;
                        border-radius: 8px;
                        background: linear-gradient(270deg, rgba(255, 176, 27, 1) -2.89%, rgba(227, 232, 123, 1) 100%);
                        display: block;
                        position: absolute;
                        left: 0px;
                        bottom: -6px;
                        z-index: 1;
                    }
                }
            }
        }
    }
    & .tabsBoxin {
        position: relative;
        & button {
            background: $color1;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
            border-radius: 10px;
            border: 0;
            color: #ffffff;
            font-weight: 600;
            padding: 5px 11px 5px 17px;
            text-align: center;
            position: absolute;
            right: 0;
            top: -6px;
            font-size: 16px;
            &.colapseBtn {
                & i{
                    transform: rotate(180deg);
                    position: relative;
                    top: 1px;
                    transition: all .5s;
                }
            }
            & i{
                margin: 0 5px;
                display: inline-block;
                transition: all .5s;
            }
        }
    }
  }

  .tabContentWrap {
    min-height: 430px;
    position: relative;
    
  }
  .notArticle {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    & figure {
        // background: #EBF4F4;
        // width: 60px;
        // height: 60px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-bottom: 0;
        width: 100%;
    }
    & h2 {
        display: block;
        width: 100%;
        color: $color2;
        font-weight: 700;
        font-size: 18px;
        text-transform: capitalize;
        margin-bottom: 5px;
    }
    & p{
        font-size: 16px !important;
        display: inline-block;
        span {
            font-size: 13px !important;
            color: #167092 !important;
            line-height: 15px;
            text-align: left;
            display: block;
        }
    }
    
  
      & .au_draft{
        
            width: 308px;
         
      }
      & .au_submit {
        width: 393px;
      }
      & .au_approved {
        width: 375px;
      }
      & .au_rejected {
        width: 470px;
      }
      & .au_published {
        width: 459px;
      }

      & .notArticleDraft {
        width: 440px;
      }
      & .notArticleAccepted {
        width: 425px;
      }
      & .notArticleSendFor {
        & p{
            width: 379px !important;
        }
      }
      & .notArticlePeer {
        & p {
            width: 375px !important;
        }
      }
      & .notArticleApproved {
        & p {
            & span {
                width: 398px;
                position: relative;
                left: 14px;
            }
        }
      }
      & .notArticleSentRevision {
        & p {
            width: 420px !important;
        }
      }
      & .notArticleRejected {
        & p {
            width: 478px !important;
        }
      }
      & .notArticleNot {
        & p{
            width: 454px !important;
        }
      }
      & .pr_draft {
         
        width: 410px;
         
      }
      & .pr_accepted {
        
            width: 458px;
         
      }
      & .pr_reviewed {
        width: 471px;
      }
      & .pr_notAccepted {
        width: 461px;
      }
      &.notArticleRedingList{
        width: 464px;
        &.notArticleNoFix {
            position: relative;
            top: 0;
            transform: translate(-50%, 0%);
        }
      } 



  }

  .searchTag {
    margin-top: 30px;
    margin-bottom: 60px;
    color: #167092;
    & span, a {
        border-radius: 15px;
        border: 0.5px solid rgba(22, 112, 146, 0.50);
        background: #FFF;
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.10);
        position: relative;
        padding: 15px 25px;
        margin: 10px;
        display: inline-block;
        color: #167092;
        font-weight: 700;
        cursor: pointer;
        & i {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #FF8300;
            display: inline-block;
            vertical-align: middle;
            margin-right: 6px;
        }
    }
  }
 

  .recommendedSlider {
    width: 100%;
    overflow: hidden;
    & .slick-track {
        & .recommendedBox:nth-child(1) {
            &::before {
                content: '';
                background: #FCC24A;
            }
            
         }
         & .recommendedBox:nth-child(2) {
            &::before {
                content: '';
                background: #FF9D36;
            }
            
         }
    }
    & .slick-dots {
        display: flex;
        align-items: center;
        justify-content: center;
        list-style: none;
        & li {
            &.slick-active {
                & button {
                    background: $color1;
                    width: 24px;
                    opacity: 1;
                    transition: all .5s;
                } 
            }
            & button {
                border: 0;
                background: transparent;
                font-size: 0;
                background: $color1;
                opacity: 0.5;
                width: 6px;
                height: 4px;
                border-radius: 4px;
                margin: 0 2px;
                transition: all .5s;
            }
        }
    }
  }
  .recommendedMain {
    margin-left: -14px;
    & .recomHead {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 14px;
        align-items: center;
       
        & h3 {
            color: $color2;
            font-weight: 700;
            font-size: 20px;
            padding-left: 14px;
        }
    }
  }
  .articlesRecommended {
    .recommendedBox {
        margin: 8px 0;
        width: 100%;
    }
  }

  .recommendedMain {
    & .col-md-4 {
        opacity: 1;
        & .recommendedBox {
            position: relative;
            overflow: hidden;
            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0px;
           
                border-bottom: 10px solid #EAE686;
                height: 100%;
                width: 100%;
                border-radius: 0 0 18px 18px;
                z-index: 0;
                }
                
            
        }
        &:nth-child(2){
            & .recommendedBox {
            &::after {
             
            border-bottom: 10px solid #FCC24A;
            }
        }
        }
        &:nth-child(3){
            & .recommendedBox {
            &::after {
          
                border-bottom: 10px solid #FF9D36;
            }
        }
        }
    }
  }
  .recommendedBox {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding: 20px;
    margin: 15px; 
    border: 1px solid rgba(22,112,146,0.6);
    position: relative;
    width: 30%;
    display: inline-block;
    height: calc(100% - 30px);
    .reviewtype{ 
        text-transform: capitalize;
        font-weight: 500;
        color: #009B94;
    }
    .readmorebtn{
        text-transform: capitalize;
        font-weight: 600;
        cursor: pointer;
        color: #FF8300;
    }
    &.newlook{
        margin: 0px; 
        height: auto;
        border-radius: 0;
        border-right: 0;
        border-left: 0;
        box-shadow: none;
        background-color: #FAFAFA;
        border-color: #AFD0DB;
        font-size: 16px;
        .recommendedHead{
            margin-bottom: 5px;
            .left-part{
                span{
                    color: #828282;
                    font-weight: 500;
                    &:last-child::after{
                        content: none;
                    }
                }
                span:after{
                    content: "";
                    width: 5px;
                    margin-top: 6.5px;
                    margin-left: 10px;
                    height: 5px;
                    background-color: #336D91;
                    position: absolute;
                    border-radius: 20px;
                   
                }
               
            }
            .recommendedShare i{
                line-height: normal;
            }
        }
    }
    &::before {
        content: '';
        position: absolute;
        left: 1%;
        bottom: -1px;
        background: #EAE686;
        height: 10px;
        width: 98%;
        border-radius: 0 0 10px 10px;

        }
        &:nth-child(2) {
            &::before {
                content: '';
                background: #FCC24A;
            }
            
         }
         &:nth-child(3) {
            &::before {
                content: '';
                background: #FF9D36;
            }
            
         }
    &.bgColorNone{
        &::before {
            display: none;
        }
    }
    &.inlineBox {
        display: inline-block;
        width: calc(33.3% - 30px);
    }
    & .recommendedHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        z-index: 2;
        position: relative;
        & span {
            font-weight: 400;
            font-size: 15px;
            line-height: 19px;
        }
        & .recommendedShare {
            display: flex;
            position: relative;
            right: -7px;
            & i {
                margin: 0 10px;
                cursor: pointer;
            }
        }
  
    }
    & .recommendedTitle {
        z-index: 1;
        position: relative;
        & h2{
            font-weight: 700;
            font-size: 18px;
            line-height: 25px;
            color: $color1;
            min-height: 24px;
            // white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: pointer;
        }
        & h3{
            margin-bottom: 0;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: $color_gray;
        }

    }
    & .recommendedContent {
        margin-top: 15px;
        z-index: 1;
        position: relative;
        & p {
            display: -webkit-box;
            max-width: 400px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            min-height: 50px;
        }
        & .t-tag-box { 
        }
        & .t-tag {
            max-width: calc(48% - 3px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        & .t-tag-journalWrap {
            & .t-tag {
                max-width: 100%;
            } 
        }
    }
  }


  .t-tag-box {
    border-top: 0.5px solid #97979B;
    padding-top: 4px;
    margin-top: 10px;
  }
  .t-tag { 
    border-radius: 5px;
    padding: 6px 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #009B94;
    display: inline-block; 
    border: 0.5px solid #009B94;
    margin-right: 8px;
    margin-top: 9px;
    
    &.t-tag-journal  {
        max-width: none;
        white-space: normal;
    }
  }
  .t-tag-type { 
    
    padding: 3px 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $color2;
    display: inline-block; 
     
    margin-right: 10px;
    margin-top: 9px;
  }
  .button-border {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: $color1;
    border: 1px solid $color1;
    padding: 8px 15px;
    background: transparent;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
    &:hover{
        color: $color1;
        text-decoration: none;
    }
  }
.contentListBox {
    margin-top: 35px;
}
  .contentList {
    background: #FFFFFF;
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);      
    border-radius: 20px;
    padding: 18px 20px 20px 20px;
    margin-bottom: 25px;
    position: relative;
    -webkit-border: 0.5px solid rgba(22, 112, 146, 0.4);
    -ms-border: 0.5px solid rgba(22, 112, 146, 0.4);
    border: 0.5px solid rgb(190 30 45);
    & .contentListHead { 
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 14px;
        & .moreCustom {
            padding-right: 0;
            margin-right: -20px;
        }
        & .customDrop .dropdown .dropdown-toggle img {
            width: 4px;
        }
        & .t-type {
            position: absolute;
            right: 20px;
            top: 20px;
        }
        & .timeList {
            & .t-type {
                position: relative;
                right: auto;
                top: auto;
            }
            & span {
                margin-right: 20px;
                padding-right: 20px;
                border-right: 1px solid $color_black;
                line-height: 12px;
                display: inline-block;
                &:last-child {
                    border-right: 0;
                    margin-right: 0;
                    padding-right: 0;
                }
                & b {
                    font-weight: 700;
                    font-size: 16px;
                    color: $color1;
                }
            }
        }
    }
    & .contentListMidd {
        & h2 {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: $color1;
            margin-bottom: 0; 
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
            & a {
                text-decoration: none;
                &:hover{
                    color: $color1;
                }
            }
        }
        & .authorName {
            color: #404040;
            padding-top: 5px;
            & b {
                font-weight: 600;
                font-size: 16px;
            }
        }
        & .authorAff {
            font-weight: 400;
            color: #404040;
            margin-bottom: 10px;
        }
        & p {
            margin-top: 8px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            min-height: 70px;
            word-wrap: break-word;
            max-width: 890px;
            &.peContent {
                display: block;
                min-height: 10px;
            } 
        }
    }
    & .contentListFoot {
        display: flex;
        align-items: end;
        justify-content: space-between; 
        margin-top: 27px;
        &.contentListTwo {
            // border-top: 0.2px solid #97979B;
            padding-top: 22px;
            margin-top: 20px;
            & .footAction {
                justify-content: center;
                align-items: center;
                & a {
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
        }
        & .footTag {
            max-width: 50%;
        }
    }
  }

  .footAction {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: end;
    & a {
        margin: 0 12px;
        text-decoration: none;
        color: $color_gray;
        text-transform: uppercase;
        &.button-link {
            color: $color1;
        }
        & .ovelBg {
            background: $color1;
            height: 32px;
            width: 32px;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            
        }
        & span {
            display: block;
            font-weight: 500;
            font-size: 14px;
        }
    }
}
  .mr-0 { margin-right: 0 !important;}
  .mr-1 { margin-right: 5px !important;}
  .ml-0 { margin-left: 0 !important;}
  .ml-1 { margin-left: 5px !important;}
  .mr-2 { margin-right: 10px !important;}
  .ml-2 { margin-left: 10px !important;}
  .mr-3{ margin-right: 20px !important; } 
  .mb-3 { margin-bottom: 15px !important;}
  .mr-4{ margin-right: 30px !important; } 
  .ml-4{ margin-left: 30px !important; } 
  .mb-4 { margin-bottom: 20px !important;}
  .mb-5 { margin-bottom: 30px !important;}
  .mt-3 { margin-top: 15px !important;}
  .mt-4 { margin-top: 20px !important;}
  .mt-5 { margin-top: 30px !important;}
  .pl-0{ padding-left: 0 !important; }
  .pb-0{ padding-bottom: 0 !important; }
  .pt-0{ padding-top: 0 !important; }
  .pr-0{ padding-right: 0 !important; }
  .pl-2{ padding-left: 8px !important; }
  .pr-2{ padding-right: 8px !important; }
  .pl-4{ padding-left: 30px !important; }
  .pr-4{ padding-right: 30px !important; }
  .pl-5{ padding-left: 40px !important; }
  .pr-5{ padding-right: 40px !important; }

.preReview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & span {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: $color2;
    }
   
    }
    .innerTabsView {
    // border-top: 0.5px solid #97979B;
    // border-bottom: 0.5px solid #97979B;
    padding: 30px 0;
    margin-bottom: 30px;
    & .tab-content{
        &.hide {
            display: none;
        }
        &.show {
            display: block;
        }
    }
}
.pb-36 {
    padding-bottom: 36px !important;
}
.button-link-gray {
    color: $color_gray;
    border: 0;
    background: transparent;
    margin-right: 16px;
    font-weight: 700 !important;
    text-decoration: none;
    cursor: pointer;
    &.text-transform-uppercase {
        text-transform: uppercase;
    }
}
.greenTitle {
    color: #009B94;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
}
.affilSmall {
    display: block;
    font-weight: normal;
    margin-bottom: 15px;
}
.verifiyLink {
    
    & a {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: $color1;
        text-decoration: none;
    }
}
.modal {
    & .password-body {
        & .custom_input .input_post {
            top: 6px;
        }
        & .custom_input .with_icon {
            color: #009B94 !important;
        }
    }
}
.link-admin {
    font-weight: 700;
    color: $color1;
}
.highestText {
    font-weight: 400;
font-size: 16px;
line-height: 19px;
}
.filter-heading .value-fill .ant-collapse-header-text {
    color: #009B94 !important;
    font-size: 16px !important;
}

.authorModal {
    &.feedBack {
        &.modal {
            & .modal-content{
                border: 0 !important;
                &::before{ 
                    content: '';
                    border: 3px solid #009B94 !important;
                    width: calc(100% - 60px);
                    height: calc(100% - 60px);
                    position: absolute;
                    left: 30px;
                    top: 30px;
                    border-radius: 15px;
                }
            }
        }        
    }
    &.md-500{
        & .modal-dialog{
            max-width: 520px !important;        
        }
    }
    &.md-640{
        & .modal-dialog{
            max-width: 640px !important;        
        }
    }
    &.mdl {
        & .modal-dialog{
            max-width: 590px !important;        
        }
    }
    &.modal-dialog.mdl{
        max-width: 590px !important;        
    }
    &.modal {
        
        & .modal-content{
            border: 3px solid $color1 !important;
        }
        
    }
    & .modal-dialog {
        &.modal-md {
            max-width: 475px; 
        }

    }
    &.modal-dialog .modal-title{
        color: $color2 !important;
        font-size: 20px !important;
        margin-top: 10px;
        &.uploadTitle { 
            padding-bottom: 15px;
            & span {
                display: inline-block;
                width: 100%;
                font-weight: 700;
                font-size: 16px !important;
                line-height: 22px;
                color: #167092 !important;
                text-transform: uppercase;
            }
        }
    }
    & .modal-header {
        
        &.customHeader {
            justify-content: start;
            & .modal-title {
                text-align: left;
                padding-left: 0;
                & .backTop {
                    margin-right: 10px;
                }
            }
        }
    }
    & .labelForm {
        text-align: left;
        font-weight: 600;
    }
    & .button {
        height: 35px;
        padding: 5px 24px;
        min-width: 148px;
    }
    & .updateNumber{
        color: $color_gray;
        & i{
            position: absolute;
            left: 35px;
            top: 10px;
            font-style: normal;
            color: $color_gray;
            font-weight: 600;
            padding-right: 10px;
            border-right: 2px solid $color_gray;
            line-height: 16px;
        }
        & .fieldForm{
            padding-left: 86px;
        }
    }
    & .otpFieldBox {
        max-width: 300px;
        margin: 0 auto;
    }
    & .otpField {
        display: flex; 
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        & .fieldForm {
            width: 35px;
            height: 35px;
            padding: 0;
            text-align: center;
            font-size: 16px; 
            border-radius: 5px;
            
            
        }
        & .form-control {
            font-weight: 700;
            color: $color1;
        }
    }


}

.yellowTextWelcome {
    color: #FF8300;
    font-style: normal;
}
.grantDetails { 
    text-align: left;
    padding: 0 30px; 
    & .writeYourImg {
        width: calc(100% + 50px );
        margin-left: -25px;
        & img {
            width: 100%;
        }
    }
    &.cancelPopup {
        padding: 0 10px;
    }
    & .infotext { 
        & b{
            margin-bottom: 8px;
            display: block;
            padding-left: 15px;
            font-weight: 700;
        }
        &.publisedUrl{
            margin-top: 26px;
            position: relative;
             
            & span {
                text-align: left;
                background: rgba(64, 64, 64, 0.05) url(../../svg/active_link.svg);
                background-repeat: no-repeat;
                background-position: 10px center;
                padding-left: 28px;
            }
        }
        & .knowMore {
            color: #FF8300;
            font-size: 18px;
            text-transform: uppercase;
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid #a7a7a7;
            margin-bottom: 20px;
        }
    }
    & b{
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $color_gray;
    }
    & span {
        background: rgba(64,64,64,0.05);
        border-radius: 10px;
        padding: 8px 14px;
        display: block;
        margin-bottom: 15px;
      
        &.textBlue {
            color: $color1;
            font-weight: 600;
        }
        &.textGreen {
            color: #009B94;
            font-weight: 600;
        }
    }
   
    & .listText{
            border-radius: 10px;
            background: rgba(64,64,64,0.05);
            margin-bottom: 15px;
            padding: 14px 14px 14px 34px;
            & li{

            }
    }
    & .knowList {
        & li {
            list-style: none;
            border-radius: 10px;
            background: rgba(64,64,64,0.05);
            margin-bottom: 15px;
            padding: 14px;
            
            & p {
                display: flex; 
                font-weight: 500;
                position: relative;
                padding: 10px 0;
                align-items: center;
                justify-content: space-between;
                &:before {
                    content: '';
                    background: url(../img/icons/border.png);
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
                &:last-child{
                    margin-bottom: 0;
                    &:before {
                        display: none;
                    }
                    & i {
                        color: #009B94;
                    }
                    & small {
                        border: 1px solid #FF8300;
                        color: #FF8300;
                        background: rgba(255,131,0,0.1);
                        border-radius: 8px;
                        padding: 2px 10px;
                        font-size: 18px;
                        font-weight: 700;
                    }
                }
            }
            & i{
                font-style: normal;
                display: inline-block;
                position: relative;
                width: 40%;
                font-size: 16px;
                font-weight: 500;
                // &::before{
                //     content: ':';
                //     position: absolute;
                //     right: 0;
                // }
            }
            & small {
            display: inline-block;
            
            padding-left: 70px;
            text-align: right;
            }
            & .downloadBOx {
                display: inline-block;
                width: 60%;
                padding-left: 70px;
                vertical-align: top;
                & a {
                    background: $color1 url(../img/icons/downloadfile.svg);
                    width: 32px;
                    height: 32px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    background-repeat: no-repeat;
                    background-position: center;
                    border-radius: 50%;
                }
                & strong {
                 font-weight: normal;
                 display: block;
                }
            }
        }
    }
    &.submitArticle {
        text-align: center;
    }
    &.alertPopup {
        text-align: center;
        padding: 0;
        & .guidelinesText {
            & a {
                color: $color1;
                font-size: 16px;
                line-height: 22px;
                font-weight: 700;
                text-decoration: none;
            }
        }
        & h4 {
            font-weight: 500;
            font-size: 16px;
            margin: 18px 0;
        }
        & .offensiveBox{
            border-radius: 10px;
            background: rgba(0, 155, 148, 0.04);
            text-align: left;
            padding: 8px 20px;
            margin: 16px auto 0;
            width: 92%;
            & ol {
                text-align: left;
                font-weight: 600;
                font-size: 18px;
                line-height: 29px;
                margin-left: 20px;
                & li { 
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 29px;
                    color: #009B94;
                    text-transform: capitalize;
                }
            }
        }
    }
    & textarea.fieldForm {
        color: #404040; font-weight: 500;
    }
}
.authorModal.authorModalRed .modal-content { border: 3px solid #BF1E2E !important; } 
.modal .button-primary.redBtn{ background-color: #BF1E2E !important; font-size: 18px; }
.reactSelect-container .reactSelect__control:active,
.reactSelect-container .reactSelect__control:focus,
.reactSelect-container .reactSelect__control:hover,
.reactSelect-container .reactSelect__control--is-focused,
.reactSelect-container .reactSelect__control--is-selected {
    box-shadow: none !important;
    border-color: rgba(153, 153, 153, 0.5) !important;
}

.labelSubHeading {
    &.subjectHead{
    padding-top: 4px;
    padding-bottom: 15px;

    }
}

.backBtnWrap {
    display: block;
    & a{
        text-decoration: none;
        cursor: pointer;
    }
     
    & span {
        display: block;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        text-decoration: none;
        line-height: 10px;
        color: $color1;
        cursor: pointer;
    }
}
.fieldWrap {
    &.emailField {
        & .fieldCover {
            & b{
                color: #009B94;
            }
            & .fieldForm {
                color: #009B94;
            }
            ::placeholder {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: $color1;
            }
        }
    }
    & .fieldCover {
        &.active{
            color: #009B93;
        }
        & .icon-eye {
            position: absolute;
            right: 14px;
            top: 10px;
        }
    }
    & .labelFormTwo {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        margin-left: 0;
        margin-bottom: 6px !important;
        color: $color2;
        & small {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #404040;
            & b {

            }
        }
    }
    & .labelFormHead {
        border: 0.5px solid #989898;
        border-radius: 10px;
        font-weight: 700;
        font-size: 16px !important;
        line-height: 19px;
        padding: 8px 14px !important;
        
    }
}
.addKey {
    text-transform: uppercase;
}
.small-Text {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #404040;
}
.css-1xc3v61-indicatorContainer {
    color: $color_gray;
}

.fieldMore a {
    font-size: 16px;
}
.verifyText {
    font-size: 12px;
    padding-left: 18px;
    & a {
        text-decoration: none;
    }
}

.previewProfile .profile-camera {
    color: #7c7c7c;
}

.verifyIuuse {
    position: relative;
    &.active {
        display: flex;
        & .verifyInput {
            width: 100%;
            & .fieldForm{
                padding-right: 30px;
            }
            & .input_post {
                right: 30px;
            }
        }
        & img {
            margin-right: 8px;
            margin-top: 3px;
            position: absolute;
            right: 2px;
            top: 7px;
        }
        & a {
            display: flex;
            align-items: center;
            margin-left: 15px;
            text-decoration: none;
            font-weight: 600;
            
        }
        & .link {
            color: $color_gray !important;
        }
        
    }
    & .verifyInput {
        position: relative;
    }
}

.selectBox {
   & .custom_input{

        & .with_icon {
        padding: 9px 74px 11px 34px !important;
        &::placeholder{
            font-size: 16px;
        }
      }
    }
    & .reactSelect-container .reactSelect__control:focus,
    & .reactSelect-container .reactSelect__control--is-focused,
    & .reactSelect-container .reactSelect__control:hover {
        border-color: #009B94 !important;
    }
}
.modal-header .btn-close {
    opacity: 1;
}

.footer-author{
    & .footer-title {
        margin-bottom: 26px;
    }
    & .navListAuthor {
        text-align: center;
        & h3{
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: $color2;
            margin-top: 8px;
        }
        & ul{
            & li{
                list-style-type: none;
                padding: 4px 0;
                & a{
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    text-align: center;
                    color: $color_gray !important;
                    text-decoration: none;
                }
            }
        }
    }
    & .navListSite {
        margin-top: 18px;
        text-align: center;
        & a {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: $color_gray !important;
            position: relative;
            text-decoration: none;
            padding-left: 26px;
            margin-left: 26px;
            &::before{
                content: '';
                left: 0px;
                top: 5px;
                height: 12px;
                width: 1px;
                background: $color_gray;
                position: absolute;

            }
            &:first-child{
                padding-left: 0;
                margin-left: 0;
                &::before{
                    display: none;
                }
            }
        }
    }
    & .footerMidd {
        & .containWrap{
            border-top: 0.5px solid rgba(151, 151, 155, 0.6);
            margin-top: 24px;
            padding-top: 30px;
        }
        & .contactLinks {
            text-align: center;
            margin-top: 20px;
            & p {
                display: flex;
                align-items: center;
                justify-content: center;
                & b{
                    margin-right: 6px;
                    &::before{
                        font-weight: bold;
                    }
                }
            }
            
        }
        & .socialLinks {
            &:after {
                display: none;
            }
            & .socialLinks-wrap {
                display: flex;
                align-items: center;
                justify-content: center;
                & h3{
                    margin-bottom: 0;
                }
                & ul{
                    & li {
                        & a {
                           
                            border: 1px solid $color1;
                            &:hover{
                                background-color: $color1;
                               
                                & b {
                                    color: #ffffff;
                                }
                            }
                            & b {
                                color: $color1;
                            }
                        }
                    }
                }
            }
        }
    }
    & .footerBottom{
        padding-bottom: 40px;
        & .containWrap{
            border-top: 0.5px solid rgba(151, 151, 155, 0.6);
            margin-top:30px;
            padding-top: 15px;
        }
        text-align: center;
        & p{
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: $color_black;
            margin-bottom: 10px;
        }
        & .tremNav {
            & a {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: $color_gray !important;
                position: relative;
                text-decoration: none;
                padding-left: 12px;
                margin-left: 12px;
                &::before{
                    content: '';
                    left: 0px;
                    top: 3px;
                    height: 12px;
                    width: 1px;
                    background: $color_gray;
                    position: absolute;

                }
                &:first-child{
                    padding-left: 0;
                    margin-left: 0;
                    &::before{
                        display: none;
                    }
                }
            }
        }
    }
}

.otpInfo small {
    color: $color_black !important;
}
.verifiyLink.activeCheck {
    padding-right: 25px;
    position: relative;
    background-image: url(../../svg/verifiedCheck.svg);
    background-repeat: no-repeat;
    background-position: center right;
}

.genderfield {
    margin-top: -7px;
}

.primarySubjectList {
    margin-top: -6px;
}
.pinCode {
    margin-top: 6px;
}

.editProfilePage {
    input.fieldForm, select.fieldForm, textarea.fieldForm 
    {
        background-color: transparent;
    }
    & .custom_input .with_icon{
        padding-left: 20px;
    }

}
.inner-pages .editProfilePage .custom_input .with_icon {
    padding-left: 32px !important;
}
.inner-pages .editProfilePage .custom_input .input_pre img { filter: brightness(0); }
.inner-pages .editProfilePage .custom_input .active-input { font-size: 16px !important; color: #009B94 !important; }
.editProfilePage .ant-collapse-content-box .ant-menu-title-content input[type=checkbox]:checked::before {content: url("../../svg/active-check_blue.svg");  }
.editProfilePage .ant-collapse-content-box .search-dropdown { margin-bottom: 0;}
.editProfilePage .ant-collapse-content-box .search-dropdown .py-2{ padding-bottom: 0 !important;}
.editProfilePage .ant-collapse>.ant-collapse-item >.ant-collapse-header >.ant-collapse-header-text { font-size: 16px; color: #777777; }
.editProfilePage .search-dropdown br{ display: none;}
.editProfilePage  .fieldSet .search-dropdown .ant-checkbox-group ul:not([class]) li { padding: 0 !important; width: 100%; }
.link.removeLinlk{
    color: $color_gray;
    font-weight: 600;
    text-decoration: none;
    &:hover{
        color: $color_gray !important;
    }
}
.grp-add-remove.manageRemve{ position: relative; left: -20px; width: calc(100% + 36px)}
.pedashboardBox {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    & .pedashboardMidd {
        display: flex;
        
    }
    & .analyticsWrap {
        background: #FAFAFA;
        border: 0.5px solid $color_gray;
        border-radius: 15px; 
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 90px;
        width: 200px;
        margin-left: 15px;
    }
}

.reviewGray {
    list-style-type: none;
    display: flex;
    padding: 20px;
    border-radius: 10px;
    background: rgba(22, 112, 146, 0.08);
    border: 0.5px solid $color1;
    color: #000000;
    & li {
        padding-left: 40px;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            left: 20px;
            height: 100%;
            width: 1px;
            background: #97979B;
            top: 0;
            opacity: .6;
        }
        &:first-child{
            padding-left: 0;
            &::before {
                display: none;
                 
            }
        }
        & b {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            display: block;
            text-align: center;
            color: #16ACE4;
        }
        & span {
            display: block;
            font-weight: 600;
        }
    }
}
.subReviewGray {
    display: flex;
    justify-content: end;
}
.readMore {
    font-weight: 700;
    font-size: 16px;
    color: $color1;
    cursor: pointer;
    text-decoration: none;
}

.rejectModal {
    text-align: left;
    padding: 0 20px;
    & p {
        font-family: $familySecondaryOpen, $fallbackfont;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
    }
    & .inputField {
        width: 100%;
        border: 1px solid #989898;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        margin-top: 20px;
        border-radius: 10px;
        min-height: 90px;
        resize: none; 
        color: #000000;
        &::placeholder{
            font-size: 16px;
        }
    }
    & .customRadio {
        margin-top: 15px;
        & b {
            top: 4px;
        }
        & span {
            font-weight: 500;
            line-height: 22px;
        }
    }
    & .customRadio input:checked + b + span {
        font-weight: 500;
    }
}

.customRadio {
    position: relative;
    padding-left: 20px;
   
    & input {
        display: none;
    }
    & input:checked + b + span {
        color: #009B94;
    }
    & input:checked + b::before{
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $color1;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    & b {
        background: #A7C8D5;
        width: 12px;
        height: 12px;
        border-radius:50%;
        position: absolute;
        left: 0;
    }
    & span {
        font-weight: 600;
        font-size: 18px;
    }
}

.backPage {
    display: flex;
    & a {
        font-weight: 700;
        font-size: 16px;
        color: $color1;
        text-decoration: none;
        padding-left: 15px;
        background: url(../img/icons/backarrow.svg);
        background-position: center left;
        background-repeat: no-repeat;
        &:hover{
            color: $color1; 
        }
    }
    & p {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: $color_gray;
        position: relative;
        padding-left: 24px;
        &::before{
            content: '';
            position: absolute;
            left: 12px;
            top: 50%;
            transform: translateY(-50%);
            background: $color_black;
            width: 1px;
            height: 12px;

        }
    }
}

.reviewerDetails {
    background: #FFFFFF;
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.07);
    border-radius: 0 40px 0 40px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 36px;
    & .reviewerProfile {

        & figure {
            width: 95px;
            height: 95px;
            border-radius: 50%;
            display: inline-block;
            background: #f1f1f1;
        }
        & .reviewerProfileContent {
            width: calc(100% - 100px);
            display: inline-block;
            padding-left: 16px;
            & h3 {
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #009B94;
                margin-bottom: 14px;
            }
            & .areaBox {
                & span {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    color: $color_gray;
                    padding-left: 24px;
                    position: relative;
                    &:first-child {
                        padding-left: 0;
                        &::before {
                            display: none;
                        }
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        left: 12px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 1px;
                        height: 12px;
                        background: $color_gray;
                    }
                }
            }
            & .emailBox {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    color: $color_gray;
                    position: relative;
                    display: flex;
                    align-items: center;
                    & b {
                        margin-right: 5px;
                    }
            }
        }
    }
}

.paymentAlert {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.5px solid rgba(22, 112, 146, 0.5);
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 12px;
    margin-bottom: 30px;
    & .paymentAlertLeft {
        & span {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #BF1E2E;
        }
        & p {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #BF1E2E;
        }
    }
}

.mudraAlert {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(270deg, #FFB01B -2.89%, #EEF55B 100%);
    border-radius: 13px;
    padding: 7px 20px 3px 20px;
    margin-bottom: 40px;
    & .mudraLeft {
        display: flex;
        align-items: center;
        & figure {
            margin-bottom: 0;
        }
        & p {
            padding-left: 20px;
            & span {
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                color: #404040;
                display: block;
            }
            & small {
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #404040;
            }
        }
    }
    & b {
        cursor: pointer;
        & img {
            filter: brightness(0);
            position: relative;
            top: -2px;
        }
    }
}

.btn-link-black {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: $color_gray;
    text-transform: uppercase;
}
.btn-link-active {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: $color1;
    text-transform: uppercase;
    
    cursor: pointer;
    text-decoration: none;
    &:hover{
        color: $color1;
    }
}
 

.uploadHeadMain {
    background: #F6F9FB;
    padding: 20px 0;
    & .uploadAction {
        display: flex;
        align-items: center;
        & span {
            margin-right: 22px;
            cursor: pointer;
        }
        & .delete {
            height: 22px;
            width: 22px;
            border-radius: 50%;
            display: inline-flex;
            background: $color1 url(../img/icons/delete.svg);
            background-position: center;
            background-repeat: no-repeat;
            margin-left: 20px;
            cursor: pointer;
        }
    }
}
.uploadHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 44px;
    & h2 {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: $color2;
        margin-bottom: 0;
        padding-left: 40px;
        position: relative;
        &::before{
            content: '';
            background: rgba(0, 0, 0, 0.3);
            width: 1px;
            height: 42px;
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    & .uploadHeadLeft {
        display: flex;
        align-items: center;
       
    }
}
.backTop {
    padding-left: 18px;
    position: relative;
    font-weight: 700;
    font-size: 16px;
    color: $color1;
    cursor: pointer;
    &::before{
        content: '';
        position: absolute;
        background: url(../img/icons/backarrow.svg);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
    }
}
.commanBox {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(22, 112, 146, 0.5);
    border-radius: 20px;
    width: 100%;
    padding: 20px;
    position: relative;
    &.commanBoxBorder0{
        border: 0;
    }

}
.mudraFilter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .mudraFilterListLeft {
        display: flex;
        & .mudraFilterList {
            min-width: 220px;
            margin-right: 15px;
        }
    }
    & .actionWrap {
        & span {
            display: block;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #ff8300;
            text-align: center;
            margin-bottom: 14px;
        }
        & b {
            position: relative;
            margin-right: 30px;
            cursor: pointer;
            &::before {
                content: '';
                position: absolute;
                right: -14px;
                height: 18px;
                background: #A7A7A7;
                width: 1px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        & .button {
            padding: 7px 15px;
        }
    }
}
.selectSpaceBox {
    margin-bottom: 36px;
    & .selectSpaceList {
        margin-bottom: 36px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.mb-25 {
    margin-bottom: 25px !important;
}
.mt-25 {
    margin-top: 25px !important;
}
.tagContent {
    border: 0.5px solid rgba(153, 153, 153, 0.5);
    border-radius: 10px;
    padding: 11px 14px;
}
.tagTitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: $color2;
    margin-bottom: 12px;
    display: block;
    & .fileBox {
        float: right;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: $color1;
        cursor: pointer;
        & input {
            display: none;
        }
    }
}


.editorWrap {
    & .sun-editor {
        border: 1px solid rgba(153, 153, 153, 0.5);
        border-radius: 10px;
        overflow: hidden;
        & .se-toolbar {
            border-radius: 10px 10px 0 0 !important;
            background-color: rgba(0,155,148,0.08);
        }
        & button{
            & .txt{
               color: #167092;
               font-size: 16px;
                font-weight: bold;
                text-transform: capitalize;
            }
            
        } 
        & .se-btn-select.se-btn-tool-font {
            width: 65px;
        }
        & .se-btn-select.se-btn-tool-size {
            width: 65px;
        }
        & .se-btn {
            color: #167092;
            font-size: 16px;
        }
        & .se-resizing-bar {
            background-color: rgba(0,155,148,0.08);
            & .se-navigation {
                opacity: 0;
            }
        }
        & .se-resizing-bar .se-char-counter-wrapper .se-char-label {
            font-family: "Inter", sans-serif;
            font-weight: 600;
            font-size: 14px;
            text-transform: capitalize;
            color: #009b94;
        }
        & .se-resizing-bar .se-char-counter-wrapper .se-char-counter {
            font-family: "Inter", sans-serif; 
            font-size: 14px;
            color: #009b94;
        }
    }
      
}
.dragArea {
    background: $color1 url(../img/icons/drag_icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    right: 0;
    top: -10px;
    cursor: all-scroll;
}
.imgShowBox {
    & b {
        width: 102px;
        height: 80px;
        margin-right: 30px;
        display: inline-block;
        position: relative;
        background-position: top;
        background-size: cover;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 10px;
        overflow: hidden;
        & img {
            max-width: 100%;
            border-radius: 4px;
            max-height: 100%;
        }
        
    }
}
.keyBox {

    & .t-tag {
        position: relative;
        margin-top: 15px;
        word-break: break-all;
    }
}
.tagsNewWrap {
    margin-top: 15px;
    padding-top: 10px;
    border-top: 0.5px solid #A7A7A7;
    & .t-tag {
        border-radius: 10px;
        padding: 8px;
    }
}

.imgClose {
    position: absolute;
    width: 15px;
    height: 15px;
    display: inline-block;
    right: -8px;
    top: -8px;
    background: $color_gray url(../../assets/img/icons/white-cross.svg);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
    border-radius: 50%;
    background-size: 6px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.editMore {
    display: block;
     vertical-align: middle;
      
    & span {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: $color2;
        float: left;
    }
    & a{
        float: right;
        cursor: pointer;
    }
}

.formulaeBox {
    & span {
        position: relative;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        margin-bottom: 23px;
        color: #009B94;
        display: block;
        padding: 7px 14px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.citationsBox {
    & span {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #009B94;
        display: block;
        margin-bottom: 6px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.authorAppiWrap {
    & .authorAppiLeft {
        float: left;
        width: 45%;
        &.firstBox{
            & .selectBox{
                width: 100px;
                display: inline-block;
                vertical-align: top;
            }
            & .fieldInput {
                display: inline-block;
                width: calc(100% - 124px);
                vertical-align: top;
            }
        }
        & .fieldWrap {
            display: inline-block;
            margin-right: 12px;
            width: 100%;
        }
    }
    & .authorAppiRight {
       float: right;
       width: 45%;
    }
}
.dBoxAuthor {
    display: inline-block;

}
.historyTable {
 
    & table {
        width: 100%;
        border-collapse: initial;
        & thead {
            & tr {
                & th {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    text-transform: uppercase;
                    color: $color2;
                    padding: 10px 14px; 
                    border-bottom: 0.5px solid rgba(151, 151, 151, 0.85);
                    &.mudraTh{
                        width: 124px;
                    }
                }
            }
        }
        & tbody {
            & tr {
                
                // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
                // -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
                // -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);

                // border-radius: 20px;
                // -webkit-border-radius: 20px;
                // -moz-border-radius: 20px;

                background: #ffffff;
           // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 0px 1px .5px rgba(22, 112, 146, 0.1), 0 0 10px 4px rgba(0, 0, 0, 0.07);
            -webkit-box-shadow: 0px 0px 1px .5px rgba(22, 112, 146, 0.1), 0 0 10px 4px rgba(0, 0, 0, 0.07);
            -moz-box-shadow: 0px 0px 1px .5px rgba(22, 112, 146, 0.1), 0 0 10px 4px rgba(0, 0, 0, 0.07);

            border-radius: 20px;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
               
                &.blankTr {
                    box-shadow: none;
                    background: transparent;
                    opacity: 0;
                    & td {
                        padding: 10px;
                        background: transparent;
                    }
                }
                &.empty {
                    opacity: 0;
                }
            }
            & td {
                padding: 0;
                background: #FFFFFF;
                border-top: 1px solid rgba(45, 51, 141, 0.25);
                border-bottom: 1px solid rgba(45, 51, 141, 0.25);
                &:first-child {
                       
                      border-left: 1px solid rgba(45, 51, 141, 0.25);
                      border-top-left-radius: 20px;
                    border-bottom-left-radius: 20px;
                }
                &:last-child {
                    border-right: 1px solid rgba(45, 51, 141, 0.25);
                    border-top-right-radius: 20px;
                  border-bottom-right-radius: 20px;
                }
                & .mudraContent {
                    padding-right: 24px;
                }
                & .mudraContentBox {
                    padding-right: 20px;
                }
            }
        }
    }
    & .hisTableBox {
        background: transparent;
        //box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        height: 100%;
        width: 100%;
        display: inline-block;
        padding: 16px 14px;
        & span {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            & .transNid {
                width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
                font-style: normal;
            }
            &.debitColor{
                color: $color2;
                text-transform: capitalize;
            }
        }
       & .txnWrap {
            display: block;
            width: 200px;
            & .transNid {
                width: 120px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
                vertical-align: middle;
                font-style: normal;
            }
            &.debitColor{
                color: $color2;
            } 
        }
        & .inrText {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            display: block;
            color: $color_black;
        }
        & .transText { 
            display: block;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: $color1;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-decoration: none;
            &.showLimitedChar{
                max-width: 200px;
            }
            &.mxWiText {
                max-width: 300px;
            }
        }
        & .downFileWrap {
            text-align: center;
            & .downloadFile {
                background: $color1 url(../img/icons/downloadfile.svg);
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                border-radius: 50%;
                background-position: center;
                background-repeat: no-repeat;
                margin: 0 auto;
            }
        }
       
    }
}

.midraMainBox {
    & .midraLeft {
        width: 100%;
        max-width: 65%;
        display: inline-block;
        vertical-align: top;
    }
    & .midraRight {
        width: 100%;
        max-width: 31%;
        display: inline-block;
        margin-left: 34px;
        vertical-align: top;
        & .referralWrap p {
            max-width: 250px;
        }
    }
}

.mudraSearch {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    margin-bottom: 38px;
    & h3{
        font-weight: 700;
        font-size: 20px; 
        color: $color2;
    }
    & .searchHistory {
        position: relative;
        & b{
            position: absolute;
            right: 7px;
            top: 10px;
            display: flex;
            background: #F6F9FB url(../img/icons/closeText.svg);
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            cursor: pointer;
            font-size: 0;
            background-repeat: no-repeat;
        }
        & input {
            appearance: none;
            background: #F6F9FB url(../img/icons/search.svg);
            background-repeat: no-repeat;
            background-position: 12px center;
            border-radius: 10px;
            padding: 12px 14px;
            border: 0;
            padding-left: 42px;
            color: #167092;
            font-weight: 600;
            border: 1px solid rgba(22,112,146,0.3);
            &::placeholder {
                color: $color_gray;
            }
            &::-webkit-search-cancel-button{
                appearance: none;
                -webkit-appearance: none;
            }
        }
    }
}

.couponsWrap {
    & h3 {
        font-weight: 700;
        font-size: 20px;
        color: $color2;
        margin-bottom: 15px;
        & img {
            width: 26px;
            vertical-align: middle;
            position: relative;
            top: -3px;
        }
    }
    & .couponsBox {
        & .couponsList {
            border: 0.5px solid rgba(22,112,146,0.6);
            border-radius: 20px;
            background: #FAFAFA;
            padding: 20px;
            margin-bottom: 15px;
            height: 125px;
            & .couponsOff {
                display: flex; 
                justify-content: space-between;
                margin-bottom: 12px;
                & div {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    text-align: left;
                    & .coopanCode {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 19px;
                        color: $color1;
                        display: block;
                        margin-bottom: 6px;
                        margin-top: 6px;
                        & i {
                            cursor: pointer;
                        }
                    }
                }
                & b {
                    color: #009B94;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                }
            }
            & .couponsTmc {
                display: flex;
                align-items: center;
                justify-content: space-between;
                & span {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                }
                & a {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    color: $color1;
                    text-decoration: none;
                }
            }
        }
    }
}
.wordStyle { font-weight: 400; }
.referralWrap {
    position: relative;
    margin-bottom: 30px;
    height: 125px;
    & h3{
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: $color2;
        margin-bottom: 24px;
    }
    & p {
        display: inline-block;
        background: $color1;
        border-radius: 10px;
        color: #ffffff;
        text-transform: uppercase;
        padding: 8px 10px;
        max-width: 88%;
        align-items: center;
        max-width: 144px;
        & img {
            filter: brightness(100);
            margin-right: 6px;
        }
        & span {
            position: relative;
            margin-left: 4px;
            padding-left: 4px;
            display: inline-flex;
            align-items: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            text-transform: capitalize;
            cursor: pointer;
            &::before{
                content: '';
                position: absolute;
                width: 1px;
                height: 8px;
                background: #ffffff;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                display: none;
            }
        }
    }
     & i {
        position: absolute;
        right: 18px;
        bottom: 6px;
     }
}


.midraTop {
    display: flex;
    margin-bottom: 30px;
    & .midraLeft{
       
    }
    & .commanBox {
        height: 100%;
    }
}
.balanceWrap {
    & p{
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center; 
        margin-bottom: 21px; 
        color: $color2;
        & img {
            margin-right: 6px;
        }
        & small {
            color: #009B94;
            font-weight: 600;
            font-size: 30px;
            line-height: 36px; 
        }
        & big {
            font-weight: 700;
            font-size: 30px;
            color: #009B94;
            padding-left: 4px;
        }
    }
}
.mudrasWrap {
    & .mudrasHead {
        margin-bottom: 0px;
        position: relative;
        & .customTool {
            position: absolute;
            right: 0px;
            top: 0;
        }
        & h3 {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 0; 
            color: $color2;
        }
        
    }
    & .mudTitle {
        margin-top: 6px;
        & big {
            font-weight: 600;
            font-size: 24px;
            color: #009B94;
        }
        & span {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #009B94;
        }
    }
    & .mudrasFoot {
        display: flex;
        align-items: end;
        justify-content: space-between;
        & p {
            padding-right: 30px;
            line-height: 19px;
            font-family: "Open Sans", sans-serif;
        }
        & a {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: $color1;
            text-decoration: none;
            text-transform: uppercase;
        }
    }
}

.emosyWrap {
    margin-top: 15px;
    & img {
        cursor: pointer;
    }
}
.byncWrap { 
    margin-top: 45px;
}
.historyModalWrap {
    & .historyList {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgba(64, 64, 64, 0.05);
        padding: 14px;
        border-radius: 10px;
        margin-top: 15px;
        margin-bottom: 15px;
        & p {
            & b {
                font-weight: 600;
                display: block;
            }
            & i {
                font-style: normal;
                display: block;
            }
        }
        & span {
            color: #009B94;
            font-weight: 600;
        }
    }
}
.modalFoot {
    margin-bottom: 30px;
    margin-top: 31px;
}


.draftTable {
    & table {
             border-collapse: initial;
    }
    & thead {
        
        & tr {
            & th {
                padding: 10px;
                border-bottom: 0.5px solid rgba(151, 151, 151, 0.85);
                text-transform: uppercase;
                color: $color2;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                &:first-child{
                    padding-left: 20px;
                    
                 }
                 &:last-child{
                     padding-right: 20px;
                     text-align: center;
                    //  & span {
                    //     justify-content: center;
                    //  }
                 }
            }
        }
    }
    & tbody{
        tr {
            background: #ffffff;
           // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 0px 1px .5px rgba(22, 112, 146, 0.1), 0 0 10px 4px rgba(0, 0, 0, 0.07);
            -webkit-box-shadow: 0px 0px 1px .5px rgba(22, 112, 146, 0.1), 0 0 10px 4px rgba(0, 0, 0, 0.07);
            -moz-box-shadow: 0px 0px 1px .5px rgba(22, 112, 146, 0.1), 0 0 10px 4px rgba(0, 0, 0, 0.07);

            border-radius: 20px;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            &.empty {
                opacity: 0;
                & td {
                    border: 0;
                    box-shadow: none;
                    padding: 14px;
                    border: 0 !important;
                }
            }
            & td {
                  padding: 10px 20px ; 
                  position: relative;
                    border-top: 1px solid rgb(191 32 47);
                    border-bottom: 1px solid rgb(191 32 47);
                    // border-top: 1px solid rgba(45, 51, 141, 0.25);
                    // border-bottom: 1px solid rgba(45, 51, 141, 0.25);
                &:first-child{
                   padding-left: 20px;
                   
                    // border-left: 1px solid rgba(45, 51, 141, 0.25);
                    border-left: 1px solid rgb(191 32 47);
                    border-top-left-radius: 20px;
                    border-bottom-left-radius: 20px;
                }
                &:last-child{
                    padding-right: 20px;
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;
                    border-right: 1px solid rgb(191 32 47);
                    // border-right: 1px solid rgba(45, 51, 141, 0.25);
                }
            }

        }
    }
    & .footTag{
        // position: absolute;
        bottom: 10px;
    }
    & .t-tag{
        max-width: 48%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        &.t-tag-journal {
            max-width: none;
        }
    } 
}
 .textDetailsWrap{
    font-size: 16px;
    font-weight: 400;
    & b {
        color: #167092;
        border-bottom: 1px solid #167092;
        cursor: pointer;
    }
    &.textRed{
        color: #BF1E2E;
    }
    &.textGreen{
        color: #16B14B;
    }
    &.textOrange{
       color: #FF8300;
    }
  }
.firstDataBox {
    width: 480px;  
}
.draftArticleWrap {     
    & h2{
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        min-height: 30px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 40px;
        color: $color1;
        margin-top: 8px;
        cursor: pointer;
        & a {
            text-decoration: none;
           &:hover{
            color: $color1;
           } 
        }
        & img {
            margin-left: 8px;
        }
    }
    & span {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: $color1;
        margin-right: 20px;
    }
    & p {
        min-height: 70px;
    }
    & .footTag {

    }
    & h2.successText {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            color: #4CAF50;
            & img {
                margin-left: 5px;
            }
            & span {
                font-weight: 600;
            font-size: 16px;
            line-height: 19px;
                color: #4CAF50;
            }
    }

    & h2.failedText {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        color: #BF1E2E;
        & img {
            margin-left: 5px;
        }
        & span {
            font-weight: 600;
        font-size: 16px;
        line-height: 19px;
            color: #BF1E2E;
        }
}
& h2.progressText {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #FF8300;
    & img {
        margin-left: 5px;
    }
    & span {
        font-weight: 600;
    font-size: 16px;
    line-height: 19px;
        color: #FF8300;
    }
}

 & .similarityCheck.successText {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            color: #4CAF50;
            & img {
                margin-left: 5px;
            }
            & span {
                font-weight: 600;
            font-size: 16px;
            line-height: 19px;
                color: #4CAF50; 
            }
    }

    & .similarityCheck.failedText {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        color: #BF1E2E;
        & img {
            margin-left: 5px;
        }
        & span {
            font-weight: 600;
        font-size: 16px;
        line-height: 19px;
            color: #BF1E2E;
        }
}
& .similarityCheck.progressText {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #FF8300;
    & img {
        margin-left: 5px;
    }
    & span {
        font-weight: 600;
    font-size: 16px;
    line-height: 19px;
        color: #FF8300;
    }
}

}
.statusDraft {
    width: 125px;
}
.draftTitle {
    font-weight: 700;
    font-size: 20px;
    color: $color2;
    margin-bottom: 48px;
    & span {
        display: block;
        color: #000;
        font-weight: 400;
        font-size: 16px;
    }
}

.paginationCustom {
    & ul {
        display: flex;
        align-items: center;
        justify-content: center;
        list-style-type: none;
        & li {
            font-weight: 600;
            color: $color1;
            padding: 4px 4px;
            margin: 0 4px;
            cursor: pointer;
            
            &.arrow {

            }
            &.active {
                color: $color_gray;
                &.prev {
                    background: url(../img/icons/arrow_gray.svg);
                    transform: rotate(0deg);
                    background-repeat: no-repeat;
                    background-position: center;
                }
                &.next {
                    background: url(../img/icons/arrow_down.svg);
                    transform: rotate(0deg);
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
            &.prev {
                background: url(../img/icons/arrow_down.svg);
                transform: rotate(90deg);
                background-repeat: no-repeat;
                background-position: center;
                  
            }
            &.next {
                background: url(../img/icons/arrow_down.svg);
                transform: rotate(270deg);
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }
}
.fundingWrap {
    padding: 0 20px;
    & .commanTitle02 {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    & .defaultInput {
        margin-bottom: 50px;
      
    }
    & .defaultFeild::placeholder {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #404040;
    }
    & .customCheck {
        & i {
            top: 2px;
        }
    }
}
.transText { cursor: pointer; }
.backFooterBox {
    display: inline-block;
    position: absolute;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
    width: calc(100% - 324px);
    bottom: 10px;
    & .backBtnWrap {
        position: absolute;
        left: 0;
        top: 0;
    }
    & .paginationWrap {
        display: inline-flex;
        align-items: center;
    }
}

.paginationWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    & .pagination  {
        align-items: center;
        & a {
            text-decoration: none;
            margin: 0 5px;
            cursor: pointer;
            font-weight: 600;
            &.active {
                color: #404040;
            }
            & b {
                font-size: 12px;
            }
        }
    }
}

.full-view-page {
    & .pageWrap { 
        & .pageRight {
            padding: 0px;
        }
        & .manageEditorBox {
            margin-bottom: 110px;
            & .manageEditorInner {
                padding: 0px 40px 30px 40px;
                max-width: 1440px;
                margin: 0 auto;
                & .personal-details {
                    padding-top: 0;
                    & input.fieldForm{
                        color: #404040;
                        font-weight: 500; 
                        padding-left: 0.75rem;
                        &:read-only{
                            background: #F3F3F3 !important;
                            border-color: #F3F3F3 !important;
                        } 

                    }
                    & .form-control { 
                        color: #404040;
                        font-weight: 500 !important;
                    }
                    & .fieldForm {
                        &::placeholder {
                            color: #404040;
                            font-weight: 500;
                            font-size: 14px;
                        }
                    }
                }
                & .getToolTips {
                    padding: 4px 0;
                    display: block;
                }
            }
           
        }
    }
}

.t-type {
    font-weight: 400;
    font-size: 20px;
    line-height: 17px;
    color: $color2;
}

.manageEditorInner {
    & .backFooterBox {
        margin-top: 0;
        margin-bottom: 50px;
    }
    & .form-control {
        border-radius: 10px;
        font-size: 14px;
        color: #009B94;
        font-weight: 600 !important;
    }
    & .form-control::placeholder{
        font-weight: 500 !important; 
        font-size: 16px !important; 
        color: $color_gray !important;
    }
    & .fieldWrap .labelFormTwo {
        margin-bottom: 20px !important;
    }

}

.peerArticlePage { 
    // padding: 30px 40px;
}
.guidelinesWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    padding: 0px 20px;
    &.paymentGuidel {
        margin-bottom: 120px;
        margin-top: 50px;
    }
   & span {
    color: #009B94; 
   }
   & .crossrefWrap {
    display: flex;
    align-items: center;
    & span {
        border-right: 1px solid rgba(167, 167, 167, 0.4);
        padding-right: 12px;
        margin-right: 12px;
        & img {
            margin-right: 0;
        }
    }
    & .guidelText {
        margin-top: 10px;
        display: flex;
        align-items: center;
        & a {
            color: #BF1E2E;
            &:hover{
                color: #BF1E2E;
            }
        }
    }
    
}
    & .toolWrap {
        color: #BF1E2E;
        display: flex;
        align-items: center;
       
    }
    & img { 
        margin-right: 4px;
    }
}
.customAccordion {
    margin-top: 30px;
    & .ant-picker{
        height: 50px;
        min-height: 50px;
        width: 100%;
        border-radius: 10px;
        border: 1px solid rgba(153, 153, 153, 0.5);
        input{
            background-color: #fff;
            padding: 4px 4px;
            color: #009B94;
            font-size: 16px;
            font-weight: 600;
        }
    }
    & .labelForm {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 10px;
    }
    & .selectBox .reactSelect-container .reactSelect__control { 
        height: 50px;
        min-height: 50px; 
    }
    & .selectBox .reactSelect-container .reactSelect__control--menu_is_open .css-1xc3v61-indicatorContainer {
        transform: rotate(180deg);
    }
    & .selectBox .reactSelect-container .reactSelect__placeholder{ 
        margin-left: 7px; 
        color: #404040;
    }
    & input.fieldForm { 
        height: 50px; 
        min-height: 50px; 
        font-weight: 600 !important; 
        font-size: 16px !important; 
        color: #009B94 !important; 
        &::placeholder {
            font-weight: 600 !important; 
            font-size: 16px !important; 
            color: $color_gray !important;
        }
    }
    & textarea.form-control {
        min-height: 100px;
        &::placeholder {
            font-weight: 600 !important; 
            font-size: 16px !important; 
            color: $color_gray !important;
        }
    }
    & .form-control { 
        height: 50px; 
        min-height: 50px; 
        font-weight: 600 !important; 
        font-size: 16px !important; 
        color: #009B94 !important;
        background: #ffffff !important;
        
        &::placeholder {
            font-weight: 600 !important; 
            font-size: 16px !important; 
            color: $color_gray !important;
        }
    
    }
        & .reactSelect-container .reactSelect__value-container {
            font-size: 16px;
        }
        & .accordion-flush .accordion-collapse {}
    & .accordion-flush .accordion-item {
        margin-bottom: 20px;
        border: 0.5px solid rgba(22, 112, 146, 0.5) !important;
        border-radius: 12px;
        overflow: visible;
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
        & .accordion-button:not(.collapsed) {
            background: transparent;
            border-radius: 11px 11px 0 0 !important;
         }
         &.disabled{
            opacity: .5;
            pointer-events: none;
         }
    }
    & .accordion-body {
        background: #fcfcfc;
        border-radius: 11px; 
        position: relative;
        & .customCheck {
            & i {
                background: #167092;
                &.ds-change{
                    background: #e3e3e3;
    border: 1px solid #009b9466;
    height: 17px;
    width: 17px;
    top: 3px;
                }
            }
            & b {
                color: #404040;
            }
        }
        & sup {
            font-size: 18px !important;
            font-weight: 600;
        }
    }
    & .accordion-header {
        
        & .accordion-button {
            justify-content: space-between;
            border-radius: 11px !important;
            &:focus {
                box-shadow: none;
            }
            &.collapsed{
                & p {
                color: $color1;
                }
            }
            & p {
                font-weight: 700;
                font-size: 20px;
                color: $color2;
                width: calc(100% - 100px);
                text-transform: uppercase;
                & span {
                    display: block;
                    font-weight: 400;
                    font-size: 16px;
                    color: $color_gray;
                }
            }
            & b {
                font-weight: 600;
                font-size: 16px;
                color: $color_gray;
                white-space: nowrap;
                text-transform: none;
                &.completed{
                    color: #009B94;
                }
            }
            &::after{
                background-color: $color1;
                background-image: url(../img/icons/downarrow.svg);
                border: 1.5px solid transparent;
                box-shadow: none;
                border-radius: 6px;
                width: 30px;
                height: 28px;
                background-position: center;
                margin-left: 16px;
                background-size: 15px;
            }
            
        }
       
    }
}

.recommendBoxTit {
    margin-bottom: 40px;
}
.redingFilterWrap {
    margin-top: 40px;
}
.redingFilterArea {
    display: flex;
    align-items: center;
    padding-top: 20px;
    & .redingFilterList { 
        width: calc(25% - 50px);
        & .reactSelect-container .reactSelect__placeholder {
            color: #404040;
            font-size: 16px;
        }
        & .fieldWrap {
            width: 100%;
            padding-right: 14px;
            & .ant-select {
                width: 100%;
            }
            & label {
                position: absolute;
                top: -22px;
            }
            & .ant-collapse-header-text {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: calc(100% - 23px);
                font-size: 16px;
            }
            & .ant-collapse-item {
                padding: 5px 15px;
            }
            & .ant-collapse:has(input[type=checkbox]:checked) .ant-collapse-item {
                padding: 9px 12px 9px 12px !important;
            }
            & .ant-menu-item{
                padding: 7px 0;
                margin: 0;
                white-space: break-spaces;
                line-height: normal;
                &:hover {
                    background: transparent;
                }
            }
            & .ant-menu-item-selected {
                background-color: transparent !important;
            }
             
            & input[type=checkbox]::after { 
                content: '';
                width: 12px;
                height: 12px;
                border-radius: 2px;
                border: 1px solid #848484;
                position: absolute;
                display: none;
            }
            & .ant-menu-vertical >.ant-menu-item {
                height: auto;
            }
            & .ant-collapse-content-box {
                width: 370px;
            }
            & .ant-collapse-content-box .ant-menu-title-content input[type=checkbox]:checked::before{
                top: 50%;
                transform: translate(0px, -8px); 
            }
        }
        &.actionWrap{
            width: 194px;
            position: absolute;
            right: 20px;
            & .filterText{
                display: block;
                text-align: center;
                position: absolute; 
                top: -20px;
                width: 100%;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: #EF6209;
            }
            & .button  {
                padding: 5px 10px;
            }
            & b {
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                margin-right: 30px;
                position: relative;
                cursor: pointer;
                text-transform: uppercase;
                &::before{
                    content: '';
                    width: 1px;
                    height: 14px;
                    background: $color_black;
                    top: 4px;
                    right: -13px;
                    position: absolute;
                    opacity: .7;
                    
                }
            }
        }
    }
}

.sortWrap {
    padding: 15px 0;
     
    & .customDrop {
        float: right;
        margin-top: 10px;
        & .dropdown.show > .btn-success.dropdown-toggle {
            color: #167092;
        }
        & .dropdown .dropdown-toggle{ 
            position: relative;
            background: transparent; 
            &::before{
                content: '';
                position: absolute;
                left: -6px;
                top: 10px;
                background: url(../img/icons/sort.svg);
                width: 16px;
                height: 10px;
                background-repeat: no-repeat;
            }
            &.show{
                background: transparent;
                &::before {
                    transform: rotate(180deg);
                   left: -10px;
                  
                }
            }
        }
    }
}


.modal .authorModal.right.modal-dialog {
    position: fixed;
    margin: auto;
    max-width: 560px;
    width: 100%;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
         -o-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0);
}
.modal .authorModal.right .modal-content {
    height: 100%;
    overflow-y: auto;
    border-radius: 20px 0 0 20px;
    & .draftTable tbody tr td,
    & .draftTable thead tr th:last-child {text-align: left;}
    & .draftTable thead tr th:first-child {
        text-align: left;
    }
}
.modal .authorModal.right.modal-dialog {
    right: -560px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
       -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
         -o-transition: opacity 0.3s linear, right 0.3s ease-out;
            transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal .authorModal.right.modal-dialog {
    right: 0;
}






.modal.authorModal.rightSide .modal-dialog {
    position: fixed;
    margin: auto;
    max-width: 560px;
    width: 100%;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
         -o-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0);
}
.modal.authorModal.rightSide .modal-content {
    height: 100%;
    overflow-y: auto;
    border-radius: 20px 0 0 20px;
    & .draftTable tbody tr td,
    & .draftTable thead tr th:last-child {text-align: left;}
    & .draftTable thead tr th:first-child {
        text-align: left;
    }
}
.modal.authorModal.rightSide .modal-dialog {
    right: -560px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
       -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
         -o-transition: opacity 0.3s linear, right 0.3s ease-out;
            transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.authorModal.rightSide .modal-dialog {
    right: 0;
}
.modal.authorModal.rightSide .modal-title { text-align: left;}

.customCheck {
    position: relative;
    padding-left: 20px;
    margin-bottom: 0;
    cursor: pointer;
    & input {
        display: none;
    }
    & input[type="checkbox"]:checked + i { background: $color1 url("../../svg/active-check_blue.svg");}
    & input[type="checkbox"]:checked + i + b {color: #009B94;}
    & input[type="checkbox"]:checked + i.ds-change { background: $color1 url("../../svg/active-check_blue.svg");}
    & input[type="checkbox"]:checked + i.ds-change + b {color: #009B94;}
    & i {
        position: absolute;
        left: 0;
        top: 4px;
        width: 15px;
        height: 15px;
        display: inline-block;
        background: rgba(64, 64, 64, 0.5);
       border-radius: 2px;

    }
    & b {
        font-weight: 600;
        font-size: 16px;
        color: #009B94;  
        line-height: 135%;
    }
}
.grantFootB{ 
    padding-top: 36px;
    margin-top: 36px;
    border-top: 0.5px solid #A7A7A7;
}

 

.confirmFooter {
    background: #FFFFFF;
    border: 0.5px solid $color1;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 20px 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 6;
    max-width: 1440px;
    &.confirmFooterFix {
        position: absolute;
    }
    & .btn-link-active {
        margin-right: 20px;
    } 
    & .confirmContent {
        & h3 {
            font-weight: 700;
            font-size: 18px;
            color: #009B94;
            text-transform: uppercase;
            & b {
                // background: rgba(241, 97, 54, 0.05);
                // border: 0.5px solid $color2;
                border-radius: 5px;
                padding: 3px 0px;
                color: $color2;
                font-weight: 700;
            }
        }
        & p {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: $color_black;
        }
        & small {
            color: #BF1E2E;
        }
        & .questionBox {
            & span {
                color: #000000;
                font-weight: 400;
            }
        }
    }
    & .confirmBtn {
        display: inline-flex;
        align-items: center;
    }

}
.addSctionEditor {
    .se-image-container {
        & figure {
            
            & figcaption {
                width: auto;
            }
        }
    }
    .se-image-container figure figcaption::before { 
        left: 8px;
        top: 0;
    }
}
.se-image-container {
    & figure {
        img {
             max-width: 100%;
            margin: 0 auto;
           
            display: inline-block;

        }
        & figcaption {
            color: #404040;
            font-size: 16px; 
            font-weight: 600;
            display: inline-block;
            width: 100%;
            padding-left: 20px;
            position: relative;
            padding-top: 25px !important;
            text-align: left;
            &::before {
                content: 'Image Description';
                position: absolute;
                left: 8px;
                color: #FF8300; 
                font-size: 16px; 
                font-weight: 600;
                top: 5px;
            }
        }
    }
}


.contentReview {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: $color_black;
    display: flex;
    align-items: center;
    margin-bottom: 28px;
    margin-top: 10px;
}
.regardingChkBox { 
    background: #FFFFFF;
    border: 0.5px solid rgba(22, 112, 146, 0.5);
    box-shadow: 0px 0px 10px rgba(0, 155, 148, 0.13);
    border-radius: 10px;
    padding: 16px;
    &.active {
        & .regardingRadio label b {
            color: #009B94;
        }
        & .regardingRadio big {
            background: rgba(241, 97, 54, 0.05);
            border: 0.5px solid $color2;
            color: $color2;
        }
        & .tollArea{
            background: rgb(226 243 243);
            border: 0.5px solid #009B94;
            &:before {
                background: url(../img/icons/tool-active.png)
            }
        }
        
       & .priceReview {
            background-image: linear-gradient(to right, #e46333, #ee762d, #f68927, #fb9c21, #ffb01b);
            border: 0;
            padding: 3px 3px 3.2px 3.2px;
            border-radius: 13px;
            & ul {
                background: #ffffff;
                & li {
                     
                    &::before {
                        border-bottom: 0.5px dashed rgba(191, 30, 46, 0.34);
                    }
                    &:last-child{
                        
                        & .priceReviewImg {
                            color: #FF8300;
                        }
                    }
                    & .priceReviewRate {                        
                        color: #16ACE4;
                        & del {
                            position: relative; 
                            text-decoration: none;
                            &::before {
                                content: '';
                                position: absolute;
                                left: -4px;
                                top: 50%;
                                transform: translateY(-50%);
                                width: calc(100% + 8px);
                                height: 2px;
                                background:  #BF1E2E;
                            }
                        }
                        & big {
                       
                            color: #FF8300;
                            padding-right: 0;
                           
                        }
                    }
                    & .priceReviewImg {
                      
                        color: #009B94;
                        & i {
                            
                            // background-color: rgba(0, 155, 148, 0.11);
                           
                            &.priceIcon{
                                background-image: url(../img/icons/listprice.svg);
                            }
                            &.saveIcon{
                                background-image: url(../img/icons/save.svg);
                            }
                            &.roducIcon{
                                background-image: url(../img/icons/reduse.svg);
                            }
                        }
                        
                    }
                    &.saveprice {
                        color: #BF1E2E; 
                        & .priceReviewRate {
                            color: #009B94; 
                        }
                    }
                }
            }
        }

    }
    & .form-check {
        & label{
            text-transform: uppercase;
            font-weight: 700 !important;
            font-size: 18px !important;
            line-height: 22px;
            position: relative;
            top: -3px;
        }
    }
    
}
 .regardingRadio {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 53px;
    & label {
        
        position: relative;
        margin-bottom: 0;
        font-weight: 600 !important;
        line-height: 20px;
        & label {
            padding-left: 7px;
            
        }
        & input {
            display: none;
        }
        & input:checked + i::before {
            content: '';
            position: absolute;
            left: 3px;
            top: 3px;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background: $color1;
        }
        & input:checked + i + b { 
            color: #009B94;
        }
        & i {
            position: absolute;
            left: 0;
            top: 3px;
            width: 15px;
            height: 15px;
            background: #A7C8D5;
            border-radius: 50%;
            z-index: 0;
        }
        & b {
            display: inline-block;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            text-transform: uppercase;
        }
        & span {
            display: block;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: $color_gray;
            font-style: italic;
        }
    }
    & big {
        background: rgba(64, 64, 64, 0.06);
        border: 0.5px solid $color_gray;
        border-radius: 5px;
        padding: 3px 10px;
        font-weight: 700;
        font-size: 18px;
    }
    & .custom_radio {
        position: relative;
        z-index: 1;
        left: -23px;
    }
    & .custom_radio .form-check-input[type=radio]{
        background: url(../img/icons/radio.png);
        background-position: -3px -2px !important;
        background-size: 19px;
    }
    & .custom_radio .form-check-input:checked[type=radio] {
        background: url(../img/icons/radio-check.png) !important;
        position: relative;
        top: 0px;
        background-position: -1px -1px !important;
        background-size: 18px !important;
        
    }
}
.tollArea {
    background: #F4F4F4;
    border: 0.5px solid $color_gray;
    
    font-weight: 400;
    font-size: 16px;
    color: $color_black;
    margin-top: 20px;
    position: relative;
    padding: 12px;
    display: inline-block;
    width: 100%;
    border-radius: 10px;
    &::before {
        content: '';
        position: absolute;
        top: -10px;
            left: 40px;
        width: 31px;
        height: 15px;
        background: url(../img/icons/tool_default.png);
    }
    &.active{
        background: rgba(0, 155, 148, 0.06);
        border: 0.5px solid #009B94;
        &::before {
            background: url(../img/icons/tool-active.png)
        }
    }
}

.paymentSuccessWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border: 0.5px solid #009B94;
    border-radius: 10px;
    margin-top: 50px;
    & .paymentSuccessText {
        font-weight: 600;
        font-size: 16.5px;
        line-height: 20px;
        color: #009B94;
    }
    & .paymentSuccessTool {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px; 
        color: $color1;
        cursor: pointer;
    }
}
.warningText {
    background: #FFFFFF;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #009B94;
    border: 0.5px solid #BF1E2E;
    padding: 15px;
    margin-top: 50px;
    display: flex;
    & span {
        color: #BF1E2E;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        margin-right: 5px;
    }
}

.innerMerginHead { max-width: 1362px;  margin: 0 auto; }
.editorHeadMain {
    width: 100%;
    display: inline-block;
    padding: 15px 44px;
    background: linear-gradient(270deg, #FEF1D4 0%, #FAF9E2 100.77%);
    & .innerMergin {
        
    }
    & .editorHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        & h2{
            color: #009B94;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 0;
            text-transform: uppercase;
            & small {
                font-size: 14px;
            }
        }
        
    }
}

 .editorAction {
    & a {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;  
        position: relative;
        text-decoration: none; 
        margin-left: 28px;
        display: inline-block;
        cursor: pointer;  
        &:hover {
            color: #009B94 !important; 
        }
        &:first-child{
            margin-left: 0;                    
        }
        &:last-child{
                
            &::before{
                display: none;
            }                
        }
        &::before{
            content: '';
            position: absolute;
            right: -14px;
            top: 2px;
            background: $color_gray;
            width: 1px;
            height: 17px;
        }
        &.active {
            color: $color_gray;
            opacity: 1;
            &:after {
                content: "";
                width: 34px;
                height: 8px;
                border-radius: 8px;
                background: linear-gradient(270deg, rgba(255, 176, 27, 0.84) -2.89%, rgba(227, 232, 123, 0.84) 100%);
                display: block;
                position: absolute;
                left: 0;
                bottom: -13px;
                z-index: 1;
            }
        }
    }
}

.registrationPaymentMain {
    padding: 30px 44px; 
    max-width: 1184px;
    margin: 0 auto 90px;
    & .paymentCheck {
        padding: 20px 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &.mailBoxPay{
            & .customCheck { 
                width: calc(100% - 240px) ;
            }
        }
        & .customCheck { 
            margin-bottom: 10px;
           width: calc(100% - 120px) ;
           & i {
            background: #167092;
            top: 2px;
           }
           & b {
            color: #404040;
            & .btn-link-active {
                text-decoration: underline;
            }
           }
        }
    }
    & .registrationPaymentCard {
        background: #FFFFFF;
        border: 0.5px solid rgba(22, 112, 146, 0.5);
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        & .registrationPaymentHead {
            padding: 14px 25px;
            border-bottom: 0.5px solid rgba(22, 112, 146, 0.5);
            & h3{
                font-weight: 700;
                font-size: 20px;
                color: $color2;
                margin: 0;
                text-transform: uppercase;
                padding: 20px 0;
            }
            & p {
                font-weight: 400;
                font-size: 16px;
                color: $color_gray;
                margin: 0;
            }
            & .articleSuccess {
                padding: 20px 0;
                text-transform: uppercase;
                color: #009B94;
                font-weight: 700;
                font-size: 20px;
            }
        }
        & .registrationPaymentCotts {
            padding: 14px 25px;
            border-bottom: 0.5px solid rgba(22, 112, 146, 0.5);
        }
        & .registrationMidd {
            background: #FCFCFC;
            padding: 10px 24px;
            display: inline-flex;
            width: 100%;
            border-radius: 12px;
            & .registrationMiddLeft {
               display: inline-block;
               vertical-align: top;
               width: 100%;
                & h4 {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    color: $color2;
                    text-transform: uppercase;
                    margin-top: 20px;
                }
                & ul {
                    padding: 0;
                    list-style-type: none;
                    & li {
                        padding: 14px 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        position: relative;
                        font-size: 18px;
                        &:before {
                            content: '';
                            background: url(../img/icons/border.png);
                            width: 100%;
                            height: 1px;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                        }
                        &:last-child{
                            &:before {
                                display: none;
                            }
                        }
                        &.grandTotalList{
                            display: block;
                        }
                        & .grantApplied {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            color: #16ACE4; 
                            padding: 6px 0;
                            & b {font-weight: 600;}
                            & span {font-weight: 600;}
                        }
                        & .subtotalPrice {
                            & .mudra {
                                line-height: 17px;
                                display: inline-block;
                            }
                        }
                        & .subTitle {
                            font-weight: 600;
                            color: #009B94;
                            font-size: 18px;
                            line-height: 22px;
                        }
                        & .listCardBox {
                            display: inline-block;
                            width: 100%;
                            & .listCard {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin-bottom: 14px;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                                & span {
                                    font-weight: 500;
                                    color: #404040;
                                }
                                & b {
                                    font-weight: 600;
                                }
                                & a {
                                    font-weight: 700;
                                    font-size: 16px;
                                    line-height: 19px;
                                    color: $color1;
                                    text-transform: uppercase;
                                    text-decoration: none;
                                    &:hover{
                                        color: $color1;
                                    }
                                }
                            }
                        }
                        & .grandCardBox {
                            width: 100%;
                            & .grandCardList {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin-bottom: 10px;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                                & .errorText {
                                    color: #BF1E2E;
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }
            & .registrationMiddRight {
                display: inline-block;
                width: 100%;
                margin-left: 24px;
                position: relative;
                vertical-align: top;
                padding-left: 20px;
                &::before{
                    content: '';
                    background: rgba(22, 112, 146, 0.5);
                    width: 1px;
                    height: calc(100% + 22px);
                    position: absolute;
                    left: 0;
                    top: -11px;
                }
                & .questionBox {
                    position: absolute;
                    bottom: 17px;
                    left: 25px;
                }
                & ul {
                    list-style-type: none;
                    padding-left: 0;
                    & li {
                        padding: 14px 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        position: relative;
                        &:before {
                            content: '';
                            background: url(../img/icons/border.png);
                            width: 100%;
                            height: 1px;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                        }
                        &:last-child{
                            &:before {
                                display: none;
                            }
                        }
                        & .listCardBox {
                            display: inline-block;
                            width: 100%;
                            & .listCard {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin-bottom: 14px;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                                & span {
                                    font-weight: 600;
                                    color: #404040;
                                    &.coupanColor {
                                        color: #16ace4;
                                    }
                                }
                                & b {
                                    font-weight: 500;
                                }
                                & a {
                                    font-weight: 700;
                                    font-size: 16px;
                                    line-height: 19px;
                                    color: $color1;
                                    text-transform: uppercase;
                                    text-decoration: none;
                                    &:hover{
                                        color: $color1;
                                    }
                                }
                                & .applyInput {
                                    display: flex;
                                    align-items: center;
                                    & input {
                                        border: 0.5px solid #A7A7AA;
                                        font-size: 16px;
                                        font-weight: 400;
                                        border-radius: 8px;
                                        padding: 4px 10px;
                                        width: 200px;
                                        margin-right: 10px;
                                        &::placeholder{
                                            font-size: 16px;
                                        }
                                    }
                                }
                            }
                        }
                        & .successText {
                            color: #4CAF50;
                            font-weight: 500;
                            & b {
                                font-weight: 700;
                            }
                            & img {
                                margin-right: 5px;
                            }
                        }
                    }
                }
                & .offerArea {
                    padding-left: 24px;
                    & .offerAreaTitle {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 19px;
                    }
                    & .offerAreaValue {
                        & a {
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 19px;
                            margin-left: 15px;
                            text-decoration: none;
                        }
                        & input {
                            background: #FFFFFF;
                            border: 0.5px solid #A7A7AA;
                            border-radius: 8px;
                            height: 38px;
                            padding: 0 12px;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            width: 152px;
                            &::placeholder{
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.subTitleTiel { color: #404040; font-weight: 600; } 
.subtotalPrice {
    & .mudra { 
        margin-right: 6px;
    }
}

.btn-link-active.inputSet{
    text-transform: capitalize;
    font-weight: 600;
}
.availableCoupanModal {
     & ul {
        list-style-type: none;
        & li {
            padding: 0 20px;
            margin: 15px 0;
        }

     }
    & .coupanCard {
        border: 0.5px solid #16709299;
        background: #FAFAFA;
        border-radius: 20px;
        padding: 20px;
        & .coupanHead {
            display: flex;
            align-items: start;
            justify-content: space-between;
            margin-bottom: 20px;
            & .coupanCopy {
                text-align: left;
                & b {
                    display: block;
                }
                & strong {
                    color: #167092;
                    cursor: pointer;
                }
            }
            & .offOfer {
                color: #009B94;
                font-weight: 700;
            }
        }
        & .coupanFoot {
            display: flex;
            align-items: start;
            justify-content: space-between;
            & a {
                color: #167092;
                font-size: 16px;
                text-transform: uppercase;
                font-weight: 700;
                text-decoration: none;
            }
        }
     }
}
.mudra {
    font-weight: 700;
    font-size: 18px;
    color: $color2;
    background: rgba(241, 97, 54, 0.05);
    border: 0.5px solid $color2;
    border-radius: 5px;
    padding: 7px 10px;
    &.sm-mudra {
        padding-top: 2px;
        padding-bottom: 2px;
        background: transparent;
        border: 0;
        padding-right: 0;
    }
}
.defaultInput {
    margin-top: 24px;
    & label{
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $color_gray;
        display: block;
        width: 100%;
        padding-left: 16px;
    }
    & input {
        background: #FFFFFF;
        border: 0.5px solid #009B94;
        border-radius: 10px;
        padding: 10px 16px;
        display: block;
        width: 100%;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #009B94;
        &:read-only {
            background: #F3F3F3 !important;
            border-color: #F3F3F3 !important;
        }
    }
    & textarea {
        background: #FFFFFF;
        border: 0.5px solid #989898;
        border-radius: 10px;
        padding: 10px 16px;
        display: block;
        width: 100%;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: $color_gray;
    }
    & .defaultFeild {
        background: #FFFFFF;
        border: 0.5px solid #989898;
        border-radius: 10px;
        padding: 14px 16px;
        
    }
    & .py-2 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    & .hidden{
        display: none;
    }
}
.defaultInput .reactSelect-container.reactSelect--is-disabled .reactSelect__control {
            background: #F3F3F3 !important;
            border-color: #F3F3F3 !important;
}
.selectBox .reactSelect-container.reactSelect--is-disabled .reactSelect__control {
    background: #F3F3F3 !important;
    border-color: transparent !important;
}
.form-control:read-only {
    background: #F3F3F3 !important;
    border-color: #F3F3F3 !important;
}
#article-editor__comp label {
    font-size: 16px !important;
}
.inrBox {
    margin-top: 24px;
    display: flex;
     & i {
        display: inline-block;
        vertical-align: middle;
        font-style: normal;
        margin-top: 35px;
     }
     & .defaultInput {
        margin-top: 0;
        display: inline-block;
        margin-left: 16px;
        width: calc(100% - 40px);
     }
     
}
.sun-editor .se-resizing-bar .se-char-counter-wrapper {
    margin-right: 15px !important;
}
.sun-editor .se-toolbar {
    border-radius: 20px 20px 0px 0px !important;
}
.contributorsBody {
    & .contributorsTitle { 
        display: flex;
        align-items: center;
        justify-content: space-between; 
        &.secondryAuthor {
            margin-top: 40px;
            padding-top: 40px;
            position: relative;
            &::before{
                content: ''; 
                background: url(../img/icons/border.png); 
                height: 1px;
                width: 100%;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        & h3 {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            color: $color2;
        }
        & span {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: $color1;
        }
        & .customCheck b {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: $color1;
        }
    }
    & .contributorsDetails {
        & .contributorsDetailsLeft {
            width: 50%;
            display: inline-block;
            vertical-align: top;
            padding: 0 20px;
            & .contributorsFirst {
                display: flex;
                & .defaultInput {
                    margin-right: 15px;
                    &.title{
                        width: 150px;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
        & .contributorsDetailsRight {
            width: 50%;
            display: inline-block;
            vertical-align: top;
            padding: 0 20px;
        }
    }
}

.posiTopManage { position: relative; top: 5px; }
.statementWrap {
    
    margin-top: 30px; 
    margin-left: -20px;
    padding: 14px 40px 0 40px;
    width: calc(100% + 40px);
    position: relative;
    &::before{
        content: '';
        background: rgba(22, 112, 146, 0.5);
        height: 1px;
        width: 98%;
        position: absolute;
        left: 1%;
        top: 0;
        display: block;
    }
    & .defaultInput {
        max-width: 49%;
        & label {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        & textarea {
            height: 110px;
            resize: none;
            color: #000000 !important;
        }
    }
    & .guidelinesWrap {
        margin-top: 25px;
    }
}

.commanTitle02 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: $color2;
    margin-bottom: 16px;
}
.fundingInfo {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 20px;
    & .funderContent {
        font-weight: 400;
        font-size: 16px;
        color: $color_gray;
        width: 100%;
        padding-right: 45px;
    }
    & .funderTextBoxArea {
        width: 100%;
        margin-left: 70px;
    }
    & .defaultFeild {
        width: 100%;
        
        border: 0.5px solid #989898;
        border-radius: 10px;
        padding: 15px;
        color: #009B94;
        &:read-only{ 
            background: #F3F3F3 !important;
            border-color: #F3F3F3 !important;
        }
    }
}

.funderWrap {
    margin-top: 30px;
    padding-top: 30px;
    position: relative;
    &::before{
        content: '';
        width: 100%;
        height: 1px;
        background: url(../img/icons/border.png);
        position: absolute;
        left: 0;
        top: 0;
    }
    & .commanTitle02 {
        margin-bottom: 30px;
        margin-top: 0px;

    }
    & .primaryFunder {
        position: relative;
        display: inline-block;
        width: 100%;
        padding: 0 20px;
        & .primaryFunderLeft {
            width: 50%;
            display: inline-block;
            padding-right: 45px;
            vertical-align: top;
        }
        & .primaryFunderRight {
            width: 50%;
            display: inline-block;
            padding-left: 45px;
            vertical-align: top;
        }
    }
}

.editNoteWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.5px solid rgba(167, 167, 167, 0.8);
    padding-bottom: 8px;
    margin-top: 27px;
    & .noteBox {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #009B94;
        & span {
            font-weight: 600;
            color: #BF1E2E;
            text-transform: uppercase;
            padding-right: 5px;
        }
    }
}

.journalWrapTab {
    border-top: 0.5px solid rgba(22, 112, 146, 0.5);
    padding-top: 40px;
    margin-top: 40px;
    & .commanTitle02 {
        margin-bottom: 30px;
        display: block;
    }
    & .fieldWrap {
        margin-bottom: 40px;
    }
}
.tabCenter {
    padding: 10px 20px 0 20px;
    min-height: 70px;

    
}
.inputTextBox {
    position: relative;
    & .py-2 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    & input {
        border: 1px solid #858585 !important;
        border-radius: 10px;
    }
    & b{
        position: absolute;
        right: 10px;
        top: 4px;
        background: url(../img/icons/closeText.svg);
        width: 27px;
        height: 27px;
        cursor: pointer;
    }
}

.reviewerWrap {
    border-top: 0.5px solid rgba(22, 112, 146, 0.5);
    margin-top: 30px;
    padding-top: 40px;
    & .commanTitle02 {
        margin-bottom: 15px;
        display: block;
    }
    & .customCheck {
        margin-bottom: 25px;
    }
}
.reviewerFormArea {
    padding: 0 40px;
    position: relative;
    display: inline-block;
    width: 100%;
    &.recommendArea .reviewrHeading {
        padding-top: 12px;
        padding-bottom: 5px;
    }
    & textarea.form-control {
        border: 1px solid #858585 !important;
        border-radius: 10px;
        min-height: 100px; 
        &::placeholder {
           
        }
    }
}
.closeRow {
    background-image: url(../img/icons/white-cross.svg);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: $color1;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    background-position: center;
    background-size: 7px;
    background-repeat: no-repeat;
}
.reactSelect-container.reactSelect--is-disabled .reactSelect__dropdown-indicator { 
    display: none; 
}
.reviewerFormWrap {
    display: flex;
    align-items: start;
    justify-content: space-between;
    // margin-bottom: 50px;
    margin-bottom: 12px;
  
    & .reviewerFormLeft {
        width: 48%;
        &.reviewerTitle {
            display: flex;
            & .reviewerFormLeftBox{
                width: 232px;
                padding-right: 15px;
            }
            & .reviewerFormRightBox {
                width: 100%;
            }
        }
    }
    & .reviewerFormRight {
        width: 48%;
    }

    & .reactSelect-container.reactSelect--is-disabled .reactSelect__control {
        // background: #F3F3F3 !important;
        // border-color: #F3F3F3 !important;
        pointer-events: none; 
    }
    & .reactSelect-container.reactSelect--is-disabled .reactSelect__dropdown-indicator { display: none; }
    & .custom_input input:read-only {
        // background: #F3F3F3 !important;
        // border-color: #F3F3F3 !important;
        pointer-events: none; 
        &::placeholder{
            opacity: 0.4;
        }
    }
}
.reviewerFormArea textarea.form-control[readonly]
  {
    pointer-events: none; 
    background-color: #F3F3F3;
}

.suggestWrap { 
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: $color1;
    margin-left: 40px;
    margin-top: 10px;
    & a {
        cursor: pointer;
        text-decoration: none;
        text-transform: uppercase;
        &:hover {
            color: #167092;
        }
    }
}

.articlePopupContent {
    background: rgba(64, 64, 64, 0.05); 
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    & .articleTitle {
        font-size: 18px;
    }
    & .content {
        font-weight: 400;
        font-size: 16px;
        font-family: $familySecondary, $fallbackfont;
        margin-top: 5px;
    }
}

.statementsWrap {
    display: flex;
    & .statementsLeft {
        width: 100%;
        padding: 30px 40px 79px 20px;
        border-right: 0.5px solid rgba(22, 112, 146, 0.5);

    }
    & .statementsRight {
        width: 100%;
        padding: 30px 20px 0 40px;
        & ul {
            padding-left: 22px;
            padding-top: 15px;
            & li {
                padding: 10px 0;
                & a{
                    font-weight: 600;
                    font-size: 16px;
                    text-decoration: none;
                }
            }
        }
        & .copyright {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #858585;
            padding-left: 7px;
        }

    }
}
.statements { 
    margin-bottom: 30px;
    & strong {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 20px;
        display: block;
    }
    & textarea,
    & textarea.form-control {
        background: #FFFFFF;
        border: 0.5px solid #989898;
        border-radius: 10px;
        padding: 14px;
        width: 100%;
        resize: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        min-height: 110px;
        color: #000000 !important;
    }
    & .regardingChklist {
        padding-left: 25px;
    }
    & .regardingRadio label b {
        text-transform: none;
        font-size: 16px;
    }
    & .customCheck i {
        top: 50%;
        transform: translateY(-50%);
    }
}

.sortingWrap {
    cursor: pointer;
    & .sort {
        width: 8px;
        height: 12px;
        background: url(../img/icons/sorting.svg);
        display: inline-block;
        margin-left: 5px;
    }
    & span {
        position: relative;
        display: flex;
        align-items: center; 
        white-space: nowrap;
    }
}

.articleHead {
    margin-bottom: 30px;
    margin-top: 30px;
    & h2{
        font-weight: 700;
        font-size: 25px;
        color: $color2;
        margin-bottom: 2px;
    }
    & p {
        font-weight: 500;
        font-size: 18px;
        color: $color_gray;
    }
}

.lastBrowsed {
    position: relative;
    padding: 25px 0;
    margin: 40px 0 10px 0;
    &::before{
        content: '';
        position: absolute;
        background: linear-gradient(96.69deg, #EAE788 3.08%, #FDC147 96.44%);
        left: -56px;
        top: 0;
        width: calc(100% + 132px);
        opacity: 0.6;
        height: 100%;

    }
    & .lastBrowsedHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding: 0 20px;
        & h2{
            font-weight: 700;
            font-size: 20px;
            color: #009B94;
            position: relative;
            margin-bottom: 0;
        }
        & .button-primary {
            min-width: 150px;
        }
    }
    & .recommendedBox .recommendedContent p { max-width: none;}
}

.opacity5 {
    opacity: .5;
    pointer-events: none;
}

.manageEditorInner .col-lg-8 .editEditorLabel b{
    color: $color2 !important;
    // font-family: "Inter", sans-serif !important;
    // font-family: "calibriregular" !important;
    font-family: "Calibri", "Open Sans" !important; 
}
.manageEditorBox .fieldMore a { font-family: "Inter", sans-serif !important; }
.editEditorLabel {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    & b {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: $color2 !important;
        padding-right: 6px;
    }
    & i {
        background: #009B94 url(../img/icons/edit-pen.svg);
        width: 22px;
        height: 22px;
        display: inline-block;
        border-radius: 50%;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
    }
}

.abbreviationWrap {
    display: flex;
    align-items: center;
    width: 100%;
    & .fieldWrap {
        width: 100%;
        &:first-child {
            width: 100px;
            margin-right: 12px;
        }
    }
}


.abbreViewWrap {
    position: relative; 
}
.abbreView{
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #009B94;
    position: relative;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 10px;
    display: inline-block;
    & b {
        font-weight: 600; 
        padding: 0;
        display: inline-block;
        line-height: 18px; 
        &:first-child {
            padding-right: 10px;
             
            margin-right: 10px;
            position: relative; 
            &::before {
                content: '';
                background: #009B94;
                width: 1px;
                height: 14px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}
.docShowBox {
    display: block;
    & .docShowList {
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px; 
        margin-top: 20px;
        display: inline-block;
        border: 0.5px solid #009B94;
        color: #009B94;
        border-radius: 10px;
        padding: 6px 10px;
        & img {
            max-width: 12px;
        }
    }
    &.supplimentPreview{
        & .docShowList {
            & a { 
                position: relative;
                text-decoration: none;
                & b {
                    width: 22px;
                    height: 22px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    background: #167092 url(../img/icons/downloadfile.svg);
                    border-radius: 50%;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 12px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                }
            }
        }
        
    }
    &.suppleBox {
 
        & .docShowList { 
            display: inline-block;
            margin-right: 12px;
            &.downloadImg {
                & span {
                    padding-right: 30px;
                }
            }
            & a {
                position: relative;
                display: inline-block;
                padding: 4px 0; 
                color: #009B94;
                // color: #167092;
                text-decoration: none;
                & span {
                    color: #009B94;
                    margin: 0;
                }
                & .imgClose {
                    right: 8px;
                    top: 6px;
                }
                & b {
                    width: 22px;
                    height: 22px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    background: #167092 url(../img/icons/downloadfile.svg);
                    border-radius: 50%;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 12px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                }
            }
        }
    }

}
.font36 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 8px;
}
.writeYourContent {
    text-align: center;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    color: $color3;
    position: relative; 
    padding-top: 30px;
    padding-bottom: 28px;
  
    
    & p {
        max-width: 800px;
        margin: 0 auto 26px;
        & b {
            color: $color3;
            font-weight: 700;
        }
    }
    & .btn-link-active {
        margin: 0 30px;
    }
}
.writeYourTool {
    text-align: left;
    width: 100%;
 
}
.noteText {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: $color3;
    display: block;
    padding: 10px 0;
    & b {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        border: 1px solid #BF1E2E;
        border-radius: 4px;
        padding: 2px 5px;
        color: #BF1E2E;
        margin-right: 8px;
    }
}

.aboutJour {
    text-align: right;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: $color1; 
    & a {
        padding-right: 20px;
        color: #BF1E2E;
        text-decoration: underline;
        &:hover{
            color: #BF1E2E;
        }
    }
   
}

.priceReview {
    display: block;
    border-radius: 10px;      
    margin-top: 22px;
    width: 92%;
    margin: 18px auto;
    position: relative;
    background: rgba(255, 255, 255, 0.13);
    z-index: 0;
    border: 3px solid rgba(64, 64, 64, 0.8);
    
    & ul {
        background: rgba(64, 64, 64, 0.02);
        position: relative;
        z-index: 1;
        border-radius: 10px;
        & li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px;
            position: relative;
            font-size: 16px;
          
            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 2%;
                width: 96%;
                border-bottom: 0.5px dashed rgba(191, 30, 46, 0.34);
            }
            &:last-child{ 
                &::before {
                    display: none;
                }
            }
            & .priceReviewRate {
                font-weight: 800;
                font-size: 25px;
                line-height: 19px;
                color: #a4a4a4;
                & big {                    
                    border-radius: 5px;
                    color: #a4a4a4;
                    font-weight: 800;
                    padding: 6px 0px 6px 8px;
                    display: inline-flex;
                    font-size: 28px;
                }
                & del {
                    position: relative;
                    text-decoration: none;
                    &::before {
                        content: "";
                        position: absolute;
                        left: -4px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: calc(100% + 8px);
                        height: 2px;
                        background: #404040;
                    }
                }
            }
            & .priceReviewImg { 
                display: flex;
                align-items: center;
                color: #a4a4a4;
                text-transform: uppercase;
                font-weight: 800;
                font-size: 18px;
                & i {
                    display: inline-block;
                    width: 21px;
                    height: 26px;
                    border-radius: 50%; 
                    margin-right: 10px;
                    background-position: center;
                    background-repeat: no-repeat; 
                    &.priceIcon{
                        background-image: url(../img/icons/listprice_disable.svg);
                    }
                    &.saveIcon{
                        background-image: url(../img/icons/save_disable.svg);
                        background-size: contain;
                    }
                    &.roducIcon{
                        background-image: url(../img/icons/reduse_disable.svg);
                    }
                }
                
            }
            &.saveprice {
                color: #a4a4a4;
                font-weight: 600;
                & .priceReviewRate {
                    color: #a4a4a4;
                    font-weight: 800;
                }
            }
        }
    }
}

.recommendArea {
    & .form-control:read-only {
 
        background: #F3F3F3 !important;
        border-color: #F3F3F3 !important; 
    }
    & .reviewrHeading {
        position: relative;
        padding-top: 40px;
        padding-bottom: 15px;
        &:before {
            content: '';
            background: url(../img/icons/border.png);
            width: 100%;
            height: 1px;
            position: absolute;
            left: 0;
            top: 0;
             
        }
        & .commanTitle02 {

        }
    }
    & .removeAuthors i {
        top: -34px;

    }
}
.mandatorySup {
    padding-left: 18px;
}
.mandatoryAdd {
    margin-left: 40px;
}
.reviewPageBox {
    & .commanBox {
        margin-bottom: 25px;
    }
    &.reviewArticleReport {
        & .commanBox {
            padding: 0;
            border: 0;
            box-shadow: none;
        }
        & .reviewContent {
            & strong {
                margin-bottom: 5px;
            }
        }
    }
}
.commanBox.references p{
    margin-bottom: 30px;
}
.publiceUiBox {
    counter-reset: figures;
    & .previewBox {
        counter-reset: tableCount;
        & table {
            counter-increment: tableCount;
            position: relative;
            margin-bottom: 34px;        
        }
        & table:after {
            content: 'Table ' counter(tableCount);
            color: #ff8300;
            margin-bottom: 5px; 
            position: absolute;
            bottom: -30px;
            transform: translateX(-50%);
            left: 50%;
            font-weight: 600;
        }
    }
}
.pageRight {
    & .col-lg-8{
        counter-reset: tableCount;
        & table {
            counter-increment: tableCount;
            position: relative;
            margin-bottom: 34px;        
        }
        & table:after {
            content: 'Table. ' counter(tableCount);
            color: #FF8300;
            margin-bottom: 5px;
            position: absolute;
            bottom: -30px;
            left: 0;
            font-weight: 600;
        }
    }
}
.reviewArticleReport {
    counter-reset: figures;
    
    & .commanBox {
        padding: 0;
        border: 0;
        box-shadow: none;
        
    }
    & .reviewContent {
        & strong {
            margin-bottom: 5px;
        }
    }
    
    & figure {
        counter-increment: figures;
        padding-bottom: 34px;
        margin-bottom: 0;
        position: relative;
        text-align: center;
    }
    & figure:after {
        content: 'FIGURE. ' counter(figures);
        color: #FF8300;
        margin-bottom: 5px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 600;
    }
}

.reviewContent {
    & strong {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #FF8300;
        margin-bottom: 15px;
        display: block; 
        & i {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #404040;
            font-style: normal;
        }
    }

    & p {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $color_gray; 
    }
}

.reviewMaterial {
    & span {
        display: block;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #167092;
        margin: 10px 0;
        & i {
            cursor: pointer;
        }
    }
}
.button-link.statementBtn {
    line-height: 30px;
    padding: 5px 0;
    display: block;
    cursor: pointer;
    & img {
        position: relative;
        top: -2px;
    }
}
.reviewHead {
    
    padding: 20px 44px;
    background: linear-gradient(270deg, #FEF1D4 0%, #FAF9E2 100.77%);
    & .innerMergin {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    & .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #009B94;
        text-transform: uppercase;

    }
    & .reviewHeadAction {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        & span {
            cursor: pointer;
            color: #404040;
            margin-right: 35px;
            &:last-child{
                margin-right: 0;
            }
           
        }
        & .reportsBtn {
            margin-right: 35px;
            margin-left: 13px;
        }
        & .button-link-active {
            text-decoration: none;            
            margin-right: 22px;
        }
    }
}

.reviewImgWrap {
    & .reviewImg {
        max-width: 102px;
        display: inline-block;
        margin-right: 15px;
        & img {
            max-width: 100%;
        }
    }
}

.abbreTextBox {
    & .abbreTextTitle {
        display: inline-flex;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #009B94;
        border: 0.5px solid #989898;
        border-radius: 5px;
        padding: 9px;
        margin-bottom: 10px;
        margin-right: 8px;
        & span {
            word-break: break-word;
            &:first-child {
                text-transform: uppercase;
                position: relative;
                border-right: 1px solid #A7A7A7;
                padding-right: 8px;
                margin-right: 8px;
                line-height: 12px;
                min-width: 50px;
            }
        }
    }
}

.KeywordsTagWrap {
    & span {
        font-weight: 600;
        font-size: 16px;
        line-height: 14px;
        color: #009B94;
        border-right: 0.5px solid #989898; 
        padding-right: 6px;
        margin-right: 6px;
        display: inline-block; 
        word-break: break-all;
        margin-top: 5px;
        margin-bottom: 5px;
        &:last-child{
            border-right: 0;
        }
    }
}

.review-report-content {
    color: #404040;
    background: rgba(0, 0, 0, 0.04);
    padding: 20px;
}
 .customSliderWrap {
    position: relative;
    margin-bottom: 35px;
    color: #000000;
    & .left {
        position: absolute;
        left: 0;
        bottom: -30px;
        font-style: normal;
    }
    & .right {
        position: absolute;
        right: 0;
        bottom: -30px;
        font-style: normal;
    }
    & .rangeslider-horizontal .rangeslider__fill { 
        background: linear-gradient(90.19deg, #EEDD79 0%, #FDC047 73.92%) !important;
        box-shadow: none;
    }
    & .rangeslider-horizontal .rangeslider__handle:after {
        display: none;
    }
    & .rangeslider-horizontal .rangeslider__handle {
        width: 18px;
        height: 18px;
        box-shadow: none;
        background: #167092;
        border: 0;
    }
    & .rangeslider {
        box-shadow: none;
        background: #EBF3F8;
    }
 }

 .sliderMainWrap {
    
    background: #FFFFFF;
    border: 1px solid rgba(22, 112, 146, 0.6);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 24px;
    & h3 {
        line-height: 19px;
        font-weight: 700;
        font-size: 20px;
        color: #FF8300;
        text-transform: uppercase;
        border-bottom: 0.5px solid rgba(22, 112, 146, 0.5);
        margin-left: -20px;
        width: calc(100% + 40px);
        padding: 0 0 12px 20px;
        & i {
            font-size: 14px;
            font-style: normal;
            text-decoration: none;
            color: #404040;
            text-transform: initial;
        }
    }
 }
 .wordLimit {
     
    margin-top: 20px; 
    & strong {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 15px;
        display: block;
        & i {
            font-weight: 400;
            font-style: normal;
        }
    }
    & .wordLimitList {
        margin-bottom: 15px;
        & p{

        }
        & .inputField {
            margin-top: 10px;
        }
    }
 }

 .conflictWrap { 
    margin-top: 20px;
    // margin-bottom: 20px;
    // padding-bottom: 20px;
    & strong {
        font-weight: 500;
        font-size: 16px;
        color: #000000;
        & i {
            font-weight: 400;
            font-size: 14px;
            display: block;
            font-style: normal;
        }
    }
    & .inputField {
        margin-top: 10px;
        margin-bottom: 10px;
    }
 }

 .submitArticleSub {
    text-align: left;
    & strong {
        font-weight: 500;
        color: #000000;
    }
 }

 .checkResultText{
    text-align: left;
    padding: 0 28px;
    & strong {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        display: block;
        margin-bottom: 10px;
    }
    & p {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 10px;
    }
 }

 .needHelpText {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: #167092;
    text-decoration: none;
    margin-right: 30px;
 }

 .profanity-content-right {
    & .modal-icon {
        display: none;
    }
 }
 .btn-link-gray {
    font-weight: 500;
    cursor: pointer;
    &.bold{
        font-weight: 700;
    }
 }

 .preeReview {
    display: block;
    padding: 0 30px;
    & b {
        font-weight: 500;
        font-size: 22px;
        line-height: 27px;
    }
    & p {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #000000;
        & a {
            text-decoration: none;
           
            font-size: 18px;
            line-height: 25px;
            & b {
                
                font-size: 18px;
            }
        }
    }
 }

 .similarityText {
    & p {
        text-align: left;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
    }
 }
.profanityBlink {
    cursor: pointer;
}
 .editCenterWrap {
    display: flex;
    & .blinkText {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #BF1E2E;
        margin-left: 6px;
        & i {
            font-style: normal;
        }
    }

 }

 .blink_me {
    animation: blinker 1s linear infinite;
  }
  
  @keyframes blinker {  
    50% { opacity: 0; }
  }
  
.viewContentHead {
    background: #fcf4d9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    & .leftContentHead {
        display: flex;
        align-items: center;
        & h3 {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: #FF8300;
        }
        & .lisnkListWrap {
            display: flex;
            & .lisnkListBox {
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                }
            }
        }
    }
}


.createCitationAlertPopup {
    & h2 {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        color: #FF8300;
        text-align: left;
        margin-top: 16px;
        margin-bottom: 20px;
    }
    & .defaultInput .defaultFeild {
        padding: 10px 16px;
    }
    & .citationButton {
        margin-top: 25px;
    }
   
}
.mandatoryCount {
    text-align: left;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #009B94;
    width: 100%;
}
.defaultLable {
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}
.urlPopup {
    border-bottom: 0.5px solid #A7A7A7;
    border-top: 0.5px solid #A7A7A7;
    margin-top: 25px;
    padding-bottom: 25px;
}
.createCitationText {
    padding: 0 40px;
    
}
.copyInput {
    position: relative;
    & i {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        font-style: normal;
        position: absolute;
        right: 20px;
        top: 12px;
        padding-left: 30px;
        color: #167092;
        border-left: 1px solid #A7A7A7;
        &::before{
            content: '';
            position: absolute;
            left: 10px;
            top: 0;
            width: 20px;
            height: 15px;
            background: url(../img/icons/copy.svg);
            background-repeat: no-repeat;
        }
    }
}

.articleDetailsPopup {
    text-align: left;
    background: rgba(0, 0, 0, 0.05);
    padding: 20px;
    border-radius: 10px;
    margin-top: 15px;
    width: 94%;
    margin-left: 3%;
    & p{
        font-weight: 400;
        font-size: 16px;
            color: #000000;
    }
    & .commanTitle02 {
        margin-top: 20px;
        display: block;
        margin-bottom: 10px;
    }
    & .articleDetailsPopupHead {
        margin-bottom: 15px;
        & strong {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #000000;
            display: block; 
        }
        & i {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #FF8300;
            font-style: normal;
        }
    }
    & .articleDetailsPopupHeadContent {
        
        & b {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #404040;
        }
         
    }
    & .articleDetailsPopupContent {
        margin-top: 19px;
        
        padding-top: 15px;
        position: relative;
        &::before{
            content: '';
            background: url(../img/icons/border.png);
            width: 100%;
            height: 1px;
            position: absolute;
            left: 0;
            top: 0;
        }
        & .footTag {
            margin-top: 10px;
            & .t-tag {
                font-weight: 400;
            }
        }
    }


}

.questionBox {
    &.reddot{
       
        & span {
            padding-left: 16px;
            position: relative;
            display: block;
            font-weight: 500;
            font-size: 16px;
            color: #167092;
            &::before {
                content: "";
                position: absolute;
                left: 0px;
                top: 7px;
                background: #be1e2d;
                width: 8px;
                border-radius: 10px;
                height: 8px;
                display: inline-block;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
    }
    & span {
        padding-left: 20px;
        position: relative;
        display: block;
        font-weight: 500;
        font-size: 16px;
        color: #167092;
       
        &.colorBlack{
            color: #000000;
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 4px; 
            background: url(../img/icons/errorqoestion.svg);
            width: 16px;
            height: 15px;
            display: inline-block;
            background-repeat: no-repeat;
            background-size: cover; 
        }
    }
    & p {
        margin-top: 16px;
        color: #404040;
        & sup {
            font-size: 18px;
            position: relative;
            top: 0;
        }
    }
}
.supStar { 
    font-size: 18px;
    position: relative;
    top: 0;
    &.blackStar{
        color: #000000;
    }
}
.mandatoryText {
    font-size: 16px;
    color: #BE1E2D;
}
.updatePaymentmsg { border-top: 0.5px solid rgba(22, 112, 146, 0.5);     margin-left: -25px;
    padding: 21px 10px 10px 26px;
    width: calc(100% + 50px); }
.reportCheck {
    & .customRadio {
        display: inline-block;
        margin-right: 24px;
    }
}

.commanBoxHead {
    background: #FFFFFF;
    border: 1px solid rgba(22, 112, 146, 0.6);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 24px;
    & h3{
        font-weight: 700;
        font-size: 20px;
        color: #FF8300;
        text-transform: uppercase;
        border-bottom: 0.5px solid rgba(22, 112, 146, 0.5);
        margin-left: -20px;
        width: calc(100% + 40px);
        padding: 0 0 12px 20px;
    }
}

.viewHeader {
    background: linear-gradient(270deg, #FEF1D4 0%, #FAF9E2 100.77%);
    padding: 0 25px;
    & .viewHeaderBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & h3 {
            font-weight: 700;
            font-size: 22px;
            line-height: 27px;  
            text-transform: uppercase; 
            color: #009B94;
        }
        & span {
            font-weight: 700;
            font-size: 22px;
            line-height: 27px;  
            text-transform: uppercase; 
            color: #009B94;
            cursor: pointer;
        }
    }
}
.viewArtileReportWrap {
    margin: 0 auto;
    max-width: 1024px; 
    & .viewArtileSummary { 
        & h3 {
            display: block;
            font-weight: 700;
            font-size: 20px;
            color: #FF8300;
            text-transform: uppercase;
            border-bottom: 0.5px solid rgba(22, 112, 146, 0.5);
            padding: 20px;
            position: relative;
            & .arrowButton {
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
            }
            & i {
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #404040;
                text-transform: none;
            }
        }
        & ul {
            padding: 20px;
            & li {
                margin-bottom: 30px;
                list-style-type: none;
                & p {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    color: #000000;
                }
                & .inputCheck {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    color: #009B94;
                    margin-top: 14px;
                }
                & .inputDetails {
                    background: #F3F3F3;
                    border-radius: 10px;
                    display: block;
                    padding: 16px;
                    margin-top: 14px;
                }
            }
        }
        & .customSliderWrap {
            width: 60%; 
            margin: 0 0 45px 22px;
        }
    }
}

.publishingWrap {
    border-top: 0.5px solid #A7A7A7;
    padding-top: 30px;
    & textarea {
        background: #FFFFFF;
        border: 0.5px solid #989898;
        border-radius: 10px;
        width: 100%;
        padding: 10px;
        margin-top: 20px;
    }
}
.viewReportWrap {
    border-bottom: 0.5px solid #A7A7A7;
    width: 100%;
    
    padding: 30px 40px 15px 40px;
    background: #ffffff;
    // display: none;
    & ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1440px;
        margin: 0 auto;
        & li {
            list-style: none;
            border-left: 0.5px solid #A7A7A7;
            padding-left: 20px;
            &:first-child {
                border: 0;
                padding-left: 0;
            }
            & span {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #404040;
                display: block;
                &.titleReport {
                    color: #FF8300;
                }
            }
            & a {
                font-weight: 700;
                font-size: 16px;
                text-transform: capitalize;
                color: #167092;
                cursor: pointer;
            }
            & strong {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #000000;
            }
        }
    }
}

.footViewArticle {
    display: flex;
    align-items: center;
    justify-content: end;
    background: #FFFFFF;
    border: 0.5px solid #167092;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 32px 44px;
    & span {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        margin-right: 15px;
        cursor: pointer;
        & .button-gray {
            color: #404040;
        }
    }
}

.articleRightBtn {
    text-align: center;
}
.helpPopupText { 
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    border-top: 0.5px solid #A7A7A7;
    padding-top: 17px;
    margin-top: 10px;
    width:100%;
    &.paymentTile {
        & span {
            color: #167092;
            font-weight: 700;
        }
    }
    & a {
       color: #167092;
       font-weight: 700;
       padding-left: 6px; 
    }
    & .helpPopupList {
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style-type: none;
        max-width: 450px;
        margin: 0 auto;
        & a:hover {
            color: #167092;
        }
        & li {
            &:first-child{
                & a {
                    &::before {
                        display: none;
                    }
                }
            } 
            & a {
                padding-left: 10px;
                position: relative;
                text-decoration: none;
                &:first-child{
                    padding-left: 0;
                }
                &::before{
                    content: '';
                    width: 6px;
                    height: 6px;
                    background: #FF8300;
                    border-radius: 4px;
                    position: absolute;
                    left: -16px;
                    top: 50%;
                    transform: translateY(-50%); 
                }
            }
        }
    }
}

.previewHero {
    // height: 265px;
    width: 100%;
    background: #f1f1f1;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 78px;
    & h3 {
        position: absolute;
        left: 45px;
        bottom: 40px;
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        text-transform: uppercase;
        color: #ffffff;
        opacity: 0;
    }
}

.previewTags {
    border-bottom: 0.5px solid #A7A7A7;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    & ul {
        display: flex;
        & li {
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            color: #000000;
            position: relative;
            list-style: none;
            margin-right: 55px;
            font-weight: 500;
            &::before{
                content: '';
                position: absolute;
                right: -27px;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 12px;
                background: #A7A7A7;
            }
            &:last-child{
                margin-right: 0;
                &::before{ 
                    display: none;
                }
            }
        }
    }
}

.publiseTitle {
    padding: 12px 90px;
    font-weight: 700;
    font-size: 22px;
    color: #FF8300;
}

.publiseTags {
    display: block;
    background: rgba(0, 155, 148, 0.06);
    padding: 0 90px;
    & span {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #167092;
        display: inline-flex;
        padding: 10px 10px 10px 0px;
        margin-right: 35px;
        position: relative;
        &:last-child{
            &::before{ 
                display: none;
            }
        }
        &::before{
            content: '';
            width: 1px;
            height: 12px;
            background: #A7A7A7;
            top: 50%;
            right: -18px;
            position: absolute;
            transform: translateY(-50%);
        }
        & b {
            background: url(../img/icons/id.svg);
            width: 19px;
            height: 19px;
            margin-left: 8px;
        }
    }
}

.publishedBox {
    border-bottom: 0.5px solid #A7A7A7;
    padding: 15px 44px;
    & .innerMergin {
        
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
   
        display: flex;
        font-weight: 400;
    }
    & i {
        font-style: normal;
        position: relative;
        margin-right: 46px;
        &::before{
            content: '';
            position: absolute;
            right: -23px;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 12px;
            background: #a7a7a7;
        }
    }
    &.stylechange{
        padding: 29px 0px;
        & a {
           
            &::before{
                background: url(../img/icons/url_icon_orange.svg);
               
            }
        }
    }
    & a {
        text-decoration: none;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        padding-left: 16px;
        position: relative;
        &::before{
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 14px;
            height: 11px;
            background: url(../img/icons/url_icon.svg);
            background-repeat: no-repeat !important;
        }
    }
    & span {
        font-weight: 600;
    }
    & .viewcommentline{
        a{
            font-size: 15px;
        }
    }
}

.publisActionWrap {
   
    padding: 10px 44px;
    border-bottom: 0.5px solid #A7A7A7;
    & .innerMergin {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    & .publisActionBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
        & ul{
            display: flex;
            & li{
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                text-transform: uppercase;
                color: #167092;
                list-style: none;
                cursor: pointer;
                margin-right: 45px;
                position: relative;
               &:hover{
                & .publisNav {
                    display: block;
                    
                }
               }
                &:last-child{
                    &::before{ 
                        display: none;
                    } 
                }
                &::before{
                    content: '';
                    position: absolute;
                    right: -25px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 12px;
                    background: #a7a7a7;
                }
                & img {
                    vertical-align: middle;
                    display: inline-block;
                }
                & i {
                    font-style: normal;
                    padding-left: 4px;
                    vertical-align: middle;
                }
               
            }
        }
        
    }
}
.megaAlign {
    p {
        margin-bottom: 20px;
    }
}
.shareLogo {
    position: relative;
   &:hover{
  
   
   & .publisNav {
    display: block;
    left: auto;
    right: 0;
   }
} 
}
.publisNav {
    display: none;
    position: absolute; 
    left: 0;
    top: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.14);
    border-radius: 10px;
    min-width: 156px;
    z-index: 1;
    & button {
        display: block;
        border-bottom: 0.5px solid rgba(167, 167, 167, 0.14) !important;
        width: 100%;
        & a {
            text-transform: capitalize;
        }
    } 
    & a {
        border-bottom: 0.5px solid rgba(167, 167, 167, 0.14);
        padding: 9px 10px;
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #167092;
        text-transform: capitalize;
        &:not([href]) { 
            color: #167092 !important;  
        } 
        & b {
            width: 30px;
            height: 30px;
            background: #FFFFFF;
            border: 0.5px solid #167092;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
        }
        &:last-child {
            border-bottom: 0;
        }
    }
}

.publiceDetailsWrap {
    margin-top: 20px;
    & ul {
        display: flex;
        background: #F8FAFA;
        border: 0.5px solid #404040;
        border-radius: 12px;
        padding: 12px;
        justify-content: space-between;
        & li {
            list-style: none;
            
            & p {
                text-align: center;
                & span {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;                    
                    color: #404040;
                    display: block;
                    padding-bottom: 4px; 
                }
                & b {
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 27px; 
                    color: #16ACE4;
                }
            }
        }
    }
}
.publiceUiBox{
    padding-left: 30px;
}
.previewMain {
    padding: 0 90px;
    & .labelForm {
        font-weight: 600;
    }
    & figure {
        text-align: center;
        & figcaption {
            padding-right: 20px;
            &::before{
                left: 50%;
                transform: translateX(-50%);
            }
            text-align: center;
        }
    }
}
 
.modal-header .imgDownloadBtn .btn-close { color: #404040; }

.previewBox {
    margin-top: 48px;
    counter-reset: figures;


    & figure {
        counter-increment: figures;
        padding-bottom: 34px;
        margin-bottom: 0;
        position: relative;
    }
    & figure:after {
        content: 'FIGURE ' counter(figures);
        color: #FF8300;
        margin-bottom: 5px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 600;
        color: #FF8300;
    }
    & div,
    & p,
    & li,
    & span {
        font-size: 16px !important;
        color: #000000 !important;
        // font-family: 'calibriregular'  !important;
    }
    & table {

    }
}
.manageEditorInner {
    & .col-lg-8 {
        // font-family: 'calibriregular' !important;
        & .form-control {
            // font-family: 'calibriregular' !important;  
            font-family: "Calibri", "Open Sans" !important; 
        }
        & p {
            font-weight: 400;
            font-size: 14px !important;
            // font-family: 'calibriregular' !important;
            font-family: "Calibri", "Open Sans" !important; 
            color: #000 !important;
            margin: 0 0 15px;
            & span{
                // font-family: 'calibriregular' !important;
                font-family: "Calibri", "Open Sans" !important; 
                color: #000 !important;
                font-size: 46px !important;
            }
            & b {
                // font-family: 'calibriregular' !important;
                font-family: "Calibri", "Open Sans" !important; 
                color: #000 !important;
                font-size: 14px !important;
            }
            & i {
                // font-family: 'calibriregular' !important;
                font-family: "Calibri", "Open Sans" !important; 
                color: #000 !important;
                font-size: 14px !important;
            }
        }
        & div {
            font-weight: 400;
            font-size: 14px !important;
            // font-family: 'calibriregular' !important;
            font-family: "Calibri", "Open Sans" !important; 
            color: #000 !important;
            & label.labelFormTwo{
                // font-family: "Inter", sans-serif !important;
                // font-family: 'calibriregular' !important;
                font-family: "Calibri", 'Open Sans';
            }

            & span{
                // font-family: 'calibriregular' !important;
                font-family: "Calibri", "Open Sans" !important; 
                color: #000 !important;
                font-size: 14px !important;
            }
            & b {
                // font-family: 'calibriregular' !important;
                color: #000 !important;
                font-size: 14px !important;
            }
            & i {
                // font-family: 'calibriregular' !important;
                font-family: "Calibri", "Open Sans" !important; 
                color: #000 !important;
                font-size: 14px !important;
            }
        }

        & .label-error {
            text-align: left !important;
            font-size: 14px !important;
            color: #be1e2d !important;
            margin-top: 5px !important;
            font-family: "Inter", sans-serif !important;
        }

    }
}
.sun-editor .se-tooltip .se-tooltip-inner .se-tooltip-text,
.sun-editor .se-tooltip .se-tooltip-inner .se-tooltip-text .se-shortcut { color: #ffffff !important; }
.contentPreview {
    margin-bottom: 30px;
    & h2{
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #FF8300;
        margin-bottom: 5px;
        font-family: "Inter", sans-serif;
    }
    & p {
        font-weight: 400;
        margin-bottom: 12px;
        font-size: 14px !important;
        // font-family: 'calibriregular' !important;
        color: #000 !important;
        & span{
            // font-family: 'calibriregular' !important;
            color: #000 !important;
            font-size: 14px !important;
        }
        & b {
            // font-family: 'calibriregular' !important;
            color: #000 !important;
            font-size: 14px !important;
        }
        & i {
            font-family: 'calibriregular' !important;
            color: #000 !important;
            font-size: 14px !important;
        }
    }
    & div {

        font-weight: 400;
        text-align: justify;
        font-size: 14px !important;
        // font-family: 'calibriregular' !important;
        color: #000 !important;
        & span{
            // font-family: 'calibriregular' !important;
            color: #000 !important;
            font-size: 14px !important;
        }
        & b {
            // font-family: 'calibriregular' !important;
            color: #000 !important;
            font-size: 14px !important;
        }
        & i {
            font-family: 'calibriregular' !important;
            color: #000 !important;
            font-size: 14px !important;
        }
    }
    & table {
        & p {
            text-align: left;
          }
    }
    &.amendments {
        background: #FFF3E5;
        border: 1px solid rgba(22, 112, 146, 0.6);
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
        border-radius: 20px;
        padding: 20px;
        & h2{ 
            color: #009B94;
            & b {
                border: 1px solid #BF1E2E;
                border-radius: 7px;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                padding: 4px;
                color: #BF1E2E;
                text-transform: uppercase;
                margin-right: 10px;
            }
            & span {
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: #000000;
                position: relative;
                margin-left: 36px;
                &::before{
                    content: '';
                    position: absolute;
                    left: -18px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 12px;
                    background: #a7a7a7;
                }
                & i{
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    font-style: normal;
                }
            }
        }
        & p{
            margin-top: 15px;
        }
    }
    &.leftSideContent{
        & p {
            font-family: "Inter", sans-serif !important;
            font-size: 16px !important;
        }
    }
}
.commanBox.copyInfoMain {
    margin-bottom: 25px;
    padding: 15px 20px;

}
.copyInfo {
    display: flex;
    cursor: pointer;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: #167092;
    font-weight: 700;
    & img {
        margin-right: 8px;
    }
}

.adSport {
    background: rgba(0, 155, 148, 0.04);
    border: 1px solid rgba(0, 155, 148, 0.3);
    border-radius: 20px;
    text-align: center;
    min-height: 768px;
    margin-bottom: 20px;
    overflow: hidden;
    & .demoImg {
        margin-top: 50px;

    }
}

.browseArticles {
    background: linear-gradient(180deg, rgba(225, 233, 236, 0.24) 83.33%, #FFFFFF 100%);
    border-radius: 20px;
    padding: 22px; 
    margin-bottom: 20px;
    & .title {
        font-weight: 700;
        font-size: 18px;
        color: #FF8300;
        margin-bottom: 15px;
        display: block;
    }
    & .recommendedBox  {
        margin: 0 0 25px 0;
        width: 100%;
    }
}

.ctImageWrap {
    text-align: center;
    & figure {
        margin: 0 auto;
    }
    & p {
        display: block;
        margin-bottom: 20px;
        color: #404040;
        font-weight: 600;
        & span {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #FF8300;

        }
    }
}

.ctImageWrapLeft {
    display: flex;
    margin-top: 15px;
    & figure {
        width: 220px;
        margin-right: 10px;
        & img {
            max-width: 100%;
        }
    }
    & .imgDetails {
        display: inline-block;
        width: calc(100% - 225px);
        & .figureImg {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #404040;
            & span {
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: #FF8300;
            }
        }
        & p {
            margin-top: 15px;
            & span{
                display: block;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #FF8300;
            }
        }
    }
}

.citeModalWrap {
    text-align: left;
    & .citeText {
        background: rgba(0, 155, 148, 0.06);
        border-radius: 10px;
        padding: 12px 18px;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #000000;
    }
    & .questionBox {
        margin-top: 10px;
    }
}

.imgDownloadHeader.modal-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
    & h5{
        font-weight: 700;
        font-size: 18px;
        line-height: 22px; 
        text-transform: capitalize;
        color: #FF8300;
    }
    & .btn-close {
        position: relative;
        top: auto !important;
        right: auto;
        background-image: none;
        width: auto;
        margin-right: 30px;
        font-weight: 700;
        font-size: 16px;
        min-height: 21px;
    }
    & .button-link {
        cursor: pointer;
    }
}
.imageDownloadWrap {
    text-align: left;
    padding-bottom: 20px;
    padding-left: 14px;
    padding-right: 14px;
    & figure {
        text-align: center;
        & img {
            max-width: 100%;
            border-radius: 4px;


        }
    }
    & span {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #009B94;
        display: block;
        margin-bottom: 15px;
    }
    & p{
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
    }
}

.submitReviewPage {
    margin-top: 100px;
    background: #ffffff;
}
.reasonBg {
    background: rgba(0, 155, 148, 0.06);
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px; 
    color: #009B94;
    text-align: left;
    padding: 6px 20px;
    min-width: 210px;
    display: inline-block; 
    line-height: 20px;
    width: 100%;
    &.colorBlack{
        font-weight: 400;
        color: #000000;
    }
}

.landingFooter {
    display: flex;
    align-items: center;
    justify-content: end;
    background: #FFFFFF;
    border: 0.5px solid #167092;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 14px 30px;
}

.attentionText {
    text-align: left;
    max-width: 76%;
    width: 100%;
    margin: 0 auto;
    font-family: $familySecondary, $fallbackfont;
    & p {
        margin: 20px 0;
        font-family: $familySecondary, $fallbackfont;
        &:first-child{
            margin-top: 0;
        }
        &:last-child{
            margin-bottom: 10px;
        }
    }
}

.similarityText {
    text-align: left;
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    & a{
        text-decoration: none;
        font-weight: 700;
        text-transform: capitalize;
    }
    & p {
        margin: 20px 0;
        &:first-child{
            margin-top: 0;
        }
        &:last-child{
            margin-bottom: 10px;
        }
    }
}

.congratulationsPopup {
    text-align: left;
    max-width: 90%;
    width: 100%;
    margin: 0 auto;
    // font-family: $familySecondary, $fallbackfont;
    & h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        color: #009B94;
        text-align: center; 
    }
    & a{
        text-decoration: none;
        font-weight: 700;
    }
    & p {
        margin: 20px 0;
        color: #000000;
        font-family: $familySecondary, $fallbackfont; 
        &:first-child{
            margin-top: 0;
        }
        &:last-child{
            margin-bottom: 10px;
        }
    }
}

 


.preeReviewPopup {
    text-align: left;
    max-width: 90%;
    width: 100%;
    margin: 0 auto;
    &.postReviewPopup{
        max-width: 86%;
    }
    & .congText {
        font-size: 20px; 
        font-weight: 700; 
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 15px;
        display: block;
    }
   
    & h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        color: #009B94;
        text-align: center;
    }
    & a{
        text-decoration: none;
        font-weight: 700;
        color: #167092;
        font-family: $familySecondary, $fallbackfont;
    }
    & p {
        margin: 20px 0;
        color: #000000;
        font-family: $familySecondary, $fallbackfont;
        &:first-child{
            margin-top: 0;
        }
        &:last-child{
            margin-bottom: 10px;
        }
    }
}
.dragWrapShow {
    &.active{
        & .dragWrapContent {
            display: block;
        }
        & .dragWrapHead { 
            padding-bottom: 0;
        }
        & b {
            transform: rotate(180deg);
        }
    }
    & .dragWrapHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 16px;
        & h2{
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            color: #FF8300; 
            margin-bottom: 0;
            position: relative;
            
        }
        & b {
            background-color: $color1;
            background-image: url(../img/icons/downarrow.svg);
            border: 1.5px solid $color1;
            box-shadow: none;
            border-radius: 6px;
            width: 30px;
            height: 28px;
            background-position: center;
            margin-left: 16px;
            display: inline-block;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 15px;
            cursor: pointer;
        }
    }  
  
    & .dragWrapContent {
        display: none;
        position: relative;
         
        margin-top: 15px;
        background: #FCFCFC;
        margin-left: -20px;
        width: calc(100% + 40px);
        padding: 10px 20px 20px 20px;
        border-radius: 20px;
        &::before{
            content: '';
            background: rgba(22, 112, 146, 0.5);
            top: 0;
            left: 0px;
            position: absolute;
            width: 100%;
            height: .5px;
        }
        & span {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #009B94;
            margin: 20px 0;
            display: flex;
            align-items: center;
            position: relative;
            cursor: pointer;
            width: 100%;
            min-height: 26px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    } 
    & ul {
        & li {
            list-style-type: none;
            position: relative;
            display: flex;
            align-items: center;
             & span {
                padding-left: 30px;
                margin: 15px 0 !important;
             }
            & i {
                background: #167092 url(../img/icons/edit-pen.svg);
                width: 22px;
                height: 22px;
                display: inline-block;
                border-radius: 50%;
                background-position: center;
                background-repeat: no-repeat;
                cursor: pointer;
                margin-right: 6px;
                position: absolute; 
                left: 0;
            }
            .dragArea {
               
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}



.keyBox.previewKey {
    margin-top: 25px;
    margin-bottom: 25px;
    & .previewKeyArea {
        & .t-tag {
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                &::before{
                    display: none;
                }
            }
        }
    }
    & .t-tag{
        border: 0;
        position: relative;
        margin: 0 8px 0 0;
        padding: 3px 8px 3px 0px;
        &::before{
            content: '';
            position: absolute;
            right: -2px;
            width: 1px;
            height: 11px;
            top: 7px;
            background: #009B94;
        }
    }
}

.abbreviations { 
}


.commonContentScroll{
    position: relative;
    top: -120px;
}
.contentPreview {
    & ul,
    & ol{
        margin: auto;
        padding: 10px 16px;
    }
}
.editor-view-only {
    & .hidden {
        display: none;
    }
    & .fieldWrap .labelFormTwo {
        margin-bottom: 15px !important;
    }
    & .commanBox {
        & .contentPreview {
            padding: 0;
            box-shadow: none;
            border: 0;
            & ul{
                margin: auto;
            }
        }
    }
    & .tagsNewWrap {
        margin-top: 0;
        padding-top: 0;
        border: 0;
    }
    & .contentPreview {
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        border: 1px solid rgba(22, 112, 146, 0.5);
        border-radius: 20px;
        width: 100%;
        padding: 20px;
        position: relative;
        & ul{
            margin: auto;
        }
    }
    & .accordion-body {
        & .fieldWrap {
            & .form-control {
                padding: 15px;
            }
        }
        & .custom_input {
            & .fieldForm {
                padding: 15px;
                height: 13px;
                border-radius: 10px; 
                background: #F3F3F3 !important;
                border-color: #F3F3F3 !important;                
            }
        }
        & textarea.form-control {
            padding: 15px; 
            background: #F3F3F3 !important;
            border-color: #F3F3F3 !important; 
        }
        & .selectBox .reactSelect-container .reactSelect__control {
            background: #F3F3F3 !important;
            border-color: transparent !important;
        
        }
        & .selectBox .css-1xc3v61-indicatorContainer{
            display: none;
        }
    }
    & .contentPreview {
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        border: 1px solid rgba(22, 112, 146, 0.5);
        border-radius: 20px;
        width: 100%;
        padding: 20px;
        position: relative;
    }
    & .custom_input {
        & .fieldForm {
            background: transparent;
            pointer-events: none;
            color: #404040;
            border: 0;
            padding: 0;
            height: 20px;
            border-radius: 0;
            
        }

    }
    & .form-control {
        background: transparent;             
            pointer-events: none;            
            color: #404040;
            min-height: 20px;
            border: 0;
            padding: 0;
    }
    & textarea{
        max-height: none;
    }
    & .labelFormTwo {
        margin-bottom: 0;
    }
   
    & .editEditorLabel {
        & i {
            display: none;
        }
    }
    & .sun-editor-editable {
        height: auto !important;
        pointer-events: none;
        padding: 0;

    }
    & .editorWrap .sun-editor {
        border: 0;
    }
    & .sun-editor .se-toolbar,
    & .se-resizing-bar {
        display: none !important;

    }
    & .imgClose {
        display: none;
    }
    & .dragWrapShow ul li {
        pointer-events: none;
    }
    & .dragWrapShow .dragWrapContent span {
        padding-left: 0px;
    }
    & .dragWrapShow .dragWrapContent span i {
        display: none;
    }
    & .t-tag {
        border: 0;
        position: relative;
        padding: 0;
        margin: 10px 15px  0 0px;
        &:last-child {
            &::before{ 
                display: none;
            }
        }
        &::before{
            content: '';
            background: #009B94;
            height: 12px;
            width: 1px;
            right: -7px;
            position: absolute;
            top: 3px;
        }
    }
    & .fieldMore {
        & a {
            display: none;
        }
    }
    & .col-lg-4{
        & .fieldWrap {
            display: none;
        }
        & .commanBox { 
            & .fileBox {
                display: none;
            }

        }
    }

    & .selectBox .reactSelect-container .reactSelect__control {
        pointer-events: none;
    }
    & input {
        pointer-events: none;
    
    }
    & .customCheck,
    & .regardingRadio label {
        pointer-events: none;
    }

    & .addCitation{ display: none;}

    
}

.authorWrapTitle {
    display: flex;
    align-items: center;
    & .customCheck {
        & i {
            top: 1px;
        }
        & input:checked {
            & i + b {
                color: #009B94 !important;
            }
        }
        & b {
            color: #404040 !important;
        }
    } 
    & .helpText {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-transform: capitalize;
        color: #167092;
        margin-left: 35px;
        position: relative;
        cursor: pointer;
        &::before {
            content: '';
            position: absolute;
            left: -16px;
            top: 2px;
            background: #A7A7A7;
            width: 1px;
            height: 12px;
        }
    }
    & .customTool {
        position: relative;
        top: -3px;
    }
}

.doteBorder {
    background: url(../img/icons/linebox.svg);
    width: 100%;
    height: 2px;
    background-repeat: no-repeat;
     
}
.lableInfoText {
    display: flex;
    align-items: center;
    & .customTool {
        position: relative;
        top: 8px;
        left: -30px;
        text-transform: none;
        & div{
            text-transform: none;
        }
    }
}
.addMargin {
    margin-left: 20px;
}

.radioButtonCustom {
    & .custom_radio {
        left: 0;
    }
    & label {
        padding-left: 0;
    }
    & .custom_radio .form-check-input:checked[type=radio]{
        background-position: -1px -1px !important;
        background-size: 18px !important;
        background-repeat: no-repeat;
        top: -3px;
    }
    & .custom_radio .form-check-input[type=radio]{
        background-position: -1px -1px !important;
        background-size: 18px !important;
        position: relative;
        top: -3px;
    }
}
.infoTextIcon {
    position: relative;
    padding-right: 5px;
    & .customTool {
        position: absolute;
        right: 0;
        top: 5px;
        z-index: 2;
    }
}

.statementsGuide {
    position: absolute;
    bottom: 20px;
    width: calc(100% - 40px);
}

.postReviewI {
    padding-left: 20px;
    font-style: italic;
}

.se-wrapper-inner {
    // font-family: 'calibriregular' !important;
    font-family: 'Calibri' !important; 
    & b,
    & p,
    & ul,
    & li,
    & i,
    & ol,
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6,
    & div {
        // font-family: 'calibriregular' !important;
        font-family: 'Calibri' !important; 
    }
    & table{
        width: 100%;
        border: 1px solid #484848;
        & tr{
            & th,
            & td{
                border-right: 1px solid #484848;
                border-bottom: 1px solid #484848;
                color: #000000;
                &:last-child{
                    border-right: 0;
                }
            }
            &:last-child{
                
            & td{
                border-bottom: 0;
            }
            }
        }
    }
}

.beensave {
    text-align: left; 
    padding: 0 10px;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
    font-family: $familySecondary, $fallbackfont;
     
}

.recivedCheck {
    margin-top: 20px;
    margin-left: 20px;
    & .customCheck b {
        text-transform: none;
    }
}

.fieldMoreArange {
    padding-left: 20px;
}
.contactFounder {
    & .commanTitle02 {
        margin-left: -20px;
    }
}   

.submissionPopup {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
    text-align: left;
    padding: 0 15px;
    & strong {
        font-weight: 500;
        display: block;
        margin-bottom: 16px;
    }
   & p{
    font-family: $familySecondary, $fallbackfont;
    margin-bottom: 16px;
     
   }
}
.submisionHead {
    & span {
        width: 178px;
        display: inline-block;
    }
}

.customAccordion textarea.form-control.addiTextatrea {
    min-height: 172px ;
}
.incompleteText {
    line-height: 20px;
    padding-bottom: 15px;
    padding-top: 10px;
}
.removeAuthors {
    position: relative;
    & i {
        font-style: normal;
        font-weight: 700;
        text-transform: uppercase;
        color: #404040;
        position: absolute;
        right: 0;
        top: 10px;
        cursor: pointer;
    }
}

.otherAuthor {
    position: relative;
    .removeAuthors {
        & i {
            top: -30px;
        }
    }
}
.recommendAuthor {
    position: relative;
    .removeAuthors {
        position: absolute;
        right: 0;
        top: -50px;
        white-space: nowrap;
        & i {
            top: 0px;
        }
    }
}

.radioButtonCustom .hideRadio .custom_radio .form-check-input[type=radio] {
opacity: 0 !important;
}
.editTime {
    position: absolute;
    width: calc(100% - 40px);
    top: -20px;
    left: 0px;
    & label {
        display: none;
    }
    & .saveEdit {
        position: absolute;
        right: 16px;
        font-style: normal;
        top: 27px;
        text-transform: uppercase;
        cursor: pointer;
        color: #167092;
    }
}

.addSctionEditor  {
    & .imgClose {
        background-color: #404040;
        width: 24px;
        height: 24px;
        background-size: 10px;
    }
}

.invalidText {
    text-align: left; 
    max-width: 75%;
    margin: 0 auto 10px;
}

.edtInputEditor {
    position: relative;
    & .edtDone {
        position: absolute;
        right: 18px;
        font-style: normal;
        top: 7px;
        text-transform: uppercase;
        cursor: pointer;
        font-weight: 700;
        color: #167092;
    }
}
.manageEditorInner {
    & .edtInputEditor {
        & .edtDone { 
            font-weight: 700;
            color: #167092 !important;
        }
    }

}

.previewPageWrap  {
    background: #ffffff;
    padding-bottom: 90px;
}
 
.profanityTeatPopup{
    max-width: 90%;
    margin: 6px auto;
    font-size: 18px;
    
    & strong {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px; 
    }
    & p {
        margin-top: 30px;
        font-size: 18px;
        text-align: left;
        & b{
            font-weight: 600;
            font-size: 18px;
            line-height: 19px;  
            color: #BF1E2E;
        }
    }
}

.primaryRepeatWrap {
    position: relative;
        padding-top: 40px;
        padding-bottom: 15px;
        &:before {
            content: '';
            background: url(../img/icons/border.png);
            width: 100%;
            height: 1px;
            position: absolute;
            left: 0;
            top: 0;
        }
    & .removeAuthors {
        top: -60px;
    }
}

.imgControl {
    & .customTool {
        position: relative;
        top: -3px;
    }
}

.articleType-td {
    width: 100px;
}

.vertical-footAction {
    display: block;
    text-align: left;
    & div {
        margin: 15px 0;
    }
}

.t-tag-journalWrap {
    display: block;
    & .t-tag {
        margin-top: 10px;
    }
}

.reportLayout { 
    padding: 0 40px;
    margin-top: 50px;
    counter-reset: TableCount;
    & table {
        counter-increment: TableCount;
        padding-bottom: 34px;
        margin-bottom: 0;
        position: relative;
        text-align: center;
    }
    & table:after {
        content: 'Table. ' counter(TableCount);
        color: #FF8300;
        margin-bottom: 5px;
        position: absolute;
        bottom: -28px;
        left: 0;
        transform: translateX(0%);
        font-weight: 600;
    }

    & .col-md-6 {
        padding: 0 30px;
    }
    & .reportLayoutLeft {
        & .commanBox {
            margin-bottom: 25px;
            & .contentPreview {
                background: transparent;
                box-shadow: none;
                border: 0;
                border-radius: 0px;
                padding: 0;
                margin-bottom: 0;
            }
        }
        & .contentPreview {
            background: #FFFFFF;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
            border: 1px solid rgba(22, 112, 146, 0.5);
            border-radius: 20px;
            width: 100%;
            padding: 20px;
            position: relative;
        }
    }
   
}

 .viewArtileReportWrap {
    position: sticky;
    top: 60px; 
    overflow: auto;
    max-height: 420px;
}
 .reportLayoutRight {
    padding: 20px;
    border-radius: 20px 0px 0px 20px;
    border: 3px solid #167092;
    border-right: 0;
    position: relative;
    right: -56px;
    top: -49px;
    background: #ffffff;
    & .titlePree {
        text-transform: uppercase;
        color: #FF8300;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 35px;
        margin-top: 15px;
        position: relative;
        & .closeBtn {
            position: absolute;
            right: 0px;
            top: 50%;
            cursor: pointer;
            background: url(../img/icons/close_black.svg);
            width: 20px;
            height: 20px;
            background-position: center;
            background-repeat: no-repeat;
            transform: translateY(-50%);
        }
    }
}

.needHelpPopup {
    text-align: left;
    padding: 15px 20px;
    & label {
        position: absolute;
        top: -20px;
        left: 10px;
        &.labelForm{
            position: relative;
            left: auto;
            top: auto;
        }
    }
    & .mobileCode {
        height: 36px;
        border-radius: 10px;
        background-color: #fff;
        padding: 4px 15px;
        font-size: 16px; 
        width: 100%;
        font-weight: 600;
        border: 1px solid rgba(153, 153, 153, 0.5);
        & label {
            position: relative;
            margin-bottom: 0;
            top: auto;
            left: auto;
        }
    }
    & .fieldWrap {
        position: relative;
        & b {
         top: 10px !important;
        }
    }
    & .fieldWrap-mobile {
        position: relative;
        & b {
            top: 10px !important;
        }
    }
}

.arrowButton{
     
        background-color: $color1;
        background-image: url(../img/icons/downarrow.svg);
        border: 1.5px solid $color1;
        box-shadow: none;
        border-radius: 6px;
        width: 30px;
        height: 28px;
        background-position: center;
        margin-left: 16px;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 15px;
        cursor: pointer;
     
}

.customAccordion.summaryAccordion{
    margin-top: 0;
    & .accordion-flush .accordion-collapse {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        margin-top: -3px;
    }
    & .accordion-header .accordion-button p {
        font-size: 16px;
        & i {
            color: #404040;
            text-transform: none;
        }
    }
}

.rejectPr {
    position: relative;
    padding-top: 20px;
    margin-top: 15px !important;
    &::before{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        background: url(../img/icons/border.png);
        height: 1px;
        width: 100%;
        
    }
    & .inputField {
        margin-top: 10px;
    }
}

.amendmentPopup {
    padding: 0 10px;
    & .fieldWrap {
        margin-top: 20px;
    }
    & .form-control {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        // text-transform: capitalize;
        color: #009B94;
        &:focus {
            color: #009B94;
        }
    }
    & textarea.form-control {
        border: 1px solid #858585 !important;
        border-radius: 10px;
        resize: none;
        min-height: 200px;
        color: #000;
        font-weight: 400;
        padding: 15px;
        &:focus{
            color: #000;
        }
    }
    & .labelForm.amendedTextArea{
        display: flex;
        align-items: center;
        justify-content: space-between;
        & span {
            font-style: normal;
            letter-spacing: 0;
        }
        & .customTool{
            & img {
                position: relative;
                top: -1px;
                margin-right: 0;
            }
        }
    }
}

.amendmentSharing {
    padding: 0 35px;
    text-align: left;
    & p {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 24px;
        &:last-child {
            margin-bottom: 0;
        }
        & i {
            & b {
                font-weight: 600;
            }
        }
    }
}

.authorDetailsPopup {
    text-align: left;
    padding: 0 15px;
    & .authorDetails {
        background: rgba(0, 155, 148, 0.05);
        border-radius: 20px;
        padding: 20px;
        margin-bottom: 24px;
        & .authorName {
            display: block;
            color: #404040;
            font-weight: 600;
            font-size: 18px;
            line-height: 29px;
            margin-bottom: 10px;
            & i {
                width: 45px;
                height: 45px;
                border-radius: 50%;
                border: 0.5px solid #404040;
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;
                background-size: cover;
                background-position: center;
            }
        }
        & p {
            color: #000000;
        }
        & .authorDetailsTag {
            border-top: 0.5px solid #A7A7A7;
            margin-top: 15px;
            padding-top: 12px;
        }
    }
}
.publishedList {
    & li {
        margin-bottom: 20px;
        list-style-type: none;
        &:last-child {
            margin-bottom: 0;
        }
        & a {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #167092;
            display:block;
            text-decoration: none;
        }
        &.pubListLink{
            & a {
                color: #167092;
                cursor: pointer;
                &:hover{
                    color: #167092; 
                }
            }
        }
    }
}

.needHelpPage {
    margin-top: 66px; 
    background: #ffffff;
    & .draftTitle {
        margin-top: 20px;
        display: block;
        margin-bottom: 0;
    }
    & .fieldForm::placeholder,
    & .reactSelect-container .reactSelect__placeholder{
        font-size: 16px;
        color: #404040;
        font-weight: 500;
    }
    & .contactSection {
        background-image: none;
        & .pageRight { 
        }
    }
    & .contactSection .contactRow .contactLeft {
        max-width: 1040px;
        margin: 0 auto;
    }
    & .contactSection .contactForm {
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        border-radius: 20px;
        border: 0;
    }
    & .contactSection .contactForm .button { 
        background-color: #167092 !important;
    }
    & .reactSelect__control .reactSelect__single-value {
        color: #009B94 !important;
        font-size: 16px;
    }
    & .contactSection .contactRow label {
        font-size: 16px !important;
        font-weight: 500;
        color: #404040;

    }
    & .contactSection .mobileCode {
        & label {
            margin-bottom: 0;
            margin-top: 4px;
        }
    }
}
.akashganagafaq {
    margin-top: 84px;
    
    & .faqSection { 
        padding-top: 0;
        background: #ffffff; 
        & .faqWrap p a {
            color: #167092 !important;
        }
        & .faqBox {
            border: 1px solid #167092;
        }
        & .faqBox-head {
            & h3{
                color: #FF8300 !important;
                font-weight: 600;
                font-size: 18px;
            }
            & b {
                background: transparent;
                &::after{
                    background: url(../img/icons/faqtabs.svg);
                    width: 44px;
                    height: 39px;
                }
            } 
        }
    
        & .simpleTabs {
            position: relative;
            &::after{
                content: '';
                width: 100%;
                height: 1px;
                background: #a7a7a7;
                position: absolute;
                bottom: 0px;
            }
        & ul li a {
            color: #167092 !important;   
            &.active {
                color: #404040 !important;
                font-size: 16px;
                font-weight: 600;
                line-height: 22px;
            }
        }
    }
    }
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-content {
    position: absolute !important;
     left: 50%; top: 50%; transform: translate(-50%, -50%); width: 100% !important;
     border: 3px solid #167092 !important;
     background-color: #fff !important;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.09) !important;
    border-radius: 20px !important;
    overflow: hidden;
}
.editorWrap .sun-editor .se-dialog-header .se-btn {     color: #000;  text-decoration: none;
    opacity: 0.75; }
.maniSearch {
    position: absolute;
    right: 0;
    top: -4px;
    width: 43vw;
    height: 45px;
    background: #ffffff;
    display: none;
    z-index: 3;
    &.active{
        display: inline-block;
    }
    & input {
        border-radius: 10px;
        border: 0.5px solid rgba(22, 112, 146, 0.35);
        background: #F6F9FB;
    }
    & .searchArrowIcon {
        position: absolute;
        right: 10px;
        top: 5px;
        cursor: pointer;
    }
    & .mainSearchResult {
        position: absolute;
        top: 44px;
        left: 0;
        width: 100%;
        border-radius: 10px;
        background: #ffffff;
        box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
        padding-bottom: 10px;
        padding-top: 10px;
        // display: none;
        & .relatly {
            color: #404040; 
            padding: 10px 22px;
            display: block;
        }
        & .searchRepeat {
            border-bottom: 1px solid #97979B;
            margin-bottom: 10px;
            &:last-child{
                border-bottom: 0;
                margin-bottom: 0;
            }
            & .title {
                padding: 8px 22px;
                color: #FF8300;
            }
            & ul {
                list-style-type: none;
                & li {
                    & a{
                        padding: 6px 22px;
                        display: block;
                        color: #404040;
                        font-weight: 600;
                        font-size: 16px;
                        text-decoration: none;
                        width: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}





@media screen and (max-width: 1280px) {
    header.home-header .headerLeft .maniSearch { max-width: 350px;}
    .recommendedBox .recommendedHead span {
        font-size: 13px;
    }
    .publisActionWrap .publisActionBtn ul li {
        margin-right: 30px;
        font-size: 15px;
    }
    .publisActionWrap .publisActionBtn ul li::before { right: -15px;}
    .button { font-size: 15px;}
}

.needNewPopup {
    text-align: left;
    padding: 0px 30px;
    & p {
        font-size: 18px;
        font-weight: 400;
        display: block;

    }
    & a {
        font-size: 18px;
        font-weight: 600;
        display: block;
    }
}


.creationCustomPopup{
    background: #FFFFFF;
    border: 3px solid #167092 !important;
    border-radius: 20px !important;
}
.citationPopup {
    text-align: left;
    & input.fieldForm {
        &::placeholder {
            line-height: 20px;
            position: relative;
            top: 0px;  
            font-size: 16px;
        }
    }
    & .preSubmitDropDown {
        width: 100%;
        margin-bottom: 15px;
        & select {
            width: 100%;
            padding: 5px 15px;
        }
        & label{
            font-size: 16px;
            color: #444444;
            font-family: "Inter", sans-serif !important;
            padding: 0 15px !important;
            font-weight: 600;
        }
    }
    & .se-dialog-body {
        max-height: 70vh;
        overflow: auto;
    }
    & .isMultiAuthor{
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px !important;
        font-family: "Inter", sans-serif !important;
        & h2 {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            color: #FF8300;
            font-family: "Inter", sans-serif !important;
            margin-bottom: 0;
        }
        & .customCheck{
            padding-right: 0 !important;
            padding-left: 20px !important;
            & i {
                background: #167092;
            }
         b {
            font-family: "Inter", sans-serif !important;
            color: #404040 !important;

        }
    }
        & .customCheck input[type=checkbox]:checked + i + b {
            color: #009B94 !important;
        }
    }
    & .se-dialog-header {
        font-family: "Inter", sans-serif !important;
        height: auto !important;
        text-align: center !important;
        & .se-modal-title{
            float: none !important;
            font-weight: 700;
            font-size: 20px !important;
            line-height: 24px !important;
            text-transform: uppercase;
            color: #FF8300;
            height: auto !important;
            display: block;
            padding: 10px !important;
        }
    }
    & label { 
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        font-family: "Inter", sans-serif !important;
        padding: 0 15px !important;
    } 
    & .py-2 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    & .se-input-form {
        font-family: "Inter", sans-serif !important;
        padding: 0 15px !important;
        background: #FFFFFF;
        border: 0.5px solid #404040;
        border-radius: 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #009B94 !important;
    }
    & .se-dialog-form {
        margin-bottom: 25px !important;
    }
    & .creationUrl {
        border-bottom: 0.5px solid #A7A7A7;
        display: block;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }
    & .authorsDetail {
        border-bottom: 0.5px solid #A7A7A7;
        margin-bottom: 20px;
        padding-bottom: 5px;
    }
    & .repeatAuthor{ 
        &.fnamelname {
            border-bottom: 0;
            margin-bottom: 0;
        } 
        & .fieldWrap{
            & .repetField {
                width: calc(100% - 35px);
                display: inline-block;
            }
            & .se-input-form {
                width: calc(100% - 35px) !important;
                display: inline-block !important;
            }
            & b {
                display: inline-block;
                background: #404040 url(../img/icons/white-cross.svg);
                width: 20px;
                height: 20px;
                border-radius: 50%;
                margin-left: 5px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 8px;
                cursor: pointer;
                position: relative;
                top: 6px;
                right: -3px;
            }
        }
    }

    & .button-link {
        font-family: "Inter", sans-serif !important;
        cursor: pointer;
    }
    & .fnamelname {
        display: flex;
        justify-content: space-between;
        margin-top: 14px;
        & .fieldWrap {
            width: 46%;
            font-family: "Inter", sans-serif !important;
        }
    }
    & .finalData {
        padding: 0 40px;
        & .se-input-form {
            width: 100%;
            height: 34px;
            padding-right: 90px !important;
        }
        .finalInputWrap {
            font-family: "Inter", sans-serif !important;
            padding: 0 15px !important;
            background: #FFFFFF;
            border: 0.5px solid #404040;
            border-radius: 10px;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #009B94 !important;
            width: 100%;
            height: 34px;
            padding-right: 104px !important;
            line-height: 34px;
        }
        & .copyInput {
            position: relative;
            width: 100%;
            & b {
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                text-transform: uppercase;
                color: #167092;
                position: absolute;
                right: 8px;
                top: 10px;
                font-family: "Inter", sans-serif !important;
                padding-left: 36px;
                display: flex;
                align-items: center;
                cursor: pointer;
                &::after{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: -1px;
                    background: #A7A7A7;
                    width: 1px;
                    height: 16px;
                    display: inline-block;
                }
                &::before{
                    content: '';
                    position: absolute;
                    background: url(../img/icons/copy.svg);
                    width: 20px;
                    height: 20px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    left: 10px;
                    top: -3px;
                }
            }
        }
    }
    & .creation-publication {
        // max-width: 46%;
        border-bottom: 0.5px solid #A7A7A7;
        display: block;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }
}

.aaforAss {
    font-size: 18px;
    color: #000;
    padding: 0 33px;
    text-align: left;
    & a {
        font-weight: 600;
        text-decoration: underline;
    }
}
.aaforAssContent {
    font-size: 18px;
    color: #000;
    padding: 0 20px; 
    & p{
        font-size: 17px;
        color: #000;  
    }
    & a {
        font-weight: 600;
        text-decoration: underline;
    }
}

.rewardNoteRefer {
    width: calc(100% + 20px);
    padding: 10px 0;
    & small {
        display: block;
        font-size: 12px;
        font-weight: 600;
        color: $dark-400;
        text-align: left;
        & i {
            width: 28px;
            height: 28px; 
            border-radius: 50%;
            background: url(../../assets/img/icons/bigInfo.svg);
            display: inline-block;
            vertical-align: middle;
            margin-right: 8px;
        }
        & p {
            display: inline-block;
            width: calc(100% - 38px);
            vertical-align: middle;
        }
    }  
}


.addQuestionWrap {
    border-top: 0.5px solid rgba(22, 112, 146, 0.5);
& .questionFeedBack {
    margin-bottom: 22px;
    & textarea.form-control {
        min-height: 180px;
    }
    & .form-control {
       border: 1px solid #858585;
        border-radius: 10px;
        background: #ffffff;
        resize: none;
        padding: 15px;
       
        &:read-only{
            background: #F3F3F3;
            border: none;
        }
    }
    & p {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        margin-bottom: 16px;

        & sup {
            color: #BE1E2D;
        }
    }
    & span {
        display: block;
        background: #F3F3F3;
        border-radius: 10px;
        padding: 15px;
        margin-bottom: 22px;
    }
   
}

& .editorCommaintWrap {
    & .editorCommaint {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 0.5px solid rgba(167, 167, 167, 0.5);
        &:last-child{
            border-bottom: 0;
        }
        & span {
            display: block;
            background: #F3F3F3;
            border-radius: 10px;
            padding: 15px;
        }
        & textarea.form-control {
            min-height: 180px;
        }
    }
}
& .addQuestionTable {
    margin-bottom: 20px;
    & table{
        width: 100%;
        &:after{
            display: none;
        }
        & tr {
            & th{
                color: #FF8300;
                padding: 20px 10px;
                text-align: left;
                &:last-child{
                    padding-left: 0; 
                }
            }
            & td {
                padding: 20px 10px;
                text-align: left;
                &:last-child{
                    padding-left: 0;
                }
                & .questionTd {
                    max-width: 245px;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 23px;

                }
                & .customRadio {
                    position: relative;
                    top: -10px; 
                    & b {
                        width: 20px;
                        height: 20px;
                    }
                    & input:checked + b::before {
                        width: 12px;
                        height: 12px;
                        background-color: #FF8300;
                    }
                }
            }
        }
    }
}
& .recomArt {
    margin-bottom: 10px;
    display: inline-block;
    width: 100%;
    & .customRadio {
        display: block;
        margin-bottom: 20px;
    }
}
}

.approvePub{
    font-size: 18px;
    color: #000;
    max-width: 363px;
    margin: 0 auto;
}

.peerReportWrap {
    display: flex;
    align-items:center;
    justify-content: space-between;
    padding-right: 40px;
}
.link {
    &.preview-btn {
      border: none;
      background: transparent;
      padding: 0;
    }
}
.noteText {
    border-top: 0.5px solid #A7A7A7;
    margin-top: 30px;
    & h4 {
        color: #FF8300 ;
        font-size: 18px ;
        margin-top: 10px;
        text-transform: uppercase;
    }
    & ol {
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: #404040;
        padding-left: 16px;
        & li {
            padding: 6px 0;
        }
    }
}

.contactInfo {
    font-weight: 600;
    font-size: 13px;
    line-height: 21px; 
    color: #404040;
    border-top: 0.5px solid #404040;
    & a {
        color: #167092;
        text-decoration: none;
    }
}

.browseBy {
    border-bottom: 1px solid rgba(204, 204, 204, 0.5);
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #404044;
    margin-bottom: 30px;
    padding-bottom: 20px;
}
.transactionWrap{
    margin-top: 20px;
    margin-bottom: 20px;
    display: block;
     text-align: center;
    background: rgba(241, 241, 241, 0.6);
    border-radius: 12px;
    padding: 10px 20px;
    & b {
        font-weight: 600;
        font-size: 18px;
        color: #000;
        display: block;
        text-align: center;
    }
    & span {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        display: block;
        text-align: center;
    }
}

.allGoodText {
    max-width: 290px;
    margin: 0 auto;
}
.allGoodText01 {
    max-width: 310px;
    margin: 0 auto;
    font-size: 18px;
}
.allGoodText02 {
    max-width: 322px;
    margin: 0 auto;
    font-size: 18px;
}
.acceptedArticle {
    text-align: left;
    max-width: 380px;
    margin: 0 auto;
    font-size: 18px;
    & a {
        font-weight: 700;
        color: #167092;
        font-size: 18px;
    }
}

.perspectiveToolWrap {
    position: absolute;
    left: 4px;
    top: 4px;
    width: calc(100% - 8px );
    z-index: 1;
    & h2{
        position: relative;
        padding: 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #009B94;
        background: #ffffff;
        width: 70%;
        & b {
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 14px;
            background: url(../img/icons/close_black.svg);
            background-repeat: no-repeat;

            width: 15px;
            height: 14px;
            display: inline-block;
        }
    }
    & .perspectiveTool {
        position: absolute;
        background: #FFFFFF;
        border: 1px solid #DADADA;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.09); 
        border-radius: 10px;
        padding: 27px 15px 12px 15px;
        right: 0;
        top: 80px;
        width: 112%;
        &::before{
            content: '';
            position: absolute;
            width: 18px;
            height: 18px;
            border: 1px solid #DADADA;
            left: 21%;
            top: -10px;
            background: #ffffff;
            transform: rotate(45deg);
            border-right: 0;
            border-bottom: 0;
        }
        & .article-type {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            margin-bottom: 10px;
            display: block;
        }
        & .cross-icon {
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 14px;
            background: url(../img/icons/close_black.svg);
            background-repeat: no-repeat;

            width: 15px;
            height: 14px;
            display: inline-block;
        }
        & p {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
        }
        & .perspectiveFoot {
            border-top:  1px solid rgba(167, 167, 167, 0.15);
            margin-top: 10px;
            padding-top: 10px;
            & i {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: #000000;
                text-transform: uppercase;
                margin-right: 50px;
            }
            & span {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #000000;
            }
        }
    }
}

.contentPreview {
    & a {
        position: relative;
        & .refranceLinking {
            position: absolute;
            top: -100px;
            left: 0;
            opacity: 0;
            scroll-margin-top: 95px;
        }
    }
    & table {
        width: 100%;
        border: 1px solid #a7a7a7;
        margin-top: 10px;
        margin-bottom: 10px;
        & th {
            font-weight: 700;
            padding: 10px;
        }
        & tr:first-child{
            & td {
                font-weight: 700;
                background: #f1f1f1;
            }
        }
        & td {
            font-weight: 500;
            padding: 10px;
            border-bottom: 1px solid #a7a7a7;
            border-right: 1px solid #a7a7a7;
        }
    }
}

.iconManage{
    position: relative;
    top: 2px;
}
.mandatoryFoot {
    position: absolute;
    bottom: 6px;
    left: 12px;
}

.guidelinesPageWrap  {
    margin-bottom: 15px;
    display: block;
    max-width: 980px;
    margin: 100px auto 80px;
    & h2 {
        margin-bottom: 15px;
        font-size: 16px;
        font-weight: 600;
        color: #167092;
        text-transform: uppercase;
    }
    & p {
        margin-bottom: 15px;
    }
    & ul,
    & ol {
        margin-bottom: 15px;
        margin-left: 15px;
    }
}

.somethingMind {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    text-align: right;
    padding-top: 6px;
    & a {
        color: #167092;
        font-weight: 700;
        &:hover{
            color: #167092;
        }
    }
}

.feedHead {
    & span {
        float: left;
        position: relative;
        left: -15px;
        top: -28px; 
    }
    & b {
        font-size: 25px;
        font-weight: 700;
        margin-top: 30px;
        display: inline-block;
        line-height: 24px;
        margin-right: -40px;
    }
}
.feedBackPopup {
    font-size: 20px;
    font-weight: 400;
    margin: 0 auto 39px;
    max-width: 670px;
    position: relative;
    & .feedStar {
        margin-top: -60px;
        padding-bottom: 40px;
        & .style-module_emptyIcons__Bg-FZ.empty-icons {
       
            & .star-svg {
                background: url(../img/icons/feed_star.png);
                width: 50px;
                height: 50px;
                margin: 0 5px;
                background-size: 56px;
                & path {
                    display: none;
                }
            }
        }
          
            
          
         & .filled-icons {
            & .star-svg {
                background: url(../img/icons/feed_star_active.png);
                width: 50px;
                height: 50px;
                margin: 0 5px;
                background-size: 56px;
                & path {
                    display: none;
                }
            }
         }
    }
    & p{
        text-align: left;
        position: relative;
        color: #000;
        font-size: 14px;
        &.feedText {
            text-align: center;
            padding: 0 30px;
            display: inline-block;
            margin-bottom: 20px;
            font-weight: 500;
            font-size: 16px;
            &::before{
                content: '';
                width: 28px;
                height: 28px;
                background: url(../img/icons/feedComma.svg);
                display: inline-block;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            &::after{
                content: '';
                width: 28px;
                height: 28px;
                background: url(../img/icons/feedComma.svg);
                display: inline-block;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    & textarea {
        border-radius: 10px;
        border: 0.5px solid #404040;
        width: 100%;
        height: 126px;
        padding: 10px;
        margin-top: 10px;
        font-size: 16px;
        &::placeholder{
            font-size: 16px;
        }
    }
    & .feedBtn {
        margin-top: 38px;
        margin-bottom: 10px;
        display: inline-block;
        & a {
            color: #404040;
            text-transform: uppercase;
            font-weight: 700;
            text-decoration: none;
            font-size: 16px;
        }
        & button{
            border-radius: 10px;
            background: #FF8300;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.04);
            color: #ffffff;
            font-size: 16px;
            border: 0;
            margin-left: 35px;
            font-weight: 700;
            padding: 7px 15px;
        }
    }
}
.otherSubjectBorder {
    color: #BF1E2E; 
    font-size: 15.5px;
    position: relative;
    font-weight: 500;
    margin-bottom: 30px;
    &::before{
        content: '';
        background: url(../img/icons/border.png);
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    & .questionBox {
        & span {
            color: #BF1E2E; 
        }
    }
}
.mudraText {
    color: #FF8300;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
}
.top-3 {
    position: relative;
    top: 3px;
}
.tagTitle {
    .top-3 { 
        top: 1px;
    }
}

.authorModal.widthAuto {
    & .modal-dialog.modal-md {
        max-width: 100%;
    }
    & .modal-dialog-centered {
        justify-content: center;
    }
    & .modal-content {
        width: auto;
    }
}

.authorModal.right .modal-content .draftTable thead tr th.text-right:last-child,
.modal .authorModal.right .modal-content .draftTable tbody tr td.text-right {
    text-align: right ;
}
.wordCount { font-weight: 600;}
.attentionPopup {
    text-align: left;
    font-size: 18px;
    width: 84%;
    margin: 0 auto;
    & p {
        margin-bottom: 20px;
    }
    & textarea {
        border-radius: 10px;
        border: 0.5px solid #404040;
        width: 100%;
        padding: 16px;
        resize: none;

        &::placeholder{
            color: #404040;
            font-size: 16px;
        }
    }
}

.articleFootBtn {
    & .button {
        margin-left: 35px;
    }
}

.additionalTextarea {
    margin-top: 20px;
    padding-top: 22px;
    position: relative;
    &::before{
        content: '';
        background: url(../img/icons/border.png);
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        top: 0;
    }
    & span {
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
    }
    & .inputField {
        margin-top: 7px;
        &::placeholder{
            font-size: 16px;
        }
    }
}
.loginBefore {
    border-top: 1px solid rgba(167, 167, 167, 0.54);
    border-bottom: 1px solid rgba(167, 167, 167, 0.54);
    padding: 30px 0;
    margin: 40px 0;
    text-align: center;
    & h3 {
        color: #FF8300; 
        font-size: 18px; 
        font-weight: 600; 
        text-transform: uppercase;
    }
}
.loginBeforeBtn {
    & .button {
        margin-left: 20px;
    }
}
.commitPostWrap {
    & .commitPostArea {
        & .commitPostAreaHead {
            border-radius: 10px;
            background: rgba(241, 97, 54, 0.05);
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;
            padding: 14px 20px;
            & h2{
                font-size: 20px;
                color: #FF8300;
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 0;
                & p {
                    font-weight: 400;
                    display: block;
                    margin-bottom: 0;
                    font-style: italic;
                    color: #000;
                    font-size: 16px;
                    text-transform: none;
                }
            }
            & h3{
                font-size: 16px;
                color: #FF8300;
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 0;
                & span {
                    color: #16ACE4;
                }
            }
        }
        & .commitPostAreaRate {
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            & h2 {
                color: #404040;
                font-size: 18px;
                font-weight: 600;
                & p {
                    font-weight: 500;
                    display: block;
                    margin-bottom: 0;
                    color: #167092;
                    margin-top: 6px;
                }
            }
            & .rateHere {
                & i {
                    color: #009B94; 
                    font-size: 18px; 
                    font-weight: 600; 
                    display: inline-block;
                    font-style: normal;
                }
                & span {
                    & b {
                        display: inline-flex;
                        width: 30px;
                        height: 30px;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        background: rgba(22,112,146,0.3);
                        font-weight: 600;
                        margin: 0 5px;
                        color: #167092;
                        cursor: pointer;
                        &.active {
                            background: #167092;
                            color: #ffffff;
                        }
                    }
                }
            }
        }
        & .commitPostAreaText {
            & textarea {
                border: 1px solid #989898;
                padding: 15px;
                border-radius: 10px;
                width: 100%;
                resize: none;
                min-height: 150px;
                &::placeholder{
                    font-size: 16px;
                }
            }
        }
        & .commitPostAreaBtn {
            margin-top: 15px;
        }
    }
    & .commitShow {
        border: 3px solid rgba(255, 131, 0, 0.50);
        border-radius: 20px;
        padding: 25px 30px;
        margin-top: 25px;
        & .commitShowRepeat {
            border-bottom: 1px solid rgba(64,64,64,0.2);
            padding-bottom: 24px;
            margin-bottom: 24px;
            &:last-child{
                border-bottom: 0;
                margin-bottom: 0;
            }
        }
        & .commitShowHead {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;
            & .commitShowHeadUser{
                display: inline-flex;
                align-items: center;
                & figure {
                    width: 35px;
                    height: 35px;
                    overflow: hidden;
                    border-radius: 50%;
                    background-size: contain !important;
                    background-position: center;
                    background-color: #f1f1f1;
                    margin-bottom: 0;

                }
                & b {
                    color: #404040;
                    font-weight: 600; 
                    margin-left: 15px;
                }
                & span {
                    color: #000000;
                    position: relative;
                    padding: 0 38px;
                    display: inline-block;
                    &::before{
                        content: '';
                        position: absolute;
                       
                        top: 50%;
                        transform: translateY(-50%);
                        background: #A7A7A7;
                        width: 1px;
                        height: 12px;
                        left: 18px;

                    }
                    &::after{
                        content: '';
                        position: absolute;
                      
                        top: 50%;
                        transform: translateY(-50%);
                        background: #A7A7A7;
                        width: 1px;
                        height: 12px;
                        right: 18px;

                    }
                }
                & small {
                    color: #009B94;
                    font-size: 16px;
                    font-weight: 600;
                    & i {
                        width: 20px;
                        height: 20px;
                        display: inline-flex;
                         align-items: center;
                         justify-content: center;
                         background: #167092;
                         color: #ffffff;
                         font-size: 14px;
                         font-style: normal;
                         border-radius: 50%;
                         font-weight: 400;
                    }
                }
            }
            
            & .flogCommit {
                & b {
                    width: 28px;
                    height: 28px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    background: #404040 url(../img/icons/flag_w.svg); 
                    border-radius: 50%;
                    cursor: pointer;
                }
            }
        }
    }
}

.availCoup {
    width: 22px;
    position: relative;
    top: -2px;
}

.flagReviewCheck {
    display: flex;
    & ul {
        list-style: none;
        width: 100%;
        margin-top: 10px;
        & li {
            padding: 5px 0;
        }
    }
    & .customCheck{
        & i {
            background: #167092;
        }
    & b {
        color: #404040;  
        font-size: 16px; 
        font-weight: 500;
    }
} 
}

.additionalTextarea.flagReviewTextarea {
    & span {
        font-weight: 600;
        color: #404040;
        font-size: 16px;
        
    }
    & .inputField {
        color: #404040;
    }
}

.earnCoinWrap {
    border-top: 1px solid rgba(151, 151, 155, 0.24);
    padding-top: 20px;
    margin-top: 20px;
    & b {
        font-size: 22px; 
        font-weight: 700;
        color: #FF8300;
        vertical-align: baseline;
    }
    & span {
        vertical-align: baseline;
        position: relative;
        bottom: 5px;
        left: 6px;
    }
}

.authorReferPopup {
    & .referContent {
        & .referStep ul li {
            color: #167092;
            font-weight: 700;
            & span {
                border: 1px solid #ff8300;
                & b {
                color: #167092;
                }
            }
        }
        & .receiveText {
            color: #000;
            text-align: center; 
            font-size: 14px; 
            font-weight: 600;
            margin-top: 20px;
            line-height: 16px;
        }
        & .shareModal small {
            font-size: 16px;
            font-weight: 700;
            color: #FF8300;
            font-family: "Open Sans", sans-serif;
        }
        & .socialRefer li button {
            border: 1px solid rgba(22, 112, 146, 0.29) !important;
        }
        & .codeWrap {
            background: url(../img/icons/codebase.svg);
            border: 0;
            background-size: 100% 100%;
            padding: 15px 14px;
            background-repeat: no-repeat;
        & p {
            color: #009B94 !important;
        }
        &  p.sr-color {
            color: #167092 !important;
            & .icon-copy {
                color: #167092 !important;
            } 
        }
    }
    & .referStep:after{
        width: 58%;
        background: #FF8300;
    }
    & .questionBox {
        padding-bottom: 10px;
        margin-top: 20px; 
        &.reddot{
            & span {
                font-size: 13px;
                font-family: "Open Sans", sans-serif;
                &::before{
                    top: 7px !important;
                }
            }
        }
        & span {
            font-size: 13px;
            font-family: "Open Sans", sans-serif;
            &::before{
                top: 0;
            }
        }
    }
    & .link-gray {
        color: #404040; 
        font-size: 16px; 
        font-weight: 700;
        text-decoration: none;
        }
        & .modal-footer  .button {
            background: #167092 !important; 
            padding: 5px 10px !important;
            height: 40px !important;
        }
    }
  
}

.researchTextGreen {
    line-height: 27px;
    color: #009B94;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    font-family: "Inter", sans-serif;
}

.pe-accepted-th,
.pe-approved-th,
.pe-revision-th,
.author-draft-th,
.author-rejected-th,
.pr-review-th,
.pr-notAccepted-th {
    width: 190px;
}
.pe-rejected-th { width: 190px; }
.pe-review-th {
    width: 220px;
}

.author-rejected-td {
    & .footAction { 
        justify-content: start;
    }
}
.firstDataBoxTd {
    width: 520px;
}

@media (min-width: 1440px) {
    .pe-request-th {
        width: 390px;
    }
    .pr-request-th {
        width: 290px;
    }
}

.commitPostManage { 
    padding: 0 25px;
}

ul.itemsLetter{     list-style-type: lower-alpha;     padding-left: 20px; }
ul.dotsUl {
    padding-left: 20px;
}
#article-editor__comp .col-lg-8 input.fieldForm,
#article-editor__comp .col-lg-8 .form-control{ 
    // font-family: 'calibriregular'  !important; 
    font-family: "Calibri", "Open Sans" !important; 
}
#article-editor__comp .col-lg-8 input.fieldForm::placeholder,
#article-editor__comp .col-lg-8 .form-control::placeholder{ font-family: "Inter", sans-serif; }
.manageInto {position: relative; top: 2px;}

.reviewArticleReport {
    & p {
        font-weight: 400;
        font-size: 16px !important;
        // font-family: 'calibriregular'  !important;
        font-family: Calibri, Open Sans !important;
        color: #000 !important;
        text-align: left;
        & span{
            // font-family: 'calibriregular'  !important;
            font-family: Calibri, Open Sans !important;
            color: #000 !important;
            font-size: 16px !important;
        }
        & b {
            // font-family: 'calibriregular'  !important;
            font-family: Calibri, Open Sans !important;
            color: #000 !important;
            font-size: 16px !important;
        }
        & i {
            font-family: Calibri, Open Sans !important;
            // font-family: 'calibriregular'  !important;
            color: #000 !important;
            font-size: 16px !important;
        }
    }
    & div {
        font-weight: 400;
        font-size: 16px !important;
        // font-family: 'calibriregular'  !important;
        font-family: Calibri, Open Sans !important;
        color: #000 !important;
        & span{
            font-family: 'calibriregular'  !important;
            color: #000 !important;
            font-size: 16px !important;
        }
        & b {
            font-family: 'calibriregular'  !important;
            color: #000 !important;
            font-size: 16px !important;
        }
        & i {
            font-family: 'calibriregular'  !important;
            color: #000 !important;
            font-size: 16px !important;
        }
}
}

#article-editor__comp button:data-tab-link{ display: none !important;}
 
.se-dialog-body .se-dialog-form:nth-last-child(3){ display: none !important;}
.sun-editor .se-dialog-tabs button:last-child{ display: none; }
.sun-editor .se-dialog .se-dialog-inner .se-dialog-btn-radio { width: 14px; height: 14px; }

.sun-editor .se-dialog .se-dialog-inner .se-dialog-header .se-modal-title,
.sun-editor .se-dialog .se-dialog-inner {     font-family: "Inter", sans-serif !important;
    font-size: 16px !important;}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-header .se-modal-title { text-transform: capitalize; }
.sun-editor .se-dialog .se-dialog-inner .se-dialog-footer {padding: 10px 15px 0 4px;}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-footer .se-btn-primary{ color: #fff !important; background-color: #167092; padding: 10px 15px; font-size: 16px; font-weight: 700 !important; border-radius: 10px; border: 0 !important; border-radius: 10px !important; }
.sun-editor .se-dialog .se-dialog-inner .se-dialog-footer .se-btn-primary span { color: #ffffff !important; font-size: 16px !important; font-weight: 700 !important; font-family: "Inter", sans-serif !important; }

.readonlyDetailPage {
    & .selectBox .reactSelect-container.reactSelect--is-disabled .reactSelect__control {
        background: #F3F3F3 !important;
        border-color: transparent !important;
    }
    & .selectBox,
    & .customCheck,
     
    & .custom_radio {
        pointer-events: none;
    }
    & .selectBox .reactSelect-container .reactSelect__control,
    & input.fieldForm, 
    & select.fieldForm,
    & textarea.fieldForm,
    & .statementWrap .defaultInput textarea,
    & .manageEditorInner .form-control,
    & .customAccordion textarea.form-control {
        background: #F3F3F3 !important;
        border-color: transparent !important;
        pointer-events: none;
    }
}

.sun-editor .se-dialog-tabs { 
    border-bottom: 0 !important;
}
.sun-editor .se-dialog-tabs button { border-right: 0 !important; margin-top: 9px !important; font-weight: 700; }
.sun-editor .se-dialog .se-dialog-inner .se-dialog-body {
    & div {
        & .se-dialog-form {
            &:last-child{
                display: none;
            }
            & .se-dialog-size-text {
                display: none;
                & + .se-input-control {
                    display: none;
                }
            }
        }
    }
    & .se-dialog-form {
        & .se-input-control._se_image_size_y{ display: none; }
        & .se-input-control._se_image_size_y + label { display: none; }
        & .se-input-control._se_image_size_y + label + button { display: none; }
        & .se-dialog-size-text {
            display: none;
            & + .se-input-control {
                display: none;
                & + label {
                    display: none;
                }
            }
            & + .se-dialog-size-x {
                display: none;
                & + .se-input-control {
                    display: none;
                }
                & + label {
                    display: none;
                }
            }
        }
    }
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-footer {
    & div {
        display: none;
    }
}

.additionalTitle {
    position: relative;
    & b{
        position: absolute;
        left: 30px;
        top: 3px;
        cursor: pointer;
    }

}
.articleDetailsPopupContent {

& .se-table-layout-auto,
& table {
    width: 100%;
    border: 1px solid #A7A7A7;
    margin-top: 15px;
    margin-bottom: 15px;
    & td {
        padding: 8px 10px;
        border-left: 1px solid #A7A7A7;
        border-bottom: 1px solid #A7A7A7; 
    }
}
}
.full-view-page {
    .innerMergin {
        max-width: 100%;
    }
}
.innerMergin {
    max-width: 1440px;
    margin: 0 auto;
}
.innerMergin.customds {
    font-size: 32px;
    // margin: 0px;
    // max-width: 70vw;
}


.authorFaqBox {
    margin-top: 83px;
    background: #ffffff;
    padding-bottom: 140px;
    & .faqSection {
        padding-top: 50px;
        background: #ffffff;
        & .faqBox {
            border: 1px solid #167092;
            &.active{
                & .faqBox-body {
                    display: block;
                }
            }
        }
        & .faqBox-head {
            position: relative;
            & .slidePage {
                position: absolute;
                top: -120px;
            }
            & h3 {
                color: #FF8300;
                font-weight: 600;
                text-transform: uppercase;
                font-size: 18px;
            }        
            b{
            background: transparent;

            &:after {
                background: url(../img/icons/faqtabs.svg);
                width: 44px;
                height: 39px;
            }

        }
            
        }
        & .containWrap {
            max-width: 1440px;
        }
    }
    & .faqTabs {
        width: 382px;
        display: inline-block;
        vertical-align: top;
        border: 1px solid #167092;
        padding: 24px;
        border-radius: 20px;
        margin-right: 40px;
        position:sticky;
        top: 90px;
        & h3 {
            color: #FF8300; 
            font-size: 16px; 
            font-weight: 600; 
        }
        & a {
            color: #167092;
            font-size: 16px;
            font-weight: 500;
            text-decoration: none;
            margin: 10px 0;
            display: block;
            position: relative;
            &.active {
                color: #404040;
                &:after{
                    content: "";
                    width: 34px;
                    height: 8px;
                    border-radius: 8px;
                    background: linear-gradient(270deg, rgba(255, 176, 27, 0.84) -2.89%, rgba(227, 232, 123, 0.84) 100%);
                    display: block;
                    position: absolute;
                    left: 0;
                    bottom: -7px;
                    z-index: 1;
                }
            }
        }
    }
    & .faqContent {
        width: calc(100% - 430px);
        display: inline-block;

        & .faqBox-body {
            & ul {
                padding-left: 20px;
            }
        }
    }
}
.midraOutSideIndia {
    &.midraMainBox .midraRight{
        position: relative;
        top: -170px;
    }
}

.addnewLogoWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.yearLink {
    & a {
        color: #167092;
    }
}
.pageMenu-link:last-child .yearLink a {
    color: #167092 !important;
}



.faq-side-list {
    padding-left: 0;
    list-style-type: none;
 & li {
    color: #707070;
    font-size: 20px;    
    margin: 5px 0;
    cursor: pointer;
    & span {
        padding: 8px 15px;
        display: inline-block;
        border-radius: 6px;
        color: #000;
    }
    &.active{
        & span {
            box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.04);
            background: #009b94;
            color: #ffffff;
        }
      
    }
    &:hover{
        & span {
            box-shadow: inset 0 0 15px 0 #c7c7c7;
        }
    }
  }
}

.has-search {
    & .form-control {
        border: 0;
    }
}

.faw-lists {
    & .org {
        margin-bottom: 25px;
    }
    & .card {
        margin-top: 14px; 
        border-bottom: 5px solid #336d91;
        box-shadow: 0px 0px 6px #00000029;
        border-radius: 8px;
        padding: 10px 4px 14px 20px;
        & .para {
            font-weight: 600;
        }
    }
}

.ag_oppbullets li {
    font-size: 18px;
    // font-weight: 400 !important;
}

.megaRightContent {
    & .paragraph {
        font-family: "Montserrat", sans-serif;
        font-size: 20px;
        font-weight: 500;  
        color: #000000;  
    }
    & .megaList {
        list-style-type: none;
        & li {
            font-family: "Montserrat", sans-serif;
            font-size: 20px;
            font-weight: 400;  
            color: #000000; 
            margin: 30px 0; 
            & b {
                font-weight: 600;
                font-size: 20px; 
            }
            & span {
                color: #bc1c2b;
                font-weight: 600;
                font-size: 20px; 
            }
        }
    }
}

.warm-slider-des .warm-subtitle {
    margin-top: 30px;
}

.processingAPCWrap {
    max-width: 1120px;
    margin: 0 auto;
    & .understandWrapContent {
        margin-bottom: 80px;
    }
    & .offerGrab {
        margin-bottom: 80px;
    }
    & .understandWrap {
        padding: 0 15px;
        & p { 
            margin: 15px 0;
        }
        & .ag_special_offer_img p{
            margin-bottom: 0;
            border-radius: 12px 90px 0px 0px;
            padding-bottom: 35px;
            width: 62%;
            font-size: 30px;
        }
        & .ag_special_offer_text{
            top: -20px;
        }
    }
    & .tagul.underList{
        
        margin-top: 10px;
        & li {
            padding-left: 10px;
            font-style: italic;
            &:last-child{
                font-weight: 600;
                font-style: normal;
                font-size: 18px;
            }
        }
    }
    & .ag_publishjournal_text.foreign-nation {
        padding: 40px 77px 40px 60px;
        border-radius: 10px;
        position: relative;
        left: 5%;
        & p { 
            font-size: 18px;
        }

    }
}
 
.itrWrap {
    & .customimpnoteind {
        padding-left: 0;
    }
    & p {
        margin-top: 20px;
        font-weight: 400; 
        padding-left: 0;
    }
    & .ag_btn_red {
        margin-top: 20px;
    }
}

.affordableWrap {
    & p {
        margin-top: 20px;
        font-weight: 400; 
    }
    & .ag_btn_red {
        margin-top: 20px;
    }
}
 
.journalBackWrap {
    display: flex;
    align-items: center;
    justify-content: end;
    padding-bottom: 30px;
    position: relative;
    z-index: 1;
    & span {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 0;
        color: #336d91;
        position: relative;
        margin-right: 30px;
        font-weight: 600;
        &::before{
            content: '';
            transform: translateX(-50%);
            left: 50%;
            bottom: -15px;
            width: 149px;
            position: absolute;
            background-color: #fe8300;
            height: 2.5px;
        }
        &::after{
            content: "";
            position: absolute;
            background-color: #707070;
            height: 40px;
            width: 2px;
            right: -15px;
            top: -3px;

        }
    }
    & .ag_btn_red {
        font-size: 20px;
        &:focus{
            font-size: 20px;
        }
    }
}

.publishArtWrap {
    text-align: center;
    & p {
        text-align: left;
        line-height: 30px;
        font-size: 20px;
        font-weight: 500 !important;
    }
    & a  {
        font-size: 20px;
        margin-top: 20px;
        padding-top: 12px;
        padding-bottom: 12px;
        height: 50px;
        &:focus{
            font-size: 26px; 
            padding-top: 12px;
            padding-bottom: 12px;
            height: 50px;
        }
    }
}

.journalInfoList {
    & .custom-li-journal {
        & a {
            color: #000;
            font-size: 20px;
            position: relative;
            border-bottom: 2px solid transparent;
            padding-bottom: 5px;
            &:hover{
                border-bottom: 2px solid #fe8300;
                color: #336d91;
                font-weight: 600;
            }
            &.active{
                border-bottom: 2px solid #fe8300;
                color: #336d91;
                font-weight: 600;
            }
        }
    }
}

.ag_menuSub {
    & li {
        position: relative;
    }
}

.markitPopupBox {
    & .modal-title {
        border-bottom: 3px solid #fe8300;
      & .fontsizemod {
        color: #336d91;
        font-size: 26px;
        line-height: 34px;
        font-weight: 700;
        text-align: left;
        text-transform: none;
      }
    }
    & .contentModal {
        list-style-type: none;
        max-height: 60vh;
        overflow: auto;
        & li {
            margin-bottom: 22px;
            font-size: 20px;
            line-height: 26px;
            text-align: left;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #336d91;
            border-radius: 10px;
        }
        &::-webkit-scrollbar {
            width: 5px;
            border-radius: 10px;
        }
    }
  }

  .markitPopupRecomendBox {
    & .modal-title {
        text-align: left;
        z-index: 3;
    & .fontsizeshastra {
        color: #336d91;
        font-size: 26px;
        line-height: 34px;
        font-weight: 700;
        text-align: left;
        text-transform: none;
        display: inline-block;
        border-bottom: 3px solid #fe8300;
      }
    }
  }

  .recommendModalBox {
    padding-left: 40px !important;
    padding-right: 40px !important;
    & strong {
        font-size: 22px;
        font-weight: 700;
        color: #000000;
    }
    & .ag_ftr_socials {
        & li {
            & a {
                width: 60px;
                display: block;
                & img {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }
    & .dis {
        display: flex;
        margin-top: 15px;
        align-items: center;
        & .ag_newsletter_input {
            width: 100%;
            margin-top: 0;
            padding-right: 15px;
            margin-right: 15px;
            border-radius: 10px;
        }
        & .ag_btn_red {
            height: 50px;
        }
    }
  }

  .profileimgBox { 
    overflow: hidden;
    & .profileimgBoxImg { 
        // display: none;
    }
    & .bgProfile {
        background-size: cover;
        height: 100%;
        width: 100%;
    }
  }

  .imp-info {
    padding: 20px;

    p {
        text-transform: uppercase;
        font-size: 18px;
        font-family: 'Inter';
        color: #167092;
        font-weight: 700;
    }
}

.refundable-price {
    font-weight: 500;
    font-size: 16px;
    padding-left: 20px;
    color: #BF1E2E;
    font-style: italic;
}
.taga.blinkWrap2{ color: #bc1c2b;}
.blinkWrap2 {
    text-decoration: blink;
    animation-name: blinker2;
    animation-duration: 1.5s;
    animation-iteration-count:infinite;
    animation-timing-function:ease-in-out;
    animation-direction: alternate;

    -webkit-animation-name: blinker2;
    -webkit-animation-duration: 1.5s;
    -webkit-animation-iteration-count:infinite;
    -webkit-animation-timing-function:ease-in-out;
    -webkit-animation-direction: alternate;
}
@-webkit-keyframes blinker2 {
    from {opacity: 1.0;}
    to {opacity: 0.0;}
  }
  @keyframes blinker2 {
    from {opacity: 1.0;}
    to {opacity: 0.0;}
  }
.taga.blinkWrap{ color: #bc1c2b;}
.blinkWrap {
    text-decoration: blink;
    animation-name: blinker;
    animation-duration: 2s;
    animation-iteration-count:infinite;
    animation-timing-function:ease-in-out;
    animation-direction: alternate;

    -webkit-animation-name: blinker;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count:infinite;
    -webkit-animation-timing-function:ease-in-out;
    -webkit-animation-direction: alternate;
}
@-webkit-keyframes blinker {
    from {opacity: 1.0;}
    to {opacity: 0.0;}
  }
  @keyframes blinker {
    from {opacity: 1.0;}
    to {opacity: 0.0;}
  }
  
.launchWrap { 
    display: flex; 
    align-items: center; 
    justify-content: space-between; 
    position: relative;
    
    
      .launch-offer{
          
      }
    & .customTool {
        & img.pe-cursor{
            width: 112px;
            opacity: 0;
            height: 24px;
        }
        &::before{
            content: 'Know More';
            position: absolute;
            right: 12px;
            color: #009B94;
            font-weight: 600;
            text-transform: uppercase;
        }
     
    }
}

.siatemapPage { border-top: 4px solid #BF1E2E; padding-top: 76px; }
.sitemapBox {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    & .sitemapListBox {
        & .sitemapList {
            display: block;
            margin-bottom: 60px;
            position: relative;
            z-index: 3;
            & h2 {
                color: #BF1E2E;
                font-size: 24px;
                text-transform: uppercase;
                font-weight: 700;
            }

            & ul {
                list-style-type: none;
                & li {
                    margin: 8px 0;
                    & a {
                        color: #167092;
                        font-size: 20px;
                        font-weight: 700;
                        text-decoration: underline !important;
                        padding: 8px 0;
                        display: block;
                    }
                }
            }
        }
    }
}
.serachPageDiv{
    padding: 120px 108px;
}

.seo-related-tag {
    position: absolute;
    z-index: -5;
    pointer-events: none;
    opacity: 0;
}

.guidelines-save-work {
    text-align: right;
    color: #009B94;
    font-weight: 600;
    line-height: 22px;
    padding-right: 16px;
}

.editorialPage {
    flex-direction: row-reverse;
    & .content-journal {
        padding-right: 50px;
    }
}

.editorialAccordion {
    & .editorialAccordionContent {
        font-size: 18px;
        max-width: 380px;
        & b {
            font-size: 18px;
            color: #000;
            font-weight: bold;
            display: block;
        }
        & span {
            color: #336D91;
            font-weight: 600;
            display: block;
        }
        & p {

        }
    }
    & .accordion-item {
        margin-bottom: 30px;
        box-shadow: 0px 3px 6px #00000029;
        border: 0;
        & .accordion-button {
            font-size: 24px;
            color: #336D91;
            font-weight: bold;
            text-transform: capitalize !important;
            &:focus{
                outline: 0;
                box-shadow: none;
            }
            &:after {
                background-image: url(../img/icons/arrow_gray.svg);
                transform: rotate(90deg);
                transition: all .5s;
                width: 25px;
                background-size: contain;
                margin-top: 10px;
                }
            &.collapsed {

          
                &:after {
                    background-image: url(../img/icons/arrow_gray.svg);
                    transform: rotate(-90deg);
                    transition: all .5s;
                    width: 25px;
                    background-size: contain;
                    margin-top: 0;
                    }
                }
        }
        & .accordion-button:not(.collapsed) {
            background: #ffffff;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 0px 0px 4px 4px;
            position: relative;
            overflow: hidden;
            &::before{
                content: '';
                background: #FF8300;
                bottom: 0;
                width: 100%;
                height: 3px;
                left: 0;
                position: absolute;
            }
        }
    }
}
#refranceEditr{   
    .sun-editor-editable{
        div:hover{
            a{
                color: #ff8300  !important;
            }
            strong{
                color: #ff8300  !important;
            }
        }
    }
    // a:hover {
    //     color: #ff8300  !important;
    // }
    // strong:hover{
    //     color: #ff8300  !important;
    // }
}
.previewMain{
    .contentPreview.References p{
        &.dshighlight{
            background-color: #ff830040;
        }
        margin-bottom: 15px;
        display: list-item;
        list-style: auto;
        margin-left: 20px;
        span:hover{
            color: #FF8300 !important;
            transition: all .3s;
        }
        a:hover{
            color: #FF8300;
            transition: all .3s;
        }
    }
}

/* Safari syntax */
.publiceUiBox.previewBox:-webkit-full-screen {
    background-color: white;
    padding: 50px;
    overflow: auto;
}

/* IE11 syntax */
.publiceUiBox.previewBox:-ms-fullscreen {
    background-color: white;
    padding: 50px;
    overflow: auto;
}

/* Standard syntax */
.publiceUiBox.previewBox .publiseTitle {
    display: none;
}
.publiceUiBox.previewBox:-webkit-full-screen .publiseTitle{
    display: block;
}
.publiceUiBox.previewBox:-ms-fullscreen  .publiseTitle{
    display: block;
}
.publiceUiBox.previewBox:fullscreen .publiseTitle{
    display: block;
}
.publiceUiBox.previewBox:fullscreen {
    background-color: white;
    padding: 50px;
    overflow: auto;
}

.cusSearch input{
    border-radius: 10px;
    border: 0;
    padding: 6px 12px 6px 34px;
    background: #F6F9FB url('../img/icons/search.svg');
    background-position: 10px center;
    background-repeat: no-repeat;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    color: #009B94;
    width: 100%;
    border: 0.5px solid rgba(22, 112, 146, 0.35);
}
.twolinelimit{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
            line-clamp: 4; 
    -webkit-box-orient: vertical;
}
.theme-orange-color{
    color: #FF8300 !important;
}
.sub_headline_allarticle{
    font-size: 49px;
    color: #FF8300;
    font-weight: 700;
    &.withRedtheme{
        font-size: 54px;
        color: #be1e2d;
        line-height: normal;
    }
    &.conferenceStyle {
        font-size: 42px;
        color: #FF8300;
        font-weight: 700;
        line-height: normal;
    }
}
.sub_headline_submission{
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    text-transform: none;
    color: #009B94;
}
.justifyaround{
    justify-content: space-around;
}
.tableadmin{
    border-bottom-width: 1px;
    tr{
        background-color: rgba(45, 51, 141, .03);
    }
    thead th{
        border-bottom: 1px solid hsla(0, 0%, 67%, .3);
        color: #f16136;
        font-size: 13px;
        font-weight: 600;
        border-bottom-color: hsla(0, 0%, 67%, 0.3) !important;
        padding-bottom: 10px;
        text-transform: uppercase;
        white-space: nowrap;
    }
    tbody{
       td{
        border-bottom: 1px solid rgba(45, 51, 141, .25);
        border-top: 1px solid rgba(45, 51, 141, .25);
        color: #000;
        font-size: 15px;
        padding-bottom: 12px;
        padding-top: 12px;
        vertical-align: middle;
       }
    }
    .text-link {
        color: #009b94;
        cursor: pointer;
        text-decoration: none !important;
        vertical-align: middle;
        font-weight: 500;
    }
}
.searchpr{
    background-color: rgba(45, 51, 141, .04);
    border-color: rgba(45, 51, 141, .2);
    height: 36px;
    background-color: #fff;
    border: 1px solid hsla(0, 0%, 60%, .5);
    border-radius: 10px;
    color: #2d338d;
    font-size: 16px;
    font-weight: 600;
    padding: 6px 15px;
    text-decoration: none;
}
.customDrop.sideDrop.dw-auto .dropdown .dropdown-menu {
    width: auto;
}
.addpeerreviews-btn{
    color: #009b94;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    padding: 0px 25px;
    text-transform: uppercase;
    min-width: 160px;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    span.icon{
        font-size: 20px;
        color: #dc3545 !important;
    }
}
.modal-dialog.modal-w-500{
    max-width: 500px;
}
.modal-dialog.modal-w-405{
    max-width: 405px;
}
.fs-14p9{
    font-size: 14.9px !important;
}
.reddot_noti{
    position: absolute;
    background: #be1e2d;
    color: white;
    width: 15px;
    height: 15px;
    /* padding: 7px; */
    font-size: 10px;
    border-radius: 15px;
    text-align: center;
    vertical-align: middle;
    right: -20px;
}
.small-btn-ds{
    font-size: 13px !important; 
    padding: 10px  !important;
}
.inner-pages button.bg-theme-orange{
    background-color: #FF8300  !important;
}
.bulkdealcard .card-body {
    font-size: 40px
}
.bulkdealcard .couponcodebox{
    border: 2px dashed #336d91;
    border-radius: 5px;
    padding: 3px 29px;
    color: #f1613c;
    width: fit-content;
    font-size: 17px;
    margin: 10px 0px;
    font-weight: 600;
}
/****/
.reportBox {
    background-color: rgba(241,97,54, .06);
    padding: 12px 25px;
    border-radius: 10px;
    max-width: 560px;
    min-width: 325px;
    display: inline-table;
    width: 100%;
    border: 1px solid rgba(241,97,54, .4);

    &.limit {
        max-width: 360px;
    }

    & ul {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & li {
            list-style: none;
            padding: 0 15px;
            //border-right: 1px solid rgba($dark, .2);
            //width: 33.3%;
            text-align: center;

            &:last-child {
                padding-right: 0;
                border-right: none;
            }

            &:first-child {
                padding-left: 0;

                & small {
                    font-weight: 700;
                }
            }

            & small {
                display: block;
                font-size: 14px;
                color: #444;
                font-weight: 600;
                line-height: 16px;
                margin-bottom: 0;
                white-space: nowrap;
            }

            & p {
                font-size: 24px;
                line-height: 32px;
                letter-spacing: .5px;
                color: #f16136;
                font-weight: 600;
            }
            & .monthbox{
                font-size: 15px;
                // text-transform: uppercase;
                /* display: block; */
                line-height: 0;
            }
        }
    }
}
.societySubinfostyle{
    font-size: 18px;
    font-weight: 600;
    padding-left: 30px;
    color: #009b94;
    margin: auto;
    span{
        color:#ff8300;
    }
}
.fs-18{
    font-size: 18px !important;
}
.fs-22{
    font-size: 22px !important;
}
.fs-16{
    font-size: 16px !important;
}
.min-h-110{
    min-height: 110px !important;
}
.grammar-large .modal-dialog{
    max-width: 50%;
}
.min-w-auto{
    min-width: auto !important;
}
.grammar-large{
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
    .grammar-item{
        padding: 10px;
        border: 1px solid #0000002e;
        border-radius: 10px;
        margin-bottom: 25px;
        table {
            border-collapse: collapse;
            margin: 15px 0;
            font-size: 16px;
            font-family: Arial, sans-serif;
            width: 100%;
            border: 1px solid #ddd;
        }
          
        table thead tr {
            background-color: #009879;
            color: #ffffff;
            text-align: left;
        }
          
        table th,
        table td {
            padding: 5px 10px;
            border-right: 1px solid #c9c9c9;
        }
          
        table tbody tr {
            border-bottom: 1px solid #ddd;
        }
          
        table tbody tr:nth-of-type(even) {
            background-color: #f3f3f3;
        }
          
        table tbody tr:last-of-type {
            // border-bottom: 2px solid #009879;
        }
          
        table tbody tr:hover {
            background-color: #f1f1f1;
        }
          
        .right-sen{
            background-color: #F5FFF6;
            padding: 5px 10px;
            border: 1.5px solid #75B87A;
            border-radius: 5px;
            color: #04070F;
            text-align: center;
            font-size: 16px;
            span, strong{
                display: inline;
            }
            li{
                list-style: inside;
            }
            p{
                color: #04070F;
            }
        }
        .wrong-sen{
            margin-bottom: 10px;
            background-color: #FFFBFA;
            border: 1.5px solid #F32C05;
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 16px;
            text-align: center;
            color: #F32C05;
            span, strong{
                display: inline;
                font-size: 16px;
            }
            li{
                list-style: inside;
            }
            p{
                color: #F32C05;
            }
           
        }
        p{
            padding: 0;
            font-size: 16px;
            margin: 0;
        }
        .accptbtn{
            color: #56BE04;
            border: 1px solid #ffffff00;
            border-radius: 10px;
            &.active{
                border: 1px solid;
                border-radius: 10px;
                background: #56be0414;
            }
        }
        .rejectbtn{
            color: #F32C05;
            border: 1px solid #ffffff00;
            border-radius: 10px;
            &.active{
                border: 1px solid;
                border-radius: 10px;
                background: #f32c0514;
            }
        }
        .addtodictionarybtn{
            color: #167092;
            border: 1px solid #ffffff00;
            border-radius: 10px;
            &.active{
                border: 1px solid #167092;
                border-radius: 10px;
                background: #04070f14;
            }
        }
    }
    .min-w-50p{
         width: 50%;   
    }
}
.w-25px{
    width: 20px;
    margin-bottom: 3px;
}
.w-30px{
    width: 25px;
    margin-bottom: 3px;
}
.w-35px{
    width: 35px;
    margin-bottom: 3px;
}
.ds-darkblue{
    color:#0b57d0;
}
.mw-120{
    max-width: 120px;
}
.theme-red-color{
    color: #bc1c2b ;
}
.society-newpage{
    .fs-60{
        font-size: 60px;
    }
    .sell-banner-wrp{
        height: calc(100vh - 285px);
        max-height: 262px;
        min-height: 170px;
    }
    .sell-banner{
        height: calc(100vh - 285px);
        max-height: 262px;
        // min-height: 220px;
        min-height: 170px;
    }
    .sell-banner-text{
        transform: translate(-50%, -50%);
        max-height: 262px;
        // min-height: 220px;
        left: 50%;
    }
    .soci-row{
        .col-md-6 {
            &:nth-of-type(odd){
                .soci-item{        
                        margin-right: 0;
                        margin-left: auto;
                }
            }
        }
    }
    .soci-item{
        padding: 20px 15px;
        border-top: 1px solid rgb(178 212 223);
        border-bottom: 1px solid rgb(178 212 223);
        max-width: 550px;
        background-color: #FAFAFA;
        margin-top: -1px;
        .soci-m-img{
           img {
            width: 159px;
            transform: scale(1.3);
            object-fit: cover;
            object-position: left;
            height: 205px;
           }
        }
        .soci-m-content{
            a{
                color: #000;
            }
            h3{
                font-size: 24px;
                color: #000;
                margin-bottom: 25px;
            }
            ul{
                line-height: 2;
                &:first-child{
                    font-weight: 700;
                    padding-right:30px;
                    li{
                        color: #161616;
                    }
                }
                li{
                    font-size: 14px;
                    color: #444444;               
                }
            }
        }
    }
    
}