.button {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    box-shadow: none;
    border: none;
    border-radius: 10px;
    min-width: 60px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    background-color: transparent;
    color: $primary;
    transition: all 350ms ease-in-out;

    @media #{$desktop} {
        padding: 10px 15px;
        font-size: 16px;
        border-radius: 10px;
    }


    &:hover {
        opacity: .85;
    }

    &-sm {
        padding: 8px 12px !important;
        font-size: 14px !important;
        border-radius: 10px !important;

        @media #{$desktop} {
            padding: 10px 18px !important;
            font-size: 16px !important;
            border-radius: 10px !important;
        }

    }



    & b[class^=icon-] {
        font-size: 100%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        font-weight: normal;
        margin-top: 0px;
    }

    &-primary {
        color: $light !important;
        background-color: $primary;

        &:hover {
            color: $light !important;
        }
    }

    &-outline {
        background-color: rgba($primary, .12);
        border: 1px dashed $primary;
    }

    &-white {
        background: $light;
        color: $secondary;

        &:hover {
            background: $secondary;
            color: $light;
        }
    }

    &-ntv {
        color: $dark-900;
    }

}