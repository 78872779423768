.teamSection {
    background-image: url(../../img/bg/vikramshila_university.png);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    
    @media #{$desktop} {
        background-size: auto;
        padding-bottom: 30px;
        background-size: 100% 675px !important;
    }

    & .teamBanner {

        & .containWrap {
            position: relative;
            height: 76px;

            @media #{$desktop} {
                height: 300px;
            }
        }

        & p {
            font-size: 10px;
            font-weight: 400;
            color: $light;
            position: absolute;
            right: 0;
            bottom: 0;
            text-align: left;

            @media #{$desktop} {
                width: 275px;
                font-size: 14px;
                position: absolute;
                right: -104px;
                bottom: -54px;
                line-height: 20px;
                text-align: justify;
            }
        }
    }

    & .teamWrap {
        background-image: url(../../img/bg/team_bg.png);
        background-position: center top;
        background-repeat: no-repeat;
        position: relative;
        z-index: 1;
        background-size: contain;
        margin: 0 -20px;
        padding: 0 20px;
        padding-top: 60px;
        padding-bottom: 20px;

        @media #{$desktop} {
            background-size: auto;
            padding: 0;
            padding-top: 60px;
            padding-bottom: 25px;
            margin: 0 auto;
        }

        & h1 {
            font-size: 26px;
            color: $primary;
            font-weight: 400;
            letter-spacing: -2px;
            position: relative;
            padding-left: 44px;
            margin-bottom: 10px;
            width: 195px;
            margin: 0 auto;

            @media #{$desktop} {
                font-size: 46px;
                padding-left: 90px;
                width: auto;
                margin: 0;
                margin-bottom: 0;
            }

            &::before {
                content: "";
                width: 40px;
                height: 18px;
                background-image: url('../../img/icons/wave.svg');
                background-repeat: no-repeat;
                background-size: contain;
                display: block;
                position: absolute;
                left: 0;
                top: 10px;

                @media #{$tablet} {
                    width: 80px;
                    height: 28px;
                    top: 16px;
                }
            }

            & span {
                font-weight: 600;
                position: relative;
            }
        }
    }

    & .teamIntro {
        padding: 0;
        text-align: justify;
        @media #{$tablet} {
            padding: 0 45px;
            padding-right: 0;
        }

        & h3 {
            font-size: 26px;
            color: $primary;
            font-weight: 400;
            letter-spacing: -2px;
            position: relative;
            padding-left: 42px;
            margin-bottom: 10px;

            @media #{$desktop} {
                font-size: 46px;
                padding-left: 90px;
                margin-bottom: 0;
            }

            &::before {
                content: "";
                width: 40px;
                height: 18px;
                background-image: url('../../img/icons/wave.svg');
                background-repeat: no-repeat;
                background-size: contain;
                display: block;
                position: absolute;
                left: 0;
                top: 10px;

                @media #{$tablet} {
                    width: 80px;
                    height: 28px;
                    top: 16px;
                }
            }

            & span {
                font-weight: 600;
                position: relative;
            }
        }

        &-row {
            padding: 15px 0;
            align-items: center;

            @media #{$desktop} {
                display: flex;
                padding: 25px 0;
            }
        }

        &-left {
            width: 100%;



            @media #{$desktop} {
                padding-right: 80px;
                width: calc(100% - 690px);
            }

            & h4 {
                font-weight: 700;
                font-size: 18px;
                color: $dark;
                padding-bottom: 35px;

                @media #{$desktop} {
                    font-size: 36px;
                    text-align: justify;
                    padding-bottom: 0;
                    font-weight: 800;
                }
            }
        }

        &-right {
            width: 100%;

            @media #{$desktop} {
                width: 680px;
            }

            & h5 {
                color: $primary;
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 15px;
                line-height: 1.5;
                // letter-spacing: .2px;

                @media #{$desktop} {
                    font-size: 20px;
                }
            }

            & p {
                font-size: 16px;
                color: $dark;
                font-weight: 400;
                text-align: justify;

                @media #{$desktop} {
                    font-size: 20px;
                }
            }
        }

    }

    & .songWrap {
        padding: 0 5px;
        position: relative;
        margin-top: 15px;
        text-align: center;

        @media #{$tablet} {
            padding: 0 85px;
            margin-top: 30px;
        }

        & .songLyrics {
            position: relative;
            display: inline-block;

            @media #{$tablet} {
                display: block;
            }

            &:before,
            &:after {
                content: "";
                display: inline-flex;
                background: url(../../img/bg/song.png) no-repeat center center;
                background-size: 25px 25px;
                width: 25px;
                height: 25px;
                position: absolute;

                @media #{$tablet} {
                    background-size: 80px 80px;
                    width: 80px;
                    height: 80px;
                }
            }

            &:before {
                position: absolute;
                left: -24px;
                top: 0;

                @media #{$tablet} {
                    left: -85px;
                    top: -20px;
                }
            }

            &:after {
                position: absolute;
                right: 0;
                bottom: 0;

                @media #{$tablet} {
                    right: -85px;
                    bottom: 0;
                }
            }

            & p {
                font-size: 14px;
                color: $secondary;
                padding: 2px 0;
                font-style: italic;
                text-align: center;
                letter-spacing: -.5px;

                @media #{$tablet} {
                    text-align: left;
                    font-size: 26px;
                    letter-spacing: normal;
                }

                &:nth-child(2) {
                    padding-left: 0;
                    text-align: center;

                    @media #{$tablet} {
                        text-align: left;
                        padding-left: 120px;
                    }
                }

                &:nth-child(3) {
                    padding-right: 0;
                    text-align: center;

                    @media #{$tablet} {
                        padding-right: 120px;
                        text-align: right;
                    }
                }

                &:nth-child(4) {
                    text-align: center;

                    @media #{$tablet} {
                        text-align: right;
                    }
                }
            }
        }

        & small {
            text-align: center;
            display: block;
            font-weight: 700;
            color: $dark-400;
            font-size: 10px;
            font-style: italic;

            @media #{$tablet} {
                text-align: right;
            }

            & b,
            & a {
                color: $secondary;
                text-decoration: none;
            }
        }
    }

    & .quoteWrap {
        text-align: center;
        padding: 20px 0;
        margin: 40px 0;
        background: url(../../img/bg/m_brush.png) no-repeat center center;
        background-size: contain;
        margin-bottom: 0;

        @media #{$tablet} {
            background-size: auto;
            background: url(../../img/bg/brush.png) no-repeat center center;
            margin: 40px 0;

        }

        & h2 {
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            color: $dark;
            margin-bottom: 15px;

            @media #{$tablet} {
                font-size: 24px;
            }
        }

        & p {
            font-size: 14px;
            line-height: 24px;
            color: $dark;
            padding: 0 5px;
            padding-bottom: 10px;
            position: relative;

            @media #{$tablet} {
                font-size: 20px;
                line-height: 36px;
                padding: 0 35px;
                padding-bottom: 10px;
            }

            &:before,
            &:after {
                content: "";
                display: inline-flex;
                background: url(../../img/bg/quote.svg) no-repeat center center;
                background-size: 24px 16px;
                width: 24px;
                height: 16px;
                position: relative;

                @media #{$tablet} {
                    background-size: 60px 40px;
                    width: 60px;
                    height: 40px;
                }
            }

            &:before {
                vertical-align: top;
                margin-right: 5px;
                top: 0;

                @media #{$tablet} {
                    margin-right: 25px;
                    top: -15px;
                }
            }

            &:after {
                vertical-align: bottom;
                margin-right: 5px;
                top: -5px;
                transform: scaleX(-1);

                @media #{$tablet} {
                    margin-right: 25px;
                    top: 0;
                }
            }
        }

        & small {
            font-size: 14px;
            display: block;
            text-align: center;
            padding-bottom: 15px;

            @media #{$tablet} {
                font-size: 20px;
            }
        }
    }

    & .founderIntro {
        padding-top: 30px;
        display: flex;
        flex-wrap: wrap;

        @media #{$desktop} {
            padding-top: 50px;
            flex-wrap: nowrap;
            align-items: flex-end;
            justify-content: center;
        }

        &-left {
            width: 100%;
            order: 1;
            position: relative;

            & img {
                display: block;
            }

            & .nameTag {
                position: absolute;
                left: -20px;
                top: calc(50% + 120px);
                transform: translateY(-50%);
                padding: 15px 40px;
                background-color: $secondary;
                color: $light;
                border-radius: 0 20px 20px 0;
                box-shadow: 3px 4px 4px rgba(64, 64, 64, 0.38);

                @media #{$tablet} {
                    padding: 14px 34px;
                    left: 0;
                }

                & h6 {
                    font-size: 20px;
                    font-weight: 700;
                    margin: 0;

                    @media #{$tablet} {
                        font-size: 28px;
                    }
                }

                & small {
                    font-size: 16px;
                    font-weight: 300;
                    display: block;

                    @media #{$tablet} {
                        font-size: 20px;
                    }
                }
            }

            @media #{$desktop} {
                width: 565px;
                padding-right: 20px;
                order: 1;
            }
        }

        &-right {
            width: 100%;
            order: 2;

            @media #{$desktop} {
                width: 665px;
                order: 2;
            }
        }

        &.right {
            & .nameTag {
                right: -20px;
                left: inherit;
                border-radius: 20px 0 0 20px;
                box-shadow: -3px 4px 4px rgba(64, 64, 64, 0.38);

                @media #{$tablet} {
                    right: 0;
                }
            }

            & .founderIntro-left {
                order: 1;

                @media #{$desktop} {
                    padding-right: 0;
                    padding-left: 20px;
                    order: 2;
                }
            }

            & .founderIntro-right {
                order: 2;

                @media #{$desktop} {
                    order: 1 !important;
                }
            }

            & .founderbox {

                &-name {
                    width: 100%;

                    @media #{$desktop} {
                        max-width: calc(100% - 224px);
                    }
                }

                &-self {
                    width: 100%;

                    @media #{$desktop} {
                        max-width: 224px;
                    }
                }

            }
        }

        & .founderbox {
            padding-top: 25px;

            @media #{$desktop} {
                padding-bottom: 40px;
                padding-top: 0;
            }

            &-info {
                border-bottom: 1px solid $secondary;
                margin-bottom: 20px;

                @media #{$desktop} {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                }
            }

            &-name {
                padding-bottom: 15px;

                @media #{$desktop} {
                    padding-bottom: 10px;
                    max-width: calc(100% - 260px);
                }

                & h6 {
                    font-weight: 800;
                    font-size: 20px;
                    margin-bottom: 8px;
                    color: $secondary;

                    @media #{$desktop} {
                        font-size: 28px;
                    }
                }

                & small {
                    font-size: 14px;
                    font-weight: 500;
                    color: $secondary;
                    display: block;
                    text-align: justify;

                    @media #{$desktop} {
                        font-size: 18px;
                        padding-right: 30px;
                    }
                }
            }

            &-self {
                text-align: left;

                @media #{$desktop} {
                    text-align: right;
                    max-width: 260px;
                }
            }

            &-img {
                display: none;
                vertical-align: bottom;

                & img {
                    display: block;
                }

                @media #{$desktop} {
                    display: inline-flex;
                }
            }

            &-social {
                padding-bottom: 15px;
                display: inline-flex;
                vertical-align: bottom;

                @media #{$desktop} {
                    padding-bottom: 22px;
                }

                & ul {
                    display: flex;
                    justify-content: center;
                    margin: 0;
                    padding: 0;

                    & li {
                        list-style: none;
                        padding: 0 5px;

                        & a {
                            width: 28px;
                            height: 28px;
                            border-radius: 50%;
                            border: 1px solid $primary;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: $primary !important;
                            text-decoration: none;

                            @media #{$desktop} {
                                width: 32px;
                                height: 32px;
                            }

                            &:hover {
                                color: $light !important;
                                background-color: $primary;
                                & b{
                                    & img {
                                        filter: brightness(100);
                                    }
                                }
                            }
                            & b {
                                & img {
                                    margin-top: -4px;
                                 }
                            }
                        }
                    }
                }
            }

            &-bio {
                & p {
                    font-size: 16px;
                    color: $dark;
                    font-weight: 400;
                    text-align: justify;
                    padding-bottom: 16px;

                    @media #{$desktop} {
                        font-size: 18px;
                    }

                    & a {
                        font-weight: 700;
                        color: $secondary;
                        text-decoration: none;
                    }

                    &:last-child {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }

}