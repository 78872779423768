header {
    background-color: $light;
    box-shadow: 0 0 32px rgba(#E5EAFB, 1);
    width: 100%;
    max-width: 1164px;
    margin: 0 auto;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    top: 0; 
    z-index: 3;
    transition: all 350ms ease-in-out;

    @media #{$desktop} {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, 30px);
        border-radius: 20px;
        padding: 5px 20px;
        justify-content: inherit;
    }

    & .menuToggle {
        width: 30px;
        height: 30px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $dark-400;
        font-size: 150%;
        margin-left: -15px;
        position: relative;
        z-index: 1;
        padding: 4px;

        @media #{$desktop} {
            display: none;
        }
    }

    & .cmyLogo {
        max-width: 115px;
        display: block;
        width: 100%;
        @media #{$desktop} {
            max-width: 210px;
        }
        & img {
            max-width: 100%;
            display: block;
        }
    }

    & .navWrap {
        position: fixed;
        left: 0;
        top: 50px;
        width: 100%;
        height: calc(100vh - 50px);
        background-color: $light;
        transform: translateX(-100%);
        opacity: 0;
        transition: all 350ms ease-in-out;

        &.active {
            transform: translateX(0);
            opacity: 1;
        }

        @media #{$desktop} {
            display: flex;
            vertical-align: middle;
            align-items: center;
            justify-content: end;
            width: calc(100% - 200px);
            position: relative;
            height: auto;
            top: 0;
            background-color: transparent;
            transform: translateX(0);
            opacity: 1;

            &.active {
                transform: translateX(0);
                opacity: 1;
            }


        }

        & .navList {
            & ul {
                margin: 0;
                padding: 0;

                @media #{$desktop} {
                    display: flex;
                    align-items: center;
                }

                & li {
                    list-style: none;
                    padding: 30px 30px;
                    border-bottom: 1px solid rgba($dark, .04);

                    @media #{$desktop} {
                        padding: 0 14px;
                        border-bottom: none;

                        &:first-child {
                            padding-left: 0;
                        }

                        &:last-child {
                            //   padding-right: 0;
                        }
                    }


                    & a {
                        text-decoration: none;
                        cursor: pointer;
                        color: $secondary;
                        font-weight: 500;
                        font-size: 16px;

                        &.active {
                            font-weight: 800;
                        }

                        &:hover {
                            opacity: .85;
                            color: $secondary !important;
                        }
                    }
                }
            }


        }
    }

    & .navSign {
        display: flex;
        align-items: center;
        border-left: 1px solid rgba($dark, .08);
        padding: 4px 0;
        padding-right: 128px;

        @media #{$desktop} {
            margin-left: 14px;
            padding-right: 154px;
        }

        & a {
            font-weight: 500;
            font-size: 14px;
            text-decoration: none;
            cursor: pointer;
            display: inline-block;

            @media #{$desktop} {
                font-size: 16px;

            }

            &:hover {
                opacity: .85;
            }
        }

        & .signIn {
            color: $secondary;
            margin: 0 12px;

            @media #{$desktop} {
                margin: 0 24px;
            }

            &.active {
                font-weight: 800;
            }
        }

        & .signUp {
            height: 100%;
            display: flex;
            background-color: $secondary;
            color: $light;
            padding: 10px 15px;
            text-align: center;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 0;
            top: 0;
            border-radius: 10px 0 0 10px;
            @media #{$desktop} {
                border-radius: 20px;
                padding: 20px;
                width: 174px;
            }
        }
    }


    @media #{$desktop} {
        &.fixed {
            position: fixed;
            left: 50%;
            top: 0;
            border-radius: 0;
            transform: translate(-50%, 0);
            width: 100%;
            max-width: 100%;
            margin: 0 auto;

            & .signUp {
                border-radius: 20px 0 0 20px;
            }
            & .navWrap {
                justify-content: center;
            }
        }
    }
}