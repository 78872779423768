@import "./variables.scss";

.success-modal {
  .btnActive, .custom_button {
    background-color: $secondary-color;
    border-radius: 10px !important;
    color: #ffffff;
    padding: 12px 22px 12px 22px;
    border: 1px solid $secondary-color;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }  
}

.register-page, .login-page,.inner-pages { 
  .inputFont {
    font-size: 14px;
  
    @media (max-width: $xl) {
      font-size: 12px;
    }
  }
  
  .headingFont {
    font-weight: 700;
    font-size: 2.5rem;
  
    @media (max-width: $xl) {
      font-size: 2rem;
    }
  }
  
  .subheadingFont {
    font-weight: 700;
    font-size: 2rem;
  
    @media (max-width: $xl) {
      font-size: 1.5rem;
    }
  }
  
  .sectionheadingFont {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5;
  
    @media (max-width: $xl) {
      font-size: 1rem;
    }
  }
  
  .txtRegular {
    font-size: 16px;
    font-weight: 500;
  
    @media (max-width: $xl) {
      font-size: 12px;
    }
  }
  
  .txtSmall {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 113.1% !important;
    color: #404040;
    font-family: 'Open Sans';
  }
  
  .txtTiny {
    font-size: 12px;
  }
}

.txtSmall {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 113.1% !important;
  color: #404040;
  font-family: 'Open Sans';
}

.txtTiny {
  font-size: 12px;
}

.sliderTitle {
  font-weight: 700;
  font-size: 0.8rem;
}

// outer screen typography

.linkFont {
  font-family: 'Inter';
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  color: $secondary-color;
  text-decoration: none;
}

.labelFont {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: $label-color;
}

.labelActive {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: $primary-color;
}

.midHeading {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: $primary-color;
}

.tinyFont {
  font-weight: 400;
  font-size: 12px;
  line-height: 135.96%;
  color: $label-color;
}

.labelColor {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #404040;
  font-family: "Open Sans";
}

.labelBlack {
  font-weight: 400;
  font-size: 14px;
  line-height: 150.68%;
  font-family: "Open Sans";
  color: #000000;
}

.successHeading {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.03em;
}

.articleLabel {
  font-size: 16px;
  color: #9b9595;
}

.formLabel {
  font-size: 14px;
  color: #404040;
  line-height: 18.06px;
}


// final typography

.frameHeading {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: $primary-color;
}

.welcomeTitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #404040;
}
.f-18{
  font-size: 18px !important;
}


.inputField {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #97979B;
  border: 0.5px solid #858585;
  border-radius: 10px;
  padding: 9px 12px 11px 14px;
  background-color: transparent;
}

.select-disable {
  color: #97979B !important;
}

.inputActive {
  color: $primary-color !important;  
}
.reactSelect__control {
  .reactSelect__single-value {
    color: $primary-color !important;      
  }

}
.select-active {
  color: $primary-color !important;  
}
.inputActive {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: $primary-color;
  border: 0.5px solid #858585;
  border-radius: 10px;
  padding: 9px 12px 11px 12px;
  background-color: transparent !important;
}

select{
  border: 0.5px solid #858585 !important;
  border-radius: 10px !important;
  background-color: transparent !important;
}
.select-active  {
  border: 0.5px solid #858585 !important;
  border-radius: 10px !important;
}

.inputLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #404040;
  display: block;
  text-align: left;
  margin-bottom: 6px;
  margin-left: 0.8rem;
}

.btnActive {
  background-color: $secondary-color;
  border-radius: 10px !important;
  color: #ffffff;
  padding: 12px 22px 12px 22px;
  border: 1px solid $secondary-color;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}

.mx-7 {
  margin: 30px 0 36px 0;
}

.mb-36 {
  margin-bottom: 36px;
}

.mr-57{
  margin-right: 57px;
}

.condition {
  padding: 0 0.5rem;
  font-weight: 600 !important;
}

.f-600 {
  font-weight: 600 !important;
}

.px-16 {
  font-size: 16px !important;
}

.f-700 {
  font-weight: 700 !important;
}

.frameNote {
  font-weight: 400;
  font-size: 14px;
  line-height: 135.96%;
  color: #404040;
  font-family: "Open Sans";
}

.f-500 {
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}

.muted-label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 113.1%;
  /* identical to box height, or 16px */  
  color: #404040;
}

.col-pd {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.color-danger {
  color: #BF1E2E;
}

.inputDisable {
  background-color: transparent !important;
  opacity: 0.5 !important;
}

.w-20 {
  width: 20%;
}

.w-80 {
  width: 80%;
}

.width-newlogo {
  width: 450.83px;
}

.text-grey{
  color: #97979B;
}

.option-badge {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #BE1E2D;
  background: #F7E4E6;
  border-radius: 5px;

}
.f-14{
  font-size: 14px !important;
}