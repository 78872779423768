.legalSection {
    background-image: url(../../img/banner/banner_bg.png);
    background-position: 50% 0%;
    background-size: contain;
    background-repeat: no-repeat;
    p {
        text-align: left;
    }
    & .legalWrap {

        & h1 {
            text-align: left;
            font-size: 30px;
            color: $primary;
            font-weight: 600;
            letter-spacing: -2px;
            position: relative;
            margin-bottom: 10px;

            @media #{$desktop} {
                font-size: 46px;
                margin-bottom: 0;
            }

        }
    }

    & .simpleTabs {
        padding-left: 0;

        @media #{$desktop} {
            padding-left: 20px;
        }
    }

    & .searchWrap {
        position: relative;
        margin-bottom: 15px;

        @media #{$desktop} {
            margin-bottom: 0;
        }

        & input {
            padding: 9px 16px;
            border-radius: 10px;
            border: 1px solid #E5E7F3;
            font-size: 16px;
            font-weight: 400;
            color: $dark;
            width: 100%;
            background-color: #E5E7F3;
            padding-left: 40px;
            margin-bottom: 0;

            @media #{$desktop} {
                width: auto;
                padding: 9px 20px;
                padding-left: 40px;
            }
        }

        & b {
            position: absolute;
            left: 12px;
            top: 50%;
            transform: translateY(-50%);
            color: $primary;
            font-size: 18px;
        }
    }

    & .legalList {
        padding: 20px 0;
    }

    & .legalBox {
        padding: 15px 15px;
        background-color: $light;
        border: 1px solid $primary;
        border-radius: 10px;
        margin-top: 15px;

        @media #{$desktop} {
            padding: 22px 30px;
            border-radius: 20px;
        }

        &:first-child {
            margin-top: 0;
        }

        &-head {
            position: relative;
            cursor: pointer;

            & h3 {
                text-align: left;
                font-size: 16px;
                font-weight: 700;
                color: $primary;
                margin: 0;
                padding-right: 45px;

                @media #{$desktop} {
                    font-size: 20px;
                    padding-right: 65px;

                }
            }

            & b {
                position: absolute;
                right:-15px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 22px;
                color: $red;
                font-weight: bold;
                width: 50px;
                height: calc(100% + 30px);
                background-color: $secondary;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;

                @media #{$desktop} {
                    font-size: 40px;
                    right: -30px;
                    width: 70px;
                    height: 70px;
                    border-radius: 20px;

                }
                // &::before{
                //     display: none;
                // }
                // &::after{
                //     content: '';
                //     background: url(../img/);
                // }
            }
        }

        &-body {
            font-size: 14px;
            font-weight: 400;
            color: $dark-400;
            padding-top: 15px;
            display: none;

            @media #{$desktop} {
                font-size: 16px;
                padding-top: 22px;
            }

            & p {
                text-align: left;
                padding-bottom: 15px;

                @media #{$desktop} {
                    padding-bottom: 20px;
                }

                &:last-child {
                    padding-bottom: 10px;
                }
            }
        }

        &.active {

            & .legalBox-head {
                & b {
                    transform: translateY(-50%) rotate(180deg);
                }
            }

            & .legalBox-body {
                //  display: block;
            }
        }
    }
}