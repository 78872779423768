.spinner-container{
    min-height: 100vh;
    max-width: 100%;
    flex: 1 1 auto;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 999999;

        span:nth-child(1) {
            background: #217FE9 !important;
        }
        span:nth-child(2) {
            background: #4BA393 !important;
        }
        span:nth-child(3) {
            background: #96CD53 !important;
        }
        span:nth-child(4) {
            background: #F4B94B !important;
        }
        span:nth-child(5) {
            background: #E47254 !important;
        }
        span:nth-child(6) {
            background: #EA3429 !important;        }
}