.owl-item {
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -10px;
    overflow: visible;  
}
.owl-item:after {
    content: "";
    width: 1px;
    height: 50px;
    background: #ccc;
    position: absolute;
    left: 0;
    top: 15px;
  }
 .owl-item img {
    display: block;
    width: 140% !important
}
 .owl-item .item img {
    max-width: 180px !important;
  }
  .owl-item  {
    padding: 0 10px;
    min-height: 100px !important;
  }

