/** VAR **/
@import "./base/themecomponents/_variablesGlobal.scss";
.pdfMainWrap {
 & sup {
    color: #404040;
    font-size: 10px;
 }
 & .commanPdfHear {
    display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #A7A7A7;
        padding-bottom: 10px;
        & span {
            font-weight: 500;
                font-size: 16px;
                line-height: 12px;
                color: #404040;
        }
        & a {
            font-weight: 500;
            font-size: 14px;
            line-height: 12px;
            color: #167092;
            position: relative;
            padding-left: 15px;
            &::before{
                content: '';
                background: url(../img/icons/url_icon.svg);
                width: 12px;
                height: 11px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
 }
    & .pdfHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & .pdfPublishDetails {
            text-align: right;
            & h4 {
                text-transform: uppercase;
                color: #404040;
                font-weight: 600;
                font-size: 14px;
                line-height: 12px;
            }
            & p {
                font-weight: 400;
                font-size: 14px;
                line-height: 12px;
                color: #404040;
            }
            & a {
                font-weight: 500;
                font-size: 14px;
                line-height: 12px;
                color: #167092;
                position: relative;
                padding-left: 15px;
                &::before{
                    content: '';
                    background: url(../img/icons/url_icon.svg);
                    width: 12px;
                    height: 11px;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
    & .pdfIcko {
        background: rgba(0, 155, 148, 0.06);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        & ul {
            padding-left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            list-style-type: none;
            & li {
                font-weight: 400;
                font-size: 14px;
                line-height: 12px;
                text-transform: uppercase;
                color: #404040;
                margin-left: 14px;
                padding-right: 14px;
                border-right: 0.5px solid #A7A7A7;
                &:last-child{
                    border-right: 0;
                }
            }
        }
    }
    & .pdfContentWrap {
        & h2{
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
        }
        & h3 {
            font-weight: 600;
            font-size: 20px;
            color: #FF8300;
            & b {
                font-weight: 600;
                font-size: 13px;
                line-height: 11px;
                background: #009B94;
                border-radius: 4px;
                color: #ffffff;
                padding: 2px 5px;
                margin-right: 6px;
            }
        }
        & h4 {
            font-weight: 400;
            font-size: 16px; 
            text-transform: capitalize;            
            color: #404040;
        }
    }
    & .pdfDocName {
        & span {
            display: inline-flex;
            align-items: center;
          
            padding-right: 8px;
            margin-right: 8px;
            position: relative;
            &::before{
                content: '';
                background: #A7A7A7;
                width: 1px;
                height: 14px;
                position: absolute;
                right: 0;
            }
            &:last-child {
                &::before{
                    display: none;
                }
            }
            & b {
                background: url(../img/icons/id.svg);
                width: 19px;
                height: 19px;
                margin-left: 5px;
                display: inline-block;
            }
        }
    }
    & .departmentName {
        & span {
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            color: #000000;
        }
    }
    & .contentPdf {
        margin-bottom: 14px;
        & h2 {
            font-weight: 600;
            font-size: 16px;
            line-height: 15px; 
            color: #FF8300;
        }
        & p {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px; 
            color: #000000;
        }
    }
    & .addsPdf {
        background: rgba(0, 155, 148, 0.04);
        border-top: 1px solid rgba(0, 155, 148, 0.3);
        border-bottom: 1px solid rgba(0, 155, 148, 0.3);
        text-align: center;
        padding: 20px;
        margin: 20px 0 0 0;
    }
    & .pdfImgWrap {
        border: 0.5px solid #404040;
        border-radius: 4px;
        padding: 15px 30px;
        & figure {
            margin: 0 auto;
            display: block;
            text-align: center;
        }
    }
    & .contentFigure {
        display: block;
        margin-bottom: 10px;
        & span{
            width: 75px;
            vertical-align: middle;
            display: inline-block;
            color: #FF8300;
            font-weight: 600;
        }
        & p {
            display: inline-block;
            vertical-align: middle;
        }
    }
    & .firstHeader {
        padding: 10px 0;
        display: flex;
        align-items: end;
        justify-content: space-between;
        & p{
            font-weight: 400;
            font-size: 16px;
            line-height: 22px; 
            color: #000000;
            & a {
                color: #FF8300;
            }
            & span{
                display: inline-block;
                border-right: 1px solid #A7A7A7;
                line-height: 14px;
                margin-right: 10px;
                padding-right: 10px;
                &:last-child{
                    border: 0;
                }
            }
        }
    }
}