@import "../base/themecomponents/_variables.scss";
.aboutSection {
    background-image: url(../../img/banner/banner_bg.png);
    background-position: 50% 0%;
    background-size: contain;
    background-repeat: no-repeat;
    &::after{
        content: '';
        background: url(../../img/bg/about_bg01.svg);
        height: 400px;
        width: 100%;
        display: inline-block;
        position: absolute;
        top: 40%;
        background-size: cover;
        background-position: bottom center;
        z-index: -1;
    }
    &::before{
        content: '';
        background: url(../../img/bg/about_bg02.svg);
        height: 500px;
        width: 100%;
        display: inline-block;
        position: absolute;
        top: 60%;
        background-size: cover;
        background-position: center;
        z-index: -1;
    }

    & .aboutWrap {
        padding-bottom: 35px;
         position: relative;

        &:last-child {
            padding-bottom: 20px;
        }

        @media #{$desktop} {
            padding-bottom: 55px;
        }

        & .pullUp {
            position: absolute !important;
        }
    }

    & .divideWrap {
        background-image: url(../../img/bg/border.svg);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        height: 14px;
        width: 100%;
        max-width: 700px;
        margin: 0 auto;
        margin-bottom: 35px;
    }

    & h1,
    & h2 {
        font-size: 30px;
        color: $primary;
        font-weight: 400;
        margin-bottom: 10px;
        letter-spacing: -2px;
        position: relative;
        padding-top: 22px;

        @media #{$desktop} {
            font-size: 46px;
            padding-top: 28px;

        }

        &::before {
            content: "";
            width: 60px;
            height: 22px;
            background-image: url('../../img/icons/wave.svg');
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            position: absolute;
            left: 0;
            top: 0;

            @media #{$tablet} {
                width: 80px;
                height: 28px;
            }
        }



        & span {
            font-weight: 600;
            position: relative;

            /*&:after,
            &:before {
                content: "";
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background: $red;
                position: absolute;
                display: block;
                //animation: none;

                @media #{$desktop} {
                    width: 8px;
                    height: 8px;
                }
            }

            &:before {
                left: 46px;
                top: 12px;

                @media #{$desktop} {
                    left: 46px;
                    top: 0;
                }
            }

            &:after {
                left: 46px;
                top: 12px;

                @media #{$desktop} {
                    left: 108px;
                    top: 0;
                }
            } */

        }
    }



    & p {
        font-size: 16px;
        font-weight: 400;
        color: $dark-400;
        padding: 10px 0;
        line-height: 1.4;
        text-align: justify;
        &:last-child {
            padding-bottom: 0;
        }

        @media #{$desktop} {
            font-size: 22px;
            line-height: 30px;
            
            padding: 14px 0;
        }

        &.type {
            text-align: justify;
            padding: 20px 0;
            margin: 0 auto;
            font-size: 18px;
            color: $primary;

            @media #{$desktop} {
                // width: 85%;
                padding: 30px 0;
                font-size: 26px;
                text-align: left;
            }
        }
    }

    & h1+p,
    & h2+p {
        font-weight: 700;
        text-align: left;
        color: $primary;
    }

    & .quoteWrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;

        @media #{$desktop} {
            flex-wrap: nowrap;
        }

        &-main {
            padding: 30px 15px;
            max-width: 420px;

            @media #{$desktop} {
                padding: 14px 60px;
            }

            &.rightNill {
                padding-right: 0;
            }

            &.leftNill {
                padding-left: 0;
            }

            & p {
                font-size: 16px;
                color: $dark;
                font-weight: 500;
                font-style: italic;
                text-align: center;
                line-height: normal;
            }
        }

    }


    & .quoteBox {
        position: relative;

        & p {
            z-index: 1;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            padding: 30px;
        }

        &:after,
        &:before {
            content: "";
            display: block;
        }

        &:before {
            width: 288px;
            height: 306px;
            border-radius: 8px;
            background-color: #FFC20F;
        }

        &:after {
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;

            background-image: url(../../img/bg/border.svg);
            background-position: 50% 50%;
            background-repeat: no-repeat;
        }

        &.big {
            & p {
                padding-left: 60px;
                font-size: 17px;
                margin-top: 10px;

            }

            &:before {
                width: 288px;
                height: 306px;
                transform: translate(0, 20px);
            }

            &:after {
                width: 120%;
                background-image: url(../../img/bg/big_frame.svg);
            }

        }


        &.small {
            & p {
                padding-left: 50px;
                padding-right: 15px;
                margin-top: 10px;
            }

            &:before {
                width: 268px;
                height: 178px;
                transform: translate(0px, 20px);
            }

            &:after {
                width: 120%;
                background-image: url(../../img/bg/small_frame.svg);
            }

        }
    }
}
.dots{
    position: relative;
    font-style: normal;
    &::before {
        content: "";
        position: absolute;
        left: 1px;
        top: 2.5px;
        width: 3px;
        height: 3px;
        background-color: #BE1E2D;
        border-radius: 50%;
        @media #{$desktop} {
            left: 3.5px;
            top: 1px;
            width: 6px;
            height: 6px;
        }
        
    }
}
.aboutDots {
    .dots{
        &::before {
            top: 6px;
            left: 2.5px;
            width: 4px;
            height: 4px;
            @media #{$desktop} {
                top: 10px;
                left: 3.5px;
            }
        }
    }
}