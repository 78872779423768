.bannerLoad {
    height: 100vh;
    background-color: #F7F9FD;
    position: relative;
    background-image: url(../../img/banner/banner_bg.png);
    animation: bgMove 1400ms ease-in-out forwards;
    animation-delay: 200ms;
    transition: all 350ms ease-in-out;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 800% center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;

    //display: none;
    @keyframes bgMove {
        from {
            background-position: 800% center;
        }

        to {
            background-position: 50% center;
        }
    }

    @media #{$desktop} {
        height: 100vh;
    }

    & .bannerItem {
        text-align: center;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        &-text {
            max-width: 1164px;

            & h2 {
                font-size: 38px;
                font-weight: 600;
                color: $primary;
                letter-spacing: -1px;
                line-height: 1.37;
                padding: 0 15px;
                animation: textMove 1000ms ease-in-out forwards;
                animation-delay: 1500ms;
                transition: all 350ms ease-in-out;
                opacity: 0;
                transform: translateX(100px);

                & span {
                    text-transform: uppercase;
                }

                @media #{$desktop} {
                    font-size: 86px;
                    padding: 0 20px;
                    letter-spacing: -5px;
                }


                & .underLine {
                    position: relative;
                    display: inline-block;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: 6px;
                        width: 104%;
                        height: 14px;
                        //background: linear-gradient(90deg, rgba(227, 232, 123, 0.0) 0%, rgba(255, 176, 27, 1) 50%, rgba(227, 232, 123, 0.0) 100%);
                        background: linear-gradient(270deg, rgba(227, 232, 123, 0) 0%, rgba(227, 232, 123, 0.5) 25%, rgba(255, 185, 54, 0.7) 50%, rgba(227, 232, 123, 0.5) 75%, rgba(227, 232, 123, 0) 100%);
                        display: block;
                        z-index: -1;
                        margin: 0 -2%;
                        animation: colorMove 6600ms ease-in-out infinite;
                        background-size: 300% auto;

                        @media #{$desktop} {
                            height: 22px;
                            bottom: 20px;
                        }
                    }
                }


                @keyframes colorMove {
                    to {
                        background-position: 300% center;
                    }
                }

            }

            & h3 {
                font-size: 34px;
                font-weight: 700;
                color: $primary;
                letter-spacing: -1px;
                padding: 50px 15px;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                justify-content: center;
                animation: textMove 1000ms ease-in-out forwards;
                animation-delay: 1600ms;
                transition: all 350ms ease-in-out;
                opacity: 0;
                transform: translateX(100px);

                @media #{$desktop} {
                    font-size: 76px;
                    padding: 50px 0;
                    letter-spacing: -5px;
                }

                & span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                & b {
                    width: 10px;
                    height: 10px;
                    background-color: $red;
                    border-radius: 50%;
                    display: inline-block;
                    margin: 0 10px;

                    @media #{$desktop} {
                        width: 20px;
                        height: 20px;
                        margin: 0 15px;
                    }
                }

                &.changeText {
                    & span {
                        animation: gradientMove 3600ms ease-in-out infinite;

                    }

                    &.ani_01 {

                        & span {
                            background: $green-dark;
                            background: linear-gradient(to right, $green-dark 25%, #2C3394 50%, $green-dark 75%);
                            background-size: 200% auto;
                            background-clip: text;
                            text-fill-color: transparent;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }

                }
            }


            @keyframes gradientMove {
                to {
                    background-position: 200% center;
                }
            }

            @keyframes textMove {
                from {
                    opacity: 0;
                    transform: translateX(100px);
                }

                to {
                    opacity: 1;
                    transform: translateX(0);
                }
            }

        }


    }
    & .bannerSkip{
        position: absolute;
        right: 50px;
        bottom: 30px;
        font-size: 20px;
        line-height: 19px;
        color: #009B94;
        z-index: 99999;
        cursor: pointer;
        text-transform: uppercase;
        text-decoration: underline;
    }
}


.homeBanner {
    // height: 100vh;
    //background-color: #F7F9FD;
    position: relative;
    background-image: url(../../img/banner/banner_bg.png);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @media #{$desktop} {
        // padding-top: 150px;
    }

    & .bannerItem {
        text-align: center;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        &-text {
            max-width: 1164px;

            & h2 {
                font-size: 38px;
                font-weight: 600;
                color: $primary;
                letter-spacing: -3px;
                line-height: 1.37;
                padding: 0 15px;

                @media #{$desktop} {
                    font-size: 84px;
                    padding: 0;
                }

                & .changeText {
                    display: inline-block;

                    & b {
                        font-weight: 700;
                        animation: gradientMove 3600ms ease-in-out infinite;

                        &.ani_01 {
                            background: $green-dark;
                            background: linear-gradient(to right, $green-dark 0%, $blue-light 50%, $green-dark 100%);
                            background-size: 200% auto;
                            background-clip: text;
                            text-fill-color: transparent;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }

                        &.ani_02 {
                            background: $green-dark;
                            background: linear-gradient(to right, #0083C6 0%, #FFFC00 50%, #0083C6 100%);
                            background-size: 200% auto;
                            background-clip: text;
                            text-fill-color: transparent;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }

                        &.ani_03 {
                            background: $green-dark;
                            background: linear-gradient(to right, #BE1E2D 0%, #FFC250 50%, #BE1E2D 100%);
                            background-size: 200% auto;
                            background-clip: text;
                            text-fill-color: transparent;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }


                }
            }

            & h3 {
                font-size: 18px;
                font-weight: 600;
                color: $primary;
                letter-spacing: -1px;
                padding: 40px 15px;

                @media #{$desktop} {
                    font-size: 52px;
                    padding: 40px 0;
                }
            }

            & a {
                padding: 14px 25px;
                box-shadow: 0px 0px 22px rgba($dark, 0.15);
                background-color: $light;
                color: $secondary;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 24px;
                text-align: center;
                text-decoration: none;
                cursor: pointer;
                display: inline-block;
                margin-top: 15px;
                border-radius: 10px;
                position: relative;

                @media #{$desktop} {
                    font-size: 60px;
                    padding: 15px 30px;
                    margin-top: 20px;
                    border-radius: 20px;
                    max-width: 728px;
                    // width: 100%;
                }

                &:hover {
                    box-shadow: none;
                    background-color: $secondary;
                    color: $light;
                }

                &:after {
                    content: "";
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background-color: $red;
                    position: absolute;
                    left: 58.5px;
                    top: 16px;
                    display: block;

                    @media #{$desktop} {
                        left: 115.5px;
                        top: 22px;
                        width: 9px;
                        height: 9px;
                    }
                }
            }

        }


    }
}
.stepBtn {
    & a {
        & span {
            position: relative;
            text-transform: uppercase;
            display: inline-block;
            &:after{
                content: "";
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: #BE1E2D;
                position: absolute;
                left: 1.5px;
                top: 3px;
                display: block;
            }
        }
       
    }
    & .dots {
        &:before{
            @media #{$mobile-only} {
                display: none;
            }
        }
    }
    
}
.bannerSlider {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 12rem 0 10rem 0;


    & .slick-list {
        width: 100%;
        height: 100%;

        & .slick-track {
            height: 100%;

            & .slick-slide {
                display: flex;
                justify-content: center;
                width: 100%;
                margin: 0 auto;
            }

        }
    }
}

.helpLink {
    position: fixed;
    right: 20px;
    bottom: 20px;
    padding: 10px 20px;
    background-color: rgb(45, 51, 141);
    color: $light;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    border-radius: 4px 10px 10px 10px;
    text-transform: uppercase;
    box-shadow: 0 0 16px rgba($dark, 0.14);
    cursor: pointer;
    text-decoration: none;
    z-index: 9;

    @media #{$desktop} {
        border-radius: 4px 14px 14px 14px;
        right: 60px;
        bottom: 20px;
        font-size: 18px;
        padding: 12px 20px;
    }

    & b[class^=icon-] {
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        margin-top: -3px;
    }

    &:hover {
        background-color: rgba($secondary, 0.05);
        color: rgb(45, 51, 141);
        box-shadow: none;
    }

}




@keyframes gradientMove {
    to {
        background-position: 200% center;
    }
}

.typed-cursor {
    opacity: 1;
    font-weight: 500;
    // color: $dark;
}

.typed-cursor {
    animation: typedjsBlink 0.7s infinite;
    -webkit-animation: typedjsBlink 0.7s infinite;
    animation: typedjsBlink 0.7s infinite;
}

@keyframes typedjsBlink {
    50% {
        opacity: 0.0;
    }
}

@-webkit-keyframes typedjsBlink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.0;
    }

    100% {
        opacity: 1;
    }
}